import { IPanelMenuSection } from "@core/_interfaces/ComponentProps";

export class SaturnGlobalPlugin {
    selectedStore: any;
    dealNumber: string = null;
    isAdminView: boolean = false;
    isManageOverridesEnabled: boolean = false;
    customerWindow: any = null;
    maxMilesToBeConsideredNewCar: any = null;
    Lenders: any[];
    r1CreditAppField: any = null;

    constructor(init?: SaturnGlobalPlugin) {
        if (init) {
            Object.assign(this, init);
        }
    }
}

export class StaticViewsPlugin {
    adminPages: IPanelMenuSection[];
    utilitiesPages: IPanelMenuSection[];
    reportPages: IPanelMenuSection[];

    constructor(init?: StaticViewsPlugin) {
        if (init) {
            this.adminPages = init.adminPages;
            this.utilitiesPages = init.utilitiesPages;
            this.reportPages = init.reportPages;
        }
    }
}
