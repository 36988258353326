<template>
    <div v-bind="panelObject" class="PanelTradeInVehicle-container" :style="$grid('1')">
        <Panel v-bind="panelObject" class="PanelTradeInVehicle-container" title="Vehicle Information">
            <template #header>
                <img v-if="vinLookupBusy" class="loading-anim" src="@static/img/SaturnLogoAnim.svg" style="width: 40px; margin-right:10px" />
            </template>
            <div class="control-header" :style="$grid('6-1')">
                <div class="trade-in-type">
                    <fieldset class="tradeIn-type" :invalid="v$.vehicleInfo.type.$invalid">
                        <InputRadio label="Private Trade" :valueMatch="0" v-model="vehicleInfo.type" @change="tradeInTypeChanged" :isButton="true"></InputRadio>
                        <InputRadio label="Lease Trade-In" :valueMatch="1" v-model="vehicleInfo.type" @change="tradeInTypeChanged" :isButton="true"></InputRadio>
                        <InputRadio label="Lease Walk Away" :valueMatch="2" v-model="vehicleInfo.type" @change="tradeInTypeChanged" :isButton="true"></InputRadio>
                    </fieldset>
                </div>
                <div :style="$grid('1')" v-if="plateTransactionsEnabled" class="ignore-deal-lock">
                    <button @click="handleOpenDDiResponseModal" :disabled="loadingOpenInquiryModal">
                        <i v-if=" loadingOpenInquiryModal" class="fas fa-spinner fa-spin" />
                        <span v-else>View Vehicle Inquiry</span>
                    </button>
                </div>
            </div>


            <!--<Panel title="Trade-In Information">-->
            <fieldset class="vehicle-info" ref="fieldset">
                <div :style="$grid('2-1-1-1')">
                    <InputTextbox label="VIN"
                                  :maxlength="17"
                                  v-model:saturn="vehicleInfo.vin"
                                  :invalid="v$.vehicleInfo.vin.$invalid"
                                  placeholder="VIN"
                                  @blur="handleOnBlurVin"
                                  icon="fas fa-search"
                                  :disabled="data.isDealPartiallyLockedBecauseWeSentACreditApp()"
                                  >
                        <template #validation>
                            <div v-if="v$.vehicleInfo.vin.required.$invalid">Vin is required</div>
                            <div v-if="v$.vehicleInfo.vin.validVIN.$invalid">Vin is invalid</div>
                            <div v-if="v$.vehicleInfo.vin.isVinUsed.$invalid">Vin is already being used in this deal</div>
                        </template>
                    </InputTextbox>
                    <InputNumber label="Odometer"
                                 v-model:saturn="vehicleInfo.mileage"
                                 :invalid="v$.vehicleInfo.mileage.$invalid"
                                 placeholder="Miles"
                                 :precision="0">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.mileage.required.$invalid">Odometer is required</div>
                            <div v-if="v$.vehicleInfo.mileage.minValue.$invalid">Odometer value must be at least 1</div>
                        </template>
                    </InputNumber>
                    <InputTextbox label="Year"
                                  v-model:saturn="vehicleInfo.modelYear"
                                  :invalid="v$.vehicleInfo.modelYear.$invalid"
                                  placeholder="Year"
                                  class="glow">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.modelYear.required.$invalid">Year is required</div>
                        </template>
                    </InputTextbox>
                    <InputTextbox label="Make"
                                  v-model:saturn="vehicleInfo.make"
                                  :invalid="v$.vehicleInfo.make.$invalid"
                                  placeholder="Make"
                                  class="glow">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.make.required.$invalid">Make is required</div>
                        </template>
                    </InputTextbox>
                </div>

                <div :style="$grid('4-3-4-2-2')">
                    <InputTextbox label="Model"
                                  v-model:saturn="vehicleInfo.model"
                                  :invalid="v$.vehicleInfo.model.$invalid"
                                  placeholder="Model"
                                  class="glow">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.model.required.$invalid">Model is required</div>
                        </template>
                    </InputTextbox>
                    <InputTextbox label="Style"
                                  v-model:saturn="vehicleInfo.style"
                                  placeholder="Style"
                                  :invalid="v$.vehicleInfo.style.$invalid">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.style.required.$invalid">Style is required</div>
                        </template>
                    </InputTextbox>
                    <InputTextbox label="Color"
                                  v-model:saturn="vehicleInfo.exteriorColor"
                                  :invalid="v$.vehicleInfo.exteriorColor.$invalid"
                                  placeholder="Color">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.exteriorColor.required.$invalid">Exterior Color is required</div>
                        </template>
                    </InputTextbox>
                    <InputTextbox label="Plate Number"
                                  v-model:saturn="vehicleInfo.plateNumber"
                                  :invalid="v$.vehicleInfo.plateNumber.$invalid"
                                  placeholder="Plate Number">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.plateNumber.required?.$invalid">plate Number is required</div>
                        </template>
                    </InputTextbox>
                    <InputRichDropdown label="Plate State"
                                       v-model:saturn="vehicleInfo.plateState"
                                       :invalid="v$.vehicleInfo.plateState.$invalid"
                                       :list="ENUMS.states"
                                       :valueMap="(p) => p.code"
                                       :display="(p) => p.description"
                                       search>
                        <template #validation>
                            <div v-if="v$.vehicleInfo.plateState.required?.$invalid">State is required</div>
                        </template>
                    </InputRichDropdown>
                    <!--
                    <InputCurrency v-if="!isLeaseWalkAway && !isSalesPersonAndNotSalesManager"
                                label="Trade Allowance"
                                v-model:saturn="vehicleInfo.allowance"
                                :invalid="v$.vehicleInfo.allowance.$invalid"
                                placeholder="Trade Allowance"
                                :precision="0">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.allowance.required.$invalid">Allowance is required</div>
                        </template>
                    </InputCurrency>
                    <InputCurrency v-if="!isLeaseWalkAway && !isSalesPersonAndNotSalesManager"
                                label="Actual Cash Value"
                                v-model:saturn="vehicleInfo.actualCashValue"
                                :invalid="v$.vehicleInfo.actualCashValue.$invalid"
                                placeholder="Actual Cash Value"
                                :precision="0">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.actualCashValue.required.$invalid">Actual Cash Value is required</div>
                        </template>
                    </InputCurrency>
                    -->
                    <InputRichDropdown v-if="isLeaseWalkAway"
                                       label="Send Payment To"
                                       v-model:saturn="vehicleInfo.walkAwayInfo.walkAwayPayee"
                                       :list="walkAwayPayees"
                                       :valueMap="(o) => o.value"
                                       :display="(o) => o.text"
                                          search>
                    </InputRichDropdown>
                </div>
                <div :style="$grid('1-1-2-2')">
                    <InputTextbox label="Title Number"
                                  v-model:saturn="vehicleInfo.titleNumber"
                                  :invalid="v$.vehicleInfo.titleNumber.$invalid"
                                  placeholder="Title Number">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.titleNumber.required?.$invalid">Title Number is required</div>
                        </template>
                    </InputTextbox>
                    <InputRichDropdown label="Title State"
                                       v-model:saturn="vehicleInfo.titleState"
                                       :invalid="v$.vehicleInfo.titleState.$invalid"
                                       :list="ENUMS.states"
                                       :valueMap="(p) => p.code"
                                       :display="(p) => p.description"
                                       search>
                        <template #validation>
                            <div v-if="v$.vehicleInfo.titleState.required?.$invalid">State is required</div>
                        </template>
                    </InputRichDropdown>

                    <InputRichDropdown label="Mileage Status"
                                       v-model:saturn="vehicleInfo.mileageStatus"
                                       :list="ENUMS.VEHICLE_MILEAGE_STATUS.toList()"
                                       class="glow"
                                       :valueMap="(status) => status.value"
                                       :display="(status) => status.display"
                                       :invalid="v$.vehicleInfo.mileageStatus.$invalid"
                                       nothingSelected="-- Select Value --">
                        <template #validation>
                            <div v-if="v$.vehicleInfo.mileageStatus.required.$invalid">
                                Mileage Status is required
                            </div>
                        </template>
                    </InputRichDropdown>

                    <div class="radio-button-inline no-padding-grid"
                         :invalid="v$.vehicleInfo.hasFiveDigitOdometer.$invalid">
                        <span>Five Digit Odometer?</span>
                        <div class="no-padding-grid gap">
                            <InputRadio label="Yes"
                                        :valueMatch="true"
                                        v-model="vehicleInfo.hasFiveDigitOdometer"
                                        :isButton="true"></InputRadio>
                            <InputRadio label="No"
                                        :valueMatch="false"
                                        v-model="vehicleInfo.hasFiveDigitOdometer"
                                        :isButton="true"></InputRadio>
                        </div>
                    </div>

                </div>
            </fieldset>
        </Panel>
        <div v-if="isLeaseWalkAway" :style="$grid('1')">
            <panel title="Lessor Information">
                <div class="owners-info-panel">
                    <div :style="$grid('6-1')" :class="{'valid-owner': true, 'invalid-owner': v$.vehicleInfo.walkAwayInfo.$invalid}">
                        <span class="owner-name" :invalid="v$.vehicleInfo.walkAwayInfo.$invalid">{{ vehicleInfo.walkAwayInfo.lienName ? vehicleInfo.walkAwayInfo.lienName : "Lessor Name" }}</span>
                        <div :style="$grid('1-1')" class="no-margin-grid">
                            <div></div>
                            <button class="button-accept" @click="openModalTradeInLien()">Edit</button>
                        </div>
                    </div>
                </div>
            </panel>
        </div>
        <div v-else>

            <panel title="Lien(s) Information">
                <div class="owners-info-panel">
                    <div v-for="(lien, $index) in vehicleInfo.liens" :key="$index">
                        <div :style="$grid('6-1')" :class="{'valid-owner': true, 'invalid-owner': v$.vehicleInfo.liens[$index].$invalid}">
                            <span class="owner-name" :invalid="v$.vehicleInfo.liens[$index].$invalid">{{ getLienName(lien, $index) }}</span>
                            <div :style="$grid('3-1')" class="no-margin-grid">
                                <button class="button-accept" @click="openModalTradeInLien($index)">Edit</button>
                                <button class="button-unwind" @click="confirmLienDeletion($index)" ><i class="fas fa-trash"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="radio-button-inline" :invalid="v$.vehicleInfo.shouldWeAddAnotherLien.$invalid" :style="$grid('4-.6-.8-4')">
                        <span>Is there {{ vehicleInfo.liens && vehicleInfo.liens.length > 0 ? 'another' : 'a' }} lien on this Vehicle?</span>
                        <InputRadio label="Yes" @click="shouldWeAddAnotherLienChanged" :isButton="true"></InputRadio>
                        <InputRadio label="No" :valueMatch="false" v-model="vehicleInfo.shouldWeAddAnotherLien" :isButton="true"></InputRadio>
                        <div></div>
                    </div>
                </div>
            </panel>
        </div>

        <!--</Panel>-->

        <panel title="Owner(s) Information">
            <!--<div class="radio-button-inline" :invalid="v$.vehicleInfo.customersAsOwnerInfo.$invalid" :style="$grid('4-.6-.8-4')">
                <span>Owner Information is the same as Customer Information?</span>
                <InputRadio label="Yes" :valueMatch="true" v-model="vehicleInfo.customersAsOwnerInfo" @change="customerIsOwnerChanged" :isButton="true"></InputRadio>
                <InputRadio label="No" :valueMatch="false" v-model="vehicleInfo.customersAsOwnerInfo" @change="customerIsOwnerChanged" :isButton="true"></InputRadio>
            </div>-->
            <fieldset :disabled="vehicleInfo.customersAsOwnerInfo" class="PanelTradeInVehicle-panel-container">
                <div class="owners-info-panel" v-if="vehicleInfo.customersAsOwnerInfo == false">
                    <div v-for="(owner, $index) in vehicleInfo.owners" :key="$index">
                        <div :style="$grid('6-1')" :class="{'valid-owner': true, 'invalid-owner': v$.vehicleInfo.owners[$index].$invalid}">
                            <span class="owner-name" :invalid="v$.vehicleInfo.owners[$index].$invalid">{{ getOwnerName(owner, $index) }}</span>
                            <div :style="$grid('3-1')" class="no-margin-grid trade-in-owner">
                                <!-- <button class="button-accept" @click="openModalTradeInOwner($index)">Edit</button> -->
                                <button class="button-accept" @click="() => handleRetrieveCustomer($index)">Retrieve Customer</button>
                                <button :disabled="vehicleInfo.owners.length == 1" class="button-unwind" @click="confirmOwnerDeletion($index)"><i class="fas fa-trash"></i></button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="radio-button-inline" :invalid="v$.vehicleInfo.shouldWeAddAnotherOwner.$invalid" :style="$grid('4-.6-.8-4')">
                            <span>Is there another Owner for this Vehicle?</span>
                            <InputRadio label="Yes" :valueMatch="null" v-model="vehicleInfo.shouldWeAddAnotherOwner" @click="(e) => shouldWeAddAnotherOwnerChanged(true)" :isButton="true"></InputRadio>
                            <InputRadio label="No" :valueMatch="false" v-model="vehicleInfo.shouldWeAddAnotherOwner" :isButton="true"></InputRadio>
                            <div></div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </panel>

        <Panel title="Other Information">
            <div :style="$grid('1-1-1-1')">
                <InputCheckbox label="Assembled from Parts" v-model="vehicleInfo.tradeInExtra.isAssembledFromParts"> </InputCheckbox>
                <InputCheckbox label="Bonded Title" v-model="vehicleInfo.tradeInExtra.isBondedTitle"></InputCheckbox>
                <InputCheckbox label="Rebuild" v-model="vehicleInfo.tradeInExtra.isRebuild"></InputCheckbox>
                <InputCheckbox label="Kit Car" v-model="vehicleInfo.tradeInExtra.isKitCar"></InputCheckbox>
                <InputCheckbox label="Police Vehicle" v-model="vehicleInfo.tradeInExtra.isPoliceVehicle"></InputCheckbox>
                <InputCheckbox label="Glider Kit" v-model="vehicleInfo.tradeInExtra.isGliderKit"></InputCheckbox>
                <InputCheckbox label="Private Use" v-model="vehicleInfo.tradeInExtra.isPrivateUse"></InputCheckbox>
                <InputCheckbox label="Manufacturer Buy Back" v-model="vehicleInfo.tradeInExtra.isManufacturerBuyBack"></InputCheckbox>
                <InputCheckbox label="Taxi Cab" v-model="vehicleInfo.tradeInExtra.isTaxiCab"></InputCheckbox>
                <InputCheckbox label="Replica" v-model="vehicleInfo.tradeInExtra.isReplica"></InputCheckbox>
                <InputCheckbox label="Flood" v-model="vehicleInfo.tradeInExtra.isFlood"></InputCheckbox>
                <InputCheckbox label="Autonomous" v-model="vehicleInfo.tradeInExtra.isAutonomous"></InputCheckbox>
                <InputCheckbox label="ILEV" v-model="vehicleInfo.tradeInExtra.isILEV"></InputCheckbox>
                <InputCheckbox label="Electric" v-model="vehicleInfo.tradeInExtra.isElectric"></InputCheckbox>
                <InputCheckbox label="Custom" v-model="vehicleInfo.tradeInExtra.isCustom"></InputCheckbox>
                <InputCheckbox label="Street Rod" v-model="vehicleInfo.tradeInExtra.isStreetRod"></InputCheckbox>
            </div>
        </Panel>
    </div>
</template>

<script>
    import Panel from '@core/components/Panel.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputRadio from '@core/components/InputRadio.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    //import InputCurrency from '@core/components/InputCurrency.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import modalInfo from '@core/modals/modalInfo.vue'
    import $modal from '@core/services/modal'
    import modalCustomerTradeinOwner from '@/modals/modalCustomerTradeinOwner.vue'
    import modalTradeInLien from '@/modals/modalTradeInLien.vue'
    import api from '@core/services/api'
    import util from '@core/services/util';
    import auth from '@core/services/auth';
    import ENUMS from "@core/classes/Enums"
    import { requiredIf } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core';
    import InputCheckbox from '@core/components/InputCheckbox.vue'

    import FIMenuCustomer from '@core/classes/FIMenuCustomer'
    import FIMenuVehicleLien from '@core/classes/FIMenuVehicleLien';
    import ModalPre from '../modals/modalPre.vue'
    import { openRetrieveCustomersModal } from '@/helpers/customers-helper'
    import { AccessEmployeeClass } from '@core/classes/AccessEmployeeClass'
    import FIMenuTradeInWalkAwayInfo from '@core/classes/FIMenuTradeInWalkAwayInfo'
    import { openDDiResponseModal, inquireVehicle } from '@/helpers/plate-transactions-helper'
    import FIMenu from '@core/classes/FIMenu'
    import { IsPlateTransactionsEnabled } from '@/helpers/plate-transactions-helper'

    export default {
        name: "PanelTradeInVehicle",
        props: ['data', 'vehicleInfo', 'panelObject', 'tradeIndex'],
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                previousVin: null,
                vinLookupBusy: false,
                vinLookupPressed: null,
                needLien: null,
                needSecondLien: null,
                vehicle: null,
                vehicleSaved: null,
                walkAwayPayees: [
                    { value: 0, text: 'Lien Holder' },
                    { value: 1, text: 'Customer' },
                    { value: 2, text: 'Co-Customer' }
                ],
                dealUpdatedExternally: false,
                loadingOpenInquiryModal: false
            }
        },
        computed: {
            isLeaseWalkAway() {
                return this.vehicleInfo && this.vehicleInfo.type === 2;
            },
            util() {
                return util;
            },
            user() {
                return new AccessEmployeeClass(auth.getTokenPayload());
            },
            ENUMS() {
                return ENUMS;
            },
            title() {
                return (this.vehicleInfo.modelYear && this.vehicleInfo.make && this.vehicleInfo.model)
                    ? (this.vehicleInfo.modelYear + " " + this.vehicleInfo.make + " " + this.vehicleInfo.model + " " + this.vehicleInfo.style).toUpperCase()
                    : ''
            },
            plateTransactionsEnabled() {
                return !!IsPlateTransactionsEnabled(this.data)
            },
            lockVehicleVin() {
                // return this.data.store.storeSettings.lockVehicleVin
                return this.data.store.storeSettings.lockVehicleVin && !this.user.EmployeeAccess.IsAdmin;
            },
            isSalesPersonAndNotSalesManager() {
                return auth.isSalesPersonAndNotSalesManager();
            }
        },
        created() {
            this.previousVin = this.vehicleInfo.vin
            this.EventBus.on('dealUpdatedExternally', this.dealUpdatedExternallyHandler);
        },
        beforeUnmount() {
            this.EventBus.off('dealUpdatedExternally', this.dealUpdatedExternallyHandler);
        },
        validations() {
            return {
                vehicleInfo: this.vehicleInfo.validation(this.data)
            }
        },
        methods: {
            async fetchCustomer(customer) {
                this.isBusyLoadingCustomer = true;
                try {

                    const customerResponse = await api.customers.getCustomerById(customer.id)

                    if (!customerResponse?.data) util.toastr('error', "Error", "Coulnd't find customer.")
                    return customerResponse.data;

                } catch (err) {
                    util.toastr('error', "Error", "An error has ocurred while retrieving the customer.")
                } finally {
                    // Cleanup
                    if (this.isRetrieveApplicationEnabled) $modal.close()
                    $modal.close();
                }

            },
            handleRetrieveCustomer(index) {
                const actionRetrieveCustomer = async (customer) => {
                    const customerData = await this.fetchCustomer(customer)
                    if (customerData) this.vehicleInfo.owners[index].setFromCustomer(customerData)
                }

                openRetrieveCustomersModal({
                    actionOnRetrieveCustomer: actionRetrieveCustomer,
                    actionOnRetrieveCompany: actionRetrieveCustomer,
                    canRetrieveCompany: true,
                })
            },
            requiredIfLeaseWalkAway(vehicle) {
                return { required: requiredIf(() => vehicle.type == 2) }
            },
            tradeInTypeChanged() {
                switch (this.vehicleInfo.type) {
                    case 0:
                    case 1:
                        this.vehicleInfo.walkAwayInfo = new FIMenuTradeInWalkAwayInfo();
                        break;
                    case 2:
                        this.vehicleInfo.allowance = null;
                        this.vehicleInfo.actualCashValue = null;
                        this.vehicleInfo.liens = [];
                        this.vehicleInfo.shouldWeAddAnotherLien = null;
                        break;
                }
            },
            getOwnerPanelObject(index) {
                const panelObject = {
                    title: `Owner ${index + 1}`,
                    action: [
                        {
                            text: 'Fill from Customer',
                            onClick: () => this.copyFromCustomer(index)
                        },
                    ]
                };

                // console.log("this.data", this.data)
                if (this.data.hasCosigner) {
                    panelObject.action.push({
                        text: 'Fill from CoCustomer',
                        onClick: () => this.copyFromCoCustomer(index)
                    })
                }

                panelObject.action.push({
                    text: 'X',
                    onClick: () => {

                        this.removeOwner(index);

                        ////IF THEY REMOVED ALL THE OWNERS, RESET THE CUSTOMER AS OWNER CHECKBOX
                        //if (this.vehicleInfo.owners.length == 0) {
                        //    this.vehicleInfo.customersAsOwnerInfo = null
                        //}

                    }
                })

                return panelObject
            },
            shouldWeAddAnotherLienChanged() {
                this.addLien();
                this.vehicleInfo.shouldWeAddAnotherLien = null;
            },
            shouldWeAddAnotherOwnerChanged(value) {
                // This is needed otherwise the v-model update will miss timing
                this.$nextTick(() => {
                    if (value) {
                        this.addNewOwner();
                        }
                })
            },
            customerIsOwnerChanged(ownerIndex) {
                if (this.dealUpdatedExternally) return;

                if (this.vehicleInfo.customersAsOwnerInfo === null) {
                    return;
                }
                else if (this.vehicleInfo.customersAsOwnerInfo) {
                    this.vehicleInfo.owners = [];
                }
                else {
                    this.addNewOwner();
                }
            },
            openModalTradeInOwner(ownerIndex) {
                const isCustomerTypeDisabled = ownerIndex > 0 && this.data.tradeIns[this.tradeIndex].owners[0].isEntity
                /*eslint-disable no-console*/
                console.log("ownerIndex", ownerIndex);
                console.log("this.data.tradeIns[this.tradeIndex].owners[0].isEntity", this.data.tradeIns[this.tradeIndex].owners[0].isEntity);
                console.log("isCustomerTypeDisabled", isCustomerTypeDisabled);
                /*eslint-enable*/
                $modal.open(modalCustomerTradeinOwner, { name: 'modalCustomer', passedData: { header: "Owner", fimenu: this.data, customer: this.data.tradeIns[this.tradeIndex].owners[ownerIndex], isCustomerTypeDisabled, validation: this.v$.vehicleInfo.owners[ownerIndex], buttonText: "Save and Close", panelObject: { title: 'Owner Information', subtext: [{ label: 'Name', value: this.data.tradeIns[this.tradeIndex].owners[ownerIndex].getFullName() }] } }, backdrop: false });
            },
            openModalTradeInLien(lienIndex) {
                const poqRequestDetails = {
                    r1DealerId: this.data.store.partnerCodes.find(pc => pc.type === ENUMS.PARTNER_CODES.ROUTEONE_DEALER_ID)?.code, //"IS1RF"
                    currentOdometer: this.vehicleInfo.mileage, //12,
                    vin: this.vehicleInfo.vin, //"JT3VN39W8L8009779",
                }

                if (this.vehicleInfo.owners && this.vehicleInfo.owners[0]) {
                    poqRequestDetails.ssn = this.vehicleInfo.owners[0].ssnHashed //"234263111",
                }

                if (lienIndex != null) {
                    $modal.open(modalTradeInLien, {
                        name: 'modalTradeInLien', passedData: {
                            isWalkAway: false,
                            type: this.vehicleInfo.type,
                            lien: this.data.tradeIns[this.tradeIndex].liens[lienIndex],
                            poqRequestDetails,
                            validation: this.v$.vehicleInfo.liens[lienIndex],
                            fimenu: this.data
                        }, backdrop: false
                    });
                } else {
                    $modal.open(modalTradeInLien,
                        {
                            name: 'modalTradeInLien',
                            passedData: {
                                isWalkAway: true,
                                type: this.vehicleInfo.type,
                                lien: this.data.tradeIns[this.tradeIndex].walkAwayInfo,
                                poqRequestDetails,
                                validation: this.v$.vehicleInfo.walkAwayInfo,
                                fimenu: this.data
                            }, backdrop: false
                        });
                }
            },
            getLienName(lien, index) {
                const name = lien?.lienName
                if (name) {
                    return name;
                } else {
                    return "Lien " + (index + 1);
                }
            },
            confirmLienDeletion(index) {
                $modal.open(modalInfo, { name: 'modalInfo', passedData: { info: 'Are you sure you want to delete this lien?', acceptText: 'Yes', cancelText: 'Cancel', validation: this.validation }, backdrop: false, postFunction: () => this.deleteLien(index) });
            },
            getOwnerName(owner, index) {
                const name = owner?.getFullName()
                if (name) {
                    return name;
                } else {
                    return "Owner " + (index + 1);
                }
            },
            addNewOwner() {
                this.data.tradeIns[this.tradeIndex].owners.push(new FIMenuCustomer());
                this.getOwnerName(this.data.tradeIns[this.tradeIndex].owners[length - 1], this.data.tradeIns[this.tradeIndex].owners.length - 1);
                this.vehicleInfo.shouldWeAddAnotherOwner = null;
            },
            deleteOwner(ownerIndex) {
                if (this.data.tradeIns[this.tradeIndex].owners.length == 1) {
                    this.vehicleInfo.customersAsOwnerInfo = null
                }
                else {
                    this.vehicleInfo.shouldWeAddAnotherOwner = null;
                }
                this.data.tradeIns[this.tradeIndex].owners.splice(ownerIndex, 1);
            },
            confirmOwnerDeletion(ownerIndex) {
                $modal.open(modalInfo, { name: 'modalInfo', passedData: { info: 'Are you sure you want to delete this owner?', acceptText: 'Yes', cancelText: 'Cancel', validation: this.validation }, backdrop: false, postFunction: () => this.deleteOwner(ownerIndex) });
            },
            copyFromCustomer(ownerIndex) {
                this.vehicleInfo.owners.splice(ownerIndex, 1, new FIMenuCustomer(this.data.customer));
            },
            copyFromCoCustomer(ownerIndex) {
                this.vehicleInfo.owners.splice(ownerIndex, 1, new FIMenuCustomer(this.data.coCustomer));
            },
            removeOwner(ownerIndex) {
                this.vehicleInfo.owners.splice(ownerIndex, 1);
            },
            async handleOnBlurVin() {
                const actions = [this.vinLookup()]

                // IF the vin is valid and it's different than the previous one.
                if (
                    !this.v$.vehicleInfo.vin.$invalid &&
                    this.previousVin != this.vehicleInfo.vin &&
                    this.plateTransactionsEnabled
                ) {
                    actions.push(inquireVehicle(this.data.storeCode, this.vehicleInfo.vin, null, this.data.id ));
                }

                await Promise.all(actions);
            },
            async vinLookup() {
                if (!this.v$.vehicleInfo.vin.isVinUsed) {
                    let message = `VIN#${this.vehicleInfo.vin} is already in use for the sold vehicle.`;

                    if (this.data.tradeIns.filter(d => d.vin == this.vehicleInfo.vin).length > 1) {
                        message = `VIN#${this.vehicleInfo.vin} is already used in another trade-in vehicle.`
                    }

                    $modal.open(modalInfo, {
                        name: 'modalInfo',
                        passedData: {
                            info: message,
                            acceptText: 'OK'
                        },
                        backdrop: false,
                        postFunction: () => {
                            this.vehicleInfo.vin = null;
                        }
                    })
                }
                else if (!this.v$.vehicleInfo.vin.$invalid && this.previousVin != this.vehicleInfo.vin) {
                    this.clearVehicleInformation();
                    this.vinLookupBusy = true;
                    this.EventBus.emit('populatingVehicleDetails', true);

                    const response = await api.vinLookup(this.vehicleInfo.vin, this.data.store.storeSettings.useCustomVinDecoder);

                    const vinInfo = response.data?.payload ?? null;

                    //Processed VIN != Submitted VIN
                    if (vinInfo && vinInfo.processedNotMatchSubmitted) {
                        const onContinue = () => this.populateVinFields(response);
                        const onCancel = () => {
                            this.vehicleInfo.vin = null;
                            this.populateVinFields(null);
                        }

                        $modal.open(modalInfo, {
                            name: 'modalInfo',
                            passedData: {
                                info: 'The VIN number you entered seems to be incorrect and is most likely a typo. Please confirm the VIN number from the actual vehicle dashboard/door jam and re-enter the VIN number.',
                                acceptText: 'Proceed Anyways',
                                cancelText: 'Re-Enter VIN'
                            },
                            postFunction: onContinue,
                            cancelFunction: onCancel
                        });
                    }
                    else {
                        this.populateVinFields(response);
                    }
                    this.EventBus.emit('populatingVehicleDetails', false);
                    this.vinLookupBusy = false;
                }

            },
            populateVinFields(response) {
                if (!response || !response.data) return;

                const vinInfo = response.data.payload;
                this.vehicleInfo.updateUsingVINLookupResponse(vinInfo);

                const glowInputs = this.$refs.fieldset?.querySelectorAll(".glow");
                if(glowInputs){
                    util.setInputGlow(glowInputs, vinInfo ? 'success' : 'error');
                }
            },
            addLien() {
                if (!util.hasValue(this.vehicleInfo.liens)) {
                    this.vehicleInfo.liens = [];
                }

                this.vehicleInfo.liens.push(new FIMenuVehicleLien())
            },
            deleteLien(index) {
                this.vehicleInfo.liens.splice(index, 1);
                this.vehicleInfo.shouldWeAddAnotherLien = null;
            },
            dealUpdatedExternallyHandler(state) {
                this.dealUpdatedExternally = state;
            },
            clearVehicleInformation() {
                this.vehicleInfo.make = null;
                this.vehicleInfo.model = null;
                this.vehicleInfo.modelYear = null;
                this.vehicleInfo.style = null;
            },
            async handleOpenDDiResponseModal() {
                this.loadingOpenInquiryModal = true;
                await openDDiResponseModal(this.data.storeCode, this.vehicleInfo.vin, this.vehicleInfo.plateNumber, this.data.id)
                this.loadingOpenInquiryModal = false;
            }
        },
        components: {
            Panel,
            InputTextbox,
            InputNumber,
            //InputCurrency,
            InputRadio,
            InputRichDropdown,
            InputCheckbox
        }
    };
</script>
<style>
    .PanelTradeInVehicle-container .delete-lien {
        display: flex;
        height: 100%;
        align-items: flex-end;
    }

    .PanelTradeInVehicle-container .owner-name {
        display: flex;
        align-items: center;
        text-transform: uppercase;
    }

    .PanelTradeInVehicle-container .valid-owner {
        border-left: 3px solid transparent;
        padding-left: 7px;
    }

    .PanelTradeInVehicle-container .invalid-owner {
        border-left: 3px solid var(--error-color);
        padding-left: 7px;
    }

    .PanelTradeInVehicle-container .owners-info-panel {
        padding-right: 10px;
        margin-bottom: -10px;
    }

    .PanelTradeInVehicle-panel-container {
        height: fit-content;
        margin-bottom: 10px;
    }

        .PanelTradeInVehicle-panel-container .trade-in-owner {
            width: 225px !important;
        }
</style>