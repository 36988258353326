<template>
    <div :style="$grid('1-1')">
        <Panel title="Freight Forwarder Information" class="PanelExportInfo">
            <div :style="$grid('1')">
                <InputTextbox label="Name"
                              v-model:saturn="registrationInfo.freightForwarderInfo.name"
                              :invalid="util.isNull(validation?.freightForwarderInfo?.name) ? false : validation.freightForwarderInfo.name.$invalid"
                              placeholder="Name">
                    <template #validation>
                        <div v-if="!util.isNull(validation?.freightForwarderInfo) && validation.freightForwarderInfo.name.required.$invalid">Name is required</div>
                    </template>
                </InputTextbox>
            </div>
            <div class="no-margin-grid" :style="$grid('1-1')">
                <InputPhone label="Phone Number"
                            type="tel"
                            v-model:saturn="registrationInfo.freightForwarderInfo.phoneNumber"
                            :invalid="(util.isNull(validation?.freightForwarderInfo?.phoneNumber)) ? false : validation.freightForwarderInfo.phoneNumber.$invalid"
                            placeholder="Phone Number"
                            maxlength="10"
                            minlength="10">
                    <template #validation>
                        <div v-if="!util.isNull(validation?.freightForwarderInfo) && validation.freightForwarderInfo.phoneNumber.required.$invalid"> Phone Number is required </div>
                        <div v-if="!util.isNull(validation?.freightForwarderInfo) && validation.freightForwarderInfo.phoneNumber.$invalid"> Phone Number is invalid </div>
                    </template>
                </InputPhone>

                <InputTextbox label="License Number"
                              v-model:saturn="registrationInfo.freightForwarderInfo.licenseNumber"
                              :invalid="util.isNull(validation?.freightForwarderInfo?.licenseNumber) ? false : validation.freightForwarderInfo.licenseNumber.$invalid"
                              placeholder="License Number"
                              :precision="0"
                              thousandSeparator="">
                    <template #validation>
                        <div v-if="!util.isNull(validation?.freightForwarderInfo) && validation.freightForwarderInfo.licenseNumber.required.$invalid">License is required</div>
                        <div v-if="!util.isNull(validation?.freightForwarderInfo) && validation.freightForwarderInfo.licenseNumber.validLicense.$invalid">License is invalid</div>
                    </template>
                </InputTextbox>
            </div>
            <div>
                <!--registrationInfo.primaryUsage == VehiclePrimaryUsage.EXPORT-->
                <AddressComponent v-if="true"
                                  :data="registrationInfo.freightForwarderInfo.address"
                                  :panelView="false"
                                  :showCounty="true"
                                  :searchable="true"
                                  :validation="validation.freightForwarderInfo.address"
                                  countryQuestionText="Is the address in the US?"
                                  lineTwoQuestionText="Does the address have an apartment, condo or suite #?"
                                  fallbackzipcode="fallbackzipcode">
                </AddressComponent>
            </div>

        </Panel>
        <Panel title="Shipping Company Information" class="PanelExportInfo">
            <div :style="$grid('1')">
                <InputTextbox label="Name"
                                v-model:saturn="registrationInfo.shippingCompanyInfo.name"
                              :invalid="util.isNull(validation?.shippingCompanyInfo?.name) ? false : validation.shippingCompanyInfo.name.$invalid"
                              placeholder="Name">
                    <template #validation>
                        <div v-if="!util.isNull(validation?.shippingCompanyInfo) && validation.shippingCompanyInfo.name.required.$invalid">Name is required</div>
                    </template>
                </InputTextbox>
            </div>
            <div class="no-margin-grid" :style="$grid('1-1')">
                <InputPhone label="Phone Number"
                            type="tel"
                            v-model:saturn="registrationInfo.shippingCompanyInfo.phoneNumber"
                            :invalid="util.isNull(validation?.shippingCompanyInfo?.phoneNumber) ? false : validation.shippingCompanyInfo.phoneNumber.$invalid"
                            placeholder="Phone Number"
                            maxlength="10"
                            minlength="10">
                    <template #validation>
                        <div v-if="!util.isNull(validation?.shippingCompanyInfo) && validation.shippingCompanyInfo.phoneNumber.required.$invalid"> Phone Number is required </div>
                        <div v-if="!util.isNull(validation?.shippingCompanyInfo) && validation.shippingCompanyInfo.phoneNumber.$invalid"> Phone Number is invalid </div>
                    </template>
                </InputPhone>

                <InputTextbox label="License Number"
                              v-model:saturn="registrationInfo.shippingCompanyInfo.licenseNumber"
                              :invalid="util.isNull(validation?.shippingCompanyInfo?.licenseNumber) ? false : validation.shippingCompanyInfo.licenseNumber.$invalid"
                              placeholder="License Number"
                              :precision="0"
                              thousandSeparator="">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && !util.isNull(validation.shippingCompanyInfo) && validation.shippingCompanyInfo.licenseNumber.required.$invalid">License is required</div>
                        <div v-if="!util.isNull(validation?.shippingCompanyInfo) && validation.shippingCompanyInfo.licenseNumber.validLicense.$invalid">License is invalid</div>
                    </template>
                </InputTextbox>
            </div>
            <div>
                <!--registrationInfo.primaryUsage == VehiclePrimaryUsage.EXPORT-->
                <AddressComponent v-if="true"
                                  :data="registrationInfo.shippingCompanyInfo.address"
                                  :panelView="false"
                                  :showCounty="true"
                                  :searchable="true"
                                  :validation="validation.shippingCompanyInfo.address"
                                  countryQuestionText="Is the address in the US?"
                                  lineTwoQuestionText="Does the address have an apartment, condo or suite #?"
                                  fallbackzipcode="fallbackzipcode">
                </AddressComponent>
            </div>

        </Panel>
    </div>
</template>

<script lang="ts">

    import { defineComponent, PropType } from 'vue'
    import AddressComponent from '@/components/AddressComponent.vue'
    import FIMenuExportInfo from '@core/classes/FIMenuExportInfo'
    import FIMenuVehicleRegistrationInfo from '@core/classes/FIMenuVehicleRegistrationInfo'
    import InputPhone from '@core/components/InputPhone.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import Panel from '@core/components/Panel.vue'
    import util from '@core/services/util'

    export default defineComponent({
        name: 'PanelExportInfo',
        props: {
            registrationInfo: {
                type: Object as PropType<FIMenuVehicleRegistrationInfo>,
            },
            fallbackzipcode: {
                type: String,
            },
            validation: {
                type: Object,
            },
        },
        data() {
            return {
                freightForwarderInfo: this.registrationInfo.freightForwarderInfo as FIMenuExportInfo,
                shippingCompanyInfo: this.registrationInfo.shippingCompanyInfo as FIMenuExportInfo,
            }
        },
        methods: {

        },
        computed: {
            util(): typeof util {
                return util
            }
        },

        created() {
        },
        mounted() { },
        components: {
            Panel,
            InputTextbox,
            InputPhone,
            AddressComponent,
        },
    })
</script>
<style>
</style>
