<template>
    <div class="modal-container modal-x-large modal-compare-pdf">
        <div class="modal-header">
            <span>{{ modalTitle }}</span>

            <div class="header-actions">
                <button v-show="uploadedDocBase64" @click="clearUploadedFile">
                    Clear Document
                </button>
            </div>
        </div>

        <div :class="{'modal-body': true, 'has-no-uploaded-file': !uploadedDocBase64}">
            <section class="compare-pdf-section" :style="$grid('1-1')">
                <div class="original-doc">
                    <h2>Original Document</h2>
                    <PDFViewerLazyLoad v-if="originalPdf" :pdfSrc="originalPdf" />
                </div>

                <div v-if="!uploadedDocBase64" class="input-file-container">
                    <InputFile filesAccepted="application/pdf" :invalid="!uploadedDoc" @fileDataChange="onFileDataChange" />
                </div>

                <div v-else>
                    <h2>Uploaded Document</h2>
                    <PDFViewer :src="uploadedDocBase64" />
                </div>
            </section>

            <div class="final-checkbox">
                <InputCheckbox 
                    v-show="uploadedDocBase64"
                    v-model="newDocConfirmed"
                    class="final-checkbox"
                    :disabled="!uploadedDoc"
                    :invalid="!newDocConfirmed"
                    label="I confirm that both documents match."
                />
            </div>
        </div>

        <div class="modal-footer">
            <button class="button button-unwind" @click="cancel">
                Cancel
            </button>
            <ButtonLoading class="button button-save" :isLoading="isBusy" :disabled="!newDocConfirmed" @click="saveFileAndClose">
                Save
            </ButtonLoading>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { onBeforeMount, ref } from 'vue';

    import $modal from '@core/services/modal';
    import util from '@core/services/util';

    import ButtonLoading from '@core/components/ButtonLoading.vue';
    import InputCheckbox from '@core/components/InputCheckbox.vue';
    import InputFile from '@core/components/InputFile.vue';
    import PDFViewer from '@core/components/PDFViewer.vue';
    import PDFViewerLazyLoad from '@core/components/PDFViewerLazyLoad.vue';

    interface ModalUploadAndComparePDFProps {
        modal: {
            passedData: {
                /** The title to display in the modal header */
                title?: string,

                /** The original PDF to compare as a base-64 byte string */
                originalPDF: string,

                /** 
                 * A method run when clicking "Save". Modal won't close until method runs without errors. If not passed in, the uploaded file will
                 * be sent to the modal's `postFunction`.
                */
                saveFunc?: (fileData: File) => Promise<any>
            }
        }
    }

    const props = defineProps<ModalUploadAndComparePDFProps>();

    const modalTitle = ref<string>(null);
    const originalPdf = ref<string>(null);

    const uploadedDoc = ref<File>(null);
    const uploadedDocBase64 = ref<string>(null);

    const newDocConfirmed = ref(false);
    const isBusy = ref(false);

    onBeforeMount(async () => {
        modalTitle.value = props.modal.passedData?.title ?? 'Upload PDF';
        originalPdf.value = props.modal.passedData?.originalPDF;
    });

    const saveFileAndClose = async (): Promise<void> => {
        isBusy.value = true;

        try {
            if (props?.modal?.passedData?.saveFunc)
                await props.modal.passedData.saveFunc(uploadedDoc.value);

            $modal.close({ file: uploadedDoc.value, base64: uploadedDocBase64.value });
        }
        catch (err) {
            console.error('ERROR SAVING PDF: ', err);

            const errorMessage = (err as Error)?.message ?? 'An error occurred while trying to save the PDF.'
            util.toastr('error', 'Error Saving PDF', errorMessage);
        }
        finally {
            isBusy.value = false;
        }

    };

    const cancel = () => {
        $modal.cancel();
    };

    const clearUploadedFile = () => {
        uploadedDoc.value = null;
        uploadedDocBase64.value = null;
        newDocConfirmed.value = false;
    }

    const onFileDataChange = (file: File) => {
        uploadedDoc.value = file;

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => { 
            const base64 = reader.result.toString().replace('data:application/pdf;base64,', '');
            uploadedDocBase64.value = base64;
        };
    };
</script>
<style>
    .modal-compare-pdf .modal-body {
        position: relative;
    }

    .modal-compare-pdf .modal-body.modal-body.has-no-uploaded-file .original-doc {
        filter: blur(2px);
        max-height: 500px;
        overflow: hidden;
    }

    .modal-compare-pdf .original-doc {
        transition: all ease-in-out 0.3s;
    }

    .modal-compare-pdf .input-file-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .modal-compare-pdf .input-file-container .input-file {
            height: 100%;
            width: 100%;
        }

            .modal-compare-pdf .input-file-container .input-file .file-drop-area {
                height: 90%;
            }

    .modal-compare-pdf h2 {
        text-align: center;
    }

    .modal-compare-pdf .final-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
    }
</style>