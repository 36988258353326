<template>
	<div class="modal-container modal-info modal-large">
		<div class="modal-header">Vehicle Inquiry
            <button style="max-width: 120px" @click="performInquiry" :disabled="loadingInquiry">
                <span v-if="!loadingInquiry" >New Inquiry</span>
                <i v-else class="fas fa-spinner fa-spin" />
            </button>
        </div>
		<div class="modal-body">
			<div class="ddi-response-result">
                <div v-if="vehInfo">
                    <!-- <p><strong>ID:</strong> {{ vehInfo.id }}</p>
                    <p><strong>Type:</strong> {{ vehInfo.type }}</p>
                    <p><strong>Date Created:</strong> {{ vehInfo.dateCreated }}</p> -->
                    <div class="response" v-if="vehInfo.response">
                        <!-- <h3>Response Information</h3>
                        <p><strong>Status:</strong> {{ vehInfo.response.status }}</p>
                        <p><strong>Transaction ID:</strong> {{ vehInfo.response.transactionId }}</p>
                        <p><strong>Error Code:</strong> {{ vehInfo.response.errorCode }}</p>
                        <p><strong>Message:</strong> {{ vehInfo.response.message || 'N/A' }}</p> -->





                        <div class="payload" v-if="vehInfo.response.payload">
                            <div class="page-header">
                                <div class="left">
                                    <p><strong>VIN:</strong> {{ vehInfo.response.payload.vin }}</p>
                                    <p><strong>Reason Code:</strong> {{ vehInfo.response.payload.reasonCode || 'N/A' }}</p>
                                    <p><strong>Plate Number:</strong> {{ vehInfo.response.payload.plateNumber || 'N/A' }}</p>
                                    <p><strong>Title Number:</strong> {{ vehInfo.response.payload.titleNumber || 'N/A' }}</p>
                                </div>
                                <div class="right">

                                    <p style="font-size: 0.9rem">Inquiry Date</p>
                                    <p><strong>{{ util.toMoment(vehInfo.dateCreated).format("MM/DD/YYYY hh:mm A") || 'N/A' }}</strong></p>
                                </div>
                            </div>

                            <div class="vehicle-inquiry-results" v-if="vehInfo.response.payload.vehicleInquiryResult && vehInfo.response.payload.vehicleInquiryResult.length">
                                <h4>Vehicle Inquiry Results</h4>
                                <div class="divider"/>
                                <div v-for="(vehicle, index) in vehInfo.response.payload.vehicleInquiryResult" :key="index" class="vehicle-inquiry">
                                    <div class="vehicle-info-wrapper">
                                        <div class="vehicle-info">
                                            <h5>Basic Vehicle Information</h5>
                                            <p><strong>Type:</strong> {{ vehicle.type }}</p>
                                            <p><strong>Year:</strong> {{ vehicle.year }}</p>
                                            <p><strong>Make:</strong> {{ vehicle.make }}</p>
                                            <p><strong>Model:</strong> {{ vehicle.model }}</p>
                                            <p><strong>Body Style:</strong> {{ vehicle.bodyStyle }}</p>
                                            <p><strong>Title Number:</strong> {{ vehicle.titleNumber }}</p>
                                            <p><strong>Plate Number:</strong> {{ vehicle.plateNumber }}</p>
                                            <p><strong>Plate Expiration:</strong> {{ vehicle.plateExpiration || 'N/A' }}</p>
                                            <p><strong>Registration Date:</strong> {{ vehicle.registrationDate }}</p>
                                            <p><strong>Owner Count:</strong> {{ vehicle.ownerCount }}</p>
                                            <p><strong>Lien Count:</strong> {{ vehicle.lienCount || 'N/A' }}</p>
                                            <p><strong>Lien Flag:</strong> {{ vehicle.lienFlag || 'N/A' }}</p>
                                            <p><strong>Brand Flag:</strong> {{ vehicle.brandFlag || 'N/A' }}</p>
                                            <p><strong>Title Status Flag:</strong> {{ vehicle.titleStatusFlag || 'N/A' }}</p>
                                        </div>

                                        <div class="vehicle-info">
                                            <h5>Registration and Titling Information</h5>
                                            <p><strong>Is E-Title:</strong> {{ vehicle.isETitle || 'N/A' }}</p>
                                            <p><strong>Control Number:</strong> {{ vehicle.controlNumber || 'N/A' }}</p>
                                            <p><strong>Title Issue Date:</strong> {{ vehicle.titleIssueDate || 'N/A' }}</p>
                                            <p><strong>Title Status:</strong> {{ vehicle.titleStatus || 'N/A' }}</p>
                                            <p><strong>Title Status Reason:</strong> {{ vehicle.titleStatusReason || 'N/A' }}</p>
                                            <p><strong>Title Type:</strong> {{ vehicle.titleType || 'N/A' }}</p>
                                            <p><strong>Purchase Price:</strong> {{ vehicle.purchasePrice || 'N/A' }}</p>
                                            <p><strong>Vehicle County:</strong> {{ vehicle.vehicleCounty || 'N/A' }}</p>
                                            <p><strong>Odometer:</strong> {{ vehicle.odometer || 'N/A' }} miles</p>
                                            <p><strong>Odometer Brands:</strong> {{ vehicle.odometerBrands || 'N/A' }}</p>
                                            <p><strong>Net Weight:</strong> {{ vehicle.netWeight || 'N/A' }} lbs</p>
                                            <p><strong>Gross Weight:</strong> {{ vehicle.grossWeight || 'N/A' }} lbs</p>
                                            <p><strong>Major Color:</strong> {{ vehicle.majorColor || 'N/A' }}</p>
                                            <p><strong>Minor Color:</strong> {{ vehicle.minorColor || 'N/A' }}</p>
                                            <p><strong>Plate Type:</strong> {{ vehicle.plateType || 'N/A' }}</p>
                                            <p><strong>Registration Expiration Date:</strong> {{ vehicle.registrationExpirationDate || 'N/A' }}</p>
                                            <p><strong>Registration Use:</strong> {{ vehicle.registrationUse || 'N/A' }}</p>
                                            <p><strong>Purchase Date:</strong> {{ vehicle.purchaseDate || 'N/A' }}</p>
                                            <p><strong>Message:</strong> {{ vehicle.message || 'N/A' }}</p>
                                            <p><strong>Log Number:</strong> {{ vehicle.logNumber || 'N/A' }}</p>
                                            <!-- <p><strong>Error Code:</strong> {{ vehicle.errorCode || 'N/A' }}</p> -->
                                        </div>
                                    </div>

                                    <div class="owners" v-if="vehicle.owner && vehicle.owner.length">
                                        <h5>Owners</h5>
                                        <ul>
                                            <li v-for="(owner, ownerIndex) in vehicle.owner" :key="ownerIndex">
                                                <p><strong>Owner Name:</strong> {{ owner.ownerName }}</p>
                                                <p><strong>Owner Type:</strong> {{ owner.ownerType }}</p>
                                                <p><strong>Owner Relationship:</strong> {{ owner.relationshipType }}</p>
                                                <p><strong>Address:</strong> {{ formatAddress(owner.address1, owner.city, owner.state, owner.zipCode) }}</p>
                                                <p><strong>Mailing Address:</strong> {{ formatAddress(owner.mailingAddress1, owner.mailingAddress2, owner.state, owner.zipCode) }}</p>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="liens" v-if="vehicle.lien && vehicle.lien.length">
                                        <h5>Liens</h5>
                                        <ul>
                                            <li v-for="(lien, lienIndex) in vehicle.lien" :key="lienIndex">
                                                <p><strong>Lien Number:</strong> {{ lien.lienNumber || 'N/A' }}</p>
                                                <p><strong>Lienholder Number:</strong> {{ lien.lienholderNumber || 'N/A' }}</p>
                                                <p><strong>Lienholder Name:</strong> {{ lien.lienholderName || 'N/A' }}</p>
                                                <p><strong>Lien Date:</strong> {{ formatDate(lien.lienDate) }}</p>
                                                <p><strong>Lien Satisfaction Date:</strong> {{ formatDate(lien.lienSatisfactionDate) }}</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <div class="metadata" v-if="vehInfo.response.payload.metadata">
                                <h3>Metadata</h3>
                                <p><strong>Inquiry ID:</strong> {{ vehInfo.response.payload.metadata.InquiryId }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>No response data available.</p>
                </div>
            </div>
		</div>
        <div class="modal-footer">
            <button @click="close()">Close</button>
        </div>
	</div>
</template>


<script lang="ts">
    import { DDIResponse, DDIVehicleInquiry } from "@core/classes/DDI/DDIVehicleInquiry";
    import $modal from "@core/services/modal";
	import { defineComponent } from "vue";
    import util from "@core/services/util";
    import api from "@core/services/api";

	export default defineComponent({
		name: "modalDDIVehicleInquiryResult",
		components: {},
		props: {
			modal: {
				type: Object
			}
		},
		data() {
			return {
				vehInfo: new DDIResponse({}),
                loadingInquiry: false
			};
		},
		created() {
			this.vehInfo = new DDIResponse(this.modal?.passedData?.DDIResponse ?? {});
		},
        computed: {
            inquiry(): DDIVehicleInquiry {
                const vehicleInquiryResult = this.vehInfo.response?.payload?.vehicleInquiryResult;
                return (vehicleInquiryResult && vehicleInquiryResult.length > 0)
                    ? vehicleInquiryResult[0]
                    : new DDIVehicleInquiry({});
            },
            util(): any {
                return util
            }
        },
		methods: {
            close() {
                $modal.close();
            },
            formatAddress(address1: string, city: string, state: string, zipCode: string) {
                // Create an array to hold the address components
                const components = [address1, city, state, zipCode];

                // Filter out any empty components
                const filteredComponents = components.filter(component => component && component.trim());

                // Return the formatted address or 'N/A' if all components are empty
                return filteredComponents.length > 0 ? filteredComponents.join(', ') : 'N/A';
            },
            async performInquiry() {
                try {
                    this.loadingInquiry = true

                    await this.modal.passedData.inquireVehicle()

                    const response = await api.plateTransactions.getLastVehicleInquiryVin(this.modal.passedData.vin, this.modal.passedData.storeCode);

                    if (response.data) {
                        util.toastr("success", "Inquiry", "Vehicle inquiry successful")
                        this.vehInfo = new DDIResponse(response.data ?? {});

                    } else util.toastr("error", "Inquiry", "There was an error performing the vehicle inquiry.")

                } catch(err){
                    console.error(err)
                } finally {
                    this.loadingInquiry = false
                }
            },
            formatDate(date: any){
                if(util.isNullOrEmpty(date)) return "N/A";
                return util.toMoment(date).format("YYYY-MM-DD")
            }
        }
	});
</script>


<style scoped>
.ddi-response-result {
    padding: 20px;
}

.response, .payload, .metadata {
    margin-bottom: 20px;
}

h2 {
    color: #333;
}

h3, h4, h5 {
    color: #555;
    margin-bottom: 10px;
}

p {
    margin: 5px 0;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

li {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.vehicle-inquiry {
    display: column;
    flex-wrap: wrap;
    margin-bottom: 20px; /* Space between vehicle inquiries */
}

.vehicle-info-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.vehicle-info {
    flex: 1; /* Grow to fill space */
    margin-right: 20px; /* Space between the two sections */
}

.vehicle-info:last-child {
    margin-right: 0; /* Remove margin from the last item */
}
.page-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.page-header .left p {
    font-size: 0.9rem;
}
.divider {
    margin: 0;
    width: 100%;
}

</style>

