<template>
    <div class="trade-ins-container" :style="$grid('1')">
        <TabMenu v-if="tabs && tabs.length > 0" ref="tabMenu" :tabs="tabs" :initialTab="lastTab">
            <template #[`tab-head-${tab}`] v-for="(tab, index) in tabs" :key="index">
                <div :invalidTab="v$.fimenu.tradeIns[index].$invalid" >
                    <span>{{ tab }}</span>

                    <button class="close-button button-span" @click="confirmDeletion(index)" :disabled="fimenu.isDealPartiallyLockedBecauseWeSentACreditApp()">
                        <i class="far fa-times-circle" />
                    </button>
                </div>
            </template>
            <template #[`tab-panel-${tab}`] v-for="(tab, index) in tabs" :key="index">
                <fieldset :disabled="populatingVehicleDetails" >
                    <div :style="$grid('1')">
                        <PanelTradeInVehicle :data="fimenu"
                                             :panelObject="{title: fimenu.tradeIns[index].getTitle()}"
                                             :vehicleInfo="fimenu.tradeIns[index]"
                                             :tradeIndex="index">
                        </PanelTradeInVehicle>
                    </div>
                </fieldset>
            </template>
        </TabMenu>

        <fieldset class="radio-button-inline" :invalid="fimenu.hasOtherTradeIns == null" :disabled="fimenu.isDealPartiallyLockedBecauseWeSentACreditApp()">
            <span>
                Is there {{ (tabs && tabs.length > 0) ? 'another' : 'a' }} trade in?
            </span>

            <InputRadio 
                label="Yes" 
                :valueMatch="null" 
                v-model="fimenu.hasOtherTradeIns" 
                :isButton="true" 
                @click="addTradeIn" 
                :disabled="isDisabled || fimenu.isDealPartiallyLockedBecauseWeSentACreditApp()" 
            />
            <InputRadio 
                label="No" 
                :valueMatch="false" 
                v-model="fimenu.hasOtherTradeIns" 
                :isButton="true" 
                :disabled="isDisabled"
            />
        </fieldset>
    </div>
</template>

<script>
    //import PanelVehicleRecallInfo from '@core/components/PanelVehicleRecallInfo.vue'
    import $modal from '@core/services/modal'
    import FIMenu from '@core/classes/FIMenu'
    import FIMenuCustomer from '@core/classes/FIMenuCustomer'
    import FIMenuTradeIn from '@core/classes/FIMenuTradeIn'
    import InputRadio from '@core/components/InputRadio.vue'
    import modalInfo from '@core/modals/modalInfo.vue'
    import PanelTradeInVehicle from '@/components/PanelTradeInVehicle.vue'
    import TabMenu from '@/components/TabMenu.vue'
    import {useVuelidate} from '@vuelidate/core';
    import util from '@core/services/util'

    export default {
        name: "PanelTradeIn",
        props: {
            fimenu: FIMenu,
            isDisabled: {
                type: Boolean,
                default: false
            }
        },
        setup() {
            return { v$: useVuelidate({$scope: false}) }
        },
        data() {
            return {
                populatingVehicleDetails: false,
            }
        },
        validations() {
            return {
                fimenu: {
                    tradeIns: {
                        ...(this.fimenu.tradeIns.map(t => t.validation(this.fimenu)))
                    }
                }
            };
        },
        computed: {
            tabs() {
                return this.fimenu.tradeIns?.map((t, index) => `Trade-In ${index + 1}`);
            },
            lastTab() {
                return this.tabs[this.tabs.length - 1];
            },
        },
        created() {
            this.EventBus.on('populatingVehicleDetails', (newVal) => this.populatingVehicleDetails = newVal)
        },
        unmounted() {
            this.EventBus.off('populatingVehicleDetails', (newVal) => this.populatingVehicleDetails = newVal)
        },
        methods: {
            addTradeIn() {
                // When Co-Browsing enabled, block Spectators from adding a trade-in
                if (this.fimenu.store.storeSettings.isDealCoBrowsingEnabled && this.fimenu.isSpectator) return;

                if(this.fimenu.tradeIns.length > 7) return util.toastr('Error', "Exceeded Trade In Amounts", "The limit is 8 trade ins per deal. Please submit a support ticket for further instance.")

                const newTrade = new FIMenuTradeIn();
                newTrade.owners.push(new FIMenuCustomer())
                newTrade.customersAsOwnerInfo = false;

                this.fimenu.tradeIns.push(newTrade);
                this.fimenu.hasOtherTradeIns = null;
            },
            confirmDeletion(tradeIndex) {
                $modal.open(modalInfo, {
                    name: 'modalInfo',
                    passedData: {
                        info: 'Are you sure you want to delete this Trade-In?',
                        acceptText: 'Yes',
                        cancelText: 'Cancel'
                    },
                    backdrop: false,
                    postFunction: () => {
                        this.fimenu.tradeIns.splice(tradeIndex, 1);

                        this.fimenu.hasOtherTradeIns = null;
                    }
                });
            },
        },
        components: {
            PanelTradeInVehicle,
            //PanelVehicleRecallInfo,
            TabMenu,
            InputRadio,
        }
    };
</script>
<style>
    .trade-ins-container {
        margin-top: 10px;
    }
</style>