<template>
    <div class="modal-container modal-xxx-large r1container">
        <div class="modal-header">
            {{ dealType }} Credit Application to {{ modal.passedData.row.lender.lenderName }}
        </div>
        <div class="modal-body">
            <IsBusySectionComponent v-if="isLoading"/>
            <TabMenu :tabs="tabs" :initialTab="initialTab" v-else class="r1-tabmenu">
                <!--tabs: ['deal-jacket', 'error','credit-app','credit-decision']-->
                <template v-slot:tab-head-dealjacket>
                    Deal Jacket
                </template>
                <template v-slot:tab-panel-dealjacket>
                    <div :style="$grid('1-1')" v-if="dealJacket">
                        <Panel title="Customer">
                            <template v-slot:header>
                                <div class="panel-buttons">
                                    <button v-if="canViewRawData" class="button button-edit" :disabled="!dealJacket" @click="viewDealJacketJSON()"><i class="fas fa-binoculars"></i> Deal Jacket</button>
                                </div>
                            </template>
                            <div :style="$grid('1-1-1-1')" v-if="dealJacket && dealJacket.primaryBuyer && dealJacket.primaryBuyer.customerTypeCode === 'D'">
                                <InputTextbox label="First Name"
                                              v-model:saturn="dealJacket.primaryBuyer.firstName"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Middle Name"
                                              v-model:saturn="dealJacket.primaryBuyer.middleName"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Last Name"
                                              v-model:saturn="dealJacket.primaryBuyer.lastName"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="SSN"
                                              v-model:saturn="dealJacket.primaryBuyer.ssn"
                                              disabled="true">
                                </InputTextbox>
                            </div>
                            <div :style="$grid('2-2-1')" v-if="dealJacket && dealJacket.primaryBuyer && dealJacket.primaryBuyer.customerTypeCode === 'B'">
                                <InputTextbox label="Trade Name"
                                              v-model:saturn="dealJacket.primaryBuyer.tradeName"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Legal Name"
                                              v-model:saturn="dealJacket.primaryBuyer.legalName"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Tax ID"
                                              v-model:saturn="dealJacket.primaryBuyer.taxId"
                                              disabled="true">
                                </InputTextbox>
                            </div>
                            <div :style="$grid('1')" v-if="dealJacket && dealJacket.primaryBuyer">
                                <InputTextbox label="Address Line 1"
                                              v-model:saturn="dealJacket.primaryBuyer.addressLine1"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Address Line 2"
                                              v-model:saturn="dealJacket.primaryBuyer.addressLine2"
                                              disabled="true">
                                </InputTextbox>
                            </div>
                            <div :style="$grid('4-1-2')" v-if="dealJacket && dealJacket.primaryBuyer">
                                <InputTextbox label="City"
                                              v-model:saturn="dealJacket.primaryBuyer.city"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="State"
                                              v-model:saturn="dealJacket.primaryBuyer.state"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Zip"
                                              v-model:saturn="dealJacket.primaryBuyer.zipCode"
                                              disabled="true">
                                </InputTextbox>
                            </div>
                        </Panel>
                        <Panel title="Co-Customer" v-if="dealJacket && dealJacket.coBuyer">
                            <div :style="$grid('1-1-1-1')">
                                <InputTextbox label="First Name"
                                              v-model:saturn="dealJacket.coBuyer.firstName"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Middle Name"
                                              v-model:saturn="dealJacket.coBuyer.middleName"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Last Name"
                                              v-model:saturn="dealJacket.coBuyer.lastName"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="SSN"
                                              v-model:saturn="dealJacket.coBuyer.ssn"
                                              disabled="true">
                                </InputTextbox>
                            </div>
                            <div :style="$grid('1')">
                                <InputTextbox label="Address Line 1"
                                              v-model:saturn="dealJacket.coBuyer.addressLine1"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Address Line 2"
                                              v-model:saturn="dealJacket.coBuyer.addressLine2"
                                              disabled="true">
                                </InputTextbox>
                            </div>
                            <div :style="$grid('4-1-2')">
                                <InputTextbox label="City"
                                              v-model:saturn="dealJacket.coBuyer.city"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="State"
                                              v-model:saturn="dealJacket.coBuyer.state"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Zip"
                                              v-model:saturn="dealJacket.coBuyer.zipCode"
                                              disabled="true">
                                </InputTextbox>
                            </div>
                        </Panel>
                    </div>
                    <div :style="$grid('1')" v-if="dealJacket && dealJacket.dealJacketVehicles">
                        <Panel title="Vehicles">
                            <RichTable
                                       :tableData="dealJacket.dealJacketVehicles"
                                       :headers="vehicleHeaders">

                            </RichTable>
                            </Panel>
                            <div :style="$grid('1-1')">
                                <Panel title="Finance">
                                    <div :style="$grid('1-1-1-1-1')" class="lines">
                                        <InputTextbox label="Type"
                                                      v-model:saturn="dealJacket.finance.transactionType"
                                                      disabled="true">
                                        </InputTextbox>
                                        <InputCurrency label="Cash Price"
                                                       v-model:saturn="dealJacket.finance.vehicleCashPrice"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                        <InputCurrency label="Cash Down"
                                                       v-model:saturn="dealJacket.finance.cashDownPayment"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                        <InputCurrency label="Incentives"
                                                       v-model:saturn="dealJacket.finance.incentiveAmount"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                        <InputCurrency label="Monthly Payment"
                                                       v-model:saturn="dealJacket.finance.monthlyPayment"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                    </div>
                                    <div :style="$grid('1-1-1-1')" v-if="dealJacket.finance && dealJacket.finance.transactionType == 'RETAIL'" class="lines">
                                        <InputNumber label="Term"
                                                     v-model:saturn="dealJacket.finance.term"
                                                     :disabled="true">
                                        </InputNumber>
                                        <InputNumber label="APR"
                                                     v-model:saturn="dealJacket.finance.annualPercentageRate"
                                                     :disabled="true"
                                                     :isPercent="true"
                                                     :precision="2">
                                        </InputNumber>
                                        <InputCurrency label="Amount Financed"
                                                       v-model:saturn="dealJacket.finance.amountFinanced"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                        <InputCurrency label="Taxable Accessories"
                                                       v-model:saturn="dealJacket.finance.taxableAccessories"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                        <InputCurrency label="Non-Taxable Accessories"
                                                       v-model:saturn="dealJacket.finance.nonTaxableAccessories"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                        <InputCurrency label="Total Accessories"
                                                       v-model:saturn="dealJacket.finance.totalAccessories"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                    </div>
                                    <div :style="$grid('1-1-1-1')" v-if="dealJacket.finance && dealJacket.finance.transactionType == 'LEASE'" class="lines">
                                        <InputNumber label="Term"
                                                     v-model:saturn="dealJacket.finance.term"
                                                     :disabled="true">
                                        </InputNumber>
                                        <InputNumber label="Money Factor"
                                                     v-model:saturn="dealJacket.finance.leaseFactor"
                                                     :disabled="true"
                                                     :isPercent="true"
                                                     :precision="5">
                                        </InputNumber>
                                        <InputNumber label="Residual %"
                                                     v-model:saturn="dealJacket.finance.residualPercentage"
                                                     :disabled="true"
                                                     :isPercent="true"
                                                     :precision="2">
                                        </InputNumber>
                                        <InputCurrency label="Residual $"
                                                       v-model:saturn="dealJacket.finance.residualAmount"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                        <InputCurrency label="Gross Cap Cost"
                                                       v-model:saturn="dealJacket.finance.grossCapitalizedCost"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                        <InputCurrency label="Adjusted Cap Cost"
                                                       v-model:saturn="dealJacket.finance.adjustedCapitalizedCost"
                                                       :disabled="true"
                                                       :precision="2">
                                        </InputCurrency>
                                        <InputNumber label="Mileage"
                                                     v-model:saturn="dealJacket.finance.leaseMileage"
                                                     :disabled="true">
                                        </InputNumber>
                                    </div>
                                </Panel>
                                <Panel title="Finance" v-if="dealJacket.insuranceWarranties">
                                    <RichTable
                                               :tableData="dealJacket.insuranceWarranties"
                                               :headers="warrantiesHeaders">

                                    </RichTable>
                                </Panel>
                            </div>
                    </div>
                </template>
                <template v-slot:tab-head-error>
                    Error
                </template>
                <template v-slot:tab-panel-error>
                    <div :style="$grid('1')">
                        <Panel v-if="modal.passedData.row.errorResponse != null" title="Error">
                            <div :style="$grid('1-1')">
                                <InputNumber label="Status"
                                             v-model:saturn="modal.passedData.row.errorResponse.status"
                                             :disabled="true">
                                </InputNumber>
                                <InputNumber label="Error Code"
                                             v-model:saturn="modal.passedData.row.errorResponse.errorCode"
                                             :disabled="true">
                                </InputNumber>
                            </div>
                            <div :style="$grid('1-1')">
                                <InputTextbox label="Error Message"
                                              disabled="true"
                                              :multiline="true"
                                              v-model:saturn="modal.passedData.row.errorResponse.errorMessage">
                                </InputTextbox>
                                <InputTextbox label="Property"
                                              disabled="true"
                                              :multiline="true"
                                              v-model:saturn="modal.passedData.row.errorResponse.property">
                                </InputTextbox>
                            </div>
                            <div :style="$grid('1-1')">
                                <InputTextbox label="Developer Message"
                                              disabled="true"
                                              :multiline="true"
                                              v-model:saturn="modal.passedData.row.errorResponse.developerMessage">
                                </InputTextbox>
                                <InputTextbox label="Details Location"
                                              disabled="true"
                                              :multiline="true"
                                              v-model:saturn="modal.passedData.row.errorResponse.detailsLocation">
                                </InputTextbox>
                            </div>
                        </Panel>
                    </div>
                </template>
                <template v-slot:tab-head-creditapp>
                    Credit Application
                </template>
                <template v-slot:tab-panel-creditapp>
                    <div :style="$grid('1-1')">
                        <Panel title="Application" v-if="modal.passedData.row.creditApplication && modal.passedData.row.creditApplication.creditAppFinanceSourceDetails && modal.passedData.row.creditApplication.creditAppFinanceSourceDetails.length > 0 && modal.passedData.row.creditApplication.creditAppFinanceSourceDetails[0]" class="creditDecisionPanel">
                            <div :style="$grid('2-3')">
                                <InputTextbox label="Finance Source ID"
                                              v-model:saturn="modal.passedData.row.creditApplication.creditAppFinanceSourceDetails[0].fsid"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Program Name"
                                              v-model:saturn="modal.passedData.row.creditApplication.creditAppFinanceSourceDetails[0].programName"
                                              disabled="true">
                                </InputTextbox>
                            </div>
                        </Panel>
                        <Panel v-if="modal.passedData.row" title="Response">
                            <div :style="$grid('2-3')">
                                <InputTextbox label="Conversation ID"
                                              v-model:saturn="modal.passedData.row.conversationId"
                                              disabled="true">
                                </InputTextbox>
                                <InputTextbox label="Credit Application GUID"
                                              v-model:saturn="modal.passedData.row.id"
                                              disabled="true">
                                </InputTextbox>
                            </div>
                        </Panel>
                    </div>
                </template>
                <template v-slot:tab-head-creditdecision>
                    Credit Decision
                </template>
                <template v-slot:tab-panel-creditdecision >

                    <AccordionList
                        :list="creditApplicationsResponses"
                         listLabel="name"
                        >
                        <template #listHeader="{ headerItem: item, index: index }">
                           <div class="r1-accordion-header">
                            <div class="main-content">
                                <div class="status">
                                    <div class="value">{{ item.status }}</div>
                                    <i v-if="item.status === 'Error'" class="fas fa-exclamation-triangle icon-error"></i>
                                    <i v-if="item.status === 'Approved'" class="fas fa-thumbs-up icon-approved"></i>
                                    <i v-if="item.status === 'Denied'" class="fas fa-thumbs-down icon-denied"></i>
                                    <i v-if="item.status === 'Conditional'" class="fas fa-exchange-alt icon-conditional"></i>
                                    <i v-if="item.status === 'Condition'" class="fas fa-exchange-alt icon-conditional"></i>
                                    <i v-if="item.status === 'Preliminary'" class="fas fa-circle-notch icon-preliminary"></i>
                                    <i v-if="item.status === 'Withdrawn'" class="fas fa-trash icon-withdrawn"></i>
                                    <i v-if="item.status === 'Booked'" class="fas fa-solid fa-coins icon-approved"></i>
                                    <i v-if="item.status === 'Waiting'" class="fas fa-spinner fa-spin icon-waiting"></i>
                                    <i v-if="item.status === 'Contract Received'" class="fas fa-file icon-approved"></i>
                                </div>
                                <div class="date-description">
                                    <div class="datetime">
                                        <div class="label">Submitted:</div>
                                        <div class="value">{{ util.toMoment(item.creditDecision.decision.submittedDateTime).format("MM/DD/YYYY - h:mm a") }}</div>
                                    </div>
                                    <div class="datetime">
                                        <div class="label">Decision:</div>
                                        <div class="value">{{ util.toMoment(item.creditDecision.decision.decisionDateTime).format("MM/DD/YYYY - h:mm a") }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="dev-reference-row">
                                <div class="dev-data">
                                    <div class="label">App #:</div>
                                    <div class="value">{{ item.creditDecision.applicationNumber }}</div>
                                </div>
                                <div class="dev-data">
                                    <div class="label">Conversation Id:</div>
                                    <div class="value">{{ item.creditDecision.conversationId }}</div>
                                </div>
                                <div class="dev-data">
                                    <div class="label">Sequence #:</div>
                                    <div class="value">{{ item.creditDecision.sequenceNumber }}</div>
                                </div>
                            </div>
                           </div>
                        </template>

                        <template #listBody="{bodyItem: item, index: index}">
                            <RouteOneCreditDecisionView
                                :creditDecision="item.creditDecision"
                                :status="item.status"
                                :financeType="item.financeType"
                                :canViewRawData="canViewRawData"
                            />
                        </template>
                    </AccordionList>
                </template>

            </TabMenu>
        </div>
        <div class="modal-footer">
            <button class="button" @click="cancel()">Close</button>
        </div>

    </div>
</template>

<script>
    import $modal from '@core/services/modal'
    import api from '@core/services/api'
    import util from '@core/services/util'
    import Panel from '@core/components/Panel.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import RichTable from '@core/components/RichTable.vue'
    import TableItemListComponent from '@/components/TableItemListComponent.vue'
    import TabMenu from '@/components/TabMenu.vue'
    import modalPre from '@/modals/modalPre.vue'
    import settings from 'settings'
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue'
    import CreditApplication, {CreditApplicationType} from '@core/classes/CreditApplication'
    import RouteOneCreditDecisionView from '@/components/RouteOneCreditDecisionView.vue'
    import AccordionList from '@core/components/AccordionList.vue'
    import FIMenuCreditApplication from '@core/classes/FIMenuCreditApplication.ts'

    export default {
        name: "modalRouteOneView",
        props: ['modal'],
        data() {
            return {
                fimenu: null,
                dealJacket: null,
                creditDecision: null,
                status: null,
                statuses: [
                    { code: 'A', description: 'Approved' },
                    { code: 'D', description: 'Denied' },
                    { code: 'O', description: 'Condition' },
                    { code: 'R', description: 'Preliminary' },
                    { code: 'I', description: 'Withdrawn' },
                    { code: 'B', description: 'Booked' },
                    { code: 'N', description: 'New' },
                    { code: 'OT', description: 'Contract Received'}
                ],
                financeType: null,
                financeTypes: [
                    { code: 1, description: 'Retail' },
                    { code: 2, description: 'Lease' },
                    { code: 3, description: 'Balloon' },
                    { code: 4, description: 'Payment Call' },
                    { code: 6, description: 'TRAC / Open - End' },
                    { code: 99, description: 'Custom' },
                ],
                vehicleHeaders: [
                    { name: 'tradeInVehicleIndicator', display: "Trade-In" },
                    { name: 'vin', display: "VIN", value: (v) => v.vehicle.vin },
                    { name: 'style', display: "Style", value: (v) => v.vehicle.year + ' ' + v.vehicle.make + ' ' + v.vehicle.model + ' ' + v.vehicle.style },
                    { name: 'condition', display: "Condition", value: (v) => v.vehicle.condition },
                    { name: 'mileage', display: "Mileage", value: (v) => v.vehicle.mileage },
                    { name: 'msrp', display: "MSRP", value: (v) => v.vehicle.msrp, filter: this.$filters.currency2, cssClass: 'column-number' },
                    { name: 'invoice', display: "Invoice", value: (v) => v.vehicle.wholesaleAmount, filter: this.$filters.currency2, cssClass: 'column-number' },
                    { name: 'grossAllowance', display: "Allowance", value: (v) => v.vehicle.grossAllowance, filter: this.$filters.currency2, cssClass: 'column-number' },
                    { name: 'netTradeInAllowance', display: "Net Trade", value: (v) => v.vehicle.netTradeInAllowance, filter: this.$filters.currency2, cssClass: 'column-number' },
                    { name: 'payoffOwed', display: "Payoff", value: (v) => v.vehicle.payoffOwed, filter: this.$filters.currency2, cssClass: 'column-number' },

                ],
                warrantiesHeaders: [
                    { name: 'productTypeCode', display: "Type" },
                    { name: 'premium', display: "Amount", filter: this.$filters.currency2, cssClass: 'column-number' },
                    { name: 'otherProductTypeDescription', display: "Description" },
                ],
                initialTab: 'creditdecision',
                tabs: ['creditdecision', 'dealjacket', 'creditapp'],
                isLoading:  false,
                creditApplicationsResponses: [] // Credit decisions
            }
        },
        computed: {
            util() {
                return util
            },
            canViewRawData() {
                return this.$route.query.debug == 'true' || settings.environmentName != 'PRODUCTION' || this.$global.isAdminView;
            },
            dealType(){
                return CreditApplicationType[this.modal.passedData.row.dealType]
            }

        },

        async created() {
            //this.dealJacket = JSON.parse(this.modal.passedData.row.dealJacketJson);
            this.dealJacket = await this.loadDealJacketJson();


            if (this.modal.passedData.row.errorResponse != null) {
                this.tabs.unshift('error');
                this.initialTab = 'error';
            }
            this.fimenu = this.modal.passedData.fimenu

            await this.getCreditDecision();
        },

        methods: {
            // R1 Specific stuff for now.
            async loadDealJacketJson() {
                try {
                    this.isLoading = true;
                    const response = await api.routeOne.getDealJacketJSON(this.modal.passedData.row.dealJacketId);
                    if (response.data) return response.data;
                } catch (err){
                    util.toastr("Could not load the deal jacket.")
                } finally {
                    this.isLoading = false;
                }

                return "";
            },
            close() {
                $modal.close();
            },
            cancel() {
                $modal.cancel();
            },
            viewDealJacketJSON() {
                $modal.open(modalPre, { name: 'modalPre', passedData: { data: { request: this.dealJacket } }, backdrop: true });
            },
            viewCreditDecisionJSON() {
                $modal.open(modalPre, { name: 'modalPre', passedData: { data: { request: this.creditDecision } }, backdrop: true });
            },
            updateContactsForDisplay(contact) {
                //console.log('telephoneDisplay', telephoneDisplay)
                let telephoneDisplay = contact.telephone?.map(t => { return { key: t.type, divider: "\u00A0", value: t.number } });
                if (telephoneDisplay) contact.telephoneDisplay = { itemList: telephoneDisplay };

                const emailAddressDisplay = contact.emailAddress?.map(t => { return { value: t } });
                if (emailAddressDisplay) contact.emailAddressDisplay = { itemList: emailAddressDisplay };

                const faxDisplay = contact.fax?.map(t => { return { value: t } });
                if (faxDisplay) contact.faxDisplay = { itemList: faxDisplay };
            },
            async getCreditDecision() {
                //console.log('about to await');
                if (this.modal.passedData.row) {

                    try {
                        const creditApplication = new FIMenuCreditApplication(this.modal.passedData.row);

                        // Find the R1 lender code for this credit application.
                        const saturnLender = this.$global.Lenders.find(lender =>lender.lenderCode == creditApplication.lender.lenderCode);
                        const lastVersion = saturnLender?.versions[0];
                        const lenderByDealType = lastVersion?.getLenderByDealType(this.fimenu.dealType);
                        const r1LenderCode = lenderByDealType?.getR1Code() ?? null;

                        const response = await api.routeOne.getAllCreditDecisionsForCreditApp(creditApplication?.conversationId, r1LenderCode)
                        if (response?.data && Array.isArray(response.data)) {
                            this.creditApplicationsResponses = response.data.map(resp => {
                                const cAppResponse = {
                                    creditDecision: util.clone(resp),
                                    status: this.statuses.find(s => s.code === resp.applicationStatus)?.description,
                                    financeType: null
                                }

                                if (cAppResponse.creditDecision.decision?.financing) {
                                    cAppResponse.financeType =  this.financeTypes.find(f => f.code === cAppResponse.creditDecision.decision.financing.financeType).description;
                                }

                                cAppResponse.creditDecision.creditContractData?.financeCompany?.contact?.forEach(c => this.updateContactsForDisplay(c));

                                return cAppResponse
                            })
                        }
                    } catch(err) {
                        console.error(err)
                    }

                    // await api.routeOne.getLatestRouteOneCreditDecision(this.modal.passedData.row.conversationId).then((response) => {
                    //     //console.log('response', response);
                    //     if (response && response.data) {
                    //         //console.log('response.data', response.data)
                    //         this.creditDecision = util.clone(response.data);
                    //         // eslint-disable-next-line no-console
                    //         console.log('this.creditDecision', this.creditDecision);
                    //         this.status = this.statuses.find(s => s.code === this.creditDecision.applicationStatus).description;
                    //         if (this.creditDecision && this.creditDecision.decision && this.creditDecision.decision.financing)
                    //             this.financeType = this.financeTypes.find(f => f.code === this.creditDecision.decision.financing.financeType).description;
                    //         if (this.creditDecision && this.creditDecision.creditContractData && this.creditDecision.creditContractData.financeCompany && this.creditDecision.creditContractData.financeCompany.contact && this.creditDecision.creditContractData.financeCompany && this.creditDecision.creditContractData.financeCompany.contact.length > 0)
                    //             this.creditDecision.creditContractData.financeCompany.contact.forEach(c => this.updateContactsForDisplay(c));

                    //     }
                    // });
                }
            },
        },
        components: {
            Panel,
            InputNumber,
            InputTextbox,
            InputCurrency,
            RouteOneCreditDecisionView,
            RichTable,
            TabMenu,
            IsBusySectionComponent,
            AccordionList
        }
    };
</script>
<style>
    .lines {
        align-items: flex-end;
    }

    .creditDecisionPanel {
        margin-bottom: 10px;
    }

    .r1container .column-number{
        text-align: start;
    }

    .r1container .Accordion .accordion-header {
        height: 95px;
    }
        .r1container .Accordion .accordion-header .slot-container {
        left: 0;
        bottom: 0;
        top:0;
        right: 0;
        max-width: 100%;
    }

    .r1container .r1-tabmenu {
        min-height: 698px;
    }

    .r1-accordion-header{
        display: flex;
        padding: 0 20px;
        flex-direction: column;
        width: 100%;
    }
    .r1-accordion-header div {
        display: flex;
    }

    .r1-accordion-header .dev-reference-row {
        align-self: flex-end;
        gap: 30px;
        font-size: .8rem;
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0 0 10px 25px;
        z-index: 99999;
    }
    .r1-accordion-header .dev-reference-row > div {
        gap: 5px;
    }
    .r1-accordion-header .main-content {
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .r1-accordion-header .main-content .status{
        align-items: center;
        gap: 10px;
        font-size: 1.8rem;
        font-weight: 400;
    }
    .r1-accordion-header .main-content .status i {
        font-size: 1.1rem;
    }
    .r1-accordion-header .main-content .date-description {
        display: flex;
        flex-direction: column;
        margin-right: 60px;
        font-size: 0.9rem;
    }
    .r1-accordion-header .main-content .date-description .value{
        font-weight: 600;;
    }
    .r1-accordion-header .main-content .date-description > div {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
    }



    /* Define color styles for each icon */
    .icon-error {
        color: #dc3545; /* Red for error */
    }

    .icon-approved {
        color: #28a745; /* Green for approved */
    }

    .icon-denied {
        color: #dc3545; /* Red for denied */
    }

    .icon-conditional {
        color: #ffc107; /* Orange for conditional */
    }

    .icon-preliminary {
        color: #ffc107; /* Orange for preliminary */
    }

    .icon-withdrawn {
        color: #dc3545; /* Red for withdrawn */
    }

    .icon-booked {
        color: #28a745; /* Green for booked */
    }

    .icon-waiting {
        color: #dc3545; /* Red for waiting */
    }

</style>
