import EnumHelper, { ToListItems } from "@core/helpers/enum-helper";
import { CustomersRelationship } from "@core/classes/SharedEnums";

export default class CustomersRelationshipHelper extends EnumHelper<CustomersRelationship>
{ 
   public static toList: ToListItems<CustomersRelationship>[] = [
    {
        display: 'Other',
        value: CustomersRelationship.Other,
     },
    {
        display: 'Spouse',
        value: CustomersRelationship.Spouse,
     },
    {
        display: 'Resides with',
        value: CustomersRelationship.ResidesWith,
     },
    {
        display: 'Parent',
        value: CustomersRelationship.Parent,
     },
   ];
   
   constructor()
   { 
       super(CustomersRelationshipHelper.toList);
   }
   
} 