<template>
    <div class="modal-container ModalAddEditAddition">
        <div class="modal-header">
            Addition Details <span v-if="$global.isAdminView && addition">{{ addition.titleId }}</span>
        </div>
        <div class="modal-body">
            <fieldset :disabled="modal.isModalDisabled">
                <Panel title="Basic Info">
                    <template v-slot:HeaderComponent>
                        <div class="panel-buttons">
                            <InputRichDropdown v-if="isNewAddition"
                                               v-model:saturn="selectedAddition"
                                               :list="storeAdditions"
                                               :valueMap="(pf) => pf"
                                               :display="(pf) => (pf.name ? pf.name : 'CUSTOM ADDITION')"
                                               @change="onStoreAdditionSelected"
                                               nothingSelected="-- ADDITION Type --">
                            </InputRichDropdown>
                        </div>
                    </template>

                    <div :style="$grid('1-1')">
                        <div>
                            <InputTextbox label="Name"
                                          v-model:saturn="addition.name"
                                          placeholder="Addition Name"
                                          :isUpperCase="true"
                                          :invalid="v$.addition.name.$invalid"
                                          :disabled="!addition.isEditableName || (isNewAddition && !selectedAddition) || (modal.isDealLocked && addition.itemized)">
                                <template #validation>
                                    <div v-if="v$.addition.name.required.$invalid">Name is required</div>
                                    <div v-if="v$.addition.name.uniqueAdditionName.$invalid">Name must be unique</div>
                                </template>
                            </InputTextbox>
                        </div>
                        <div>
                            <InputRichDropdown label="Type"
                                               v-model:saturn="addition.type"
                                               :list="additionTypes"
                                               :valueMap="(additionType) => additionType.value"
                                               :display="(additionType) => additionType.display"
                                               nothingSelected="-- Select Value --"
                                               @change="onAdditionTypeSelected"
                                               :invalid="v$.addition.type.$invalid"
                                               :disabled="!addition.isEditableType || (isNewAddition && !selectedAddition) || modal.isDealLocked">
                                <template #validation>
                                    <div v-if="v$.addition.type.required.$invalid">Type is required</div>
                                </template>
                            </InputRichDropdown>
                        </div>

                    </div>
                    <div :style="$grid('1-1')">
                        <InputCurrency label="Price"
                                       placeholder="Price"
                                       v-model:saturn="addition.price"
                                       :precision="2"
                                       :invalid="v$.addition.price.$invalid"
                                       :disabled="!addition.isEditablePrice || (isNewAddition && !selectedAddition) || modal.isDealLocked">
                            <template #validation>
                                <div v-if="v$.addition.price.required.$invalid">Price is required</div>
                            </template>
                        </InputCurrency>
                        <InputCurrency label="Cost"
                                       placeholder="Cost"
                                       v-model:saturn="addition.cost"
                                       :precision="2"
                                       :invalid="v$.addition.cost.$invalid"
                                       :disabled="!addition.isEditableCost || (isNewAddition && !selectedAddition)">
                            <template #validation>
                                <div v-if="v$.addition.cost.required.$invalid">Cost is required</div>
                            </template>
                        </InputCurrency>
                    </div>
                    <div :style="$grid('1-1')" class="no-margin-grid">
                        <InputRichDropdown label="Profit Flag"
                                           v-model:saturn="addition.profitFlag"
                                           :list="profitFlags"
                                           :valueMap="(pf) => pf.value"
                                           :display="(pf) => pf.text"
                                           nothingSelected="-- Select Value --"
                                           :invalid="v$.addition.profitFlag.$invalid"
                                           :disabled="!addition.isEditableProfitFlag || (isNewAddition && !selectedAddition) || modal.isDealLocked">
                            <template #validation>
                                <div v-if="v$.addition.profitFlag.required.$invalid">Profit Flag To is required</div>
                            </template>
                        </InputRichDropdown>

                        <InputRichDropdown v-if="!isNewAddition || selectedAddition"
                                           label="Itemized on Buyer's Order"
                                           class="itemized"
                                           v-model:saturn="addition.itemized"
                                           :list="itemizedOptions"
                                           :valueMap="(o) => o.value"
                                           :display="(o) => o.display"
                                           :disabled="!addition.isEditableItemized || (addition.isEditableItemized && modal.isDealLocked)">
                        </InputRichDropdown>

                        <!--PhysicalAddition-->
                        <fieldset v-if="addition.physicalAddition" :disabled="modal.isDealLocked">
                            <div class="radio-button-inline" :invalid="v$.addition.physicalAddition.isInstalled.$invalid">
                                <div>Is this item already installed?</div>
                                <div>
                                    <InputRadio label="Yes" :valueMatch="true" v-model="addition.physicalAddition.isInstalled"></InputRadio>
                                    <InputRadio label="No" :valueMatch="false" v-model="addition.physicalAddition.isInstalled"></InputRadio>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset v-if="addition.monetaryAddition" :disabled="modal.isDealLocked">
                            <div>
                                <div>
                                    <InputTextbox label="Paid To"
                                                  v-model:saturn="addition.monetaryAddition.paidTo"
                                                  placeholder="Paid To"
                                                  :isUpperCase="true"
                                                  :invalid="v$.addition.monetaryAddition.paidTo.$invalid">
                                        <template #validation>
                                            <div v-if="v$.addition.monetaryAddition.paidTo.required.$invalid">Paid To is required</div>
                                        </template>
                                    </InputTextbox>
                                </div>
                            </div>
                        </fieldset>

                    </div>
                </Panel>
                <Panel title="Tax Lines to Apply">
                    <fieldset class="no-margin-grid" :style="$grid('1')" :disabled="modal.isDealLocked">
                        <div class="no-margin-grid" :style="$grid('5-1')" v-for="(taxLine, taxLineIndex) in bo.taxGroups" :key="taxLine.name">
                            <InputCheckbox :modelValue="isInThisTaxLine(taxLineIndex)" @click="toggleTaxLine(taxLineIndex)" class="panel-header-collapse-button description" :label="taxLine.name"></InputCheckbox>

                            <div class="amt">
                                <InputNumber v-model:saturn="taxLine.rate"
                                             placeholder="Tax Rate"
                                             :precision="2"
                                             :isPercent="true"
                                             :disabled="true">
                                </InputNumber>
                            </div>
                        </div>
                    </fieldset>
                </Panel>
            </fieldset>
        </div>
        <div class="modal-footer modal-footer-split">
            <div>
                <button v-if="!isNewAddition" class="button-unwind" @click="deleteAddition()" :disabled="modal.isDealLocked && (addition.amount || addition.itemized)">Delete Addition</button>
            </div>
            <div>
                <button class="button-unwind" @click="cancel()">Cancel</button>
                <button class="button-save" @click="close()" :disabled="modal.isModalDisabled || v$.$invalid || (modal.isDealLocked && addition.itemized && addition.isEditableItemized)">Save</button>
            </div>
        </div>
    </div>
</template>
<script>

    import $modal from '@core/services/modal';
    import DropdownHelper from '@core/helpers/dropdown-helper'
    import FIMenuBuyersOrder from '@core/classes/FIMenuBuyersOrder'
    import FIMenuBuyersOrderBucketItem from '@core/classes/FIMenuBuyersOrderBucketItem'
    import StoreAddition from '@core/classes/Store/StoreAddition'
    import util from '@core/services/util'
    import ENUMS from "@core/classes/Enums"
    import PhysicalAddition from '@core/classes/Deal/PhysicalAddition'
    import MonetaryAddition from '@core/classes/Deal/MonetaryAddition'
    import Panel from '@core/components/Panel.vue'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import InputRadio from '@core/components/InputRadio.vue'
    import FIMenuBuyersOrderBucket from '../../../vue-core/classes/FIMenuBuyersOrderBucket';
    import { useVuelidate } from '@vuelidate/core'

    export default {
        name: "modalAddEditAddition",
        props: ['modal'],
        setup(){
            return { v$: useVuelidate({$scope: false}) }
        },
        data() {
            return {
                bo: null,
                addition: null,
                additionIndex: null,
                bucket: null,
                originalName: null,
                isNewAddition: false,
                selectedAddition: null,
                storeAdditions: null,
                additionTypes: null,
                profitFlags: null,
                itemizedOptions: [
                    {
                        value: true,
                        display: "Yes"
                    },
                    {
                        value: false,
                        display: "No"
                    }
                ],
                ADDITIONS_TITLE_ID: '10001',
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            }
        },
        async created() {
            this.bo = new FIMenuBuyersOrder(this.modal.bo);
            this.additionTypes = DropdownHelper.getAdditionTypes();
            this.profitFlags = DropdownHelper.getProfitFlags();

            //WE ONLY WANT TO ALLOW TO ADD THE SAME ADDITION ONCE, NAME MUST BE UNIQUE
            //WE ARE NOT ALLOWING CUSTOM ADDITIONS DUE TO ACCOUNTING MAPPING LOGIC
            //let allAdditionNamesAlreadyUsed = this.bo.additions.map(a => a.name);
            //this.storeAdditions = this.modal.storeAdditions.filter(addition => !allAdditionNamesAlreadyUsed.includes(addition.name)).map(addition => new StoreAddition(addition));

            this.storeAdditions = this.modal.storeAdditions.map(addition => new StoreAddition(addition));

            //TO ALLOW CUSTOM ADDITIONS
            //this.storeAdditions.push(this.getNewStoreAddition())

            //IF NEW ADDITION, CREATE IT AND CREATE A BUCKET FOR IT
            if (!this.modal.itemInfo.item) {

                this.isNewAddition = true;

                //CREATE NEW ADDITION AND NEW BUCKET
                let bucket = this.bo.buckets.find(b => b.titleId == this.ADDITIONS_TITLE_ID)
                if (!bucket)
                    bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, null, this.ADDITIONS_TITLE_ID);

                this.bucket = bucket;
                this.addition = this.bo.addNewDefaultAddition(this.bucket.titleId, true, true, true);

            }
            else {

                this.isNewAddition = false;

                this.addition = this.bo.additions.find(f => f.name == this.modal.itemInfo.item.name);
                this.originalName = this.addition.name
                this.bucket = this.bo.buckets.find(b => b.titleId == this.addition.titleId);

                if (!this.bucket) {
                    this.bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, this.addition.titleId, this.addition.titleId);
                }

                this.additionIndex = this.bo.additions.findIndex(f => f.name == this.modal.itemInfo.item.name);
            }

            // Only tax lines get reset when Buyer's Order is reset
            this.$watch(() => this.bucket.taxIndex, () => {
                this.bo.isManuallyChanged = true;
            });
        },
        validations() {

            return {
                addition: this.addition.validation(this.bo)
            };

        },
        methods: {
            getNewStoreAddition() {
                //STORE DEFAULT ADDITION
                const customStoreAddition = new StoreAddition();
                customStoreAddition.isCustom = true;
                customStoreAddition.isEditableName = true;
                customStoreAddition.isEditableCost = true;
                customStoreAddition.isEditablePrice = true;
                customStoreAddition.isEditableType = true;
                customStoreAddition.isEditableProfitFlag = true;
                customStoreAddition.isEditableTitleId = true;
                customStoreAddition.profitFlag = ENUMS.PROFIT_FLAGS.defaultForAdditions();
                customStoreAddition.titleId = this.ADDITIONS_TITLE_ID;

                return customStoreAddition;
            },
            onStoreAdditionSelected() {
                if (!this.selectedAddition) return;

                //ADDITION INFO
                this.addition.id = this.selectedAddition.id;
                this.addition.type = this.selectedAddition.type;
                this.addition.name = this.selectedAddition.name;
                this.addition.price = this.selectedAddition.price;
                this.addition.cost = this.selectedAddition.cost;
                this.addition.isCustom = this.selectedAddition.isCustom;
                this.addition.profitFlag = this.selectedAddition.profitFlag;
                this.addition.isEditableName = this.selectedAddition.isEditableName;
                this.addition.isEditablePrice = this.selectedAddition.isEditablePrice;
                this.addition.isEditableCost = this.selectedAddition.isEditableCost;
                this.addition.isEditableType = this.selectedAddition.isEditableType;
                this.addition.isEditableProfitFlag = this.selectedAddition.isEditableProfitFlag;
                this.addition.isEditableTitleId = this.selectedAddition.isEditableTitleId;
                this.addition.isEditableItemized = this.selectedAddition.isEditableItemized;
                this.addition.itemized = this.addition.isEditableItemized ? false : this.selectedAddition.itemized;
                this.addition.titleId = this.selectedAddition.titleId;

                //SET UP BUCKET & TITLE ID
                let bucket = null;
                if (this.selectedAddition.titleId) {
                    bucket = this.bo.buckets.find(b => b.titleId == this.selectedAddition.titleId);
                }

                if (!bucket) {
                    bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, 0, 0, null, null, this.selectedAddition.titleId);
                }

                this.bucket = bucket
            },

            //GIVEN A taxLineIndex RETURNS TRUE IF THE CURRENT ITEM'S IS TAXED ON THAT TAX LINE
            isInThisTaxLine(taxLineIndex) {

                const taxBinaryArray = util.taxIndexToBinaryArray(this.bucket.taxIndex);
                const isInThisTaxLine = (taxBinaryArray[taxLineIndex] == '1');

                return isInThisTaxLine;

            },
            toggleTaxLine(taxLineIndex) {
               const isSelected = !this.isInThisTaxLine(taxLineIndex);

                const originalBucketData = new FIMenuBuyersOrderBucket(this.bucket);

                //GET THE NEW TAX INDEX THIS ITEM WILL HAVE AFTER TOGGLE
                const taxBinaryArray = util.taxIndexToBinaryArray(this.bucket.taxIndex);
                taxBinaryArray[taxLineIndex] = isSelected ? '1' : '0';
                const newTaxIndex = util.binaryArrayToTaxIndex(taxBinaryArray);

                //GET THE NEW TAX INDEX THIS ITEM WILL HAVE AFTER TOGGLE
                const upfrontTaxBinaryArray = util.taxIndexToBinaryArray(this.bucket.upfrontTaxIndex);
                upfrontTaxBinaryArray[taxLineIndex] = isSelected ? '1' : '0';
                const newUpfrontTaxIndex = util.binaryArrayToTaxIndex(upfrontTaxBinaryArray);

                //CREATE NEW BUCKET WITH THE NEW TAX INDEX
                this.bucket = this.bo.addNewDefaultBucket(this.modal.itemInfo.bucketType, newTaxIndex, newUpfrontTaxIndex, originalBucketData, null, this.bucket.originalTitleId);
                this.addition.titleId = this.bucket.titleId;


            },

            onAdditionTypeSelected() {

                if (this.addition.type == ENUMS.ADDITION_TYPES.PHYSICAL_ADDITION) {
                    this.addition.physicalAddition = new PhysicalAddition();
                    this.addition.monetaryAddition = null;
                }
                else if (this.addition.type == ENUMS.ADDITION_TYPES.MONETARY_ADDITION) {
                    this.addition.monetaryAddition = new MonetaryAddition();
                    this.addition.physicalAddition = null;
                }
                else {
                    this.addition.physicalAddition = null;
                    this.addition.monetaryAddition = null;
                }

            },
            deleteAddition() {
                const result = confirm('Are you sure you want to delete this addition?');
                if (result) {
                    this.bo.removeAddition(this.additionIndex);
                    this.bucket.removeItem(this.originalName)
                    $modal.close(this.bo);
                }
            },
            close() {
                let item = this.bucket.items.find(i => i.name == this.originalName)
                if (item) {
                    item.name = this.addition.name
                    item.cost = this.addition.cost
                }
                else {
                    item = new FIMenuBuyersOrderBucketItem({
                        amount: this.addition.price,
                        cost: this.addition.cost,
                        profitFlag: this.addition.profitFlag,
                        name: this.addition.name,
                        sign: 1,
                        metadata: {
                            additionName: this.addition.name,
                            itemized: this.addition.itemized
                        },
                        bucket: this.bucket
                    });

                    this.bucket.items.push(item);
                }

                item.profit = this.addition.price - this.addition.cost;

                $modal.close(this.bo);
            },
            cancel() {
                $modal.cancel();
            },
        },
        components: {
            InputRichDropdown,
            InputCheckbox,
            InputCurrency,
            InputTextbox,
            InputRadio,
            InputNumber,
            Panel
        }
    };
</script>
<style>
    .ModalAddEditAddition .description {
        justify-content: flex-start;
    }
</style>