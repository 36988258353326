<template>
    <Panel
        class="PanelRouteOneSendTerms"
        :style="$grid('1')"
        :title="IsFinance ? 'Finance Term' : 'Lease Term'"
        :isSection="true"
    >
        <div class="small-gap" :style="$grid(IsFinance ? '1-3-3' : '1-2-2-3-1')">
            <InputNumber
                label="Term"
                v-model:saturn="localSubmissionData.term"
                placeholder="Months"
                :showIcon="false"
                max="999"
                min="0"
                :invalid="v$.localSubmissionData.term.$invalid"
            >
                <template #validation>
                    <div v-if="v$.localSubmissionData.term.$invalid">
                        {{ v$.localSubmissionData.term.$silentErrors[0].$message }}
                    </div>
                </template>
            </InputNumber>

            <InputNumber
                :label="!DisplayRateAsMF ? 'Sell Rate' : 'Sell MF'"
                v-model:saturn="displaySellRate"
                :placeholder="IsFinance ? 'Sell Rate' : 'Money Factor'"
                :precision="!DisplayRateAsMF ? 2 : 5"
                :disabled="!localSubmissionData.term"
                :invalid="v$.localSubmissionData.sellRate.$invalid"
                :isPercent="!DisplayRateAsMF"
                :showIcon="false"
            >
                <template #validation>
                    <div v-if="v$.localSubmissionData.sellRate.$invalid">
                        {{ v$.localSubmissionData.sellRate.$silentErrors[0].$message }}
                    </div>
                </template>
            </InputNumber>

            <InputNumber
                :label="!DisplayRateAsMF ? 'Buy Rate' : 'Buy MF'"
                v-model:saturn="displayBuyRate"
                placeholder="Buy Rate"
                :precision="!DisplayRateAsMF ? 2 : 5"
                :disabled="!localSubmissionData.term"
                :isPercent="!DisplayRateAsMF"
                :invalid="v$.localSubmissionData.buyRate.$invalid"
                :showIcon="false"
            >
                <template #validation>
                    <div v-if="v$.localSubmissionData.buyRate.$invalid">
                        {{ v$.localSubmissionData.buyRate.$silentErrors[0].$message }}
                    </div>
                </template>
            </InputNumber>

            <InputNumber
                v-if="!IsFinance && DisplayResidualAsPorc"
                label="Residual %"
                v-model:saturn="localSubmissionData.residualPercentage"
                placeholder="Residual"
                :precision="2"
                :isPercent="true"
                :disabled="!localSubmissionData.term"
                max="100"
                min="0"
                :invalid="!localSubmissionData.residualPercentage"
                @blur="residualPercentageOnBlurHandlerRewrite"
            >
            </InputNumber>

            <InputNumber
                v-else-if="!IsFinance && !DisplayResidualAsPorc"
                label="Residual Amt"
                v-model:saturn="localSubmissionData.residualAmount"
                placeholder="Residual Amount"
                :precision="2"
                :isPercent="false"
                :disabled="!localSubmissionData.term"
                :max="deal.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? deal.getMSRP() ?? 0"
                @blur="residualAmountOnBlurHandlerRewrite"
                :invalid="!localSubmissionData.residualAmount"
            >
            </InputNumber>
            <span class="fa-stack fa-1x swap-icon" @click="handleChangeResidualDisplay" v-if="!IsFinance">
                <i class="fas fa-square fa-stack-2x stack-background" />
                <i class="fas fa-exchange-alt fa-stack-1x" style="color: white" />
            </span>
        </div>
    </Panel>
</template>
<script setup lang="ts">
    // Classes & Interfaces
    import FIDealJacketAdditionalInformation, {
        FIDealJacketAdditionalInformationValidation,
    } from "@core/classes/FIDealJacketAdditionalInformation";
    import FIMenu from "@core/classes/FIMenu";

    // Helpers
    import { computed, ref, toRefs, watch } from "vue";
    import { useVuelidate } from "@vuelidate/core";

    // Components
    import InputNumber from "@core/components/InputNumber.vue";
    import Panel from "@core/components/Panel.vue";
    import VersionsLender from "@core/classes/LenderVersionClass";
    import Deal from "@/views/Deal.vue";
    import LenderRetail from "@core/classes/LenderRetail";
    import LenderLease from "@core/classes/LenderLease";
    import LenderDraft from "@core/classes/LenderDraft";

    export interface PanelRouteOneSendOtherPanelProps {
        submissionData: FIDealJacketAdditionalInformation;
        deal: FIMenu;
        lender: VersionsLender
    }

    const props = defineProps<PanelRouteOneSendOtherPanelProps>();
    const emit = defineEmits<{
        (event: "update:submissionData", value: FIDealJacketAdditionalInformation): void;
    }>();

    // Create a local copy of the prop
    // const localSubmissionData = ref(new FIDealJacketAdditionalInformation(props.submissionData));
   
    const { submissionData: localSubmissionData } = toRefs(props);

    // Watch for changes in the local data and emit updates to the parent
    watch(
        () => localSubmissionData.value,
        newValue => {
            emit("update:submissionData", newValue);
        },
        { deep: true },
    );

    const displaySellRate = computed({
        get() {
            return DisplayRateAsMF.value
                ? localSubmissionData.value.sellRate / 2400 // Convert MF to APR for Lease 
                :localSubmissionData.value.sellRate // APR for Finance
                
        },
        set(newValue: number) {
            localSubmissionData.value.sellRate = DisplayRateAsMF.value
                ? newValue * 2400 // Convert APR to MF for Lease
                : newValue   // Keep as-is for Finance
        },
    });

    const displayBuyRate = computed({
        get() {
            return DisplayRateAsMF.value
                ? localSubmissionData.value.buyRate / 2400 // Convert MF to APR for Lease
                :localSubmissionData.value.buyRate
        },
        set(newValue: number) {
            localSubmissionData.value.buyRate = DisplayRateAsMF.value
                ? newValue * 2400 // Convert APR to MF for Lease
                : newValue // Keep as-is for Finance
        },
    });

    const IsFinance = computed(() => props.deal.isFinance());
    
    function isLenderLease(lender: LenderRetail | LenderLease | LenderDraft): lender is LenderLease {
        return 'isMoneyFactorAsRate' in lender;
    }
    
    const LenderInformation = new VersionsLender(props.lender);
    const DisplayRateAsMF = computed(() => {
        const lender = LenderInformation.getLenderByDealType(props.deal.dealType);
        return  (isLenderLease(lender) ? (!lender.isMoneyFactorAsRate): false);
    });

    const DisplayResidualAsPorc = ref(false);

    const residualAmountOnBlurHandlerRewrite = () => {
        const vehicleValue = props.deal.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? props.deal.getMSRP() ?? 1;
        localSubmissionData.value.residualPercentage = (localSubmissionData.value.residualAmount / vehicleValue) * 100;
    };

    const residualPercentageOnBlurHandlerRewrite = () => {
        const vehicleValue = props.deal.getMSRPOrHighestOrDefaultRetailBookValue()?.retail ?? props.deal.getMSRP() ?? 0;

        localSubmissionData.value.residualAmount = (localSubmissionData.value.residualPercentage / 100) * vehicleValue;
    };

    const handleChangeResidualDisplay = () => {
        DisplayResidualAsPorc.value = !DisplayResidualAsPorc.value;
    };

    const rules = computed(() => ({
        localSubmissionData: FIDealJacketAdditionalInformationValidation(props.deal, localSubmissionData.value, DisplayRateAsMF.value) ?? {},
    }));

     // Use type assertion or provide a default validation
     const v$ = useVuelidate(rules, { localSubmissionData: localSubmissionData.value }, { $scope: false });
</script>
<style>

    .PanelRouteOneSendTerms .small-gap {
        gap: 3px !important;
        height: 100%;
        align-items: start;
        justify-content: center
    }
    .PanelRouteOneSendTerms .swap-icon {
        color: var(--main-color);
        cursor: pointer;
    }
</style>
