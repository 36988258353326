<template>
    <div class="checklist-subset-modal modal-container modal-x-large">
        <div class="modal-header">
            <span>Checklist</span>

            <div class="header-send-text">
                <LoaderComponent v-show="isBusy && fiManagerLinkActions.length > 0" />

                <DropdownButton v-if="fiManagerLinkActions.length > 0"
                                :label="(allowAccessInStore) ? 'Get Checklist Link' : 'Text Checklist Link'"
                                :dropUp="true"
                                :actions="fiManagerLinkActions"
                                :disabled="lockAllActions || isBusy" />
            </div>
        </div>
        <div class="modal-body">
            <h3 v-if="checklist.length < 1">No items pending.</h3>

            <div v-else>
                <h3 v-if="additionalMessage" class="additional-message">{{ additionalMessage }}</h3>
                <RichTable :tableData="checklist" :headers="headers">
                    <!--Status Column-->
                    <template #checkmark="row">
                        <i v-if="isItemVerified(row)" class="fas fa-check" />
                        <i v-else class="fas fa-times" />
                        <span>{{ getStatusText(row) }}</span>
                    </template>

                    <template #itemActions="row">
                        <button v-if="row.needsManualVerify" class="button-accept" :disabled="!row.status.document || lockAllActions || isBusy" @click="openChecklistReviewModal(row)">
                            Review
                        </button>

                        <button v-else-if="canResetPlaidIDV(row)" class="button-load" :disabled="lockAllActions || isBusy" @click="resetPlaidIDV(row)">
                            Reset Session
                        </button>

                        <span v-else />
                    </template>
                </RichTable>
            </div>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="close()" :disabled="isBusy">Close</button>
        </div>
    </div>
</template>


<script>
    import settings from "settings";
    import ENUMS from "@core/classes/Enums";
    import api from '@core/services/api';
    import util from '@core/services/util';
    import auth from '@core/services/auth';
    import $modal from '@core/services/modal';
    import modalInfo from '@core/modals/modalInfo.vue';
    import modalDealChecklistReview from '@/modals/modalDealChecklistReview.vue';
    import RichTable from '@core/components/RichTable.vue';
    import DropdownButton from '@/components/DropdownButton.vue';
    import LoaderComponent from '@core/components/LoaderComponent.vue';

    export default {
        name: "modalDealChecklistSubset",
        props: ['modal'],
        data() {
            return {
                fimenu: null,
                checklist: [],             // A list of DealChecklistItems
                isBusy: false,
                fiManagerLinkActions: null,
                lockAllActions: false,
                enableItemActions: false,   // When true, buttons that allow you to act on an item are visible
                allowAccessInStore: false,  // When true, Can generate a Redirect code to access customer checklist from an in-store tablet.
                additionalMessage: null,
            }
        },
        computed: {
            user() {
                return auth.getTokenPayload();
            },
            headers() {
                const headerList = [
                    {
                        name: 'status',
                        display: 'Status',
                        value: (row) => this.isItemVerified(row),
                        slot: 'checkmark',
                        cellCssClass: (row) => this.isItemVerified(row) ? 'verified' : 'not-verified'
                    },
                    {
                        name: 'name',
                        display: 'Name',
                        value: (row) => {
                            if (row.namePrefix) return `${row.namePrefix}: ${row.name}`;
                            return row.name;
                        }
                    },
                    {
                        name: 'type',
                        display: 'Type',
                        value: (row) => this.formatEnumKey(ENUMS.DealChecklistType, row.type)
                    },
                    {
                        name: 'description',
                        display: 'Description'
                    },
                ];

                if (this.enableItemActions && !this.lockAllActions)
                    headerList.push({ name: 'action', display: '', slot: 'itemActions' });

                return headerList;
            },
        },
        async created() {
            this.fimenu = this.modal.passedData.fimenu;
            this.checklist = this.modal.passedData.checklist;
            this.lockAllActions = this.modal.passedData.lockAllActions;

            this.additionalMessage = this.modal.passedData.additionalMessage;
            this.enableItemActions = this.modal.passedData.enableItemActions ?? false;
            this.allowAccessInStore = this.modal.passedData.allowAccessInStore ?? false;

            this.fiManagerLinkActions = [
                {
                    label: this.fimenu.customer.fullName,
                    role: ENUMS.PERSON_TYPES.CUSTOMER,
                    cellNumber: this.fimenu.customer.cell,
                    click: () => this.sendTextMessage(ENUMS.PERSON_TYPES.CUSTOMER, this.fimenu.customer.cell)
                }
            ];

            if (this.fimenu.coCustomer.fullName) {
                this.fiManagerLinkActions.push({
                    label: this.fimenu.coCustomer.fullName,
                    role: ENUMS.PERSON_TYPES.COCUSTOMER,
                    cellNumber: this.fimenu.coCustomer.cell,
                    click: () => this.sendTextMessage(ENUMS.PERSON_TYPES.COCUSTOMER, this.fimenu.coCustomer.cell)
                });
            }
        },
        methods: {
            close() {
                $modal.close();
            },
            getChecklistItem(id) {
                return this.checklist.find(c => c.id === id);
            },
            getStatusText(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);
                return ' ' + checklistItem.getStatusText();
            },
            formatPhoneNumber(number) {
                if (!number) return null;
                return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
            },
            formatEnumKey(enumObj, value) {
                //Expects either camel-case or all-caps delimited by underscore
                const key = util.getObjectKeyFromValue(enumObj, value);
                const spacesInserted = key.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
                return spacesInserted.replace('_', ' - ');
            },
            isItemVerified(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);
                return checklistItem.isItemVerified();
            },
            canResetPlaidIDV(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);
                return checklistItem.canResetPlaidIDV();
            },
            async sendTextMessage(forWhom, cellNumber) {
                if (!cellNumber) {
                    util.toastr('error', 'Error', 'No cell number provided. Cannot send text message.');
                    return;
                }

                if (this.allowAccessInStore) {
                    this.openRedirectModal(forWhom);
                    return
                }

                this.isBusy = true;

                const response = await api.checklist.textChecklistLink(settings.appDomain, this.fimenu.storeCode, this.fimenu.id, forWhom, `+1${cellNumber}`);
                if (response && response.data)
                    util.toastr('success', 'Success', 'Text message successfully sent');
                else
                    util.toastr('error', 'Error', 'Error sending text message.');

                this.isBusy = false;
            },
            async resetPlaidIDV(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);

                if (!checklistItem.canResetPlaidIDV()) {
                    util.toastr('error', 'Invalid Action', 'Cannot reset this checklist item.');
                    return;
                }

                this.isBusy = true;

                const idvResponse = checklistItem.getPlaidData().identityVerification.response;
                const idvAssociations = checklistItem.getPlaidData().associations;

                const response = await api.plaid.retryIdentityVerification(idvResponse.template.id, idvResponse.clientUserId, idvResponse.user, this.fimenu.storeCode, idvAssociations);
                if (!response || !response.data || response.data.error)
                    util.toastr('error', 'Error', 'Unable to reset Plaid session.');
                else
                    util.toastr('success', 'Success', 'Successfully reset Plaid session.');

                this.isBusy = false;

            },
            openRedirectModal(forWhom) {
                const customerName = this.fiManagerLinkActions.find(a => a.role === forWhom).label;
                const message = `${customerName} needs to give you permission to access their checklist. `
                    + 'They will be texted the secret code you need to login to their checklist portal. '
                    + 'You can enter this code on the Redirect Portal in order to access their checklist.';

                $modal.open(modalInfo, {
                    name: 'modalInfo',
                    passedData: {
                        title: 'Generate Access Code',
                        info: message,
                        acceptText: 'Generate Access Code',
                        cancelText: 'Cancel',
                    },
                    postFunction: async () => {
                        this.isBusy = true;

                        const response = await api.checklist.textAccessCode(this.fimenu.storeCode, this.fimenu.id, forWhom);
                        if (response && response.data)
                            util.toastr('success', 'Success', 'Text message successfully sent');
                        else
                            util.toastr('error', 'Error', 'Error sending text message.');

                        this.isBusy = false;
                    },
                    backdrop: false,
                });
            },
            openChecklistReviewModal(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);

                $modal.open(modalDealChecklistReview, {
                    name: 'modalDealChecklistReview',
                    passedData: {
                        checklistItem: checklistItem
                    },
                    postFunction: async (isVerified) => {
                        this.isBusy = true;
                        checklistItem.status.isVerified = isVerified;

                        const doc = checklistItem.getDocument();
                        if (doc) {
                            doc.docInfo.manualVerifications.push({ timestamp: Date.now(), isVerified: isVerified });
                            const verification = {
                                isVerified: isVerified,
                                verifiedBy: { EmployeeName: this.user.EmployeeName, EmployeeNumber: this.user.EmployeeCode },
                                verifierRole: ENUMS.PERSON_TYPES.FIMANAGER,
                                timestamp: new Date()
                            }

                            const verificationResponse = await api.documents.addDocumentVerification(doc.id, verification);
                            if (!verificationResponse?.data)
                                util.toastr('error', 'Server Error', 'Unable to save verification.');
                            else
                                util.toastr('success', 'Success', 'Verification successfully saved.');
                        }
                        this.isBusy = false;
                    }
                });
            }
        },
        components: {
            RichTable,
            DropdownButton,
            LoaderComponent,
        }
    };
</script>
<style>
    .checklist-subset-modal .verified, .checklist-subset-modal .not-verified {
        white-space: nowrap;
    }

    .checklist-subset-modal .modal-header .header-send-text .button-dropdown-container button {
        text-transform: capitalize;
    }

    .checklist-subset-modal .additional-message {
        margin: 0 0 10px 0;
        color: var(--error-color);
    }

    .checklist-subset-modal .verified {
        color: var(--success-color);
    }

    .checklist-subset-modal .not-verified {
        color: var(--error-color);
    }

    .checklist-subset-modal table.rich-table tbody {
        text-transform: unset;
    }

    .checklist-subset-modal .header-send-text {
        display: flex;
        align-items: center;
        gap: 10px;
    }
</style>
