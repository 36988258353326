import $modal from "@core/services/modal"
import modalBroadcastMessages from "@core/modals/modalBroadcastMessages.vue"
import ENUMS from "@core/classes/Enums"

let subscribedEvents = []

export const broadcastSubscribeEvents = (meetingHub, subscribeTo) => {
    subscribeTo.map(subscribe => {
        meetingHub.subscribeToGroupEvent(subscribe.event, 'Broadcast', subscribe.callback)
        subscribedEvents.push(subscribe.event)
    })
}

export const broadcastUnsubscribeEvents = (meetingHub, subscribeTo) => {
    subscribeTo.map(subscribe => {
        meetingHub.unsubscribeToGroupEvent(subscribe.event)

        subscribedEvents = subscribedEvents.filter(sEvent => sEvent !== subscribe.event)
    })
}

export const broadcastUnsubscribeAllEvents = (meetingHub) => {
    subscribedEvents.map(sEvent => {
        meetingHub.unsubscribeToGroupEvent(sEvent)
    })
    subscribedEvents = []
}

export const broadcastEmitEvent = (meetingHub, eventName, data) => {
    meetingHub.broadcastGroupEvent(eventName, 'Broadcast', data)
}

export const broadcastCallbacks = {
    openModalBroadcastMessages: (data) => {
        $modal.open(modalBroadcastMessages, { name: 'modalBroadcastMessages', passedData: { data: JSON.parse(data) }, backdrop: true });
    }
}

