<template>
    <div id="panel-video-chat">
        <CustomerMeetingDropdownMenu :fimenu="fimenu"
                                     :customerScreenActions="customerScreenActions"
                                     :paymentSheetDisabled="paymentSheetDisabled"
                                     :peopleInLobby="peopleInLobby"
                                     >
        </CustomerMeetingDropdownMenu>

        <div id="video-chat-wrapper">
            <div v-if="$meetingHub.connected || peopleInLobby.length > 0" class="video-chat-container">
                <VideoChatRoom v-if="enableVideoChat"
                    :peopleInLobby="peopleInLobby" >
                </VideoChatRoom>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomerMeetingDropdownMenu from '@/components/CustomerMeetingDropdownMenu.vue'
    import FIMenu from '@core/classes/FIMenu';
    import VideoChatRoom from '@core/components/VideoChatRoom.vue'

    export default {
        name: 'PanelCustomerVideoChat',
        props: {
            fimenu: FIMenu,
            customerScreenActions: Object,
            paymentSheetDisabled: Boolean,
            peopleInLobby: Array
        },
        computed: {
            inStore() {
                return this.meetingHelper.meetingInfo?.inStore;
            },
            meetingDropDownButtonLabel() {
                return this.meetingHelper.currentMeetingUsers?.length < 1 ? "Create Meeting" : "Add Participant";
            },
            enableVideoChat() {
                return (
                    (
                        this.$meetingHub.connected &&
                        this.$meetingHub.code &&
                        this.$meetingHub.myConnectionId &&
                        /* video chat should be enabled if meeting is remote or
                        if the customerWindowButton is not enabled (this applies to stores that have all-in-one computers since they have webcams)
                        */
                        (this.meetingHelper.inStore === false || !this.fimenu.store.storeSettings.isCustomerWindowButtonEnabled )
                    )
                    /* if the customer has joined the meeting before fimanager, their user will be in peopleInLobby
                     (fimanager will not be connected to meetingHub at this point, but the video chat should be enabled)
                    */
                    || this.peopleInLobby.length > 0
                )
            },
        },
        created() {
            this.meetingHelper.myRole = 'fimanager'
            this.meetingHelper.cameraAccessAcknowledged = true
            this.meetingHelper.cameraAccessAllowed = true
        },
        components: {
            VideoChatRoom,
            CustomerMeetingDropdownMenu,
        },
    }
</script>

<style>
    #panel-video-chat {
       /* width: 100%;
        height: 515px;*/

        width: 100%;
        height: 70%;
        position: relative;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 15px;
        box-sizing: border-box;
    }

    #video-chat-wrapper {
        /*margin-top: 5px;
        height: inherit;
        overflow: auto;*/
        max-height: 555px;
    }

</style>