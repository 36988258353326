<template>
    <Panel title="Customer Information" isSection>
        <div :style="$grid('1')">
            <InputRichDropdown
                v-model:saturn="localCustomerInfo.relationship"
                :list="customerRelationshupHelper.enumList"
                :valueMap="(x: ToListItems<CustomersRelationship>) => x.value"
                :display="(x: ToListItems<CustomersRelationship>) => x.display"
                label="Customers Relationship"
                nothingSelected="Spouse, Parents, etc."
            >
            </InputRichDropdown>

            <InputTextbox
                label="Relationship Description"
                v-model:saturn="localCustomerInfo.relationshipDescription"
                :disabled="localCustomerInfo.relationship != CustomersRelationship.Other"
                placeholder=""
            >
            </InputTextbox>
        </div>
    </Panel>
</template>
<script setup lang="ts">
    // Classes & Interfaces
    import { CustomersRelationship } from "@core/classes/SharedEnums";
    import FIMenu from "@core/classes/FIMenu";
    import { FIMenuCustomersInfo } from "@core/classes/FIMenuCustomersInfo";

    // Helpers
    import { ref, watch } from "vue";
    import CustomersRelationshipHelper from "@core/enums/CustomerRelationshipEnumsHelper";
    import { ToListItems } from "@core/helpers/enum-helper";

    // Components
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    import InputTextbox from "@core/components/InputTextbox.vue";
    import Panel from "@core/components/Panel.vue";

    export interface PanelRouteOneSendOtherCustomerInformationProps {
        customerInfo: FIMenuCustomersInfo;
    }

    const props = defineProps<PanelRouteOneSendOtherCustomerInformationProps>();
    const emit = defineEmits<{
        (event: "update:customerInfo", value: FIMenuCustomersInfo): void;
    }>();

    // Create a local copy of the prop
    const localCustomerInfo = ref({ ...props.customerInfo });

    // Watch for changes in the local data and emit updates to the parent
    watch(
        () => localCustomerInfo.value,
        newValue => {
            emit("update:customerInfo", newValue);
        },
        { deep: true },
    );

    const customerRelationshupHelper = new CustomersRelationshipHelper();
</script>
<style scoped></style>
