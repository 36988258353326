<template>
    <Panel title="Other" isSection>
        <div :style="$grid('1')">
            <InputRichDropdown
                v-model:saturn="submissionDataModel.bureauType"
                :list="bureausHelper.enumList"
                :valueMap="(x: ToListItems<CreditBureaurs>) => x.value"
                :display="(x: ToListItems<CreditBureaurs>) => x.display"
                label="Suggested Bureau"
                nothingSelected="Experian, Transunion or Equifax ..."
            />

            <InputTextbox
                label="Comments"
                v-model:saturn="submissionDataModel.comment"
                placeholder="Comments to send to the lender/analyst"
                :multiline="true"
                :maxlength="1999"
            />

            <InputCheckbox 
                v-model:model-value="submissionDataModel.allowLendersPassthrough"
                label="Allow the lender to passthrough"
            />
        </div>
    </Panel>
</template>
<script setup lang="ts">
    // Classes & Interfaces
    import { CreditBureaurs } from "@core/classes/SharedEnums";
    import FIDealJacketAdditionalInformation from "@core/classes/FIDealJacketAdditionalInformation";

    // Helpers
    import { computed, ref, watch } from "vue";
    import CreditBureausEnumsHelper from "@core/enums/BureaursEnumsHelper";
    import { ToListItems } from "@core/helpers/enum-helper";

    // Components
    import InputCheckbox from "@core/components/InputCheckbox.vue";
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    import InputTextbox from "@core/components/InputTextbox.vue";
    import Panel from "@core/components/Panel.vue";

    export interface PanelRouteOneSendOtherPanelProps {
        submissionData: FIDealJacketAdditionalInformation;
    }

    const props = defineProps<PanelRouteOneSendOtherPanelProps>();
    const emit = defineEmits<{
        (event: "update:submissionData", value: FIDealJacketAdditionalInformation): void;
    }>();

    const submissionDataModel = computed({
        get: () => props.submissionData,
        set: (newValue) => {
            emit("update:submissionData", new FIDealJacketAdditionalInformation(newValue));
        }
    });

    const bureausHelper = new CreditBureausEnumsHelper();
</script>
<style scoped></style>
