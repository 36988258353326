<template>
    <div class="modalCountdown">
        <div :class="['modal-container', 'modal-countdown']" :style="{zIndex: '9999999999'}">
            <div class="modal-header">
                {{ title }}

                <span class="modal-timer" ref="timerTicker">
                    <span class="modal-timer-inner">
                        {{ timeLeft }}
                    </span>
                </span>
            </div>
            <div class="modal-body">
                <div :class="['modal-body-info']">
                    {{ info }}
                </div>

                <div v-if="additionalInfo" class="modal-body-info modal-body-info-important">
                    {{ additionalInfo }}
                </div>
            </div>
            <div class="modal-footer">
                <button v-if="acceptText" :class="'button-save'" @click="close()">{{ acceptText }}</button>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
    /* A modal that will auto-accept after a set amount of time. (default ticks in seconds, does not support mixed units)
     * Functionality is virtually identical to modalInfo
     */
    import settings from 'settings';
    import $modal from '@core/services/modal';
    import { defineComponent } from 'vue';

    interface DetailedInfo {
        text: string;
        class?: string;
    }

    export default defineComponent({
        name: "modalLockoutByInactivity",
        props: {
            modal: Object
        },
        data() {
            return {
                // Config Data
                title: 'Inactivity Lock Out',
                info: 'You have been inactive for a while. For security reasons, we will automatically lock you out from this deal.',
                acceptText: "I'm Still Here",
                additionalInfo: 'You will be locked out when the timer expires unless you respond.',
                circleTickUp: true,
                timerDuration: 20,
                timerTickUnit: 1000,

                // Internal State Properties
                timeLeft: 0,
                startTime: null as number,
                tickTimeout: null as number|string|ReturnType<typeof setTimeout>,
            };
        },
        computed: {
            isDevEnvironment(): boolean {
                return settings.environmentName === 'DEVELOPMENT';
            },
            timerToDegrees(): number {
                let rawDegrees = (this.timeLeft / this.timerDuration) * 360;
                return (this.circleTickUp) ? 360 - rawDegrees : rawDegrees;  
            },
        },
        watch: {
            'global.isAdminView'(newVal: boolean) {
                // Don't pause timeout if we're not in development. 
                if (!this.isDevEnvironment) return;

                // Pauses timer when admin view is enabled.
                if (newVal)
                    clearTimeout(this.tickTimeout);
                else 
                    this.timerTick();
            }
        },
        created() {

        },
        mounted() {
            document.addEventListener('visibilitychange', this.pageHiddenHandler);

            this.startTime = Date.now();

            if (!this.$global.isAdminView)
                this.pageHiddenHandler(); // Checks page visibility & starts the right kind of timeout

        },
        beforeUnmount() {
            clearTimeout(this.tickTimeout);
            document.removeEventListener('visibilitychange', this.pageHiddenHandler);
        },
        methods: {
            close(): void {
                
                $modal.close({killTimer: false});
            },
            timerTick() {
                clearTimeout(this.tickTimeout);

                this.timeLeft = this.timerDuration - Math.trunc(((Date.now() - this.startTime) / this.timerTickUnit));

                // Set arc of little progress circle
                if(this.$refs.timerTicker)
                    (this.$refs.timerTicker as HTMLElement).style.setProperty('--degree', `${this.timerToDegrees}deg`);

                if (this.timeLeft > 0) {
                    this.tickTimeout = setTimeout(this.timerTick, this.timerTickUnit);
                }
                else {
                    $modal.close({killTimer: true});
                    this.$router.push("/fimenudeals");
                }
            },
            pageHiddenHandler() {
                // When a page is hidden, repeated timer ticks will get throttled by the browser.
                // So a single, longer timeout will fire more accurately.
                if (document.visibilityState === 'visible') {
                    this.timerTick();
                    return;
                }

                clearTimeout(this.tickTimeout);
                let milisecondsRemaining = (this.timerDuration * this.timerTickUnit) - (Date.now() - this.startTime);
                this.tickTimeout = setTimeout(this.close, milisecondsRemaining);
            },
            isInfoDetailed(info: any): info is DetailedInfo {
                return info.text !== undefined;
            }
        },
    });
</script>
<style>
    .modalCountdown {
        margin: auto;
    }

        .modalCountdown .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

            .modalCountdown .modal-body .modal-body-info.display-block, 
            .modalCountdown .modal-body .modal-body-info.display-inline {
                display: block;
            }

                .modalCountdown .modal-body .modal-body-info.display-block > * {
                    display: block;
                    margin: 10px 0;
                }

                    .modalCountdown .modal-body .modal-body-info.display-block > *:first-child {
                        margin-top: 0;
                    }

                    .modalCountdown .modal-body .modal-body-info.display-block > *:last-child {
                        margin-bottom: 0;
                    }

                .modalCountdown .modal-body .modal-body-info.display-inline > * {
                    display: inline;
                }

            .modalCountdown .modal-body .modal-body-info.display-flex {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                align-items: center;
            }

        .modalCountdown .modal-timer {
            --degree: 0deg;

            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 35px;
            padding: 1px;
            border-radius: 50%;
            background: conic-gradient(var(--error-color) var(--degree), transparent calc(var(--degree) + 0.5deg) 100%);
            color: var(--error-color);
        }

            .modalCountdown .modal-timer .modal-timer-inner {
                height: 80%;
                width: 80%;
                border-radius: 50%;
                background: var(--background-color);
                text-align: center;
            }

        .modalCountdown .modal-body-info {
            white-space: pre-wrap;
            margin: 10px 0;
        }

        .modalCountdown .modal-body-info-important {
            font-weight: bold;
        }

        .modalCountdown .modal-container {
            width: auto;
            max-width: 815px;
        }
</style>
