import ENUMS from "@core/classes/Enums"
import util from '@core/services/util'
const soundPlayer = {
    currSound: null,
    soundsPlayed: [],
    reset() {
        this.soundsPlayed = [];
    },
    shush() {
        if (this.currSound) this.currSound.pause();
    },
    playSound(type, language, volumeLevel) {

        this.shush();

        //SELECT WHICH SOUND TO PLAY
        let path = null;
        switch (type) {
            case ENUMS.SOUNDS.TERMS_OF_COVERAGE:
                if (language == ENUMS.LANG.SPANISH)
                    path = '/static/snd/Term of Coverage - SP.mp3';
                else
                    path = '/static/snd/Term of Coverage.mp3';
                break;
            case ENUMS.SOUNDS.PAYMENT_START_DATE:
                if (language == ENUMS.LANG.SPANISH)
                    path = '/static/snd/Payment Start Date - SP.mp3';
                else
                    path = '/static/snd/Payment Start Date.mp3';
                break;
            case ENUMS.SOUNDS.SELECT_PAYMENT:
                if (language == ENUMS.LANG.SPANISH)
                    path = '/static/snd/Select Payment - SP.mp3';
                else
                    path = '/static/snd/Select Payment.mp3';
                break;

            default:
                break;
        }

        //console.log('path', path);

        //PLAY SOUND
        if (!this.soundsPlayed.includes(path)) {
            this.currSound = util.playSound(path, volumeLevel);
            this.soundsPlayed.push(path);
            //this.currSound.onended = () => {
            //    this.soundsPlayed.push(path);
            //};
        }

    }
}

export default soundPlayer;