<template>
    <div :style="$grid('2-2-2-1')" autocomplete="off">
        <InputTextbox
            label="DDI Email"
            v-model:saturn="placeHolderFields.username"
            autocomplete="one-time-code"
            :disabled="!!employeeEmail"
        />
        <InputTextbox
            label="DDI Password"
            type="password"
            v-model:saturn="placeHolderFields.password"
            placeholder="DDI Password"
            @blur="onBlurEncryptPassword"
            :disabled="!isDDIPasswordEdiable"
            :alwaysClearable="true"
            :clearable="true"
            @cleared="() => (isDDIPasswordEdiable = true)"
            icon="fas fa-hashtag"
            data-private
        />
        <InputRichDropdown
            :list="userStores"
            label="Store"
            placeholder="-- Select Something --"
            :valueMap="storeValuePredicate"
            :display="storeNamePredicate"
            :search="true"
            :selectAll="true"
            :single="false"
            :invalid="!placeHolderFields.store"
            @change="val => (placeHolderFields.store = val)"
        />
        <button
            @click="validateCredentials"
            :disabled="
                busyValidatingDDICredentials ||
                encryptingPassword ||
                placeHolderFields.password == null ||
                placeHolderFields.password == '' ||
                !placeHolderFields.store
            "
        >
            <i v-if="busyValidatingDDICredentials || encryptingPassword" class="fas fa-cog fa-spin" />
            <span v-else>Verify</span>
        </button>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from "vue";
    import api from "@core/services/api";
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    import InputTextbox from "@core/components/InputTextbox.vue";
    import settings from "@/settings";
    import Store from "@core/classes/Store/Store";
    import { storeHasDDIEnabled } from "@/helpers/plate-transactions-helper";
    import util from "@core/services/util";

    interface DDICredentials {
        username: string | null;
        password: string | null;
        store: string | null;
    }

    export default defineComponent({
        name: "DDIUserSetCredentials",
        props: {
            modal: {
                type: Object,
            },
            ddiCredentials: {
                type: Object as PropType<DDICredentials>,
            },
            submitBusy: {
                type: Boolean,
            },
            employeeEmail: {
                type: String,
            },
        },
        components: { InputTextbox, InputRichDropdown },
        data() {
            return {
                busyValidatingDDICredentials: false,
                isDDIPasswordEdiable: true,
                encryptingPassword: false,
                placeHolderFields: {
                    username: "",
                    password: "",
                    store: null,
                } as DDICredentials,
            };
        },
        created() {
            this.isDDIPasswordEdiable = !this.ddiCredentials.password;
            this.placeHolderFields.username = this.ddiCredentials.username ?? this.employeeEmail;
            this.placeHolderFields.password = this.ddiCredentials.password;
        },
        computed: {
            userStores(): any[] {
                return settings.userStores.filter((x: Store) => storeHasDDIEnabled(x));
            },
        },
        methods: {
            /**
             * Encrypts the DDI password if it is not null or empty.
             * Sets the appropriate flags to indicate the encryption state.
             * @returns {Promise<void>} A promise indicating the completion of the encryption process.
             */
            async encryptPassword(): Promise<void> {
                // Check if the password is null or empty
                if (util.isNullOrEmpty(this.placeHolderFields.password)) return;

                try {
                    // Indicate that the password encryption process is ongoing
                    this.encryptingPassword = true;

                    // Call the API to encrypt the password
                    const response = await api.utilities.encrypt(this.placeHolderFields.password);

                    // If the response contains an encrypted value, update the password and disable editing
                    if (response.data?.encryptedValue) {
                        this.placeHolderFields.password = response.data.encryptedValue;
                        this.isDDIPasswordEdiable = false;
                    } else {
                        // If encryption fails, reset the password and enable editing
                        this.placeHolderFields.password = null;
                        this.isDDIPasswordEdiable = true;
                    }
                } catch (err) {
                    // In case of an error, reset the password and enable editing
                    this.placeHolderFields.password = null;
                    this.isDDIPasswordEdiable = true;
                    console.error(err);
                } finally {
                    // Indicate that the password encryption process has completed
                    this.encryptingPassword = false;

                    // If the blur prevented the validateCredentials to run (person clicekd it)
                    // We ensure that is executed afterwards.
                    if (
                        this.busyValidatingDDICredentials == false &&
                        !util.isNullOrEmpty(this.placeHolderFields.password)
                    ) {
                        this.validateCredentials();
                    }
                }
            },
            /**
             * Triggers encryption on blur and ensures the encryption is completed before proceeding.
             */
            async onBlurEncryptPassword(): Promise<void> {
                await this.encryptPassword();
            },
            /**
             * Validates the DDI credentials with the provider after ensuring the password encryption is complete.
             * Displays appropriate success or error messages based on the validation result.
             */
            async validateCredentials() {
                // Wait for the password encryption process to complete
                this.busyValidatingDDICredentials = true;
                await this.waitForEncryption();

                try {
                    // Indicate that the credential validation process is ongoing

                    // Call the API to validate the credentials
                    const response = await api.plateTransactions.validateCredentialsWithProvider(
                        this.placeHolderFields.username,
                        this.placeHolderFields.password,
                        this.placeHolderFields.store,
                    );

                    // If the validation is successful, display a success message
                    if (response.data?.payload === true) {
                        this.ddiCredentials.username = this.placeHolderFields.username;
                        this.ddiCredentials.password = this.placeHolderFields.password;

                        util.toastr("success", "Success", "Your credentials were validated successfully.");
                    } else {
                        // If validation fails, reset the password, enable editing, and display an error message
                        this.placeHolderFields.password = null;
                        this.isDDIPasswordEdiable = true;
                        util.toastr(
                            "error",
                            "Incorrect",
                            response.data?.messages[0] ?? "Your credentials are incorrect.",
                        );
                    }
                } catch (err) {
                    // In case of an error, log it and display a failure message
                    console.error(err);
                    util.toastr("error", "Failed", err ?? "We could not validate your credentials.");
                } finally {
                    // Indicate that the credential validation process has completed
                    this.busyValidatingDDICredentials = false;
                }
            },
            /**
             * Waits for the password encryption to complete by periodically checking the encryption status.
             * Stops checking after a maximum of 5 attempts.
             * Resolves the promise once the encryption process is finished.
             *
             * @returns {Promise<boolean>} A promise that resolves when encryption is complete.
             */
            waitForEncryption(): Promise<boolean> {
                const maxAttempts = 5; // Maximum number of attempts to check encryption status
                let attempts = 0; // Counter for the number of attempts

                return new Promise(resolve => {
                    // Define a function to check the encryption status
                    const checkEncryption = () => {
                        attempts++;
                        // If encryption is complete, resolve the promise with true
                        if (!this.encryptingPassword) {
                            resolve(true);
                        } else if (attempts >= maxAttempts) {
                            // If the maximum attempts are reached, resolve the promise with false
                            resolve(false);
                        } else {
                            // If encryption is still ongoing, check again after 500ms
                            setTimeout(checkEncryption, 500);
                        }
                    };
                    // Start checking the encryption status
                    checkEncryption();
                });
            },
            storeValuePredicate(store: any): string {
                return store?.storeCode ?? "";
            },
            storeNamePredicate(store: any): string {
                return store.storeName;
            },
        },
    });
</script>
<style scoped></style>
