<template>
    <div :class="{'display-data-container': true, 'display-column': displayColumn}">
        <span>{{ labelToDisplay }}</span>
        <p v-if="isNumber">{{ formatNumber(valueToDisplay) }}</p>
        <p v-else-if="isCurrency">{{ displayDollarSign() }}{{ formatNumber(valueToDisplay) }}</p>
        <p v-else>{{ valueToDisplay }}</p>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({

    name: 'DisplayData',
    components: {},
    props: {
        label: {
            type: String
        },
        saturn: [String, Number],
        displayColumn:{
            type: Boolean,
            default: false
        },
        isNumber: {
            type: Boolean,
            default: false
        },
        isCurrency: {
            type: Boolean,
            default: false
        },
        precision: {
            type: [Number, null],
            default: null
        }
    },
    data() {
        return {
        }
    },
    computed: {
        valueToDisplay() {
            return (this.saturn?.toString() || '').trim() === '' ? '-' : this.saturn?.toString()
        },
        labelToDisplay() {
            return this.label?.toUpperCase() ?? "";
        },
    },
    methods: {
        formatNumber(value: string | number): string {
            let actualValue = value;
            let precision = this.precision ?? 0;


            if (typeof value === 'string')  actualValue = parseFloat(value);
            if (typeof value != 'number') return value;

            if (precision !== undefined) {
                precision = Math.max(0, Math.floor(precision)); // Ensure precision is a non-negative integer
                const options: Intl.NumberFormatOptions = {
                    minimumFractionDigits: precision,
                    maximumFractionDigits: precision,
                };
                return new Intl.NumberFormat(undefined, options).format(actualValue as number);
            }

            return new Intl.NumberFormat().format(actualValue as number);
        },
        displayDollarSign() {
            return this.saturn != null? '$' : ''
        }
    },
})
</script>
<style scoped>
    .display-data-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 7px 10px;

    }
    .display-data-container span {
        font-size: 1rem;
        margin: 0;
    }
    .display-data-container p {
        margin-left: 10px;
        font-size: 1rem;
        margin: 0;
        font-weight: 500px;
    }
    .display-data-container.display-column {
        flex-direction: column;
        justify-content: flex-start;
        padding: unset;
        align-items: flex-start;
    }
    .display-data-container.display-column span {
        font-size: 0.8rem;
        padding: 0 5px
    }
    .display-data-container.display-column p {
        background-color:  var(--black-10percent);
        padding: 7px 5px
    }
    .display-data-container.display-column span,
    .display-data-container.display-column p {
        width: 100%;
        box-sizing: border-box;
    }

</style>