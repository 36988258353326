export class CB_ChatMessage {
    class: string = null;
    senderId: string = null;
    senderName: string = null;
    text: string[] = [];
    timestamp: Date = null;

    constructor(className: string, senderId: string, senderName: string, textArray: string[], timestamp: Date) {
        this.class = className;
        this.senderId = senderId;
        this.senderName = senderName;
        this.text = textArray;
        this.timestamp = timestamp;
    }

    addMessage(message: string) {
        this.text.push(message);
    }

    getLastMessage() {
        return this.text[this.text.length - 1];
    }

    getTimestampFormatted() {
        return this.timestamp.toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' });
    }
}

export interface ICB_MouseData {
    xPercent: number;
    yPercent: number;
    id: string;
    name: string;
    role: 'Editor' | 'Spectator' | 'Unknown';
}

export interface ICB_DealRoomStatus {
    roomState: number;
    editorLogin: string;
    editorName: string;
    isEditorAdmin: boolean;
    editorConnections: number;
}

export interface ICB_JoinRoomRequest {
    login: string;
    name: string ;
    isAdmin: boolean;
    followEditor: boolean;
    sendEditRequest: boolean;
}

export interface ICB_ViewerInfo {
    id: string;
    name: string;
    role: 'Editor' | 'Spectator' | 'Unknown';
    isAdmin: boolean;
    followEditor: boolean;

    visibleToRoom?: boolean;
    requestPending?: boolean;
    activeRequesterId?: string;
    activeRequesterName?: string;
}

export interface ICB_RoomInfo {
    viewerInfoList: ICB_ViewerInfo[];
    followerCount: number;
    viewerCount: number;
    connectedWithCustomerViewerName: string; 
    editorSection: string;
}

export interface ICB_HTMLCoordinates {
    x: number;
    y: number;
}