<template>
    <div class="document-packet ignore-deal-lock">
        <IsBusyScreenComponent v-if="isPacketBusy" />
        <div v-if="packet" class="document-packet-container">
            <div v-show="showTitle || (showButtons && packet.isCurrent)" 
                 class="document-packet-title-container"
                 :style="$grid('2-1-1')"
            >
                <div v-show="showTitle" class="document-packet-subtitle">
                    <span>Packet generated by {{ packet.generatedBy.name }} - {{ packet.packetDate }}</span>
                </div>
            </div>

            <div class="document-packet-grid-container">
                <Panel class="paperwork-categories-panel" :action="getDocumentsPanelActions()">
                    <template v-slot:HeaderComponent>
                        <div class="panel-buttons">
                            <div v-if="!fimenu.isFinalizingPaperwork && showButtons && packet.isCurrent"
                                 class="panel-header-button-array"
                                 style="display: flex; align-items: center"
                            >
                                <TooltipComponent v-if="fimenu.buyersOrderEnabled && plateTransactionEnabled"
                                    :tooltip="DMVRequestTooltipMessage()"
                                    direction="right"
                                    maxWidth="450"
                                >
                                    <template #showFromOutside>
                                        <DropdownButton v-if="plateTransactionEnabled"
                                                        label="Plate Transactions"
                                                        icon="fas fa-ban"
                                                        :disabled="IsPlateTransactionButtonDisabled"
                                                        class="document-packet-button-dropdown ignore-deal-lock"
                                                        :actions="plateTransactionsActions"
                                        />
                                    </template>
                                </TooltipComponent>

                                <ButtonLoading class="button button-icon-text button-accept" 
                                    :disabled="isActivatePaperworkButtonDisabled"
                                    :isLoading="busyActivating" 
                                    loadingText="Activating"
                                    @click="() => activatePaperwork()"
                                >
                                    <i class="fas fa-magic" /> Activate
                                </ButtonLoading>

                                <ButtonLoading class="button button-icon-text button-unwind"
                                    :disabled="isVoidPeperworkButtonDisabled"
                                    :isLoading="busyVoiding"
                                    loadingText="Voiding"
                                    @click="() => voidPaperworkAction()"
                                >
                                    <i class="fas fa-ban" /> Void
                                </ButtonLoading>

                                <button class="button button-icon-text button-load"
                                        :disabled="isOpenPaperworkButtonDisabled"
                                        @click="openCustomerPaperwork()"
                                >
                                    <i v-if="$meetingHub.connected" class="fas fa-file-signature" />

                                    {{ $meetingHub.connected ? "Sign" : "Please Connect Customer First" }}
                                </button>

                                <DropdownButton v-if="$global.isAdminView"
                                                :label="`Admin Actions ${totalSelectedDocuments ? '(' + totalSelectedDocuments + ')' : ''}`"
                                                icon="fas fa-ban"
                                                class="document-packet-button-dropdown"
                                                :actions="adminAction" 
                                />

                                <button v-else 
                                    class="button button-icon-text button-load view-button"
                                    :disabled="Object.values(rawSelectedDocuments).length <= 0"
                                    @click="handleRepopulate"
                                >
                                    <i class="far fa-eye" />
                                    <span>View Selected</span>
                                </button>
                            </div>

                            <div v-if="displayFinalizePaperworkButton" class="panel-header-button-array">
                                <ButtonLoading class="button button-icon-text button-icon-text"
                                    :disabled="paperworkBusy || fimenu.isFinalizingPaperwork"
                                    :isLoading="fimenu.isFinalizingPaperwork"
                                    loadingText="Finalizing Paperwork"
                                    @click="() => handleFinalizePaperwork()"
                                >
                                    <i class="fas fa-magic" /> Finalize Paperwork
                                </ButtonLoading>
                            </div>
                        </div>
                    </template>

                    <div class="collapse-all-stack" @click="toggleCollapseExpandAllCategories()">
                        <span class="fa-stack fa-1x stack-container">
                            <i class="far fa-square fa-stack-1x stack-border"></i>
                            <i class="fas fa-square fa-stack-1x stack-background"></i>

                            <i v-if="anyCategoryCollapsed"
                               class="fas fa-plus-square fa-stack-1x stack-sign"
                               style="color: var(--main-color)">
                            </i>
                            <i v-else class="fas fa-minus-square fa-stack-1x stack-sign" style="color: var(--main-color)"></i>
                        </span>

                        <InputCheckbox :isControlled="true"
                                       @click="toggleSelectRichtables"
                                       :modelValue="anyCategorySelected"
                                       :useSquare="true" />

                        <label style="margin: unset; font-size: 20px; font-weight: bold;">Documents</label>
                    </div>

                    <div class="paperwork-categories-wrapper">
                        <RichTable v-for="(category, index) in paperworkCategories"
                                   :key="index"
                                   ref="richtable_refs"
                                   selectable="checkbox"
                                   :tableData="getCategoryData(category)"
                                   :headers="getHeaders(category.description)"
                                   :selectionKey="(doc) => category.id + '-' + doc._index"
                                   :useSquareInputCheckbox="true"
                                   :isCollapsable="true"
                                   :isSelectDisabled="(doc) => !canSelectDocument(doc)"
                                   :tableRowStyle="doc => getTableRowStyle(doc)"
                                   @selectionChanged="(listOfDocs) => toggleSelect(category, listOfDocs)"
                        >
                            <template #docName="doc">
                                <DocumentPacketDocumentName :doc="doc._ref"
                                                            :fimenu="fimenu"
                                                            :getProductDetails="getProductDetails"
                                                            :getPaperworkDocType="getPaperworkDocType" />
                            </template>

                            <template #signature="doc">
                                <DocumentPacketDocumentSignature v-if="doc.documentGenerationType == ENUMS.DocumentGenerationType.Paperwork"
                                                                 :doc="doc._ref"
                                                                 :hasSigned="hasSigned"
                                                                 :signedInformation="signedInformation"
                                                                 :customerName="customerName"
                                                                 :coCustomerName="coCustomerName"
                                                                 :requiresSigner="requiresSigner" />

                                <div v-else class="center-content">
                                    <div class="text-success defaultCursor signersText">
                                        <i class="fa fa-check-circle iconSpacing"></i>
                                        <span>N/A</span>
                                    </div>
                                </div>
                            </template>

                            <template #activation="doc">
                                <DocumentPacketDocumentActivation :fimenu="fimenu"
                                                                  :doc="doc._ref"
                                                                  :tooltipInformation="tooltipInformation"
                                                                  :user="user"
                                                                  :openEditContractNumber="openEditContractNumber" />
                            </template>

                            <template #download="doc">
                                <DocumentPacketActions :doc="doc._ref"
                                                       :downloadDocument="downloadDocument"
                                                       :paperworkBusy="paperworkBusy || fimenu.isFinalizingPaperwork"
                                                       :isCurrent="packet.isCurrent"
                                                       :canSelectDocument="canSelectDocument"
                                                       :openPreviewModal="openPreviewPDFModal"
                                                       :openPreviewModalForImpact="openPreviewPDFModalForImpactDocs"
                                                       :regenerateDocument="regenerateDocument" />
                            </template>
                        </RichTable>
                    </div>
                </Panel>
            </div>
        </div>

        <div v-else :style="{ paddingLeft: '20px' }">No Paperwork</div>
    </div>
</template>
<script>
    import moment from "moment";

    import $modal from "@core/services/modal";
    import api from "@core/services/api";
    import auth from "@core/services/auth";
    import settings from "settings";
    import util from "@core/services/util";
    
    import { CancelPlateTransaction, IsPlateTransactionsEnabled, IssuePlateRegistrationTransfer, IssueTemporaryTag } from '@/helpers/plate-transactions-helper.ts'
    import ENUMS from "@core/classes/Enums";
    import FIMenu from "@core/classes/FIMenu";
    import FIMenuCreditReport from '@core/classes/FIMenuCreditReport'
    import FIMenuPaperwork from "@core/classes/FIMenuPaperwork";
    import FIMenuPlateTransactions from "@core/classes/FIMenuPlateTransactions";
    import { PlateTransactionType } from '@core/classes/SharedEnums'

    import modalEditContractNumber from "@/modals/modalEditContractNumber.vue";
    import modalInfo from "@core/modals/modalInfo.vue";
    import modalPreviewPDF from '@/modals/modalPreviewPDF.vue'
    import modalSetControlNumber from "@/modals/modalSetControlNumber.vue";

    import ButtonLoading from "@core/components/ButtonLoading.vue";
    import DropdownButton from "@/components/DropdownButton.vue";
    import InputCheckbox from "@core/components/InputCheckbox.vue";
    import IsBusyScreenComponent from '@core/components/IsBusyScreenComponent.vue'
    import Panel from "@core/components/Panel.vue";
    import RichTable from "@core/components/RichTable.vue";
    import TooltipComponent from "@core/components/TooltipComponent.vue";

    import DocumentPacketActions from '@/components/document-packet/DocumentPacketActions.vue'
    import DocumentPacketDocumentActivation from '@/components/document-packet/DocumentPacketDocumentActivation.vue'
    import DocumentPacketDocumentName from '@/components/document-packet/DocumentPacketDocumentName.vue'
    import DocumentPacketDocumentSignature from '@/components/document-packet/DocumentPacketDocumentSignature.vue'


    export default {
        name: "DocumentPacket",
        components: {
            Panel,
            RichTable,
            InputCheckbox,
            DropdownButton,
            TooltipComponent,
            DocumentPacketActions,
            DocumentPacketDocumentActivation,
            DocumentPacketDocumentSignature,
            DocumentPacketDocumentName,
            IsBusyScreenComponent,
            ButtonLoading
        },
        props: {
            packet: Object,             // PaperworkPacket.cs
            fimenu: FIMenu, 
            showTitle: Boolean, 
            showButtons: Boolean, 
            modalStatus: Object,        // Expects { modalBusy: boolean }
            setVoidedPacket: Function,  // () => void
            restorePacket: Function     // (packet: PaperworkPacket) => void
        },
        data() {
            return {
                docHeaders: [
                    {
                        name: "documentName",
                        display: "Document Name",
                        slot: "docName",
                        cssClass: "docName-header"
                    },
                    {
                        name: "signature",
                        display: "Digitally Signed",
                        slot: "signature",
                        cssClass: "signature-header"
                    },
                    {
                        name: "activation",
                        display: "Contract #",
                        slot: "activation",
                        cssClass: "activation-header"
                    },
                    {
                        name: "download",
                        display: "",
                        sortable: false,
                        slot: "download",
                        cssClass: "download-header"
                    }
                ],
                paperworkBusy: false,
                busyVoiding: false,
                busyActivating: false,
                busyIssuingPlateReg: false,
                paperworkCategories: [],
                otherCategoryDescription: "Other",
                customerName: this.fimenu?.customer?.fullName?.toUpperCase() || "",
                coCustomerName: this.fimenu?.coCustomer?.fullName?.toUpperCase() || "",
                uploadedDocumentTypes: settings.lookups.uploadedDocumentTypes,
                rawSelectedDocuments: {},
                richTableRefs: null,
                regeneratingAndDownloadMultipleDocs: false,
                allTablesCollapsed: false,
                isLoading: false
            };
        },
        computed: {
            isPacketBusy() {
                return this.regeneratingAndDownloadMultipleDocs
                    || this.isLoading
                    || this.fimenu.isFinalizingPaperwork
                    || this.busyIssuingPlateReg;
            },
            IsPlateTransactionButtonDisabled(){
                return !this.user.EmployeeAccess.IsAccounting && !this.user.EmployeeAccess.IsFIManager
            },
            isActivatePaperworkButtonDisabled() {
                return this.paperworkBusy
                    || this.fimenu.paperworkStatus === ENUMS.PAPERWORK_STATUS.ACTIVATED
                    || !this.user.AllowDealActivation
                    || this.fimenu.isSpectator;
            },
            isVoidPeperworkButtonDisabled() {
                return this.paperworkBusy 
                    || this.fimenu.isSpectator
                    || !this.user.AllowDealActivation
            },
            isOpenPaperworkButtonDisabled() {
                return this.paperworkBusy
                    || !this.$meetingHub.connected
                    || !this.$meetingHub.code
                    || this.AllDocsSinged
                    || this.fimenu.isSpectator
                    || this.displayFinalizePaperworkButton;
            },
            displayFinalizePaperworkButton() {
                //If FinalizedPaperwork gets interrupted before finish we want to show a button to manually trigger the function again
                return (this.fimenu.isFinalizingPaperwork || (this.isManualFinalizeRequired && this.areAllOverlaysSigned())) && this.showButtons && this.packet.isCurrent;
            },
            anyCategoryCollapsed() {
                if (Array.isArray(this.richTableRefs)) {
                    return this.richTableRefs.some(table => table?.data?.isCollapsed)

                } else {
                    return !!this.richTableRefs?.data?.isCollapsed
                }
            },
            anyCategorySelected() {
                if (Array.isArray(this.richTableRefs)) {
                    return !this.richTableRefs.some(table => !table?.data?.selectAllCheckbox)

                } else {
                    return !!this.richTableRefs?.data?.selectAllCheckbox
                }
            },
            totalSelectedDocuments() {
                return this.selectedDocuments.packetDocuments.length +
                    this.selectedDocuments.unknown.length +
                    this.selectedDocuments.uploadedDocuments.length
            },
            util() {
                return util;
            },
            ENUMS() {
                return ENUMS;
            },
            hasOtherDocuments() {
                return !!this.fimenu.paperwork?.uploadedDocuments?.some((doc) =>
                    doc.associations.some((asoc) => asoc.type == 20)
                );
            },
            user() {
                return auth.getTokenPayload();
            },
            isAdmin() {
                return !!this.user.EmployeeAccess.IsAdmin;
            },
            AllDocsSinged() {
                return this.packet.documents.every((s) => s.signedDoc || !s.filledDoc);
            },
            isManualFinalizeRequired() {
                //If FinalizedPaperwork gets interrupted before finish, there should be no doc the is filled and signed
                return this.packet.documents.every((d) => !d.signedDoc);
            },
            daysUntilCanResubmit() {
                // Get latest temp tag

                const mostRecentTempTransfer = this.fimenu.vehicle.registrationInfo.plateTransactions
                    .filter(x => x.type == PlateTransactionType.NewTempPlate)
                    .sort((a, b) => new Date(b.dateSubmitted).getTime() - new Date(a.dateSubmitted).getTime())[0];
                if (mostRecentTempTransfer == null) return 0;

                return 25 + util.toMoment(mostRecentTempTransfer.dateSubmitted).diff(moment(), "days");
            },
            exceededMaximumRequestAmount() {

                const actualPlateTransaction = this.fimenu.getActualDealPlateTransactions();

                const amountOfRequests = this.fimenu.vehicle.registrationInfo.plateTransactions.reduce((prev, cur) => {
                    const driverLicenseSame = cur.otherInfo.driversLicense == actualPlateTransaction.otherInfo.driversLicense;
                    const vehicleVinSame = cur.otherInfo.vehicleVin == actualPlateTransaction.otherInfo.vehicleVin;
                    const isTempTag = cur.type == PlateTransactionType.NewTempPlate;

                    if (driverLicenseSame && vehicleVinSame && isTempTag) prev += 1;

                    return prev;
                }, 0);


                return amountOfRequests >= 2;
            },
            disableDMVRequest() {
                if (this.$global.isAdminView) return false;

                if (this.daysUntilCanResubmit > 0 || this.exceededMaximumRequestAmount || this.busyIssuingPlateReg)
                    return true;

                return false;
            },
            plateTransactionEnabled() {
                return IsPlateTransactionsEnabled(this.fimenu);
            },
            missingPlateDocuments() {
                if (!IsPlateTransactionsEnabled(this.fimenu)) return false;
                const actualDeal = this.fimenu.getActualDealPlateTransactions();

                if (!actualDeal) return false;

                return !this.fimenu.paperwork.uploadedDocuments?.some((doc) =>
                    doc.associations.some((asoc) => {
                        return asoc.id == actualDeal.providerDealId;
                    })
                );
            },
            selectedDocuments() {
                return Object.values(this.rawSelectedDocuments).reduce((documentsList, listOfDocs) => {
                    listOfDocs.forEach((doc) => {
                        let targetList = null
                        switch (doc.documentGenerationType) {
                            case ENUMS.DocumentGenerationType.Paperwork:
                                targetList = documentsList.packetDocuments
                                break
                            case ENUMS.DocumentGenerationType.Uploaded:
                                targetList = documentsList.uploadedDocuments
                                break
                            default:
                                targetList = documentsList.unknown
                                break
                        }
                        targetList.push(doc);
                    });
                    return documentsList;
                },
                    { uploadedDocuments: [], packetDocuments: [], unknown: [] }
                );
            },
            flatSelectedDocuments() {
                return Object.values(this.rawSelectedDocuments).reduce((allDocuments, listOfDocs) => {
                    listOfDocs.forEach(doc => {
                        allDocuments.push(doc); // Directly push each document into the resulting array
                    });
                    return allDocuments;
                }, []); // Initialize as an empty array to collect documents
            },
            activeProducts() {
                const hasSelectedATerm = this.fimenu.coverageTerms.find((term) => term.selected);

                if (!hasSelectedATerm) {
                    return [];
                }

                if (!this.packet.isCurrent) {
                    return this.packet.acceptList ?? []
                }

                return this.fimenu
                    .getSelectedCoverageTerm()
                    .getAcceptList(this.fimenu.dealType, this.fimenu.getSelectedDealTerm()?.term);
            },
            plateTransactionsActions() {
                const actions = []

                actions.push(
                    {
                        label: `Generate Temp. Tag.`,
                        class: 'button',
                        click: this.generateTempTag,
                        disabled: () => this.disableDMVRequest
                    }
                )

                // Check if we have any successful transfer.
                // To do so, we check if we have anything with transfer type and does NOT have any error.
                const haveSuccessfulTransfer = this.fimenu.vehicle?.registrationInfo.plateTransactions.some(x => x.type == ENUMS.PLATE_TRANSACTIONS_TYPES.Transfer && !x.hasError())

                // If we're meant to transfer and we don't have any transfer.
                // Add the button for retry.
                if (this.fimenu.vehicle.registrationInfo.isPlateTransfer && !haveSuccessfulTransfer) {
                    actions.push({
                        label: `Transfer Plate`,
                        class: 'button',
                        click: this.transferRegPlate,
                        disabled: () => this.busyIssuingPlateReg
                    })
                }

                // IAdmin
                // Add the button for retry.


                return actions;
            },
            adminAction() {

                const actions = [
                    {
                        label: `View Selected`,
                        class: 'button',
                        click: this.handleRepopulate,
                        disabled: () => this.totalSelectedDocuments <= 0
                    },
                    {
                        label: `Repopulate Selected`,
                        class: 'button-save',
                        click: () => this.handleRepopulate(false),
                        disabled: () => this.totalSelectedDocuments <= 0 || this.fimenu.isSpectator
                    },
                    //THE FILTER THAT REPLACES THE DOC IS FILTERING OUT ALL OF TIME (LIKE TITLE DOCS)
                    {
                        label: `Regenerate Selected`,
                        class: 'button-info',
                        click: this.regenerateSelected,
                        disabled: () => this.totalSelectedDocuments <= 0 || this.fimenu.isSpectator
                    },
                    {
                        label: `Delete Selected`,
                        class: 'button-unwind',
                        click: this.handleRemoveDocuments,
                        disabled: () => this.totalSelectedDocuments <= 0 || this.fimenu.isSpectator
                    },
                    {
                        label: `Refill Packet`,
                        class: 'button-load',
                        click: this.handleRefreshPacket,
                        disabled: () => this.fimenu.isSpectator
                    },
                    {
                        label: 'Void Documents Only',
                        class: 'button-unwind',
                        click: () => this.voidPaperworkAction(true),
                        disabled: () => this.fimenu.isSpectator
                    }
                ]

                const hasAnyPlateTransaction = this.fimenu.vehicle?.registrationInfo?.plateTransactions?.length <= 0;

                if (this.plateTransactionEnabled || hasAnyPlateTransaction) {
                    actions.push({
                        label: `Get Plate Docs`,
                        class: 'button',
                        click: this.getMissingPlateDocuments,
                        disabled: () => this.busyIssuingPlateReg
                    })
                }


                return actions;
            },
            isAnyDocPendingActivation() {
                return this.packet.documents.some((d) => d.activationInfo?.dateToActivate != null);
            }
        },
        created() {
            if (this.packet) {
                this.packet.packetDate = this.convertDateStringToDate(this.packet.packetDateStamp);
                this.fimenu.paperwork.orderPacketDocuments(this.packet)
                this.setPaperworkCategories();
            }

            // Unselect all the paperwork once we start finalizing.
            this.$watch(() => this.fimenu.isFinalizingPaperwork, function (newValue, oldValue) {
                if (newValue) this.unSelectAll();
            })

            this.$watch(
                () => this.packet,
                function () {
                    this.setPaperworkCategories();
                },
                { deep: true }
            );

            this.EventBus.on('paperworkReset', () => {
                this.unSelectAll();
            });
        },
        mounted() {
            this.richTableRefs = this.$refs.richtable_refs?.map(r => util.getVueRefToWatch(r))
        },
		methods: {
            handleFinalizePaperwork() {
                this.EventBus.emit("allPaperworkSigned");
            },
            getTableRowStyle(doc) {
                if (doc.isOpen) {
                    return "border-left-row"
                } else {
                    return null
                }
            },
            canSelectDocument(doc) {
				//Documents status that can't be selected.
				if(doc.status == ENUMS.PaperworkDocumentStatus.ErrorGenerating)  return false;

                // Type of document that can't be select.
                if (doc.documentGenerationType == ENUMS.DocumentGenerationType.Paperwork) {
                    return true;
                }
                else if (doc.documentGenerationType == ENUMS.DocumentGenerationType.Uploaded) {
                    return doc.docInfo.fileType.includes("image") //only imgs for now due to protected pdfs. || doc.docInfo.fileType.includes("pdf")
                }

                return false;
            },
            getHeaders(headerName) {
                return [
                    {
                        name: 'documentName',
                        display: headerName,
                        slot: 'docName',
                        cssClass: 'docName-header'
                    },
                    {
                        name: 'signature',
                        display: (headerName === 'Requires Wet Signature') ? 'Signed' : 'Digitally Signed',
                        slot: 'signature',
                        cssClass: 'signature-header'
                    },
                    {
                        name: 'activation',
                        display: 'Contract #',
                        slot: 'activation',
                        cssClass: 'activation-header'
                    },
                    {
                        name: 'download',
                        display: '',
                        sortable: false,
                        slot: 'download',
                        cssClass: 'download-header'
                    }
                ]
            },
            getCategoryData(category) {
                // We're doing this "hack" because we don't have a "solid" way to identify the documents.

                category?.docs?.forEach((doc) => {
                    doc.documentGenerationType = ENUMS.DocumentGenerationType.Paperwork
                })

                category?.uploadedDocuments?.forEach((doc) => {
                    doc.documentGenerationType = ENUMS.DocumentGenerationType.Uploaded
                })

                return [...category.docs, ...category.uploadedDocuments]
            },
            toggleSelectRichtables() {
                if (Array.isArray(this.richTableRefs)) {
                    const allCategoriesSelected = this.richTableRefs.every(table => {
                        return table.data.selectAllCheckbox;
                    });

                    this.richTableRefs.forEach((table) => {
                        if (allCategoriesSelected) 
                            table.methods.unSelectAll()
                        else 
                            table.methods.selectAll()
                    })
                }
                else {
                    if (this.richTableRefs?.data?.selectedCheckbox?.length) 
                        this.richTableRefs.methods.selectAll()
                    else 
                        this.richTableRefs.data.unSelectAll()
                }
            },
            selectAll() {
                this.richTableRefs?.forEach((richtableRef) => {
                    richtableRef.methods.selectAll();
                });
            },
            unSelectAll() {
                this.richTableRefs?.forEach((richtableRef) => {
                    richtableRef.methods.unSelectAll();
                });
            },
            toggleSelect(category, listOfDocs) {
                // ID could be description for paperwork documents, id for uploaded Documents or id for categories.
                if (listOfDocs.length) 
                    this.rawSelectedDocuments[category.id] = listOfDocs;
                else 
                    delete this.rawSelectedDocuments[category.id];
            },
            DMVRequestTooltipMessage() {
                if (this.exceededMaximumRequestAmount)
                    return ["Cannot issue more temporary tags", "This customer already had 2 temporary tags issued"];

                return this.daysUntilCanResubmit > 0
                    ? [`${this.daysUntilCanResubmit} days until you may re-submit.`]
                    : [];
            },
            async getMissingPlateDocuments() {
                try {
                    this.busyIssuingPlateReg = true;
                    const response = await api.plateTransactions.getMissingDocuments(this.fimenu);

                    if (response.data.status == "failure") {
                        util.toastr("error", "Error", response.data?.message ?? "Failed to retrieve documents.");
                    }
                    else {
                        util.toastr("success", "Success", response.data?.message ?? "Documents retrieval successful.");
                        this.fimenu.paperwork.orderPacketDocuments(this.packet)
                        this.setPaperworkCategories();
                    }

                } 
                catch (err) {
                    console.error(err);
                } 
                finally {
                    this.busyIssuingPlateReg = false;
                }
            },
            async generateTempTag() {
                $modal.open(modalSetControlNumber, {
                    name: "modalSetControlNumber",
                    passedData: {
                        plateTransactions: this.fimenu.vehicle.registrationInfo.plateTransactions,
                        acceptText: "Submit",
                        cancelText: "Cancel"
                    },
                    backdrop: true,
                    postFunction: async (ctrlNum) => {
                        this.fimenu.vehicle.registrationInfo.controlNumber = ctrlNum;
                        this.busyIssuingPlateReg = true;

                        try {
                            const response = await api.plateTransactions.hasCredentials();

                            if (!response.data) {
                                util.toastr("error", "Plate Transaction", "Missing Credentials. Please setup your DDI credentials to proceed.")
                                return;
                            }
                            
                            await IssueTemporaryTag(this.fimenu)
                            this.fimenu.paperwork.orderPacketDocuments(this.packet)
                            this.setPaperworkCategories();
                        }
                        catch (err) {
                            console.error('ERROR GENERATING TEMPORARY TAG: ', err);
                            util.toastr('error', 'Plate Transaction', 'An error occurred when trying to generate a temporary tag.');
                        }
                        finally {
                            this.busyIssuingPlateReg = false;
                        }
                    }
                });

                this.fimenu.paperwork.orderPacketDocuments(this.packet)
                return this.setPaperworkCategories();
            },
            async transferRegPlate() {
                this.busyIssuingPlateReg = true;

                try {
                    const response = await api.plateTransactions.hasCredentials()
                    if (!response.data) {
                        util.toastr("error", "Plate Transaction", "Missing Credentials. Please setup your DDI credentials to proceed.")
                        return;
                    }
                    
                    await IssuePlateRegistrationTransfer(this.fimenu);
                    this.fimenu.paperwork.orderPacketDocuments(this.packet)
                    this.setPaperworkCategories();
                }
                catch (err) {
                    console.error('ERROR TRANSFERRING REGISTRATION PLATE: ', err);
                    util.toastr('error', 'Plate Transaction', 'An error occurred when trying to transfer registration plates.');
                }
                finally {
                    this.busyIssuingPlateReg = false;
                }
            },
            convertDateStringToDate(packetDateStamp) {
                //take in a formatted UTC date and output a local date & time
                return moment.utc(packetDateStamp, "YYYYMMDD_HHmmss").local().format("MM/DD/YYYY hh:mm:ssa");
            },
            toggleCollapseExpandAllCategories() {
                const ref = this.richTableRefs;

                if (Array.isArray(ref)) {
                    const isAnyTableCollapsed = ref.some(table => {
                        return table.data.isCollapsed
                    })

                    ref.forEach(tableRef => {
                        tableRef.data.isCollapsed = !isAnyTableCollapsed
                    })
                } 
                else if (ref) {
                    ref.data.isCollapsed = !ref.data.isCollapsed
                }
            },
            getUploadedDocuments() {
                const documentsUnsorted = [];
                if (!this.packet.isCurrent) return documentsUnsorted;

                this.fimenu.paperwork?.uploadedDocuments?.forEach((doc) => {
                    const docType = doc?.docInfo?.docType;

                    // Find the document type from settings
                    const documentType = settings.lookups.uploadedDocumentTypes.find(
                        (type) => type.code === docType
                    );

                    // Get the document packet category ID, or default to other category description
                    const documentPacketCategoryId = documentType?.documentPacketCategoryId ?? this.otherCategoryDescription;

                    // Determine the display name for the document type
                    let displayName;
                    if (docType?.startsWith('CUSTOM_')) {
                        displayName = docType.substring(7).replace(/_/g, ' ').toUpperCase();
                    } else {
                        displayName = documentType?.display || "Other";
                    }

                    // Add the document to the unsorted list with additional info
                    documentsUnsorted.push({
                        ...doc,
                        documentPacketCategoryId,
                        display: displayName
                    });
                });

                // Sort the document in alphabetical order.
                return documentsUnsorted.sort((a, b) => {
                    return a.display.localeCompare(b.display);
                });
            },
            setPaperworkCategories() {
                this.paperworkCategories = [];

                const uploadedDocuments = this.getUploadedDocuments();

                // Documents that need to be printed & signed on an impact printer
                const impactDocs = this.packet.documents?.filter(d => this.needsWetSignature(d)) ?? [];
                if (impactDocs.length > 0) {
                    this.paperworkCategories.push({
                        id: 'IMPACT-DOCS-TO-SIGN',
                        description: 'Requires Wet Signature',
                        docs: impactDocs,
                        uploadedDocuments: [],
                        isOpen: true,
                        isCategory: true
                    });
                }

                settings.lookups.paperworkCategories.forEach((c) => {
                    const matchingDocs = this.packet.documents?.filter((d) => d.categoryId === c.id && !this.needsWetSignature(d)) ?? [];
                    const matchingUploaded = uploadedDocuments?.filter((d) => d.documentPacketCategoryId === c.id) ?? [];

                    if (matchingDocs.length > 0 || matchingUploaded > 0 || (c.pnrDocs && c.pnrDocs.length > 0)) {
                        this.paperworkCategories.push({
                            ...c, 
                            docs: matchingDocs,
                            uploadedDocuments: matchingUploaded,
                            isOpen: true,
                            isCategory: true
                        });
                    }
                });

                // Set "other" category
                const otherDocs = this.packet.documents?.filter((d) => !this.paperworkCategories.some((c) => c.docs.some((catDoc) => catDoc.categoryId === d.categoryId))) ?? [];
                const otherUploaded = uploadedDocuments?.filter((d) => d.documentPacketCategoryId == null) ?? [];
                if (otherDocs.length > 0 || otherUploaded.length > 0) {
                    this.paperworkCategories.push({
                        id: this.otherCategoryDescription,
                        description: this.otherCategoryDescription,
                        docs: otherDocs,
                        uploadedDocuments: otherUploaded,
                        isOpen: true,
                        isCategory: true
                    });
                }
            },
            needsWetSignature(doc) {
                return !!doc.impactDoc && doc.status !== ENUMS.PAPERWORK_STATUS.Signed;
            },
            downloadDocument(path, name, configId, isCurrent, isUploadedDoc = false) {
                this.paperworkBusy = true;
                const dealID = this.fimenu.id;
                const uploadedFilePath = path.slice(0, path.lastIndexOf("/"));

                if (isUploadedDoc) {
                    api.documents.downloadDocument(path, name).finally(() => {
                        this.paperworkBusy = false;
                    });
                } else {
                    //Repopulate and Downloas only if the package is the current one, has configId and the user is not an spectator
                    if (isCurrent && configId && !this.fimenu.isSpectator) {
                        api.fimenu
                            .repopulateAndDownloadDocument(path, name + ".pdf", dealID, uploadedFilePath)
                            .finally(() => {
                                this.paperworkBusy = false;
                            });
                    } else {
                        api.fimenu.downloadDocument(path, name + ".pdf").finally(() => {
                            this.paperworkBusy = false;
                        });
                    }
                }
            },
            handleRemoveDocuments() {
                util.modalConfirmation(`Are you sure you want to DELETE ${this.totalSelectedDocuments} documents from this packet?`, "Yes", () => {
                    try {
                        const selectedDocuments = this.flatSelectedDocuments.map(doc => doc.signedDoc ?? doc.filledDoc)
                        this.unSelectAll();
                        for (let i = this.packet.documents.length - 1; i >= 0; i--) {
                            if (selectedDocuments.includes(this.packet.documents[i].filledDoc) ||
                                selectedDocuments.includes(this.packet.documents[i].signedDoc)) {
                                this.packet.documents.splice(i, 1);
                            }
                        }


                        this.fimenu.save()
                        util.toastr("success", "Success", "Document deleted successfully!")
                    } catch (err) {
                        util.toastr("error", "Delete Document Failed", err.message ?? "Could not delete the selected documents.")
                    }
                })
            },
            async handleRefreshPacket() {
                util.modalConfirmation("Are you sure you want to refill this packet? It could include new documents.", "Yes", async () => {
                    const currentPacket = this.fimenu.paperwork.currentPacket();

                    try {
                        this.isLoading = true;
                        // This is a list of all the document configurations that SHOULD/MUST generate this deal.
                        //It includes already generated documents
                        const docsToGenerateDetails = (await api.fimenu.getDocumentsToGenerate(this.fimenu, true)).data;

                        for (const generationDetail of docsToGenerateDetails) {
                            // Send the generation details to actually generate the document.
                            await this.generateDocument(generationDetail, false)
                        }
                    } catch (err) {
                        console.error(err)
                    } finally {
                        this.fimenu.updatePaperworkStatus()
                        this.fimenu.paperwork.orderPacketDocuments(currentPacket)
                        await this.fimenu.save()
                        this.isLoading = false;
                        util.toastr("success", "Packet Refreshed", "Your paperwork was refreshed successfully.")
                    }
                })
            },
            async handleRepopulate(openModal = true) {
                const packetSelectedDocuments = this.selectedDocuments.packetDocuments
                    .filter((doc) => doc.documentGenerationType == ENUMS.DocumentGenerationType.Paperwork)
                    .map((doc) => doc.signedDoc ?? doc.filledDoc)

                const uploadedSelectedDocuments = this.selectedDocuments.uploadedDocuments
                    .filter((doc) => doc.documentGenerationType == ENUMS.DocumentGenerationType.Uploaded)

                if (openModal) {
                    this.openPreviewPDFModal(packetSelectedDocuments, uploadedSelectedDocuments)
                }
                else {
                    this.isLoading = true;
                    try {
                        await this.downloadMultipleDocuments(packetSelectedDocuments, uploadedSelectedDocuments, true)
                    }
                    catch (err) {
                        console.error('ERROR DOWNLOADING DOCUMENTS: ', err);
                    }
                    finally {
                        this.isLoading = false;
                    }
                }
            },
            openPreviewPDFModal(packetSelectedDocuments, uploadedSelectedDocuments) {
                $modal.open(modalPreviewPDF, {
                    name: "modalPreviewPDF",
                    passedData: {
                        loadPDFs: () => this.downloadMultipleDocuments(packetSelectedDocuments, uploadedSelectedDocuments),
                        title: "Documents",
                        fimenu: this.fimenu,
                        packetSelectedDocuments: packetSelectedDocuments,
                        uploadedSelectedDocuments: uploadedSelectedDocuments,
                    },
                    backdrop: false,
                });
            },
            openPreviewPDFModalForImpactDocs(docId, docToDisplay, docForImpact, docForImpactPdf) {
                const docData = this.packet.documents.find(d => d.id === docId);
                const canUploadSignedCopy = this.packet.isCurrent && this.fimenu.dealStatus < ENUMS.DEAL_STATUS.ACCOUNTING_IN_PROCESS;

                $modal.open(modalPreviewPDF, {
                    name: 'modalPreviewPDF',
                    passedData: {
                        title: 'Documents',
                        fimenu: this.fimenu,
                        packetSelectedDocuments: [docToDisplay],
                        selectedImpactDocument: docForImpact,
                        selectedImpactPdfDocument: docForImpactPdf,
                        documentId: docId,
                        isDocSigned: docData.status === ENUMS.PaperworkDocumentStatus.Signed,
                        canUploadSignedCopy: canUploadSignedCopy,
                        loadPDFs: () => this.downloadMultipleDocuments([docToDisplay]),
                        uploadSignedDoc: this.uploadSignedPaperwork
                    }
                });
            },
            async uploadSignedPaperwork(docId, fileData) {
                this.isLoading = true;
                try {
                    await this.fimenu.uploadSignedPaperwork(docId, fileData);
                    await this.fimenu.save();

                    util.toastr('success', 'Upload Success', 'Successfully Uploaded Signed Paperwork.');
                }
                catch (err) {
                    console.error('ERROR UPLOADING SIGNED DOC: ', err);
                    util.toastr('error', 'Error Uploaded', 'An error occurred while trying to upload the signed paperwork.');
                }
                finally {
                    this.isLoading = false;
                }
            },
            async downloadMultipleDocuments(packetSelectedDocuments = [], uploadedSelectedDocuments = [], forceRepopulate = false) {
                try {
                    const response = await api.fimenu.repopulateAndDownloadDocuments(this.fimenu.id, packetSelectedDocuments, uploadedSelectedDocuments, forceRepopulate);
                    const documentPacket = this.packet;

                    // If for whatever reason we can't find the packet. Don't manipulate the data.
                    if (!documentPacket) 
                        return response?.data?.documentsDictionary;

                    // If a document doesn't need to be repopulated anymore. We set it here.
                    packetSelectedDocuments.forEach((doc) => {
                        const currentDoc = documentPacket?.documents.find(current => current.filledDoc == doc || current.signedDoc == doc);

                        const responseDocument = response.data.documents.find((responseDoc) => responseDoc.id == currentDoc.id);
                        if (!responseDocument) return;

                        const document = documentPacket?.documents.find((doc) => doc.id == currentDoc.id);
                        if (document) {
                            document.mustRepopulate = responseDocument.mustRepopulate
                            document.signedDoc = responseDocument.signedDoc
                            document.filledDoc = responseDocument.filledDoc
                            document.impactDoc = responseDocument.impactDoc
                            document.error = responseDocument.error
                        }
                    });

                    return response?.data?.documentsDictionary;
                }
                catch (err) {
                    console.error(err)
                    return null;
                }
            },
            areAllOverlaysSigned() {
                return !!this.packet.documents?.every((doc) => (
                    doc.status == ENUMS.PaperworkDocumentStatus.ErrorGenerating ||
                    doc.overlays?.length == 0 ||
                    doc.overlays?.every((overlay) => overlay.signature?.isSigned || overlay.initials?.isSigned)
                ));
            },
            async openCustomerPaperwork() {
                /* eslint-disable no-console*/
                console.log("We have all signed documents.", this.AllDocsSinged);
                /* eslint-enable */

                if (!this.user.EmployeeAccess.IsAdmin) {
                    //Change the ownership of the documents just if is not admin, in case we need to play with production deal
                    if (this.fimenu.fiManagerCode != this.user.EmployeeCode) {
                        this.fimenu.fiManagerCode = this.user.EmployeeCode
                        this.fimenu.fiManagerName = this.user.EmployeeName

                        //repopulate
                        this.fimenu.paperwork.currentPacket().documents.forEach(doc => {
                            doc.mustRepopulate = true;
                        })
                    }
                }

                const packetSelectedDocuments = []
                this.fimenu.paperwork.currentPacket().documents.forEach(doc => {
                    if (doc.mustRepopulate == true) {
                        packetSelectedDocuments.push(doc.filledDoc)
                    }
                })

                if (packetSelectedDocuments.length != 0) {
                    this.isLoading = true;
                    await this.downloadMultipleDocuments(packetSelectedDocuments, [], true)
                    this.isLoading = false;
                }

                if (this.areAllOverlaysSigned() && !this.AllDocsSinged) this.EventBus.emit("allPaperworkSigned");
                else this.EventBus.emit("openCustomerPaperwork");
            },
            downloadMerged() {
                this.paperworkBusy = true;
                api.fimenu
                    .downloadAllDocuments(
                        this.fimenu.dealNumber,
                        this.fimenu.storeCode,
                        this.packet.packetDateStamp,
                        this.packet.packetDateStamp + ".pdf"
                    )
                    .finally(() => {
                        this.paperworkBusy = false;
                    });
            },
            getDocumentsPanelActions() {
                const documentsPanelActions = [];

                if (!this.packet.isCurrent) {
                    // documentsPanelActions.push({
                    // 	text: "\u00A0\u00A0" + "Download Packet",
                    // 	class: "button button-icon-text button-info",
                    // 	icon: this.paperworkBusy ? "fas fa-cog fa-spin" : "fas fa-download",
                    // 	disabled: this.paperworkBusy,
                    // 	onClick: this.downloadMerged
                    // });

                    documentsPanelActions.push({
                        text: "\u00A0\u00A0" + "View",
                        class: "button button-icon-text button-load view-button ignore-all-locks",
                        icon: "far fa-eye",
                        disabled: Object.values(this.rawSelectedDocuments).length <= 0 || this.fimenu.isFinalizingPaperwork,
                        onClick: this.handleRepopulate
                    });
                }

                if (this.user && this.user.EmployeeAccess.IsAdmin && !this.packet.isCurrent) {
                    documentsPanelActions.splice(0, 0, {
                        text: "\u00A0\u00A0" + "Restore Packet",
                        class: "button button-icon-text button-save",
                        icon: this.paperworkBusy ? "fas fa-cog fa-spin" : "fas fa-retweet",
                        disabled:
                            !this.restorePacket ||
                            this.paperworkBusy ||
                            !this.fimenu.coverageTerms.some((c) => c.selected) ||
                            this.fimenu.paperwork.packets.some((p) => p.isCurrent),
                        onClick: () => this.restorePacket(this.packet)
                    });
                }

                return documentsPanelActions
            },
            async getCustomersCreditReport() {
                const customer = this.fimenu.customer
                if (customer?.id) {
                    const response = await api.creditreport.getByCustomerId(customer.id, this.fimenu.isDealLocked() ? this.fimenu.id : null)

                    if (response.data != null) {
                        this.fimenu.customer.creditReport = new FIMenuCreditReport(response.data)
                    }
                }

                const coCustomer = this.fimenu.coCustomer
                if (coCustomer?.id) {
                    const response = await api.creditreport.getByCustomerId(coCustomer.id, this.fimenu.isDealLocked() ? this.fimenu.id : null)

                    if (response.data != null) {
                        this.fimenu.coCustomer.creditReport = new FIMenuCreditReport(response.data)
                    }
                }
            },
            voidPaperworkAction(isNonApi = false) {
                if (this.$dealRoomHub.connected && this.fimenu.isSpectator) return;

                if (isNonApi) {
                    this.voidPaperwork(isNonApi)
                } 
                else {
                    const timezone = util.getStoreTimezone(this.$global.selectedStore.storeTimeZone)

                    //if the packet is from the current month, let them proceed, otherwise don't
                    const packetDateStr = moment.utc(this.packet.packetDateStamp, "YYYYMMDD_hhmmss")
                        .toDate()
                        .toLocaleString("en-US", { timeZone: timezone });
                    const packetDate = moment(packetDateStr, "MM/DD/YYYY hh:mm:ss A")

                    // console.log("packet date", packetDate)

                    const todayStoreDateStr = moment().utc()
                        .toDate()
                        .toLocaleString("en-US", { timeZone: timezone });
                    const todayStoreDate = moment(todayStoreDateStr, "MM/DD/YYYY hh:mm:ss A")

                    // console.log("todays store date.", todayStoreDate)

                    const foundADocWithContractNumber = this.packet.documents.some(a => a.contractNumber != null)
                    if ((todayStoreDate.diff(packetDate, "years") == 0 && todayStoreDate.month() == packetDate.month()) || !foundADocWithContractNumber) {
                        $modal.open(modalInfo,
                            {
                                name: 'modalInfo',
                                passedData: {
                                    info: 'Are you sure you want to void current paperwork?',
                                    cancelText: 'Cancel',
                                    acceptText: 'Continue'
                                }, backdrop: false, postFunction: this.voidPaperwork
                            });
                    }
                    else {
                        if (this.user && this.user.EmployeeAccess.IsAccounting) {
                            $modal.open(modalInfo,
                                {
                                    name: 'modalInfo',
                                    passedData: {
                                        info: "The Products you are attempting to cancel are from a previous month. The VOID function might not void in the Provider's portal, and you might still need to flat cancel the Products. Click Continue to remove these Products from Saturn, you will still need to check these products in their Provider's portal.",
                                        cancelText: 'Cancel',
                                        acceptText: 'Continue'
                                    }, backdrop: false, postFunction: this.voidPaperwork
                                });
                        }
                        else {
                            $modal.open(modalInfo, {
                                name: 'modalInfo',
                                passedData: {
                                    info: 'This is a packet from a previous month and cannot be voided through Saturn. Please ask accounting to Flat Cancel the coverage before re-issuing new coverage.',
                                    acceptText: 'Continue'
                                }, backdrop: true
                            });
                        }
                    }
                }
            },
            async voidPaperwork(isNonApi = false) {
                this.fimenu.buyersOrderUpdateRequired = false

                this.setVoidedPacket()
                this.paperworkBusy = true;
                this.busyVoiding = true;
                this.modalStatus.modalBusy = true;

				this.EventBus.emit("paperworkStartVoiding");

                try {
                    const response = await api.fimenu.voidPaperwork(this.fimenu, isNonApi);

                    if (response && response.data) this.fimenu.paperwork = new FIMenuPaperwork(response.data);
                    this.EventBus.emit("paperworkReset");

                    // We check within the method if there's anything to be voided.
                    await this.voidPlateTransactions();

                    if (this.fimenu.customer.id == null) {
                        this.fimenu.customer.isThereAnyUnsavedCustomerChanges = true
                    }

                    if (this.fimenu.hasCoSigner && this.fimenu.coCustomer.id == null) {
                        this.fimenu.coCustomer.isThereAnyUnsavedCustomerChanges = true
                    }

                } catch (err) {
                    console.error(err)
                } finally {
                    this.paperworkBusy = false;
                    this.busyVoiding = false;
                    this.modalStatus.modalBusy = false;

                    this.EventBus.emit("paperworkEndedVoiding");
                    this.getCustomersCreditReport()
                }

            },
            async voidPlateTransactions() {
                const isTransferDeal = this.fimenu.vehicle.registrationInfo.isPlateTransfer;
                const lastTransferTransaction = this.fimenu.vehicle.registrationInfo?.getLatestTransfer();
                const hasPreviousTransferDealThatIsNotVoid = lastTransferTransaction?.isTransferVoid == false;
                const isPlateTransactionsEnabled = !!IsPlateTransactionsEnabled(this.fimenu);

                if (isPlateTransactionsEnabled && isTransferDeal && hasPreviousTransferDealThatIsNotVoid && (lastTransferTransaction != null)) {
                    try {
                        // This method will also add the new transaction into FIMenu
                        const response = await CancelPlateTransaction(lastTransferTransaction.providerDealId, this.fimenu.id)
                        const tIndex = this.fimenu.vehicle.registrationInfo.plateTransactions.findIndex(x => x.providerDealId == lastTransferTransaction.providerDealId);

                        if (tIndex >= 0 && response.data.payload) {
                            const transaction = new FIMenuPlateTransactions(response.data.payload)
                            this.fimenu.vehicle.registrationInfo.plateTransactions[tIndex] = transaction;

                            if (transaction.error.errorMessage) {
                                util.toastr("error", 'Plate Transaction', transaction.error.errorMessage)
                            } else {
                                util.toastr("success", 'Plate Transaction', 'Transaction voided successfully.')
                            }

                        }

                    } catch (err) {
                        util.toastr('error', 'Plate Transaction', err ?? "We could not void the registration.")
                    }
                }
            },
            activatePaperwork(doc = null) {
                if (this.fimenu.isAvidan() && settings.environmentName != "PRODUCTION") {
                    if (
                        !window.confirm(
                            "You are using AVIDAN's PHONE NUMBNER in Customer Cell (this will activate with PRODUCTION credentials and create real contracts). Are you sure you want to continue?"
                        )
                    )
                        return;
                }

				this.paperworkBusy = true;
				this.busyActivating = true;
				this.modalStatus.modalBusy = true;
				this.EventBus.emit("paperworkStartActivating");

                const activatePaperworkRequest = { fimenu: this.fimenu, docTypes: doc ? [doc.type] : [] };

                api.fimenu
                    .activatePaperwork(activatePaperworkRequest)
                    .then((response) => {
                        if (response && response.data) {
                            const docs = response.data;
                            docs.forEach((doc) => {
                                const index = this.packet.documents.findIndex(
                                    (d) =>
                                    d.type == doc.type && d.modelId == doc.modelId
                                );

                                for(const document in this.packet.documents) {
                                    console.log("Packet before", this.packet.documents[index].activationInfo, doc)
                                    this.packet.documents[index] = doc;
                                    console.log("Packet after", this.packet.documents[index].activationInfo)
                                }
                                // Object.keys(this.packet.documents[index]).forEach((k) => {
                                // });
                            });

							util.toastr("success", "Activation", "Activation completed.");
						} else {
							util.toastr("error", "Activation", response.data.error.description);
						}
					})
					.finally(() => {
						this.paperworkBusy = false;
						this.busyActivating = false;
						this.modalStatus.modalBusy = false;
						this.EventBus.emit("paperworkEndedActivating");
                        this.setPaperworkCategories();
					});
			},
			openEditContractNumber(doc) {
				if (this.$global.isAdminView) {
					$modal.open(modalEditContractNumber, {
						name: "modalEditContractNumber",
						passedData: { doc, acceptText: "Save", cancelText: "Cancel" },
						user: this.user,
						backdrop: true,
						postFunction: () => this.handleModalEditContractNumberSave(doc)
					});
				}
			},
			handleModalEditContractNumberSave(doc) {
				const { PaperworkDocumentStatus: pwDocSatus } = ENUMS;
				const { documents } = this.packet;

                if (!doc.contractNumber && !doc.memberNumber && doc.activationInfo?.activationError) {
                    doc.status = pwDocSatus.ErrorActivating;
                } else if (doc.status === pwDocSatus.ErrorActivating) {
                    if (doc.signedDoc) {
                        doc.status = pwDocSatus.Signed;
                    } else {
                        const index = documents.findIndex(
                            (d) => doc.modelId === d.modelId && doc.type === d.type
                        );

                        if (
                            index > -1 &&
                            documents.length >= index &&
                            documents[index].status === pwDocSatus.SigningInProgress
                        ) {
                            doc.status = pwDocSatus.SigningInProgress;
                        } else {
                            doc.status = pwDocSatus.ReadyToBeSigned;
                        }
                    }
                }

                doc.activationInfo = {
                    activatedBy: "Administrator",
                    activationDateStamp: moment.utc(),
                    activationError: null,
                    isSuccess: true
                }

				this.EventBus.emit("paperworkEndedActivating");
			},
			getProductDetails(doc) {
				if (
					doc.type == ENUMS.PAPERWORK_DOCUMENT_TYPES.PaperworkDocument
					|| doc.type == ENUMS.PAPERWORK_DOCUMENT_TYPES.AcceptDecline
				) {
					return [];
                }

                const displayDetails = [];
                const documentProductType = ENUMS.paperworkDocumentTypes.find(
                    (paperworkDocumentType) => paperworkDocumentType.typeString == doc.typeString
                );

                if (!documentProductType) return []

                const productDetails = this.activeProducts.find(
                    (product) => product.productType === documentProductType.productType && product.productPricingDetailsId === doc.modelId
                );

                if (!productDetails) return [];

                if (productDetails.productInfo?.program?.description)
                    displayDetails.push(`${productDetails.productInfo.program.description}`);

                if (productDetails.productInfo?.termLimit)
                    displayDetails.push("", `${productDetails.productInfo.termLimit / 12} years`);

                if (productDetails.productInfo?.milesLimit)
                    displayDetails.push("", `${productDetails.productInfo.milesLimit} miles`);

                if (productDetails.productPricing?.cost && productDetails.productPricing?.price) {
                    displayDetails.push(
                        "",
                        `Cost: ${util.formatPrice(productDetails.productPricing.cost)}`,
                        "",
                        `Price: ${util.formatPrice(productDetails.productPricing.price)}`,
                        "",
                        `Profit: ${util.formatPrice(
                            productDetails.productPricing.price - productDetails.productPricing.cost
                        )}`
                    );
                }

                return displayDetails.length > 0 ? displayDetails : [];
            },
            getPaperworkDocType(doc) {
                let docType = ENUMS.paperworkDocumentTypes.find((pdt) => pdt.typeString == doc.typeString);
                if (docType) {
                    docType = util.clone(docType);
                    if (docType.typeString.includes("PEN")) {
                        if (doc.modelId == util.getDefaultGuid()) {
                            docType.provider = this.fimenu.selectedProviders.find(
                                (sp) => sp.productType === docType.productType
                            )?.provider;
                        } else {
                            const mp = this.fimenu.miscellaneousProviders.find(
                                (mp) => mp.productPricingDetailsId === doc.modelId
                            );
                            if (mp.provider) {
                                docType.provider = mp.provider;
                            } else if (mp.productInfoAndPricing.length > 0) {
                                docType.provider = mp.productInfoAndPricing[0].termInfo.provider;
                            } else {
                                docType.provider = mp.productInfo.provider;
                            }
                        }

                        docType.provider = docType.provider ? docType.provider.substring(4) : "PEN";
                    }
                } else {
                    docType = { provider: doc.typeString };
                }

                return docType;
            },
            signedInformation(doc) {
                const messages = [];
                //if(doc.status != ENUMS.PaperworkDocumentStatus.ErrorGenerating && doc.status != ENUMS.PaperworkDocumentStatus.ReadyToBeSigned) {
                if (doc.signatureInfo) {
                    const customerSigned = this.hasSigned(doc.overlays, ENUMS.PERSON_TYPES.CUSTOMER);

                    if (customerSigned && doc.signatureInfo?.customerSignDate) {
                        messages.push(
                            `${this.fimenu.customer?.fullName.toUpperCase()} signed at ${this.convertDateStringToDate(
                                doc.signatureInfo?.customerSignDate
                            )}`
                        );
                    }

                    if (this.fimenu.hasCoSigner && doc.signatureInfo?.coCustomerSignDate) {
                        const coCustomerSigned = this.hasSigned(doc.overlays, ENUMS.PERSON_TYPES.COCUSTOMER);
                        if (coCustomerSigned) {
                            if (customerSigned) messages.push("");
                            messages.push(
                                `${this.fimenu.coCustomer?.fullName.toUpperCase()} signed at ${this.convertDateStringToDate(
                                    doc.signatureInfo?.coCustomerSignDate
                                )}`
                            );
                        }
                    }
                }
                return messages;
            },
            hasSigned(overlays, signer) {
                let signerNeedsToSign = 0;

                if (overlays) {
                    for (const overlay of overlays) {
                        if (
                            overlay.initials?.personType == ENUMS.PERSON_TYPES.FIMANAGER ||
                            overlay.signature?.personType == ENUMS.PERSON_TYPES.FIMANAGER
                        )
                            continue;

                        if (overlay.signature?.personType == signer || overlay.initials?.personType == signer) {
                            signerNeedsToSign++;

                            if (overlay.initials && !overlay.initials?.isSigned) return false;
                            if (overlay.signature && !overlay.signature?.isSigned) return false;
                        }
                    }
                }
                if (signerNeedsToSign > 0) return true;
                return false;
            },
            requiresSigner(overlays, signer) {
                if (overlays) {
                    for (const overlay of overlays) {
                        if (
                            overlay.initials?.personType == ENUMS.PERSON_TYPES.FIMANAGER ||
                            overlay.signature?.personType == ENUMS.PERSON_TYPES.FIMANAGER
                        )
                            continue;

                        if (overlay.signature?.personType == signer || overlay.initials?.personType == signer)
                            return true;
                    }
                }
                return false;
            },
            tooltipInformation(doc) {
                const messages = [];

                if (doc.status == ENUMS.PaperworkDocumentStatus.ErrorGenerating) return [];

                const GenerateActivationInformation = () => {
                    if (messages.length) messages.push("-");

                    if (doc.activationInfo?.activationError) {
                        messages.push("Activation Error: " + doc.activationInfo.activationError.errorDescription);
                    }

                    if (doc.activationInfo?.activatedBy) {
                        if (messages.length) messages.push("");
                        messages.push(`Activated by ${doc.activationInfo.activatedBy}`);
                    }

                    if (doc.activationInfo?.activationDateStamp) {
                        if (messages.length) messages.push("");
                        messages.push(
                            `Activated at ${moment
                                .utc(doc.activationInfo.activationDateStamp, "YYYYMMDD_HHmmss")
                                .local()
                                .format("MM/DD/YYYY hh:mm:ssa")}`
                        );
                    }

                    if (
                        doc.voidInfo?.voidError ||
                        doc.activationInfo?.activationError ||
                        doc.status == ENUMS.PaperworkDocumentStatus.Voided
                    ) {
                        if (messages.length) messages.push("");
                        if (doc.memberNumber && doc.contractNumber)
                            messages.push(`Member/Contract number: ${doc.memberNumber}/${doc.contractNumber}`);
                        else if (doc.memberNumber) messages.push(`Member number: ${doc.memberNumber}`);
                        else if (doc.contractNumber) messages.push(`Contract number: ${doc.contractNumber}`);
                    }
                };

                const GenerateVoidInformation = () => {
                    if (doc.voidInfo?.voidError) {
                        if (messages.length) messages.push("");
                        messages.push("Void Error: " + doc.voidInfo.voidError.errorDescription);
                    }

                    if (doc.voidInfo?.voidedBy) {
                        if (messages.length) messages.push("");
                        messages.push(`Voided by ${doc.voidInfo.voidedBy}`);
                    }

                    if (doc.voidInfo?.voidDateStamp) {
                        if (messages.length) messages.push("");
                        messages.push(
                            `Voided at ${moment
                                .utc(doc.voidInfo.voidDateStamp, "YYYYMMDD_HHmmss")
                                .local()
                                .format("MM/DD/YYYY hh:mm:ssa")}`
                        );
                    }
                };

                const GenerateCancelInformation = () => {
                    if (doc.cancellationInfo?.error) {
                        if (messages.length) messages.push("");
                        messages.push("Cancel Error: " + doc.cancellationInfo.error.errorDescription);
                    }

                    if (doc.cancellationInfo?.requestedBy) {
                        if (messages.length) messages.push("");
                        messages.push(`Cancelled by ${doc.cancellationInfo.requestedBy}`);
                    }

                    if (doc.cancellationInfo?.dateStamp) {
                        if (messages.length) messages.push("");
                        messages.push(
                            `Cancelled at ${moment
                                .utc(doc.cancellationInfo.dateStamp, "YYYYMMDD_HHmmss")
                                .local()
                                .format("MM/DD/YYYY hh:mm:ssa")}`
                        );
                    }
                };

                GenerateCancelInformation();
                GenerateVoidInformation();
                GenerateActivationInformation();

                if (messages[messages.length - 1] == "-") messages.splice(messages.length - 1, 1);

                return messages;
            },
            async regenerateSelected() {
                util.modalConfirmation("Are you sure you want to regenerate the selected documents? You will loose any signatures if it's signed.", "Yes", async () => {
                    try {
                        this.isLoading = true;

                        for (const document of this.flatSelectedDocuments) {
                            await this.regenerateDocument(document, [], true)
                        }

                        this.unSelectAll();
                    } finally {
                        this.isLoading = false;
                    }
                })
            },

            async regenerateDocument(document, docsToGenerateDetails = [], forceInternal = false) {
                const currentPacket = this.fimenu.paperwork.currentPacket();

                try {
                    this.regeneratingAndDownloadMultipleDocs = true;
                    let docToGenerateDetails = null;

                    const nonProductDocTypes = [ENUMS.PAPERWORK_DOCUMENT_TYPES.PaperworkDocument, ENUMS.PAPERWORK_DOCUMENT_TYPES.AcceptDecline, ENUMS.PAPERWORK_DOCUMENT_TYPES.Trades];
                    
                    if (!nonProductDocTypes.includes(document.type)) {
                        // Get all the documents details that are needed in this deal.

                        // TODO: Improve to only get the generation details of the required document. We're getting all because we're reusing this method.
                        if (!docsToGenerateDetails.length)
                            docsToGenerateDetails = (await api.fimenu.getDocumentsToGenerate(this.fimenu)).data;

                        // Find the detail that matches the document i'm trying to generate.
                        docToGenerateDetails = docsToGenerateDetails.find(x => {
                            const productType = ENUMS.paperworkDocumentTypes.find(dt => dt.typeString === document.typeString)?.productType;
                            const matchProduct = x.productDetails?.productType == productType && x.productDetails?.productPricingDetailsId == document.modelId;
                            return matchProduct
                        });
                    }
                    else if (document.paperworkDocumentConfigId != null) {
                        // If it's a non-product document.
                        const response = await api.fimenu.getLastestDocConfig(this.fimenu, document.paperworkDocumentConfigId, document.modelId);
                        if (response.data) docToGenerateDetails = response.data;
                    }

                    // Some documents cannot be regenerated because if the criteria formula to generate it changed. There's no way for us know which document has to be regenerated.
                    // In the front-end when we only have the product info within the document.
                    if (docToGenerateDetails == null) return util.toastr("error", "Error", "Document cannot be regenerated!");

                    const succeeded = await this.generateDocument(docToGenerateDetails, forceInternal, document)

                    if (succeeded) util.toastr("success", "Success", "Document generated successfully!");
                } catch (err) {
                    util.toastr("error", "Error", err.message ?? "Document could not be generated!")
                } finally {
                    this.fimenu.paperwork.orderPacketDocuments(currentPacket)
                    this.regeneratingAndDownloadMultipleDocs = false;
                }
            },
            // This method takes in a generation details and generates them one.
            async generateDocument(docToGenerateDetails, forceInternal, document = null) {
                // Send the generation details to actually generate the document.
                const { data: responseData } = await api.fimenu.generateDocument(this.fimenu, docToGenerateDetails, forceInternal);
                const generatedDocument = responseData.payload;

                let isSuccess = true
                if (responseData.statusCode >= 400) {
                    util.toastr("error", "Error", responseData?.messages[0] ?? "Unexpected server error.")
                    isSuccess = false;
                }

                if (!generatedDocument) {
                    return false;
                }

                // Populate the document in the document packet.
                const currentPacket = this.fimenu.paperwork.currentPacket();
                if (currentPacket) {
                    const documentIndex = currentPacket.documents.findIndex(indexDoc => indexDoc.id == document?.id);
                    if (documentIndex >= 0) {
                        // If we're using internal only. That means we're regenerating the document; which will update the contracts numbers to the new document.
                        if (forceInternal) {
                            generatedDocument.contractRate = currentPacket.documents[documentIndex]?.contractRate;
                            generatedDocument.contractNumber = currentPacket.documents[documentIndex]?.contractNumber;
                            generatedDocument.memberNumber = currentPacket.documents[documentIndex]?.memberNumber;
                            generatedDocument.activationInfo = currentPacket.documents[documentIndex]?.activationInfo;
                        }

                        currentPacket.documents.splice(documentIndex, 1, generatedDocument);
                    }
                    else {
                        currentPacket.documents.push(generatedDocument)
                    }

                    await this.fimenu.save()
                }

                return isSuccess;
            }
        }
    };
</script>
<style>

	.document-packet-subtitle{
		padding: 10px;
	}
	.splitlines {
		display: flex;
		flex-direction: column;
		row-gap: 2px;
	}

    .signersText {
        font-size: 10.5pt;
        font-weight: bold;
        color: #fda362;
    }

    .signed,
    .text-success {
        color: var(--success-color);
    }

    .text-error {
        color: var(--error-color);
    }

    .text-warning {
        color: #fda362;
    }

    .bold-text {
        font-weight: bold;
    }

    .messageContainer {
        box-sizing: border-box;
        cursor: default;
        user-select: none;
    }

        .messageContainer p {
            font-size: 11pt !important;
        }

    .defaultCursor {
        cursor: default;
    }

    .iconSpacing {
        margin-right: 6px;
    }

    .docName-header {
        width: 35%;
    }

	.signature-header {
		width: 30%;
	}
	.download-header {
		width: 8%;
	}
	.activation-header {
		width: 20%;
	}

	.rich-table-header-checkbox{
		width: 100px;
	}

    .rich-table-rows-checkbox .InputCheckbox {
        margin-left: 20px;
    }

    .has-field-error > button {
        border: 2px solid var(--print-color);
        border-radius: 5px;
    }

    .button.button-icon-text.button-load {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .document-packet .rich-table-container {
        max-height: unset !important;
    }

	.document-packet table.rich-table tbody {
		text-transform: unset;
	}

	.border-left-row{
		position: relative;
		background-color: var(--black-10percent) !important;
		/* border-left: 5px solid blue; */
	}
	.border-left-row::after{
		content: '';
		position: absolute;
		width: 5px;
		height: 100%;
		left: 0px;
		background: blue;
		/* border-left: 5px solid blue; */
	}

</style>
