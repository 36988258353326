<template>
    <div class="PanelDealAddresses">
        <div v-for="(addressQuestion, index) in dealAddressesQuestions" :key="index">
            <div class="radio-button-inline" :invalid="util.isNull(validation) ? false : validation.dealAddressesQuestions[index].$invalid" :style="$grid('411px-1')">
                <span>{{ addressQuestion.question }}</span>

                <div class="no-padding-grid">
                    <InputRadio label="Yes"
                                :valueMatch="true"
                                :modelValue="addressQuestion.answer"
                                @update:modelValue="(val) => onAnswerAddressQuestion(val, index, addressQuestion)"
                                :isButton="true" />

                    <InputRadio label="No"
                                :valueMatch="false"
                                :modelValue="addressQuestion.answer"
                                @update:modelValue="(val) => onAnswerAddressQuestion(val, index, addressQuestion)"
                                :isButton="true" />
                </div>
            </div>

            <Panel v-if="addressQuestion.answer && indexInDealAddresses(addressQuestion.type) !== -1" 
                class="deal-address-panel"
                :title="addressQuestion.title" 
                :action="getAvailableCopyActions(addressQuestion.type)"
            >
                <AddressComponent :data="fimenu.dealAddresses[indexInDealAddresses(addressQuestion.type)]"
                                  :validation="validation.dealAddresses[indexInDealAddresses(addressQuestion.type)]"
                                  countryQuestionText="Is the address in the US?"
                                  searchable panelView showCounty />
            </Panel>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue'
    import util from '@core/services/util'
    
    import ENUMS from '@core/classes/Enums';
    import FIMenu from '@core/classes/FIMenu'
    import FIMenuAddress from '@core/classes/FIMenuAddress'
    
    import AddressComponent from '@/components/AddressComponent.vue'
    import InputRadio from '@core/components/InputRadio.vue'
    import Panel from '@core/components/Panel.vue'

    export default defineComponent({
        name: "PanelDealAddresses",
        components: {
            Panel,
            AddressComponent,
            InputRadio
        },
        props: {
            fimenu: FIMenu, 
            validation: Object as PropType<any>, 
            disabled: Boolean
        },
        data() {
            return {
                dealAddressesQuestions: [],
                addressActions: [],
                dealUpdatedExternally: false,
            }
        },
        computed: {
            util() {
                return util;
            },
            dealAddresses(): FIMenuAddress[] {
                return this.fimenu.dealAddresses;
            },
        },
        created() {
            this.EventBus.on('dealUpdatedExternally', this.onDealUpdatedExternally);
            this.dealAddressesQuestions = this.fimenu.dealAddressesQuestions.map((i: any) => i);
        },
        beforeUnmount() {
            this.EventBus.off('dealUpdatedExternally', this.onDealUpdatedExternally);
        },
        watch: {
            'fimenu.dealAddressesQuestions': {
                handler(newVal) {
                    util.debounce(this.onDealAddressQuestionsUpdated, 100, newVal);
                },
                deep: true
            }
        },
        methods: {
            onDealUpdatedExternally(newVal: boolean) {
                this.dealUpdatedExternally = newVal;
            },
            onDealAddressQuestionsUpdated(newVal: any) {
                if (!this.$dealRoomHub.connected) return;
                    
                    // Keep radio buttons synced with FI Menu updates
                    for (let i = 0; i < newVal.length; i++) {
                        if (this.dealAddressesQuestions[i])
                            this.dealAddressesQuestions[i].answer = newVal[i].answer;
                    }
            },
            onAnswerAddressQuestion(isSelected: boolean, index: number, addressQuestion: any) {
                if (this.disabled || this.dealUpdatedExternally) return;

                if (isSelected) {
                    const newAddress = new FIMenuAddress();
                    newAddress.addressType = addressQuestion.type;
                    this.fimenu.setAddress(addressQuestion.type, newAddress);
                }
                else {
                    this.fimenu.removeAddress(addressQuestion.type);
                }

                this.fimenu.setDealAddressQuestionAnswer(this.dealAddressesQuestions[index].type, isSelected);
            },
            indexInDealAddresses(type: number): number {
                return this.fimenu.indexInDealAddresses(type);
            },
            getAvailableCopyActions(currentType: number): { text: string, onClick: () => void}[] {
                const availableActions = [];

                for (let i = 0; i < this.dealAddresses.length; i++) {
                    if (this.dealAddresses[i].addressType === currentType) continue;

                    const addressEnumObj = ENUMS.addressType.find(a => a.type === this.dealAddresses[i].addressType);
                    if (!addressEnumObj) continue;

                    availableActions.push({
                        text: `Copy address from ${addressEnumObj.description}`,
                        onClick: () => this.fimenu.copyDealAddress(currentType, this.dealAddresses[i])
                    });
                }

                return availableActions;
            },
        },
    });
</script>
<style>
    .deal-address-panel{
        margin-bottom: 10px;
    }
</style>