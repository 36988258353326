<template>
    <Panel title="Insurance Information">
        <template #HeaderComponent>
            <div class="panel-header-button-array">
                <button v-if="isInsuranceVerified" class="button ignore-all-locks" @click="openAutoCompleteModal">
                    View AutoComplete Data
                </button>
            </div>
        </template>

        <div :style="$grid('2-1.5-1.5-1.5-2')">
            <InputTextbox label="Insurance Company Name"
                          v-model:saturn="data.companyName"
                          :modelValue="data.companyName"
                          :invalid="util.isNull(validation) ? false : validation.companyName.$invalid"
                          :infoIconObj="autoCompleteTooltips.companyName"
                          placeholder="Insurance Company Name">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.companyName.required.$invalid">Insurance Company Name is required</div>
                </template>
            </InputTextbox>
            <InputTextbox label="NAIC Number"
                          v-model:saturn="data.naic"
                          :modelValue="data.naic"
                          :invalid="util.isNull(validation) ? false : validation.naic.$invalid"
                          placeholder="NAIC Number">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.naic.required.$invalid"> NAIC Number is required</div>
                </template>
            </InputTextbox>
            <InputTextbox label="Policy Number"
                          v-model:saturn="data.policyNumber"
                          :modelValue="data.policyNumber"
                          :invalid="util.isNull(validation) ? false : validation.policyNumber.$invalid"
                          :infoIconObj="autoCompleteTooltips.policyNumber"
                          placeholder="Policy Number"
                          :isUpperCase="true">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.policyNumber.required.$invalid">Policy Number is required</div>
                </template>
            </InputTextbox>
            <InputPhone label="Phone Number"
                        type="tel"
                        v-model:saturn="data.agencyPhoneNumber"
                        :modelValue="data.agencyPhoneNumber"
                        :invalid="util.isNull(validation) ? false : validation.agencyPhoneNumber.$invalid"
                        placeholder="Phone Number">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.agencyPhoneNumber.required.$invalid">Phone Number is required</div>
                    <div v-if="!util.isNull(validation) && validation.agencyPhoneNumber.minLength.$invalid">Phone Number must be 10 characters.</div>
                </template>
            </InputPhone>
            <InputTextbox label="Agency Name"
                        v-model:saturn="data.agencyName"
                        :modelValue="data.agencyName"
                        :invalid="util.isNull(validation) ? false : validation.agencyName.$invalid"
                        placeholder="Agency Name">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.agencyName.required.$invalid">Agency Name is required</div>
                </template>
            </InputTextbox>
        </div>
        <div :style="$grid('1-1')">
            <InputTextbox label="Verified By"
                          v-model:saturn="data.verifiedBy"
                          :modelValue="data.verifiedBy"
                          :invalid="util.isNull(validation) ? false : validation.verifiedBy.$invalid"
                          placeholder="Verified By">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.verifiedBy.required.$invalid">Verified By is required</div>
                </template>
            </InputTextbox>
            <InputTextbox label="Spoke With"
                        v-model:saturn="data.agentName"
                        :modelValue="data.agentName"
                        :invalid="util.isNull(validation) ? false : validation.agentName.$invalid"
                        placeholder="Spoke With">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.agentName.required.$invalid">Spoke With is required</div>
                </template>
            </InputTextbox>
        </div>
        <div :style="$grid('1-1-1-1-1')" class="no-margin-grid">
            <InputCurrency label="Collision"
                           v-model:saturn="data.collision"
                           :modelValue="data.collision"
                           :infoIconObj="autoCompleteTooltips.collision"
                           placeholder="Collison"
                           :invalid="util.isNull(validation) ? false : validation.collision.$invalid">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.collision.required.$invalid">Collision is required</div>
                    <div v-if="!util.isNull(validation) && validation.collision.minValue.$invalid">{{ validation.collision.minValue.$message }}.</div>
                </template>
            </InputCurrency>
            <InputCurrency label="Comprehensive"
                           v-model:saturn="data.comprehensive"
                           :modelValue="data.comprehensive"
                           :infoIconObj="autoCompleteTooltips.comprehensive"
                           placeholder="Compensation"
                           :invalid="util.isNull(validation) ? false : validation.comprehensive.$invalid">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.comprehensive.required.$invalid">Comprehensive is required</div>
                    <div v-if="!util.isNull(validation) && validation.comprehensive.minValue.$invalid">{{ validation.comprehensive.minValue.$message }}.</div>
                </template>
            </InputCurrency>
            <InputCurrency label="Fire / Theft"
                           v-model:saturn="data.fireTheft"
                           :modelValue="data.fireTheft"
                           placeholder="Fire / Theft"
                           :invalid="util.isNull(validation) ? false : validation.fireTheft.$invalid">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.fireTheft.required.$invalid">Fire / Theft is required</div>
                    <div v-if="!util.isNull(validation) && validation.fireTheft.minValue.$invalid">{{ validation.fireTheft.minValue.$message }}.</div>
                </template>
            </InputCurrency>
            <InputDate label="Effective Date"
                        v-model:saturn="data.effectiveDate"
                        :modelValue="data.effectiveDate"
                        :invalid="util.isNull(validation) ? false : validation.effectiveDate.$invalid"
                        :infoIconObj="autoCompleteTooltips.effectiveDate"
                        placeholder="Effective Date">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.effectiveDate.required.$invalid">Effective Date date is required</div>
                    <div v-else-if="!util.isNull(validation) && validation.effectiveDate.valid.$invalid">Effective Date must be today or a past date, not more than 12 months old.</div>
                </template>
            </InputDate>
            <InputDate label="Expiration Date"
                        v-model:saturn="data.expirationDate"
                        :modelValue="data.expirationDate"
                        :invalid="util.isNull(validation) ? false : validation.expirationDate.$invalid"
                        :infoIconObj="autoCompleteTooltips.expirationDate"
                        placeholder="Expiration Date">
                <template #validation>
                    <div v-if="!util.isNull(validation) && validation.expirationDate.required.$invalid">Expiration Date date is required</div>
                    <div v-else-if="!util.isNull(validation) && validation.expirationDate.valid.$invalid">Expiration Date has to be a future date, after effective date and less than 12 months </div>
                </template>
            </InputDate>
        </div>
    </Panel>
</template>

<script lang="ts">
    import { defineComponent, PropType } from 'vue';
    import $modal from '@core/services/modal'
    import util from '@core/services/util';

    import { AutoCompleteVerificationResponse } from '@core/classes/Insurance/AutoCompleteResponses';
    import FIMenuCustomer from '@core/classes/FIMenuCustomer';
    import FIMenuInsurance from '@core/classes/FIMenuInsurance';
    
    import modalAutoCompleteResults from '@/modals/modalAutoCompleteResults.vue';

    import InputCurrency from '@core/components/InputCurrency.vue';
    import InputDate from '@core/components/InputDate.vue';
    import InputPhone from '@core/components/InputPhone.vue';
    import InputTextbox from '@core/components/InputTextbox.vue';
    import Panel from '@core/components/Panel.vue';

    interface AutoCompleteTooltip {
        icon: string;
        tooltipText: string[]
    }

    interface AutoCompleteTooltipList {
        companyName?: AutoCompleteTooltip;
        policyNumber?: AutoCompleteTooltip;
        effectiveDate?: AutoCompleteTooltip;
        expirationDate?: AutoCompleteTooltip;
        collision?: AutoCompleteTooltip;
        comprehensive?: AutoCompleteTooltip;
    }

    export default defineComponent({
        name: "PanelInsurance",
        components: {
            Panel: Panel,
            InputTextbox: InputTextbox,
            InputDate: InputDate,
            InputCurrency: InputCurrency,
            InputPhone: InputPhone,
        },
        props: {
            isLoading: Boolean,
            isAutoCompleteEnabled: Boolean,
            dealId: String,
            storeCode: String,
            customer: FIMenuCustomer,
            data: FIMenuInsurance,
            validation: Object,
            autoCompleteVerification: Object as PropType<AutoCompleteVerificationResponse>,
            getLinkToPortal: Function,
        },
        data() {
            return {}
        },
        computed: {
            global(): any {
                return this.$global;
            },
            util(): any {
                return util;
            },
            isInsuranceVerified(): boolean {
                return this.autoCompleteVerification?.status === 'success';
            },
            autoCompleteTooltips(): AutoCompleteTooltipList {
                if (!this.isAutoCompleteEnabled || !this.isInsuranceVerified) 
                    return { companyName: null, policyNumber: null, effectiveDate: null, expirationDate: null, collision: null, comprehensive: null };

                const policyList = this.autoCompleteVerification?.person?.policies;
                const verifiedPolicy = (policyList && policyList.length > 0) ? policyList[0] : null;
                const coverages = verifiedPolicy?.coverages;
                const defaultIconObj = { icon: 'fas fa-info-circle' };
                const defaultTooltipText = 'Value found by AutoComplete:'

                // Converting cents to dollars
                const acCollision = (coverages?.collision?.deductible) ? Number(coverages.collision.deductible / 100).toFixed(2) : null;
                const acComprehensive = (coverages?.comprehensive?.deductible) ? Number(coverages.comprehensive.deductible / 100).toFixed(2) : null;

                return {
                    companyName: (verifiedPolicy?.carrierName)
                        ? { ...defaultIconObj, tooltipText: [`${defaultTooltipText} ${verifiedPolicy.carrierName.toUpperCase()}`] }
                        : null,
                    policyNumber: (verifiedPolicy?.carrierPolicyNumber)
                        ? { ...defaultIconObj, tooltipText: [`${defaultTooltipText} ${verifiedPolicy.carrierPolicyNumber.toUpperCase()}`] }
                        : null,
                    effectiveDate: (verifiedPolicy?.effectiveDate)
                        ? { ...defaultIconObj, tooltipText: [`${defaultTooltipText} ${new Date(verifiedPolicy.effectiveDate).toLocaleDateString()}`] }
                        : null,
                    expirationDate: (verifiedPolicy?.expiryDate)
                        ? { ...defaultIconObj, tooltipText: [`${defaultTooltipText} ${new Date(verifiedPolicy.expiryDate).toLocaleDateString()}`] }
                        : null,
                    collision: (acCollision)
                        ? { ...defaultIconObj, tooltipText: [`${defaultTooltipText} $${acCollision}`] }
                        : null,
                    comprehensive: (acComprehensive)
                        ? { ...defaultIconObj, tooltipText: [`${defaultTooltipText} $${acComprehensive}`] }
                        : null,
                };
            }
        },
        methods: {
            openAutoCompleteModal() {
                $modal.open(modalAutoCompleteResults, {
                    name: 'modalAutoCompleteResults',
                    passedData: {
                        autoCompleteVerification: this.autoCompleteVerification
                    }
                });
            }
        }
    });
</script>