<template>
    <div :class="{ 'pdf-viewer-lazy-load-container': true, 'is-loading': isRendering }">
        <LoaderComponent v-show="isRendering" />

        <PDFViewer 
            :src="props.pdfSrc"
            :overlays="props.pdfOverlays"
            :fimenu="props.fimenu"
            :signer="props.signerType"
            :fields="props.fields"
            :showFields="props.showFields"
            :onClickField="props.onClickField"
            @rendered="onRender"
            @error="onError"
        />

        <span v-if="hasRenderError" class="error-message fade-in-fast">
            <i class="fas fa-exclamation-circle" /> An error occurred while trying to load the PDF.
        </span>
    </div>
</template>
<script setup lang="ts">
    import { ref } from 'vue';

    import { DocumentOverlay } from '@core/classes/Paperwork/DocumentOverlay';
    import FIMenu from '@core/classes/FIMenu';

    import LoaderComponent from '@core/components/LoaderComponent.vue';
    import PDFViewer from '@core/components/PDFViewer.vue';

    interface PDFViewerLazyLoadProps {
        /** Either a base-64 byte string of the PDF or a file URL */
        pdfSrc: string,

        /** A list of overlays to display on top of the PDF */
        pdfOverlays?: DocumentOverlay[];

        /** HTTP headers to append to a GET request. Only used if src is a file URL */
        httpHeaders?: { [headerKey: string]: string };

        /** Used for overlays. FIMenu will be bound to any overlays passed in */
        fimenu?: FIMenu;

        /** ENUMS.PERSON_TYPES. The person type of the signer. Only applicable if including signature overlays. */
        signerType?: number;

        /** A list of PDF fields to append to the displayed PDF */
        fields?: any;

        /** Whether or not to display PDF fields. See PDFPageFieldsOverlay.vue. */
        showFields?: boolean;

        /** Only applicable if displaying PDF fields. An action to perform when a field has been clicked. */
        onClickField?: (data: any) => void;
    }

    const props = defineProps<PDFViewerLazyLoadProps>();

    const isRendering = ref(true);
    const hasRenderError = ref(false);

    /** Encountered when all pages in the PDF have finished rendering */
    const onRender = () => {
        isRendering.value = false;
    };

    /** Encountered when any page in the PDF encounters an error while trying to render */
    const onError = () => {
        hasRenderError.value = true;
    }
</script>
<style>
    .pdf-viewer-lazy-load-container {
        position: relative;
    }

    .pdf-viewer-lazy-load-container.is-loading {
        position: relative;
        border-radius: var(--default-border-radius);
        background-color: var(--border-color);
        animation: loadingAnimation 1.3s infinite;
    }

        .pdf-viewer-lazy-load-container.is-loading .spinner-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2.5rem;
        }

    .pdf-viewer-lazy-load-container .error-message {
        position: absolute;
        top: 0;
        left: 50%;
        width: fit-content;
        padding: 5px 10px;
        border-radius: var(--default-border-radius);
        color: var(--button-color);
        background-color: var(--error-color);
        transform: translateX(-50%);
    }

    @keyframes loadingAnimation {
        0% {
            opacity: 1;
        }
    
        70% {
            opacity: 0.7;
        }
    
        100% {
            opacity: 1;
        }
    }
</style>