<template>
    <Panel class="panel-deal-checklist ignore-deal-lock" title="Deal Checklist">
        <template #HeaderComponent>
            <button class="button" :disabled="isLoading || isPolling" @click="init">
                <span v-if="isPolling">
                    <i class="fas fa-sync fa-spin" /> Refresh
                </span>
                <span v-else>
                    <i class="fas fa-sync" /> Refresh
                </span>
            </button>

            <DropdownButton label="Text Checklist Link" :dropUp="true" :actions="sendLinkActions" :disabled="fimenu.isSpectator || isLoading" />
        </template>

        <IsBusySectionComponent v-show="isLoading" style="height:unset" />

        <section v-show="!isLoading" class="all-checklist-items">
            <div class="checklist-header">
                <div class="checklist-summary">
                    <button v-for="(person, index) in dealParticipants"
                            :key="'progress-circle-' + index"
                            :class="{'button-span': true, 'ignore-all-locks': true, 'active-person': activePerson === person.role}"
                            @click="toggleActivePerson(person.role)">
                        <ProgressCircle :class="{'all-tasks-circle': (person.role === ENUMS.PERSON_TYPES.UNKNOWN)}"
                                        :label="person.name"
                                        :subtitle="person.roleDisplay"
                                        :isLoading="isLoading"
                                        :numComplete="person.tasksCompleted"
                                        :total="person.allTasks.length" />
                    </button>
                </div>

                <button style="width:unset;" :disabled="fimenu.isSpectator" @click="openAddEditChecklistModal(null, true)">
                    <i class="fas fa-plus" />
                </button>
            </div>

            <RichTable :tableData="activeChecklist" :headers="tableHeaders" :isLoading="isLoading" noData="No pending checklist items.">
                <template #checkmark="row">
                    <i v-if="isItemVerified(row)" class="fas fa-check" />
                    <i v-else-if="isPlaidPending(row)" class="fas fa-question" />
                    <i v-else class="fas fa-times" />
                    <span>{{ getStatusText(row) }}</span>
                </template>

                <template #sendTo="row">
                    <span v-if="row.sendTo.length < 1">{{getParticipantByType(ENUMS.PERSON_TYPES.FIMANAGER)}}</span>
                    <template v-else>
                        <span v-for="(personType, index) in row.sendTo" :key="index">
                            {{getParticipantByType(personType)}}
                        </span>
                    </template>
                </template>

                <template #itemActions="row">
                    <button v-if="row.needsManualVerify" class="button-accept" :disabled="!row.status.document || fimenu.isSpectator" @click="openChecklistReviewModal(row)">
                        Review
                    </button>

                    <button v-else-if="canResetPlaidIDV(row)" class="button-load" :disabled="fimenu.isSpectator" @click="resetPlaidIDV(row)">
                        Reset Session
                    </button>

                    <span v-else />
                </template>

                <template #editActions="row">
                    <div v-if="row.isCustomItem" style="white-space: nowrap;">
                        <button class="button-edit" :disabled="fimenu.isSpectator" @click="openAddEditChecklistModal(row, false)">
                            <i class="fas fa-pencil-alt" />
                        </button>
                        <button class="button-delete" :disabled="fimenu.isSpectator" @click="onDeleteCustomItem(row)">
                            <i class="fas fa-trash-alt" />
                        </button>
                    </div>

                    <span v-else />
                </template>
            </RichTable>
        </section>
    </Panel>
</template>

<script>
    import settings from 'settings';
    import auth from '@core/services/auth';
    import util from "@core/services/util";
    import api from "@core/services/api";
    import $modal from "@core/services/modal";
    import ENUMS from "@core/classes/Enums";
    import FIMenu from "@core/classes/FIMenu";
    import DealChecklistItem from "@core/classes/DealChecklistItem";
    import Panel from "@core/components/Panel.vue";
    import RichTable from "@core/components/RichTable.vue";
    import ProgressCircle from "@/components/ProgressCircle.vue";
    import IsBusySectionComponent from "@core/components/IsBusySectionComponent.vue";
    import DropdownButton from "@/components/DropdownButton.vue";
    import modalInfo from "@core/modals/modalInfo.vue";
    import modalDealChecklistReview from "@/modals/modalDealChecklistReview.vue";
    import modalAddEditChecklistItemLocal from "@/modals/modalAddEditChecklistItemLocal.vue";

    export default {
        name: "PanelDealChecklist",
        props: {
            fimenu: {
                type: FIMenu,
                required: true
            }
        },
        data() {
            return {
                isLoading: false,
                isPolling: false,
                pollingTimeout: null,
                pollingCounter: 0,
                activePerson: ENUMS.PERSON_TYPES.UNKNOWN,
                checklist: [],
                tableHeaders: [
                    {
                        name: 'status',
                        display: 'Status',
                        sortable: true,
                        value: (row) => this.isItemVerified(row),
                        sortValue: (row) => this.isItemVerified(row),
                        slot: 'checkmark',
                        cellCssClass: (row) => {
                            return this.isItemVerified(row)
                                ? 'verified'
                                : this.isPlaidPending(row) ? 'pending' : 'not-verified';
                        }
                    },
                    {
                        name: 'sendTo',
                        display: 'Who\'s Responsible?',
                        slot: 'sendTo',
                        sortable: true,
                        cellCssClass: 'sendTo-cell',
                        value: (row) => {
                            if (row.sendTo.length < 1) return ['NO ONE SELECTED'];
                            return row.sendTo.map(p => {
                                const key = util.getObjectKeyFromValue(ENUMS.PERSON_TYPES, p);
                                if (p === ENUMS.PERSON_TYPES.CUSTOMER) return this.fimenu.customer.fullName ?? key;
                                if (p === ENUMS.PERSON_TYPES.COCUSTOMER) return this.fimenu.coCustomer.fullName ?? key;
                                return key;
                            });
                        }
                    },
                    { name: 'name', display: 'Name', sortable: true },
                    { name: 'type', display: 'Type', sortable: true, value: (row) => this.formatEnumKey(ENUMS.DealChecklistType, row.type) },
                    { name: 'action', display: '', slot: 'itemActions' },
                    { name: 'editActions', display: '', cellCssClass: 'checklist-edit-actions', slot: 'editActions' }
                ],
                employeePhone: '',
                documentTypes: settings.lookups.uploadedDocumentTypes,
                settingsChecklist: [],
            };
        },
        computed: {
            user() {
                return auth.getTokenPayload();
            },
            ENUMS() {
                return ENUMS;
            },
            dealParticipants() {
                const customerTasks = this.checklist.filter(c => c.isForCustomer());

                const dealParticipants = [
                    {
                        name: 'All Tasks',
                        role: ENUMS.PERSON_TYPES.UNKNOWN,
                        roleDisplay: 'All Tasks',
                        cellNumber: null,
                        allTasks: this.checklist,
                        tasksCompleted: this.checklist.filter(c => c.isItemVerified()).length
                    },
                    {
                        name: this.fimenu.customer.fullName?.toLowerCase() ?? 'Customer',
                        role: ENUMS.PERSON_TYPES.CUSTOMER,
                        roleDisplay: 'Customer',
                        cellNumber: this.fimenu.customer.cell,
                        allTasks: customerTasks,
                        tasksCompleted: customerTasks.filter(c => c.isTaskComplete(ENUMS.PERSON_TYPES.CUSTOMER)).length
                    }
                ];

                if (this.fimenu.coCustomer && this.fimenu.coCustomer.cell) {
                    const coCustomerTasks = this.checklist.filter(c => c.isForCoCustomer());
                    dealParticipants.push({
                        name: this.fimenu.coCustomer.fullName?.toLowerCase() ?? 'Co-Customer',
                        role: ENUMS.PERSON_TYPES.COCUSTOMER,
                        roleDisplay: 'Co-Customer',
                        cellNumber: this.fimenu.coCustomer.cell,
                        allTasks: coCustomerTasks,
                        tasksCompleted: coCustomerTasks.filter(c => c.isTaskComplete(ENUMS.PERSON_TYPES.COCUSTOMER)).length
                    });
                }

                if (this.employeePhone) {
                    // Only returns tasks that FI Manager can actively act on.
                    const fiManagerTasks = this.checklist.filter(c => {
                        const mostRecentVerification = c.getNewestManualVerification();
                        const hasManualRejection = mostRecentVerification && !mostRecentVerification.isVerified;

                        return c.needsManualVerify
                            && !hasManualRejection
                            && (c.status.document || c.status.plaidData);
                    });

                    let employeeName = this.user.EmployeeName?.toLowerCase() ?? 'FI Manager';
                    if (employeeName.indexOf(', ') !== -1) {
                        const splitName = employeeName.split(', ');
                        employeeName = `${splitName[1]} ${splitName[0]}`;
                    }

                    dealParticipants.push({
                        name: employeeName,
                        role: ENUMS.PERSON_TYPES.FIMANAGER,
                        roleDisplay: 'FI Manager',
                        cellNumber: this.employeePhone,
                        allTasks: fiManagerTasks,
                        tasksCompleted: fiManagerTasks.filter(c => c.isTaskComplete(ENUMS.PERSON_TYPES.FIMANAGER)).length
                    });
                }

                return dealParticipants;
            },
            sendLinkActions() {
                const filteredParticipants = this.dealParticipants.filter(p => p.role !== ENUMS.PERSON_TYPES.FIMANAGER && p.role !== ENUMS.PERSON_TYPES.UNKNOWN);
                if (!filteredParticipants || filteredParticipants.length < 1) return [];

                return filteredParticipants.map(p => {
                    return {
                        label: p.name,
                        role: p.role,
                        click: () => this.sendChecklistLink(p.role)
                    };
                });
            },
            activeChecklist() {
                const participant = this.dealParticipants.find(p => p.role === this.activePerson);
                if (!participant) return this.checklist;

                return participant.allTasks;
            },
            canStopPolling() {
                if (this.dealParticipants.length < 1) return true;

                const allTasksList = this.dealParticipants.find(p => p.role === ENUMS.PERSON_TYPES.UNKNOWN);
                return allTasksList.tasksCompleted === allTasksList.allTasks.length;
            }
        },
        watch: {
            canStopPolling() {
                this.pollForChecklistUpdates();
            }
        },
        created() {
            this.init();
        },
        beforeUnmount() {
            clearTimeout(this.pollingTimeout);
        },
        methods: {
            async init() {
                clearTimeout(this.pollingTimeout);
                this.isPolling = false;
                this.isLoading = true;

                const apiCallsToMake = [];
                apiCallsToMake.push(Promise.resolve(this.getSettingsCheckList()));
                apiCallsToMake.push(Promise.resolve(this.getEmployeePhone()));
                apiCallsToMake.push(Promise.resolve(this.getFiMenuCheckList()));
                apiCallsToMake.push(Promise.resolve(this.pollForChecklistUpdates()));

                this.pollingCounter = 0;
                await Promise.all(apiCallsToMake);
                this.isLoading = false;
            },
            async getSettingsCheckList() {
                try {
                    const { data: checkList } = await api.checklist.getSettingsChecklist();
                    this.settingsChecklist = checkList;
                } catch (error) {
                     
                    console.error("PANEL DEAL CHECKLIST API ERROR:\n", error);
                    util.toastr("error", "Retrieving Settings Checklist", error);
                }
            },
            async getEmployeePhone() {
                try {
                    const { data: phoneNumber } = await api.settings.getUserByCode(this.user.EmployeeCode);
                    this.employeePhone = phoneNumber;
                } catch (error) {
                     
                    console.error("PANEL DEAL CHECKLIST API ERROR:\n", error);
                    util.toastr("error", "Retrieving Employee Phone Number", error);
                }
            },
            async getFiMenuCheckList() {
                try {
                    const tempCheckList = [];
                    const { data: checkList } = await api.checklist.getChecklistForFiMenu(this.fimenu.id);
                    checkList.forEach(i => tempCheckList.push(new DealChecklistItem(i)));

                    this.checklist = tempCheckList;
                } catch (error) {
                     
                    console.error("PANEL DEAL CHECKLIST API ERROR:\n", error);
                    util.toastr("error", "Retrieving FiMenu Checklist", error);
                }
            },
            pollForChecklistUpdates() {
                clearTimeout(this.pollingTimeout);
                if (this.canStopPolling) return;

                // As the counter goes up, increase polling delay exponentially
                const pollingDelay = 100 * Math.pow(2, this.pollingCounter);
                this.pollingTimeout = setTimeout(this.initPolled, pollingDelay);
            },
            async initPolled() {
                try {
                    this.isPolling = true;
                    const { data: checkList } = await api.checklist.getChecklistForFiMenu(this.fimenu.id);

                    const updatedChecklist = checkList.map(c => new DealChecklistItem(c));
                    const anyChanges = this.checklist.some(oldC => {
                        const newItem = updatedChecklist.find(newC => newC.id === oldC.id);

                        if (!newItem) return true; // A new item was added
                        return oldC.anyStatusChanges(newItem);
                    });

                    if (anyChanges) {
                        this.pollingCounter = 0;
                        this.checklist = updatedChecklist;
                    }
                    else {
                        this.pollingCounter++;
                    }

                    this.pollForChecklistUpdates();
                } catch (error) {
                    // No response from server. Stop polling.
                    util.toastr("error", "Polling...", error);
                } finally {
                    this.isPolling = false;
                }
            },
            toggleActivePerson(personType) {
                this.activePerson = (this.activePerson === personType) ? ENUMS.PERSON_TYPES.UNKNOWN : personType;
            },
            openChecklistReviewModal(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);

                $modal.open(modalDealChecklistReview, {
                    name: 'modalDealChecklistReview',
                    passedData: {
                        checklistItem: checklistItem
                    },
                    backdrop: false,
                    postFunction: (isVerified) => this.onVerify(checklistItem, isVerified)
                });
            },
            openAddEditChecklistModal(checklistItem, isAddAction) {
                // Remove any settings checklist items that are already required on this deal
                const availableSettingsChecklist = this.settingsChecklist.filter(s => {
                    const alreadyRequired = this.checklist.some(c => c.id === s.id);
                    return !alreadyRequired;
                });

                let usedPreBuilt = true;
                if (!isAddAction && checklistItem) {
                    usedPreBuilt = this.settingsChecklist.some(ac => ac.id === checklistItem.id);
                }

                const customSubtypes = [];
                this.checklist.forEach(c => {
                    if (c.subtype && c.subtype.indexOf('CUSTOM') !== -1)
                        customSubtypes.push(c.subtype);
                });

                $modal.open(modalAddEditChecklistItemLocal, {
                    name: 'modalAddEditChecklistItemLocal',
                    passedData: {
                        item: checklistItem,
                        usedPreBuilt: usedPreBuilt,
                        isAddAction: isAddAction,
                        documentTypes: this.documentTypes,
                        preBuiltItems: availableSettingsChecklist,
                        customSubtypes: customSubtypes,
                        dealParticipants: this.dealParticipants,
                    },
                    backdrop: false,
                    postFunction: this.onAddEditCustomItem,
                });
            },
            async sendChecklistLink(forWhom) {
                const person = this.dealParticipants.find(p => p.role === forWhom);

                if (!person.cellNumber) {
                    util.toastr('error', 'Error', 'No cell number provided. Cannot send text message.');
                    return;
                }

                this.isLoading = true;

                const response = await api.checklist.textChecklistLink(settings.appDomain, this.fimenu.storeCode, this.fimenu.id, forWhom, person.cellNumber);
                if (response && response.data)
                    util.toastr('success', 'Success', 'Text message successfully sent');
                else
                    util.toastr('error', 'Error', 'Error sending text message.');

                this.isLoading = false;
            },
            formatEnumKey(enumObj, value) {
                //Expects either camel-case or all-caps delimited by underscore
                const key = util.getObjectKeyFromValue(enumObj, value);
                const spacesInserted = key.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2");
                return spacesInserted.replace('_', ' - ');
            },
            getChecklistItem(id) {
                return this.checklist.find(c => c.id === id);
            },
            getStatusText(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);
                if (!checklistItem) return '';

                if (this.activePerson === ENUMS.PERSON_TYPES.FIMANAGER || this.activePerson === ENUMS.PERSON_TYPES.UNKNOWN || !checklistItem.isTypeUpload())
                    return ' ' + checklistItem.getStatusText();

                // If Customer Checklist is being shown
                let statusText = '';
                if (!checklistItem.getDocument())
                    statusText = 'NO DOCUMENT FOUND';
                else if (!checklistItem.status.autoVerified)
                    statusText = 'INVALID DOCUMENT';
                else if (checklistItem.hasAnyManualVerifications() && !checklistItem.isDocManuallyVerified())
                    statusText = 'MANUALLY REJECTED';
                else
                    statusText = 'SUBMITTED';

                return ' ' + statusText;
            },
            getParticipantByType(type) {
                const participant = this.dealParticipants.find(p => p.role === type);
                return participant?.name ?? util.getObjectKeyFromValue(ENUMS.PERSON_TYPES, type);
            },
            canResetPlaidIDV(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);
                return checklistItem?.canResetPlaidIDV() ?? false;
            },
            isItemVerified(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);
                if (!checklistItem) return false;

                if (this.activePerson === ENUMS.PERSON_TYPES.FIMANAGER || this.activePerson === ENUMS.PERSON_TYPES.UNKNOWN)
                    return checklistItem.isItemVerified();

                // If a Customer Checklist is being shown
                return (!checklistItem.isTypeUpload() || !checklistItem.hasAnyManualVerifications())
                    ? checklistItem.status.autoVerified
                    : checklistItem.status.autoVerified && checklistItem.isDocManuallyVerified();
            },
            isPlaidPending(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);
                return checklistItem?.isPlaidPending() ?? false;
            },
            async resetPlaidIDV(rowItem) {
                const checklistItem = this.getChecklistItem(rowItem.id);

                if (!checklistItem.canResetPlaidIDV()) {
                    util.toastr('error', 'Invalid Action', 'Cannot reset this checklist item.');
                    return;
                }

                this.isLoading = true;

                const idvResponse = checklistItem.getPlaidData().identityVerification.response;
                const idvAssociations = checklistItem.getPlaidData().associations;

                const response = await api.plaid.retryIdentityVerification(idvResponse.template.id, idvResponse.clientUserId, idvResponse.user, this.fimenu.storeCode, idvAssociations);
                if (!response || !response.data || response.data.error)
                    util.toastr('error', 'Error', 'Unable to reset Plaid session.');
                else
                    util.toastr('success', 'Success', 'Successfully reset Plaid session.');

                this.isLoading = false;
            },
            async onVerify(checklistItem, isVerified) {
                this.isLoading = true;

                //Append a DocumentVerification to related document (if upload type)
                if (checklistItem.isTypeUpload()) {
                    const verification = {
                        isVerified: isVerified,
                        verifiedBy: { EmployeeName: this.user.EmployeeName, EmployeeNumber: this.user.EmployeeCode },
                        verifierRole: ENUMS.PERSON_TYPES.FIMANAGER,
                        timestamp: new Date()
                    }

                    const verificationResponse = await api.documents.addDocumentVerification(checklistItem.status.document.id, verification);
                    if (!verificationResponse || !verificationResponse.data)
                        util.toastr('error', 'Server Error', 'Unable to save verification.');
                    else
                        util.toastr('success', 'Success', 'Verification successfully saved.');
                }

                //Plaid types shouldn't have manual verifications, so nothing will happen if somehow this is a Plaid MV

                this.init();
            },
            async onAddEditCustomItem(addEditResult) {
                this.isLoading = true;

                const checklistItem = addEditResult.item;
                const isAddAction = addEditResult.isAddAction;
                const usedPreBuilt = addEditResult.preBuiltUsed;

                let subtypeCode = checklistItem.subtype.toUpperCase().replaceAll(' ', '_');
                if (!usedPreBuilt && !checklistItem.subtype.startsWith('CUSTOM_')) subtypeCode = `CUSTOM_${subtypeCode}`;

                const request = {
                    dealId: this.fimenu.id,
                    id: checklistItem.id,
                    name: checklistItem.name,
                    description: checklistItem.description,
                    sendTo: checklistItem.sendTo,
                    type: checklistItem.type,
                    subtype: subtypeCode,
                    associations: checklistItem.associations,
                }

                if (isAddAction) request.searchForExisting = usedPreBuilt;
                const response = (isAddAction)
                    ? await api.checklist.addCustomChecklistItemToDeal(request)
                    : await api.checklist.editCustomChecklistItemFromDeal(request);

                if (!response?.data) {
                    util.toastr('error', 'Error', `Unable to ${isAddAction ? 'add' : 'update'} custom item.`);
                }
                else {
                    util.toastr('success', 'Success', `Successfully ${isAddAction ? 'added' : 'updated'} custom item.`);
                    await this.init();
                }

                this.isLoading = false;
            },
            async onDeleteCustomItem(checklistItem) {
                $modal.open(modalInfo, {
                    name: 'modalInfo',
                    passedData: {
                        title: 'Delete Checklist Item',
                        info: `Are you sure you want to delete custom checklist item "${checklistItem.name}"?`,
                        acceptText: 'Delete',
                        acceptTextClass: 'button-unwind',
                        cancelText: 'Cancel',
                        cancelTextClass: 'button'
                    },
                    backdrop: false,
                    postFunction: async () => {
                        this.isLoading = true;
                        const response = await api.checklist.deleteCustomChecklistItemFromDeal(this.fimenu.id, checklistItem.id);
                        if (response?.data)
                            util.toastr('success', 'Success', 'Successfully removed custom checklist item.');
                        else
                            util.toastr('error', 'Error', 'Unable to remove custom checklist item.');

                        await this.init();
                        this.isLoading = false;
                    }
                });
            }
        },
        components: {
            Panel,
            ProgressCircle,
            RichTable,
            DropdownButton,
            IsBusySectionComponent,
        },
    };
</script>
<style>
    .panel-deal-checklist .panel-header .header-component {
        display: flex;
        align-items: center;
        gap: 10px;
    }

        .panel-deal-checklist .panel-header .header-component > button {
            flex: 1;
        }

        .panel-deal-checklist .panel-header .header-component .button-dropdown-container button {
            text-transform: capitalize;
        }

    .panel-deal-checklist .checklist-summary .active-person {
        position: relative;
        border-radius: 10px 10px 0 0;
        border-bottom: 2px solid var(--main-color);
        background-color: var(--card-background-color);
        box-shadow: -2px -3px 4px 1px rgba(0, 0, 0, 0.25);
        transition: all 0.3s ease-in-out;
        z-index: -1;
    }

        .panel-deal-checklist .checklist-summary .progress-circle-container:hover,
        .panel-deal-checklist .checklist-summary .active-person .progress-circle-container {
            color: var(--main-color);
        }

    .panel-deal-checklist .checklist-summary .progress-circle-container.all-tasks-circle label small {
        visibility: hidden;
    }

    .panel-deal-checklist .verified {
        color: var(--success-color);
    }

    .panel-deal-checklist .not-verified {
        color: var(--error-color);
    }

    .panel-deal-checklist .pending {
        color: var(--print-color);
    }

    .panel-deal-checklist .all-checklist-items .checklist-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 2px solid var(--main-color);
    }

    .panel-deal-checklist .checklist-edit-actions button {
        margin: 0 5px;
        width: unset;
    }

    .panel-deal-checklist .rich-table .sendTo-cell > div > span {
        display: block;
    }
</style>