<template>
    <Panel title="Lender Additional Options" :isSection="true">
        <div :style="$grid('1')" >
            <!-- <BookValueCRUD v-if="modal.passedData.fimenu.inventoryType != 'New'" :BookValues="BookValues" ref="BookValueCrud"/> -->
            <div :style="$grid(getGridCount(CustomFieldsDropdown.length))">
                <InputRichDropdown
                    v-for="fieldData in CustomFieldsDropdown"
                    :key="fieldData.name"
                    v-model:saturn="fieldData.value"
                    :list="fieldData.kvp"
                    :valueMap="(x: FIFieldKVP) => x.value"
                    :display="(x: FIFieldKVP) => x.key"
                    :label="fieldData.name"
                    nothingSelected="SELECT"
                    :invalid="fieldData.required && fieldData.value == null"
                />

                <InputTextbox
                    v-for="fieldData in CustomFieldsTextBox"
                    :key="fieldData.name"
                    v-model:saturn="fieldData.value"
                    :label="fieldData.name"
                    :invalid="fieldData.required && fieldData.value == null"

                />
<!--
                <div v-for="fieldData in CustomFieldsDisplay"  :key="fieldData.name" >
                    <Panel :title=" fieldData.name?.toUpperCase() ?? ''" :collapsed="true">
                        <DisplayData
                            class="color-iteration"
                            v-for="kvp in fieldData.kvp"
                            :key="fieldData.name + '' + kvp.key"
                            :label="kvp.key"
                            :saturn="kvp.value.toLowerCase() == kvp.key.toLowerCase() ? null : kvp.value"
                        />
                    </Panel>
                </div> -->
            </div>
        </div>
    </Panel>
</template>
<script setup lang="ts">
    import { FIDynamicFields, FIFieldKVP, FIFieldType } from "@core/classes/FIDynamicFields";
    import { computed } from "vue";
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    // import SoftDivider from "./SoftDivider.vue";
    // import DisplayData from "./DisplayData.vue";
    import InputTextbox from "@core/components/InputTextbox.vue";
    import Panel from "@core/components/Panel.vue";

    export interface IFinanceInstitutionsProps {
        dynamicFields: FIDynamicFields;
    }

    const Props = defineProps<IFinanceInstitutionsProps>();

    // Computed
    const CustomFields = computed(() => {
        return Props?.dynamicFields?.fields ?? [];
    });

    const CustomFieldsDropdown = computed(() => {
        return CustomFields.value?.filter(x => x.type === FIFieldType.DROP_DOWN) ?? [];
    });

    const CustomFieldsTextBox = computed(() => {
        return CustomFields.value?.filter(x => x.type === FIFieldType.TEXT_FIELD) ?? [];
    });

    // const CustomFieldsDisplay = computed(() => {
    //     return CustomFields.value?.filter(x => x.type === FIFieldType.DISPLAY) ?? [];
    // });

    // METHODS
    function getGridCount(number: number): string {
        return "1";
        // if (number <= 1) return "1";
        // if (number === 2) return "1-1";
        // if (number === 3) return "1-1-1";
        // return "1-1-1";
    }
</script>
<style scoped>


</style>
