import EnumHelper, { ToListItems } from "@core/helpers/enum-helper";
import { StateCode, StateDescription } from "@core/classes/SharedEnums"


export default class StateHelper extends EnumHelper<StateCode>{
    
    public static toList: ToListItems<StateCode>[] = [
        {
            display: "AL",
            value: StateCode.AL,
            description: StateDescription.AL,
        },
        {
            display: "AK",
            value: StateCode.AK,
            description: StateDescription.AK,
        },
        {
            display: "AZ",
            value: StateCode.AZ,
            description: StateDescription.AZ,
        },
        {
            display: "AR",
            value: StateCode.AR,
            description: StateDescription.AR,
        },
        {
            display: "CA",
            value: StateCode.CA,
            description: StateDescription.CA,
        },
        {
            display: "CO",
            value: StateCode.CO,
            description: StateDescription.CO,
        },
        {
            display: "CT",
            value: StateCode.CT,
            description: StateDescription.CT,
        },
        {
            display: "DE",
            value: StateCode.DE,
            description: StateDescription.DE,
        },
        {
            display: "DC",
            value: StateCode.DC,
            description: StateDescription.DC,
        },
        {
            display: "FL",
            value: StateCode.FL,
            description: StateDescription.FL,
        },
        {
            display: "GA",
            value: StateCode.GA,
            description: StateDescription.GA,
        },
        {
            display: "HI",
            value: StateCode.HI,
            description: StateDescription.HI,
        },
        {
            display: "ID",
            value: StateCode.ID,
            description: StateDescription.ID,
        },
        {
            display: "IL",
            value: StateCode.IL,
            description: StateDescription.IL,
        },
        {
            display: "IN",
            value: StateCode.IN,
            description: StateDescription.IN,
        },
        {
            display: "IA",
            value: StateCode.IA,
            description: StateDescription.IA,
        },
        {
            display: "KS",
            value: StateCode.KS,
            description: StateDescription.KS,
        },
        {
            display: "KY",
            value: StateCode.KY,
            description: StateDescription.KY,
        },
        {
            display: "LA",
            value: StateCode.LA,
            description: StateDescription.LA,
        },
        {
            display: "ME",
            value: StateCode.ME,
            description: StateDescription.ME,
        },
        {
            display: "MD",
            value: StateCode.MD,
            description: StateDescription.MD,
        },
        {
            display: "MA",
            value: StateCode.MA,
            description: StateDescription.MA,
        },
        {
            display: "MI",
            value: StateCode.MI,
            description: StateDescription.MI,
        },
        {
            display: "MN",
            value: StateCode.MN,
            description: StateDescription.MN,
        },
        {
            display: "MS",
            value: StateCode.MS,
            description: StateDescription.MS,
        },
        {
            display: "MO",
            value: StateCode.MO,
            description: StateDescription.MO,
        },
        {
            display: "MT",
            value: StateCode.MT,
            description: StateDescription.MT,
        },
        {
            display: "NE",
            value: StateCode.NE,
            description: StateDescription.NE,
        },
        {
            display: "NV",
            value: StateCode.NV,
            description: StateDescription.NV,
        },
        {
            display: "NH",
            value: StateCode.NH,
            description: StateDescription.NH,
        },
        {
            display: "NJ",
            value: StateCode.NJ,
            description: StateDescription.NJ,
        },
        {
            display: "NM",
            value: StateCode.NM,
            description: StateDescription.NM,
        },
        {
            display: "NY",
            value: StateCode.NY,
            description: StateDescription.NY,
        },
        {
            display: "NC",
            value: StateCode.NC,
            description: StateDescription.NC,
        },
        {
            display: "ND",
            value: StateCode.ND,
            description: StateDescription.ND,
        },
        {
            display: "OH",
            value: StateCode.OH,
            description: StateDescription.OH,
        },
        {
            display: "OK",
            value: StateCode.OK,
            description: StateDescription.OK,
        },
        {
            display: "OR",
            value: StateCode.OR,
            description: StateDescription.OR,
        },
        {
            display: "PA",
            value: StateCode.PA,
            description: StateDescription.PA,
        },
        {
            display: "RI",
            value: StateCode.RI,
            description: StateDescription.RI,
        },
        {
            display: "SC",
            value: StateCode.SC,
            description: StateDescription.SC,
        },
        {
            display: "SD",
            value: StateCode.SD,
            description: StateDescription.SD,
        },
        {
            display: "TN",
            value: StateCode.TN,
            description: StateDescription.TN,
        },
        {
            display: "TX",
            value: StateCode.TX,
            description: StateDescription.TX,
        },
        {
            display: "UT",
            value: StateCode.UT,
            description: StateDescription.UT,
        },
        {
            display: "VT",
            value: StateCode.VT,
            description: StateDescription.VT,
        },
        {
            display: "VA",
            value: StateCode.VA,
            description: StateDescription.VA,
        },
        {
            display: "WA",
            value: StateCode.WA,
            description: StateDescription.WA,
        },
        {
            display: "WV",
            value: StateCode.WV,
            description: StateDescription.WV,
        },
        {
            display: "WI",
            value: StateCode.WI,
            description: StateDescription.WI,
        },
        {
            display: "WY",
            value: StateCode.WY,
            description: StateDescription.WY,
        },
    ]

    constructor(){
        super(StateHelper.toList);
    }

    public getStateDescription(toListItem: ToListItems<StateCode>): string {
        return toListItem.description;
    }
}