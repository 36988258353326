import $modal  from '@core/services/modal';
import api from "@core/services/api";
import util from "@core/services/util";

import { PLATE_TRANSACTIONS_TYPES } from "@core/classes/Enums";
import EnumHelper, { ToListItems } from '@core/helpers/enum-helper';
import { PartnerCodeType, PlateTransactionType, VehicleDeliveryMethods, VehiclePrimaryUsage, VehicleTitledUnder } from '@core/classes/SharedEnums';
import FIMenu from "@core/classes/FIMenu";
import FIMenuPlateTransactions from "@core/classes/FIMenuPlateTransactions";
import modalDDIVehicleInquiryResult from '@/modals/modalDDIVehicleInquiryResult.vue'
import moment from 'moment'
import PartnerCode from '@core/classes/Store/PartnerCode';
import Store from '@core/classes/Store/Store';


/**
 * Common function to handle plate transaction initiation and updating the deal.
 *
 * @param deal
 * @param transactionType
 * @param successMessage
 * @param revertTransfer Wether we want to set the registration back to the old vehicle.
 */
export async function handlePlateTransaction(deal: FIMenu, transactionType: PlateTransactionType, successMessage: string, revertTransfer: boolean = false) : Promise<FIMenuPlateTransactions | null> {
    try {
        const response = await api.plateTransactions.initiate(deal, transactionType, revertTransfer);
        const newTransaction = new FIMenuPlateTransactions(response.data);

        if (response?.data?.id) {
            AddNewTransactionToDeal(deal, newTransaction);
            util.toastr("success", "Paperwork - Transfer/Plate", successMessage);
            await deal.getUploadedDocuments();
            return newTransaction;
        } else {
            util.toastr('error', 'Paperwork - Transfer/Plate', newTransaction?.error?.errorMessage ?? 'Error processing transfer/plate.');
            return null;
        }
    } catch (err) {
        console.error(err);
        return null;
    }
}

/**
 * Issue a temporary tag for the deal.
 *
 * @param deal
 */
export async function IssueTemporaryTag(deal: FIMenu) : Promise<FIMenuPlateTransactions | null> {
    const controlNumber = deal.vehicle.registrationInfo.controlNumber;

    if (util.isNullOrEmpty(controlNumber, true)) {
        util.toastr("error", "Paperwork - Transfer/Plate", "Control Number cannot be empty!", deal.vehicle.registrationInfo.plateTransactions?.length);
        return null;
    }

    return await handlePlateTransaction(deal, PLATE_TRANSACTIONS_TYPES.NewTempPlate, "Temporary tag created successfully.");
}

/**
 * Issue a plate registration transfer for the deal.
 *
 * @param deal
 */
export async function IssuePlateRegistrationTransfer(deal: FIMenu, revertTransfer: boolean = false) : Promise<FIMenuPlateTransactions | null> {
    return await handlePlateTransaction(deal, PLATE_TRANSACTIONS_TYPES.Transfer, "Plate registration transfer created successfully.", revertTransfer);
}


/**
 * Cancel plate transaction. (Can only cancel transfers.)
 */
export async function CancelPlateTransaction(dealId: string, fiMenuDealId: string) {
    return await api.plateTransactions.cancelDeal(dealId, fiMenuDealId)
}

/**
 * Update deal with new plate transaction.
 *
 * @param deal
 * @param newTransaction
 */
function AddNewTransactionToDeal(deal: FIMenu, newTransaction: FIMenuPlateTransactions) : void {
    const dealIndex = deal.vehicle.registrationInfo.plateTransactions?.findIndex((transaction: FIMenuPlateTransactions) => {
        return (
            transaction.id == newTransaction.id
        );
    });

    if (dealIndex != -1 && dealIndex != undefined) {
        deal.vehicle.registrationInfo.plateTransactions[dealIndex] = newTransaction;
    } else {
        deal.vehicle.registrationInfo.plateTransactions.push(newTransaction);
    }
}


export class VehiclePrimaryUsageEnumHelper extends EnumHelper<VehiclePrimaryUsage> {
    public static toList: ToListItems<VehiclePrimaryUsage>[] = [
        { 
            display: 'Personal',
            value: VehiclePrimaryUsage.PERSONAL 
        },
        { 
            display: 'Family',
            value: VehiclePrimaryUsage.FAMILY 
        },
        { 
            display: 'Household',
            value: VehiclePrimaryUsage.HOUSEHOLD 
        },
        { 
            display: 'Business',
            value: VehiclePrimaryUsage.BUSINESS 
        },
        { 
            display: 'Agricultural',
            value: VehiclePrimaryUsage.AGRICULTURAL 
        },
        { 
            display: 'Other',
            value: VehiclePrimaryUsage.OTHER 
        },
        { 
            display: 'Wholesale', 
            value: VehiclePrimaryUsage.WHOLESALE
        },
        { 
            display: 'Export', 
            value: VehiclePrimaryUsage.EXPORT
        },
    ];

    constructor(){
        super(VehiclePrimaryUsageEnumHelper.toList);
    }
}

export class VehicleTitledUnderEnumHelper extends EnumHelper<VehicleTitledUnder> {
    public static toList: ToListItems<VehicleTitledUnder>[] = [
        { 
            display: 'Customer', 
            value: VehicleTitledUnder.Customer
        },
        { 
            display: 'CoCustomer', 
            value: VehicleTitledUnder.CoCustomer
        },
        { 
            display: 'Both (Customer & CoCustomer)', 
            value: VehicleTitledUnder.Both
        },
    ];

    constructor(){
        super(VehicleTitledUnderEnumHelper.toList);
    }
}

export class GetDeliveryMethodsEnumHelper extends EnumHelper<VehicleDeliveryMethods> {
    public static toList: ToListItems<VehicleDeliveryMethods>[] = [
        { 
            display: 'Driven Off Lot', 
            value: VehicleDeliveryMethods.DRIVEN_OFF_LOT
        },
        { 
            display: 'Shipping (In-State)', 
            value: VehicleDeliveryMethods.DELIVERY
        },
        { 
            display: 'Shipping (Out-of-State)', 
            value: VehicleDeliveryMethods.DELIVERY_OUT_OF_STATE
        },
    ];

    constructor(){
        super(GetDeliveryMethodsEnumHelper.toList);
    }
}
export class GetTransactionTypeEnumsHelper extends EnumHelper<PlateTransactionType> {
    public static toList: ToListItems<PlateTransactionType>[] = [
        { 
            display: 'None', 
            value: PlateTransactionType.None 
        },
        { 
            display: 'PlateTransfer', 
            value: PlateTransactionType.Transfer 
        },
        { 
            display: 'NewTempPlate', 
            value: PlateTransactionType.NewTempPlate 
        },
        { 
            display: 'NewMetal', 
            value: PlateTransactionType.NewMetal 
        },
        { 
            display: 'Replacement', 
            value: PlateTransactionType.Replacement 
        },
        { 
            display: 'Salvage', 
            value: PlateTransactionType.Salvage
         }
    ];

    constructor(){
        super(GetTransactionTypeEnumsHelper.toList);
    }
}

export class TransferTypesList extends EnumHelper<PlateTransactionType>{

    public static toList: ToListItems<PlateTransactionType>[] = [
        {
            display: 'Generate a new one',
            value: PlateTransactionType.NewTempPlate,
        },
        {
            display: 'Transfer existing one',
            value: PlateTransactionType.Transfer,
        },
        {
            display: 'Nothing',
            value: PlateTransactionType.None,
        },
    ];

    constructor(){
        super(TransferTypesList.toList);
    }

}

export function getActualDealPlateTransactions(fimenu: FIMenu) {
    let result = null;
    if (fimenu?.vehicle?.registrationInfo?.plateTransactions) {
        result = (fimenu?.vehicle?.registrationInfo?.plateTransactions as FIMenuPlateTransactions[]).reduce((actualDeal, deal) => {
            const dealDate = moment(deal?.dateSubmitted);
            const actualDealDate = moment(actualDeal?.dateSubmitted);

            if (!actualDeal || dealDate.isAfter(actualDealDate) || (actualDeal.id == null && deal.id != null)) {
                return deal;
            } else {
                return actualDeal;
            }
        }, null);
    }
    return result;
}


export async function inquireVehicle(storeCode: string,vin?: string, plate?: string, dealId?: string) {
    try {
        // Ensure we have credentials before attempting any inquiries
        const response = await api.plateTransactions.hasCredentials();
        if (!response.data) return;

        if (vin) {
            // This will also create an association with the deal.
            await api.plateTransactions.tradeinVehicleInquiry(vin, dealId, storeCode);
        } else if (plate) {
            await api.plateTransactions.vehicleInquiryPlate(plate, storeCode);
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
}
/**
 *
 * @param vin
 * @param plate
 * @param dealId
 * @param storeCode
 * @returns
 */
export async function openDDiResponseModal(storeCode :string, vin?: string | null, dealId?: string | null, plateNumber?:string | null, plateState?: string|null) {
    try {

        let response;

        if (vin) {
            response = await api.plateTransactions.getLastVehicleInquiryVin(vin, storeCode);
        } else if(plateNumber) {
            response = await api.plateTransactions.getLastVehicleInquiryPlate(plateNumber);
        }

        if (!response || !response.data) {
            if(!vin) {
                const apiCallResponse = await api.cbc.plateToVIN(null, plateState, plateNumber)
                if (!apiCallResponse?.data?.vin) return util.toastr("error", "Error!", "Couldn't pull an inquiry on this vehicle.");
                vin = apiCallResponse?.data?.vin;
            }

            await inquireVehicle(storeCode, vin, plateNumber, dealId);

            if (vin) {
                response = await api.plateTransactions.getLastVehicleInquiryVin(vin, storeCode);
            } else if(plateNumber) {
                response = await api.plateTransactions.getLastVehicleInquiryPlate(plateNumber);
            }

            if (!response || !response.data) {
                return util.toastr("error", "Error!", "Couldn't pull an inquiry on this vehicle.");
            }
        }

        $modal.open(modalDDIVehicleInquiryResult, {
            name: 'ModalDDI',
            passedData: {
                DDIResponse: response.data,
                inquireVehicle: () => inquireVehicle(storeCode, vin, null, dealId),
                vin: vin,
                plate: null,
                storeCode
            },
            backdrop: false
        });
    } catch (err) {
        console.error(err);
        util.toastr("error", "Error!", "Unexpected error while performing vehicle inquiry.");
    }
}


export function storeHasDDIEnabled(store: Store) :boolean{
    return store.storeSettings.isGenerateTransferPlateEnabled && storeHasDDICredentials(store)
}

export function IsPlateTransactionsEnabled(deal: FIMenu) :boolean{
    return deal.plateTransactionsEnabled && hasDDICredentials(deal)
}


export function storeHasDDICredentials(store: Store): boolean {
    const hasPartnerId = !!store?.partnerCodes?.some((x : PartnerCode) => x.type == PartnerCodeType.DDIPartnerId);
    const hasDealerId = !!store?.partnerCodes?.some((x : PartnerCode) => x.type == PartnerCodeType.DDIDealerId);

    return hasPartnerId && hasDealerId
}


function hasDDICredentials(deal: FIMenu): boolean {
    const hasPartnerId = !!deal.store?.partnerCodes?.some((x : PartnerCode) => x.type == PartnerCodeType.DDIPartnerId);
    const hasDealerId = !!deal.store?.partnerCodes?.some((x : PartnerCode) => x.type == PartnerCodeType.DDIDealerId);

    return hasPartnerId && hasDealerId
}

