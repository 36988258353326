<template>
    <div class="cobrowsing-toolbar">
        <IsBusyScreenComponent v-if="isReconnecting" text="Reconnecting to deal..." />

        <div class="tool-main" tabindex="0">
            <!--Main Button-->
            <button v-if="viewingRole" class="button" @click="toggleMenu()">
                <FIMenuViewerIcon :role="viewingRole" :isFollowingEditor="followEditor" />
                <span v-if="unseenMessages" class="chat-notification scale-in-fast">
                    <span>{{ unseenMessageCount }}</span>
                    <i class="fas fa-circle" />
                </span>
            </button>

            <!--Chat Notification-->
            <button v-if="newestMessage" :class="['button-span chat-push-notification wipe-in', newestMessage.class]" @click="openNewestMessage()" ref="chatBubble">
                <span>
                    <b :class="{'cobrowsing-censor': newestMessage.senderName !== 'SERVER'}">
                        {{ newestMessage.senderName }}:
                    </b>
                    {{ newestMessage.text }}
                </span>
            </button>

            <!--Floating Menu-->
            <div v-if="showMenu" class="floating-toolbar scale-in" tabindex="0">
                <div class="tool-container">
                    <!--Role-->
                    <button v-if="viewingRole" @click="changeMenuTab(MENU_TAB.VIEWING_ROLE)" :class="{'active': isTabViewingRole}" :disabled="editorList.length < 1">
                        <FIMenuViewerIcon :role="viewingRole" :isFollowingEditor="followEditor" />
                    </button>

                    <!--Participants-->
                    <button @click="changeMenuTab(MENU_TAB.VIEWER_LIST)" :class="{'active': isTabViewerList}">
                        {{ viewerList.length }}
                    </button>

                    <!--Messages-->
                    <button @click="changeMenuTab(MENU_TAB.MESSAGES)" :class="{'active': isTabMessages}">
                        <i class="fas fa-comment-dots" />
                        <span v-if="unseenMessages" class="chat-notification scale-in-fast">
                            <i class="fas fa-circle" />
                        </span>
                    </button>
                </div>

                <div class="tool-content">
                    <!--Role-->
                    <div v-if="isTabViewingRole" class="role-content" tabindex="0">
                        <p><b>{{ currentRoleMessage }}</b></p>

                        <small v-if="connectedWithCustomerViewerName" style="color: var(--error-color)">
                            {{ connectedWithCustomerViewerName }} is currently connected with a customer.
                        </small>

                        <hr />

                        <!--Hide Everyone Else's Mice (Editor)-->
                        <div v-if="isEditor" class="action-button" @click="toggleHideOtherMice">
                            <button>
                                <i class="fas fa-mouse-pointer" :style="(hideOtherMice) ? 'opacity: 1;' : 'opacity: 0.5;'" />
                            </button>

                            <span>
                                {{ (hideOtherMice) ? 'Show' : 'Hide' }} Viewer Mice
                            </span>
                        </div>

                        <!--Ask Spectators to Follow-->
                        <div v-if="isEditor" class="action-button" @click="askSpectatorsToFollow">
                            <button :disabled="allSpectatorsAreFollowing">
                                <i class="fas fa-exchange-alt" />
                            </button>

                            <span>
                                {{ (allSpectatorsAreFollowing) ? 'All Viewers Already Following' : 'Ask Viewers to Follow' }}
                            </span>
                        </div>

                        <!--Cede Editing Power-->
                        <div v-if="isEditor" class="action-button" @click="cedeEditingPower">
                            <ButtonLoading :isLoading="cedeEditingRequestSent" :iconOnly="true">
                                <i class="fas fa-undo" />
                            </ButtonLoading>

                            <span>
                                {{ (cedeEditingRequestSent) ? 'Waiting...' : 'Cede Editing Power' }}
                            </span>
                        </div>


                        <!--Edit Request-->
                        <div v-if="!isEditor" class="action-button" @click="requestEditAccess">
                            <ButtonLoading :isLoading="editRequestSent" :iconOnly="true">
                                <i class="fas fa-pencil-alt" />
                            </ButtonLoading>

                            <span>
                                {{ (editRequestSent) ? 'Waiting...' : 'Request Edit Access' }}
                            </span>
                        </div>

                        <!--Follow Editor-->
                        <div v-if="!isEditor" class="action-button" @click="toggleFollowEditor">
                            <ButtonLoading :isLoading="followEditorSent" :iconOnly="true">
                                <i :class="['fas', (followEditor) ? 'fa-lock-open' : 'fa-lock']" />
                            </ButtonLoading>

                            <span v-if="followEditorSent">Waiting...</span>
                            <span v-else>
                                {{ (followEditor) ? 'Stop Following Editor' : 'Follow Editor' }}
                            </span>
                        </div>

                        <!--Become Visible to Room-->
                        <div v-if="isSpectator && !visibleToRoom" class="action-button" @click="toggleBecomeVisible">
                            <ButtonLoading :isLoading="visibleRequestSent" :iconOnly="true">
                                <i class="fas fa-eye" />
                            </ButtonLoading>

                            <span>
                                {{ (visibleRequestSent) ? 'Waiting...' : 'Become Visible to Deal' }}
                            </span>
                        </div>

                        <div v-if="followEditor">
                            <hr />

                            <!--Toggle Customer Screen-->
                            <!--
                                <div class="action-button" @click="toggleFollowScreen">
                                    <button>
                                        <i v-if="maximizeCustomerScreen" class="fas fa-compress" />
                                        <i v-else class="fas fa-expand" />
                                    </button>

                                    <span v-if="maximizeCustomerScreen">Minimize Customer Screen</span>
                                    <span v-else>Expand Customer Screen</span>
                                </div>
                            -->
                            <!--Send Mouse Toggle-->
                            <div v-if="visibleToRoom" class="action-button" @click="toggleSendMouse">
                                <button>
                                    <i class="fas fa-mouse-pointer" :style="(sendMouse) ? 'opacity: 0.5;' : 'opacity: 1;'" />
                                </button>

                                <span>
                                    {{ (sendMouse) ? 'Hide Mouse From Other Viewers' : 'Show Mouse to Other Viewers' }}
                                </span>
                            </div>

                            <!--Hide Other Mice-->
                            <div class="action-button" @click="toggleHideOtherMice">
                                <button :disabled="maximizeCustomerScreen">
                                    <i class="fas fa-mouse-pointer" :style="(hideOtherMice) ? 'opacity: 1;' : 'opacity: 0.5;'" />
                                </button>

                                <span>
                                    {{ (hideOtherMice) ? 'Show Viewer Mice' : 'Hide Viewer Mice' }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <!--Participants-->
                    <div v-if="isTabViewerList" class="viewers-content" tabindex="0">
                        <div class="viewer-header">
                            <span v-if="viewerList.length === 1">There is currently {{ viewerList.length }} viewer.</span>
                            <span v-else>There are currently {{ viewerList.length }} viewers.</span>
                        </div>

                        <span v-if="editorList.length > 0"><b>Editor</b></span>
                        <div v-for="(viewer, index) in editorList" class="viewer-item" :key="'editList-' + index">
                            <FIMenuViewerIcon :role="viewer.role" :isReadOnly="false" :isFollowingEditor="viewer.followEditor" />

                            <span>
                                <span class="cobrowsing-censor">{{ viewer.name }}</span>
                                <i v-if="viewer.isAdmin" class="fas fa-shield-alt" />
                            </span>
                        </div>

                        <span v-if="spectatorList.length > 0"><b>Viewers</b></span>
                        <div v-for="(viewer, index) in spectatorList" class="viewer-item" :key="'spectateList-' + index">
                            <FIMenuViewerIcon :role="viewer.role" :isReadOnly="false" :isFollowingEditor="viewer.followEditor" />

                            <span>
                                <span class="cobrowsing-censor">{{ viewer.name }}</span>
                                <i v-if="viewer.isAdmin" class="fas fa-shield-alt" />
                            </span>
                        </div>
                    </div>

                    <!--Messages-->
                    <div v-if="isTabMessages" class="messages-content" ref="messages" tabindex="0">
                        <div class="message-group" ref="messageGroup">
                            <div v-for="(message, index) in chatMessages" :class="'message-item ' + message.class" :key="index">
                                <FIMenuViewerIcon v-if="message.senderName !== 'SERVER'"
                                                  :role="getViewerById(message.senderId).role"
                                                  :isReadOnly="false"
                                                  :isFollowingEditor="getViewerById(message.senderId).followEditor" />

                                <div>
                                    <small>
                                        <b class="cobrowsing-censor">{{ message.senderName }}</b>
                                        <i v-if="message.senderName !== 'SERVER' && getViewerById(message.senderId).isAdmin" class="fas fa-shield-alt" />
                                    </small>
                                    <br />
                                    <small>{{ message.getTimestampFormatted() }}</small>

                                    <p v-for="(text, jndex) in message.text" :key="jndex" :class="{'cobrowsing-censor': message.senderName === 'SERVER'}">
                                        {{ text }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="message-chatbox">
                            <input type="text" v-model="chatMessage" placeholder="Send message..." @keyup.enter="sendChatMessage" />
                            <button @click="sendChatMessage">Send</button>
                        </div>

                        <button v-if="showScrollButton" class="message-scroll scale-in" @click="jumpToBottomOfChat">
                            <i class="fa fa-chevron-down" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--Mouse Ghost-->
        <div v-if="(isEditor || followEditor) && currentMouseList.length > 0" class="mouse-container" ref="mouseContainer" :style="mouseStyleProperties">
            <div v-for="(mouse) in currentMouseList"
                 :key="`mouse_${mouse.id}`"
                 :class="`mouse-${mouse.role}`"
                 ref="allMice"
                 :value="mouse.id"
                 :style="getMouseGhostPosition(mouse.xPercent, mouse.yPercent)">

                <i class="fas fa-mouse-pointer" />
                <span>{{ mouse.name }}</span>
            </div>
        </div>
    </div>
</template>
<script lang="ts" >
    export interface ICoBrowsingToolbarProps {
        /** Deal's store code, required to generate dealRoomID */
        storeCode: string;

        /** Deal's number code, required to generate dealRoomID */
        dealNumber: number | string;

        /** Deal's unique ID, required to generate dealRoomID */
        dealId: string;

        /** Used to indicate if a customer screen is available to toggle between */
        useSocketVersion?: boolean;

        /** Since role determines deal access, managed by FIMenu parent */
        viewingRole?: string;

        /** HTMLElement that should be watched for mouse events / DOM updates */
        elementToWatch: HTMLElement;

        /** HTMLElement where Editor DOM gets mirrored to in follow mode */
        elementFacade: HTMLElement;

        /** How much the editor DOM got scaled by; used to scale mouse ghosts */
        facadeScale: number;

        /** The current Saturn environment. When in Development, prevents excessive page reloads when connection to server is lost. */
        environmentName: string;

        /** When true, will delay processing certain actions until this prop is set to true again. */
        isBusyIniting: boolean;
    }
</script>
<script setup lang="ts">
    import { computed, inject, nextTick, onBeforeMount, onBeforeUnmount, ref, useTemplateRef, watch, WatchHandle } from 'vue';
    import LogRocket from 'logrocket';


    import { DealRoomHubPlugin } from '@core/plugins/dealRoomHub';

    import util, { EventBusCore } from '@core/services/util';
    import $modal from '@core/services/modal';
    import api from '@core/services/api';
    import auth from '@core/services/auth';

    import {
        CB_ChatMessage,
        ICB_DealRoomStatus,
        ICB_JoinRoomRequest,
        ICB_MouseData,
        ICB_RoomInfo,
        ICB_ViewerInfo
    } from '@core/classes/CoBrowsingModels';
    import ENUMS from '@core/classes/Enums';

    import modalCoBrowsingConnect from '@/modals/modalCoBrowsingConnect.vue';
    import modalCountdown from '@/modals/modalCountdown.vue';
    import modalInfo from '@core/modals/modalInfo.vue';

    import ButtonLoading from '@core/components/ButtonLoading.vue';
    import FIMenuViewerIcon from '@/components/fimenu/FIMenuViewerIcon.vue';
    import IsBusyScreenComponent from '@core/components/IsBusyScreenComponent.vue';

    enum MENU_TAB { VIEWING_ROLE, VIEWER_LIST, MESSAGES }

    // #region Plugins
    const $dealRoomHub = inject<DealRoomHubPlugin>('$dealRoomHub');
    const $meetingHub = inject<any>('$meetingHub');
    const meetingHelper = inject<any>("meetingHelper");
    // #endregion

    const emit = defineEmits<{
        /** Emitted when the "Expand/Minimize Customer Screen" button is clicked on the pop-up menu */
        followCustomerScreen: [maximizeCustomerScreen: boolean]
    }>();

    const props = defineProps<ICoBrowsingToolbarProps>();

    const messageGroupRef = useTemplateRef('messageGroup');
    const allMiceRef = useTemplateRef<HTMLDivElement[]>('allMice');

    // SIGNAL-R STATE VARIABLES
    const isConnected = computed((): boolean => $dealRoomHub.connected);
    const isReconnecting = computed((): boolean => $dealRoomHub.reconnecting);
    const isConnectedWithCustomer = computed((): boolean => $meetingHub.connected && !!$meetingHub.code);
    const unwatchIsConnected = ref<WatchHandle>(null);

    watch(isReconnecting, async (newVal) => {
        // Reconnection has timed out. Need to refresh page.
        if (!newVal && !isConnected.value) {
            openConnectionStatusChangedModal();
            return;
        }

        // Successfully reconnected.
        if (!newVal && isConnected.value) {
            await connectToDealRoom();
            return;
        }

        // Currently trying to reconnect
        addChatMessage(util.getDefaultGuid(), 'SERVER', 'Disconnected. Attempting to reconnect...');
    });

    watch(isConnectedWithCustomer, (newVal) => {
        $dealRoomHub.updateCustomerConnectionStatus(newVal);
    });

    // ROOM STATE VARIABLES
    const dealRoomId = computed((): string => {
        return $dealRoomHub.getDealRoomId(props.storeCode, props.dealNumber.toString(), props.dealId);
    });

    /** ID assigned to us by the DealRoomHub */
    const viewerId = ref<string>(null);

    const isEditor = computed((): boolean => props.viewingRole === 'Editor');

    const isSpectator = computed((): boolean => props.viewingRole === 'Spectator');

    const currentRoleMessage = computed((): string => {
        const roleName = (isSpectator.value) ? 'Viewer' : 'Editor';

        const visibleMsg = (visibleToRoom.value) ? ' - Visible' : ' - Invisible';
        return `${roleName} ${(isAdmin.value && !isEditor.value) ? visibleMsg : ''}`;
    });

    const visibleToRoom = ref(true);
    const isAdmin = ref(false);
    const viewerList = ref<ICB_ViewerInfo[]>([]);
    const followerCount = ref(0);

    const editorList = computed((): ICB_ViewerInfo[] => {
        return viewerList.value.filter(viewer => viewer.role === 'Editor');
    });

    const spectatorList = computed((): ICB_ViewerInfo[] => {
        return viewerList.value.filter(viewer => viewer.role === 'Spectator');
    });

    const allSpectatorsAreFollowing = computed((): boolean => {
        return spectatorList.value.every(viewer => viewer.followEditor);
    });

    const getViewerById = (viewerId: string): ICB_ViewerInfo => {
        const unknownViewer: ICB_ViewerInfo = { id: viewerId, name: 'Anonymous', role: 'Unknown', isAdmin: false, followEditor: false };
        return viewerList.value.find(viewer => viewer.id === viewerId) ?? unknownViewer;
    };

    // EVENT TRACKING VARIABLES
    const cedeEditingRequestSent = ref(false);
    const editRequestSent = ref(false);
    const editRequestModalOpen = ref(false);
    const followRequestModalOpen = ref(false);
    const followEditorSent = ref(false);
    const followEditor = ref(false);
    const visibleRequestSent = ref(false);

    const connectedWithCustomerViewerName = ref('');
    const maximizeCustomerScreen = ref(false);

    // CHAT VARIABLES
    const chatMessage = ref('');
    const newestMessage = ref<{ text: string, senderName: string, class: string }>(null);
    const showScrollButton = ref(false);
    const chatMessages = ref<CB_ChatMessage[]>([]);
    const unseenMessages = ref(false);
    const unseenMessageCount = ref(0);

    // MENU VARIABLES
    const showMenu = ref(false);
    const currentTab = ref<MENU_TAB>(null);
    const isTabViewingRole = computed((): boolean => currentTab.value === MENU_TAB.VIEWING_ROLE);
    const isTabViewerList = computed((): boolean => currentTab.value === MENU_TAB.VIEWER_LIST);
    const isTabMessages = computed((): boolean => currentTab.value === MENU_TAB.MESSAGES);

    // Toggling Event Listeners for Scroll Icon on Chat Messages
    watch(() => (currentTab.value === MENU_TAB.MESSAGES) && showMenu.value, (newVal) => {
        nextTick(() => {
            if (!messageGroupRef.value) return;

            if (newVal)
                messageGroupRef.value.addEventListener('scroll', toggleScrollIcon);
            else
                messageGroupRef.value.removeEventListener('scroll', toggleScrollIcon);
        });
    });

    // OBSERVERS
    const domObserver = ref<MutationObserver>(null);
    const resizeObserver = ref<ResizeObserver>(null);

    // GHOST MICE VARIABLES
    const hideOtherMice = ref(false);
    const mouseList = ref<ICB_MouseData[]>([]);
    const sendMouse = ref(false);
    const lastMouseMoveTime = ref(-1);
    const mouseOriginX = ref(0);
    const mouseOriginY = ref(0);
    const pauseMouseUpdatesFrom = ref<string[]>([]);

    const mouseStyleProperties = computed((): string => {
        if (!isEditor.value) {
            return `
                --origin-x: ${mouseOriginX.value}px;
                --origin-y: ${mouseOriginY.value}px;
                --facade-scale: ${props.facadeScale};
            `;
        }

        return ''; // Use default values
    });

    const currentMouseList = computed((): ICB_MouseData[] => {
        if (maximizeCustomerScreen.value) return [];
        else if (!hideOtherMice.value) return mouseList.value;
        return mouseList.value.filter(mouse => mouse.role === 'Editor');
    });


    // ===============
    // LIFECYCLE HOOKS
    // ===============
    //#region Lifecycle Hooks
    onBeforeMount(() => {
        currentTab.value = MENU_TAB.VIEWING_ROLE;
        domObserver.value = new MutationObserver(onDomUpdate);

        document.addEventListener('click', onFocusOutHandler);
        document.addEventListener('freeze', cedeEditingPower); // Only emitted on Chromium-based browsers

        // Editor Mouse Sharing + DOM Watching
        watch(
            () => props.viewingRole,
            (newVal) => {
                if (newVal === 'Editor') {
                    props.elementToWatch?.addEventListener('mousemove', sendCurrentMousePosition);
                    props.elementToWatch?.addEventListener('mousedown', sendClickEvent);
                    props.elementToWatch?.addEventListener('scroll', sendScrollEvent, true);
                    startDomWatcher();
                }
                else {
                    props.elementToWatch?.removeEventListener('mousemove', sendCurrentMousePosition);
                    props.elementToWatch?.removeEventListener('mousedown', sendClickEvent);
                    props.elementToWatch?.removeEventListener('scroll', sendScrollEvent, true);
                    stopDomWatcher();
                }
            },
            { immediate: true }
        );

        // Spectator Mouse Sharing
        watch(
            () => followEditor.value && isSpectator.value,
            (newVal) => {
                if (!newVal) {
                    props.elementFacade?.removeEventListener('mousemove', sendCurrentMousePosition);
                    props.elementFacade?.removeEventListener('mousedown', sendClickEvent);
                    return;
                }
                else {
                    props.elementFacade?.addEventListener('mousemove', sendCurrentMousePosition);
                    props.elementFacade?.addEventListener('mousedown', sendClickEvent);
                }

                nextTick(() => {
                    const watchedBoundingRect = props.elementFacade.getBoundingClientRect();
                    mouseOriginX.value = watchedBoundingRect.x || 75;
                    mouseOriginY.value = watchedBoundingRect.y || 55;
                });
            },
            { immediate: true }
        );

        startResizeObserver();
        peekIntoDealRoom();
    });

    onBeforeUnmount(() => {
        if (unwatchIsConnected.value)
            unwatchIsConnected.value();

        setupDealRoomHub(false);
        document.removeEventListener('click', onFocusOutHandler);
        document.removeEventListener('freeze', cedeEditingPower);
        stopResizeObserver();

        props.elementToWatch?.removeEventListener('mousemove', sendCurrentMousePosition);
        props.elementToWatch?.removeEventListener('mousedown', sendClickEvent);
        props.elementToWatch?.removeEventListener('scroll', sendScrollEvent);

        props.elementFacade?.removeEventListener('mousemove', sendCurrentMousePosition);
        props.elementFacade?.removeEventListener('mousedown', sendClickEvent);
        stopDomWatcher();
    });
    // #endregion


    // ============
    // MENU METHODS
    // ============
    // #region Menu Methods
    const toggleMenu = () => {
        showMenu.value = !showMenu.value;
    };

    /** When clicking outside of the co-browsing menu, automatically close the menu. */
    const onFocusOutHandler = (e: Event) => {
        if (!showMenu.value) return;

        if ((e.target as HTMLElement).closest('.cobrowsing-toolbar') === null)
            nextTick(() => showMenu.value = false);
    };

    const changeMenuTab = (tab: MENU_TAB) => {
        currentTab.value = tab;

        if (currentTab.value === MENU_TAB.MESSAGES) {
            setUnseenMessages(false);
            jumpToBottomOfChat();
        }
    };

    const toggleBecomeVisible = async () => {
        visibleRequestSent.value = true;
        await $dealRoomHub.requestBecomeVisible();
    };

    const toggleFollowEditor = async () => {
        followEditorSent.value = true;
        await $dealRoomHub.toggleFollowEditor();

        setTimeout(() => {
            if (!followEditorSent.value) return;

            followEditorSent.value = false;
            addChatMessage(util.getDefaultGuid(), 'SERVER', 'No response from the server. Try resending a follow request.');
        }, 5000)
    };

    const toggleHideOtherMice = () => {
        hideOtherMice.value = !hideOtherMice.value;
    };

    const toggleSendMouse = async () => {
        sendMouse.value = !sendMouse.value;

        if (!sendMouse.value)
            await $dealRoomHub.hideMouseUpdates();
    };

    const toggleFollowScreen = async () => {
        maximizeCustomerScreen.value = !maximizeCustomerScreen.value;
        emit('followCustomerScreen', maximizeCustomerScreen.value);

        await $dealRoomHub.hideMouseUpdates();
    };
    // #endregion


    // ============
    // CHAT METHODS
    // ============
    // #region Chat Methods
    const openNewestMessage = () => {
        showMenu.value = true;
        changeMenuTab(MENU_TAB.MESSAGES);
    };

    const setUnseenMessages = (currentState: boolean) => {
        unseenMessages.value = currentState;
        unseenMessageCount.value = (currentState) ? (unseenMessageCount.value + 1) : 0;
    };

    const toggleScrollIcon = (e: Event) => {
        util.debounce(toggleScrollIconDebounced, 100, e);
    };

    const toggleScrollIconDebounced = (e: Event) => {
        const scrollTarget = e.target as HTMLElement;

        const scrollMax = scrollTarget.scrollHeight - scrollTarget.clientHeight;
        const scrollThreshold = scrollMax - 100;

        showScrollButton.value = (scrollTarget.scrollTop < scrollThreshold);

        if (scrollTarget.scrollTop === scrollMax) {
            setUnseenMessages(false);
            clearChatNotification();
        }
    };

    const sendChatMessage = async () => {
        if (!chatMessage.value) return;
        await $dealRoomHub.sendChatMessage(chatMessage.value);
        chatMessage.value = "";
    };

    const addChatMessage = (senderId: string, senderName: string, message: string) => {
        const lastMessage = chatMessages.value[chatMessages.value.length - 1];
        const timestamp = new Date(Date.now());

        // Append message to last message if the same person sent the message within 1 minute
        if (lastMessage && (lastMessage.senderId === senderId) && (timestamp.getUTCDate() - lastMessage.timestamp.getUTCDate() < 60000)) {
            lastMessage.addMessage(message);
        }
        else {
            let msgClass = 'other-message';
            if (senderId === util.getDefaultGuid())
                msgClass = 'server-message';
            else if (senderId === viewerId.value)
                msgClass = 'our-message';

            const newMessage = new CB_ChatMessage(msgClass, senderId, senderName, [message], timestamp);
            chatMessages.value.push(newMessage);
        }

        if (!showMenu.value || currentTab.value !== MENU_TAB.MESSAGES || showScrollButton.value) {
            setUnseenMessages(true);
            newChatPushNotification();
        }
        else {
            jumpToBottomOfChat();
        }
    };

    const jumpToBottomOfChat = () => {
        nextTick(() => {
            setUnseenMessages(false);
            const lastMessage = messageGroupRef.value.querySelector('.message-group > .message-item:last-of-type');

            if (lastMessage) {
                //[behavior: 'smooth'] makes scrollIntoView freak out on Chrome most of the time. If someone has a fix, I would love to hear about it.
                lastMessage.scrollIntoView({/*behavior: 'smooth',*/ block: 'end', inline: 'nearest' });
            }

            util.debounce(clearChatNotification, 100);
        });
    };

    const newChatPushNotification = () => {
        const latestMessage = chatMessages.value[chatMessages.value.length - 1];

        newestMessage.value = {
            class: latestMessage.class,
            senderName: latestMessage.senderName,
            text: latestMessage.getLastMessage()
        };

        // Auto-Clear server messages after 10 seconds.
        if (latestMessage.senderName === 'SERVER') {
            setTimeout(() => {
                const newestMsg = chatMessages.value[chatMessages.value.length - 1];

                if (newestMsg.senderName === 'SERVER')
                    clearChatNotification();
            }, 10000);
        }
    };

    const clearChatNotification = () => {
        newestMessage.value = null;
    };

    const newChatMessageHandler = (senderId: string, senderName: string, message: string) => {
        addChatMessage(senderId, senderName, message);
    };
    // #endregion


    // =======================
    // RESIZE OBSERVER METHODS
    // =======================
    // #region Resize Observer Methods
    const startResizeObserver = () => {
        if (!resizeObserver.value)
            resizeObserver.value = new ResizeObserver(onWindowResize);

        resizeObserver.value.observe(document.querySelector('.app-container'));
    };

    const stopResizeObserver = () => {
        resizeObserver.value.disconnect();
    };

    const onWindowResize = () => {
        if (!isEditor.value || !isConnected.value || followerCount.value < 1) return;
        util.debounce(domUpdateDebounced, 100);
    };
    // #endregion


    // ====================
    // DOM OBSERVER METHODS
    // ====================
    // #region DOM Observer Methods
    const startDomWatcher = () => {
        const domObserverConfig = { attributes: true, childList: true, subtree: true, characterData: true };
        domObserver.value.observe(props.elementToWatch, domObserverConfig);
    };

    const stopDomWatcher = () => {
        domObserver.value?.disconnect();
    };

    const onDomUpdate = (mutationList: MutationRecord[]) => {
        const mutatedMice = mutationList.filter(item => {
            const mutationTarget = item.target as HTMLElement;
            return mutationTarget.className && mutationTarget.className.indexOf('mouse') !== -1;
        });

        const meetingUpdates = mutationList.filter(item => item.attributeName === 'meetingswithwaitingcustomers');
        const notTrackingUpdate = mutatedMice.length > 0 || meetingUpdates.length > 0;

        if (!isEditor.value || notTrackingUpdate || followerCount.value < 1 || !isConnected.value)
            return;

        nextTick(() => { util.debounce(domUpdateDebounced, 100); });
    };

    const domUpdateDebounced = () => {
        const currentDOM = cleanEditorHTML();

        const screenXY = {
            x: window.innerWidth,
            y: window.innerHeight
        };

        const elementXY = {
            x: props.elementToWatch.clientWidth,
            y: props.elementToWatch.clientHeight
        };

        $dealRoomHub.updateEditorHTML(currentDOM, screenXY, elementXY);
    };

    const requestForHTMLHandler = () => {
        nextTick(() => {
            const screenXY = {
                x: window.innerWidth,
                y: window.innerHeight
            };

            const elementXY = {
                x: props.elementToWatch.clientWidth,
                y: props.elementToWatch.clientHeight
            };

            $dealRoomHub.updateEditorHTMLForViewer(cleanEditorHTML(), screenXY, elementXY);
        });
    };

    const cleanEditorHTML = (): string => {
        const currentDOM = props.elementToWatch.cloneNode(true) as HTMLElement;

        const fimenuBasic = currentDOM.querySelector('.fimenubasic') as HTMLElement;
        if (fimenuBasic?.style) fimenuBasic.style.height = 'auto';

        // Store scroll% & height on relevant elements
        const scrollableSelectors = [
            '.panel.coverage-term > .panel-body',
            '.purchase-figures-container .panel > .panel-body',
            '.fi-final-step-section',
            '.RecordedLogsList .richtable-container',
            '.wizard-content',
            '.modal',
            '.cobrowsing-toolbar, .floating-toolbar .tool-content',
            '.cobrowsing-toolbar .floating-toolbar .tool-content .message-group',
        ];
        const originalPanels = props.elementToWatch.querySelectorAll(scrollableSelectors.join(', '));

        for (const element of originalPanels) {
            const maxScroll = element.scrollHeight - element.clientHeight;
            const scrollPercent = (maxScroll === 0) ? 0 : element.scrollTop / maxScroll;

            let cssSelector = `${element.tagName}[class="${element.className}"]`;
            if (element.parentElement?.className)
                cssSelector = `${element.parentElement.tagName}[class="${element.parentElement.className}"] > ` + cssSelector;

            const clonedElement = currentDOM.querySelector(cssSelector) as HTMLElement;
            clonedElement?.setAttribute('scrollPercent', scrollPercent.toString());

            if (clonedElement && element.className.indexOf('wizard-content') !== -1) {
                const origPadding = window.getComputedStyle(element).getPropertyValue('padding-top');
                clonedElement.style.height = `calc(${element.clientHeight}px - ${origPadding})`;
            }
        }

        // Remove the animation that causes the uggo flashing
        const animationClasses = ['fade-in', 'fade-in-fast', 'scale-in', 'scale-in-fast', 'wipe-in', 'flash', 'slide-in'];
        const allTheFades = currentDOM.querySelectorAll(`.${animationClasses.join(', .')}`);
        allTheFades?.forEach((element) => element.classList.remove(...animationClasses));

        // Explicitly insert value attributes for input elements
        const allInputs = currentDOM.querySelectorAll('input');
        allInputs?.forEach((element) => element.setAttribute('value', element.value));

        // Making sure initial value is set for textareas
        const allTextareas = currentDOM.querySelectorAll('textarea');
        allTextareas?.forEach((element) => element.setAttribute('value', element.value));

        // Explicitly set the current value of dropdowns
        const allOldSelects = props.elementToWatch.querySelectorAll('select');
        const allNewSelects = currentDOM.querySelectorAll('select');

        allOldSelects?.forEach((oldElement, index) => {
            for (const newChild of allNewSelects[index].childNodes) {
                const childSelect  = newChild as HTMLSelectElement;

                if (childSelect.tagName === 'OPTION' && childSelect.value === oldElement.value) {
                    childSelect.setAttribute('selected', 'selected');
                    break;
                }
            }
        });

        // Fixing styling from displayRawHTML
        currentDOM.querySelectorAll('.displayRawHTML style')?.forEach(styleElem => {
            styleElem.innerHTML = util.getScopedStyles(styleElem as HTMLStyleElement, '.displayRawHTML');
        });

        // Remove editor's EditorFacade, to prevent recursive FiMenus
        currentDOM.querySelector('.editor-facade-container')?.remove();

        // Remove mouse ghosts
        currentDOM.querySelector('.cobrowsing-toolbar .mouse-container')?.remove();

        // If there's fields containing sensitive information, try to mask it.
        const privateFields = currentDOM.querySelectorAll('[data-private]');
        privateFields?.forEach(f => {
            const allInnerInputs = f.querySelectorAll('input');
            allInnerInputs?.forEach((i => i.type = 'password'));
        });

        // Adding blur styling to anything that should be blurred for co-browsers
        const cobrowsingCensored = currentDOM.querySelectorAll('.cobrowsing-censor');
        cobrowsingCensored.forEach(element => {
            (element as HTMLElement).style.filter = 'blur(5px)';
        });

        return currentDOM.innerHTML;
    };
    // #endregion


    // ============================
    // MOUSE + SCROLL EVENT METHODS
    // ============================
    // #region Mouse + Scroll Event Methods
    const getMouseGhostPosition = (xPercent: number, yPercent: number): string => {
        const watchedElement = (isEditor.value) ? props.elementToWatch.getBoundingClientRect() : props.elementFacade.getBoundingClientRect();
        const x = xPercent * watchedElement.width;
        const y = yPercent * watchedElement.height;
        const zIndexProp = (isSpectator.value) ? 'z-index: 999;' : ''; // If Spectator, don't want mice to render on top of modals

        return `left: ${x}px; top: ${y}px; ${zIndexProp}`;
    };

    const sendCurrentMousePosition = async (e: MouseEvent) => {
        const emptyRoom = isEditor.value && followerCount.value < 1;
        const notSharingMouse = isSpectator.value && (!sendMouse.value || maximizeCustomerScreen.value);
        if (emptyRoom || notSharingMouse || !isConnected.value) return;

        // Throttling mouse every 250ms
        const rightNow = Date.now();
        if (rightNow - lastMouseMoveTime.value <= 250) return;

        lastMouseMoveTime.value = rightNow;

        const elementRect = (isSpectator.value)
        ? props.elementFacade.getBoundingClientRect()
        : props.elementToWatch.getBoundingClientRect();

        const xPercent = (e.clientX - elementRect.left) / elementRect.width;
        const yPercent = (e.clientY - elementRect.top) / elementRect.height;

        await $dealRoomHub.updateMousePosition(xPercent, yPercent);
    };

    const sendClickEvent = async (e: MouseEvent) => {
        const emptyRoom = isEditor.value && followerCount.value < 1;
        const notSharingMouse = isSpectator.value && (!sendMouse.value || maximizeCustomerScreen.value);
        if (emptyRoom || notSharingMouse || !isConnected.value) return;

        await $dealRoomHub.sendClickEvent();
    };

    const sendScrollEvent = (e: Event) => {
        if (!isEditor.value || followerCount.value < 1 || !isConnected.value) return;
        util.debounce(sendScrollEventDebounced, 100, e);
    };

    const sendScrollEventDebounced = (e: Event) => {
        const scrollTarget = e.target as HTMLElement;

        let parentClass = scrollTarget.parentElement?.tagName;
        scrollTarget.parentElement?.classList?.forEach(item => parentClass += `.${item}`);

        let targetClass = scrollTarget.tagName;
        scrollTarget.classList.forEach(item => targetClass += `.${item}`);

        if (parentClass)
            targetClass = `${parentClass} > ${targetClass}`;

        const scrollPercent = (scrollTarget.scrollTop) / (scrollTarget.scrollHeight - scrollTarget.clientHeight);

        if (!isNaN(scrollPercent) && targetClass)
            $dealRoomHub.updateScrollPosition(targetClass, scrollPercent);
    };

    const mouseUpdateHandler = (mouseData: ICB_MouseData) => {
        if (pauseMouseUpdatesFrom.value.some(m => m === mouseData.id)) return;

        const existingMouse = mouseList.value.find((mouse) => mouse.id === mouseData.id);
        if (existingMouse) {
            existingMouse.xPercent = mouseData.xPercent;
            existingMouse.yPercent = mouseData.yPercent;
            existingMouse.role = mouseData.role;
        }
        else {
            mouseList.value.push(mouseData);
        }
    };

    const clickHandler = (mouseData: ICB_MouseData) => {
        const existingMouse = allMiceRef.value.find((mouse) => mouse.getAttribute('value') === mouseData.id);
        if (!existingMouse || pauseMouseUpdatesFrom.value.some(m => m === mouseData.id)) return;

        const clickNode = document.createElement('div');
        clickNode.className = 'click-animation';
        clickNode.addEventListener('animationend', () => { clickNode.parentElement.removeChild(clickNode); })
        existingMouse.insertBefore(clickNode, existingMouse.firstChild);
    };

    const mouseHiddenHandler = (viewerId: string) => {
        mouseList.value = mouseList.value.filter(mouse => mouse.id !== viewerId);

        // Sometimes stale mouse events can still be sent up by the server after a mouseHidden event has been raised.
        // Temporarily pause processing mouse events from this viewer to prevent their mice from unintentionally lingering on screen.
        pauseMouseUpdatesFrom.value.push(viewerId);
        setTimeout(() => {
            pauseMouseUpdatesFrom.value = pauseMouseUpdatesFrom.value.filter(m => m !== viewerId);
        }, 500);
    };
    // #endregion


    // ==============================
    // DEALROOMHUB CONNECTION METHODS
    // ==============================
    // #region DealRoomHub Connection Methods
    const setupDealRoomHub = async (isSubscribing: boolean) => {
        const dealRoomSubscriptions = {
            DealRoomJoinSuccess: dealRoomJoinSuccessHandler,
            KickedFromRoom: kickedFromRoomHandler,
            WhoAreYou: connectToDealRoom, // Try to rejoin if server doesn't recognize you
            UpdatedRoomInfo: updatedRoomInfoHandler,
            VisibilityChanged: visibilityChangedHandler,
            SomeoneDisconnected: someoneDisconnectedHandler,
            EditorVacancy: editorVacancyHandler,
            EditorRoleClaimed: editorRoleClaimedHandler,
            RequestEditAccess: editRequestHandler,
            EditRequestPending: editRequestPendingHandler,
            EditorBusy: editorBusyHandler,
            ResponseToEditRequest: responseToEditRequestHandler,
            EditorWantsYouToFollow: editorWantsYouToFollowHandler,
            ResponseToFollowRequest: responseToFollowRequestHandler,
            SyncFollowEditor: syncFollowEditorHandler,
            NewChatMessage: newChatMessageHandler,
            EditorCustomerConnectionUpdate: editorCustomerConnectionUpdateHandler,
            MouseUpdate: mouseUpdateHandler,
            ClickEvent: clickHandler,
            MouseHidden: mouseHiddenHandler,
            RequestForHTML: requestForHTMLHandler,
            PingBack: pingBackHandler
        };

        if (isSubscribing)
            $dealRoomHub.bulkSubscribe(dealRoomSubscriptions);
        else
            $dealRoomHub.bulkUnsubscribe(dealRoomSubscriptions);
    };

    const peekIntoDealRoom = async () => {
        const response = await api.azureMediaServices.getActiveEditorLogin(dealRoomId.value);

        if (!response || !response.data) {
            openConnectionStatusChangedModal();
            return;
        }

        const roomStatus = response.data as ICB_DealRoomStatus;
        const dealRoomEnum = ENUMS.DEAL_ROOM_STATE;
        const userLogin = auth.getTokenPayload().EmployeeLogin;

        if (roomStatus.roomState === dealRoomEnum.DoesntExist
            || roomStatus.roomState === dealRoomEnum.EditorVacancy
            || (roomStatus.editorLogin === userLogin && roomStatus.editorConnections < 1)) {
            connectToDealRoom();
            return;
        }

        const censorEditorName = roomStatus.isEditorAdmin && !auth.getTokenPayload().EmployeeAccess.IsAdmin;

        $modal.open(modalCoBrowsingConnect, {
            name: 'modalCoBrowsingConnect',
            passedData: {
                isEditor: roomStatus.editorLogin === userLogin,
                editorName: (censorEditorName) ? 'Administrator' : roomStatus.editorName
            },
            postFunction: (connectionActions: { followEditor?: boolean, sendEditRequest?: boolean }) => {
                connectToDealRoom(connectionActions);
            }
        });
    };

    const connectToDealRoom = async (connectionActions: { followEditor?: boolean, sendEditRequest?: boolean } = null) => {
        addChatMessage(util.getDefaultGuid(), 'SERVER', 'Connecting...');

        if (!isConnected.value) {
            await $dealRoomHub.startConnection(dealRoomId.value);
            setupDealRoomHub(true);
        }

        const authToken = auth.getTokenPayload();
        isAdmin.value = authToken.EmployeeAccess.IsAdmin;

        const joinRequest: ICB_JoinRoomRequest = {
            login: authToken.EmployeeLogin,
            name: authToken.EmployeeName,
            isAdmin: isAdmin.value,
            followEditor: connectionActions?.followEditor ?? false,
            sendEditRequest: connectionActions?.sendEditRequest ?? false,
        };

        await $dealRoomHub.joinDealRoom(joinRequest);

        if (connectionActions?.sendEditRequest)
            requestEditAccess();

        // If unexpectedly disconnected, will open an alert modal to the user
        unwatchIsConnected.value = watch(isConnected, (newVal) => {
            if (!newVal)
                openConnectionStatusChangedModal();
        });
    };

    const openConnectionStatusChangedModal = () => {
        if (isReconnecting.value) return;

        $modal.open(modalCountdown, {
            name: 'modalCountdown',
            passedData: {
                title: 'Connection Lost',
                info: 'There seems to be an issue with your connection. This page will refresh shortly to try to reconnect.',
                acceptText: 'Refresh Now',
            },
            postFunction: () => {
                LogRocket.track("DealRoomHub - Lost Socket Connection");
                window.location.reload();
            }
        });
    };

    const dealRoomJoinSuccessHandler = (newViewerName: string) => {
        addChatMessage(util.getDefaultGuid(), 'SERVER', `${newViewerName} just joined the deal.`);
    };

    const kickedFromRoomHandler = () => {
        // Return to deal index
        $dealRoomHub.stopConnection();
        window.location.assign('/');
    };
    // #endregion


    // =======================
    // DEALROOM UPDATE METHODS
    // =======================
    // #region DealRoom Update Methods
    const updatedRoomInfoHandler = (roomInfo: ICB_RoomInfo, viewerInfo: ICB_ViewerInfo) => {
        viewerList.value = roomInfo.viewerInfoList;
        followerCount.value = roomInfo.followerCount;
        connectedWithCustomerViewerName.value = roomInfo.connectedWithCustomerViewerName;

        viewerId.value = viewerInfo.id;
        visibleToRoom.value = viewerInfo.visibleToRoom;
        editRequestSent.value = viewerInfo.requestPending;
        followEditor.value = viewerInfo.followEditor;

        if (viewerInfo.activeRequesterId != null && !editRequestModalOpen.value)
            editRequestHandler(viewerInfo.activeRequesterId, viewerInfo.activeRequesterName);
    };

    const someoneDisconnectedHandler = (role: string, senderName: string) => {
        // eslint-disable-next-line no-console
        console.log(`${role} (${senderName}) has disconnected.`);
        addChatMessage(util.getDefaultGuid(), 'SERVER', `${senderName} (${role}) just left the room.`);
    };

    const visibilityChangedHandler = (isVisible: boolean) => {
        visibleToRoom.value = isVisible;
    };

    const editorCustomerConnectionUpdateHandler = (editorName: string, status: boolean) => {
        connectedWithCustomerViewerName.value = (status) ? editorName : '';
    };

    const pingBackHandler = (pingData: any) => {
        //console.log('DealRoomHub - pingData', pingData);
    };
    // #endregion


    // ======================
    // EDITOR VACANCY METHODS
    // ======================
    // #region Editor Vacancy Methods
    const cedeEditingPower = async () => {
        if (isSpectator.value) return;

        cedeEditingRequestSent.value = true;
        await $dealRoomHub.cedeEditingPower();

        if ($meetingHub.connected && $meetingHub.code)
            await meetingHelper.endMeeting();
    };

    const editorVacancyHandler = () => {
        cedeEditingRequestSent.value = false;
        mouseList.value = [];
        changeMenuTab(MENU_TAB.VIEWER_LIST);

        const _openClaimEditRoleModal = () => {
            $modal.open(modalInfo, {
                name: 'modalInfo',
                passedData: {
                    title: 'Editor Left the Room',
                    info: 'The Editor has left the room. Would you like to take control of this deal?',
                    acceptText: 'Become Editor',
                    cancelText: 'Return to Deal Index',
                },
                postFunction: () => {
                    // eslint-disable-next-line no-console
                    console.log('Claimed Editor Role');
                    $dealRoomHub.claimEditorRole();
                },
                cancelFunction: (preventRedirect: boolean) => {
                    if (!preventRedirect) window.location.assign('/');
                }
            })
        };

        if (!props.isBusyIniting) {
            _openClaimEditRoleModal();
            return;
        }

        watch(() => props.isBusyIniting, _openClaimEditRoleModal, { once: true });
    };

    const editorRoleClaimedHandler = () => {
        $modal.cancel(true);
    };
    // #endregion


    // ====================
    // EDIT REQUEST METHODS
    // ====================
    // #region Edit Request Methods
    const requestEditAccess = async () => {
        if (isEditor.value) {
            addChatMessage(util.getDefaultGuid(), 'SERVER', 'You already have edit access.');
        }
        else if (editRequestSent.value) {
            addChatMessage(util.getDefaultGuid(), 'SERVER', 'You already sent a request. No response received yet.');
        }
        else {
            addChatMessage(util.getDefaultGuid(), 'SERVER', 'Requesting edit access...');
            editRequestSent.value = true;
            await $dealRoomHub.requestEditAccess();
        }
    };

    const editRequestHandler = (requesterId: string, requesterName: string) => {
        if (editRequestModalOpen.value) {
            $dealRoomHub.sendEditorBusy(requesterId);
            return;
        }

        editRequestModalOpen.value = true;
        addChatMessage(util.getDefaultGuid(), 'SERVER', `${requesterName} requested editor access.`);

        const editRequestMessage = [
            {
                text: requesterName,
                class: 'cobrowsing-censor'
            },
            {
                text: `is requesting editing access. If you accept, you will be locked out of editing the deal until they give you back control.`
            }
        ]

        if ($meetingHub.connected) {
            editRequestMessage.push({
                text: 'You are currently connected with a customer. Accepting this request will cause you to automatically disconnect from their screen.',
                class: 'token bold'
            });
        }

        $modal.open(modalCountdown, {
            name: 'modalCountdown',
            passedData: {
                title: 'Edit Request',
                info: editRequestMessage,
                additionalInfo: 'This request will be auto-accepted if you don\'t respond before the timer expires.',
                acceptText: 'Accept Request',
                altAcceptText: 'Accept & Immediately Follow',
                cancelText: 'Decline Request',
                timerDuration: 30,
                circleTickUp: false,
            },
            postFunction: async (toggleFollowImmediately: boolean) => {
                $modal.cancel();

                sendMouse.value = false;
                await $dealRoomHub.respondToEditRequest(requesterId, true);

                if ($meetingHub.connected && $meetingHub.code)
                    await meetingHelper.endMeeting();

                editRequestModalOpen.value = false;

                if (toggleFollowImmediately) await toggleFollowEditor();

                addChatMessage(util.getDefaultGuid(), 'SERVER', `Edit request from ${requesterName} has been accepted. You have been locked from making edits.`);
            },
            altPostFunction: async () => {
                return true;
            },
            cancelFunction: async () => {
                await $dealRoomHub.respondToEditRequest(requesterId, false);
                editRequestModalOpen.value = false;
            }
        });
    };

    const editRequestPendingHandler = () => {
        editRequestSent.value = true;
    };

    const editorBusyHandler = () => {
        editRequestSent.value = false;
        addChatMessage(util.getDefaultGuid(), 'SERVER', 'The editor is currently busy. Try asking again later.');
    };

    const responseToEditRequestHandler = (isRequestAccepted: boolean) => {
        editRequestSent.value = false;

        if (isRequestAccepted)
            syncFollowEditorHandler(false);

        const message = `Your request for editing access has been ${isRequestAccepted ? 'accepted' : 'rejected'}.`;
        addChatMessage(util.getDefaultGuid(), 'SERVER', message);
    };
    // #endregion


    // =====================
    // FOLLOW EDITOR METHODS
    // =====================
    // #region Follow Editor Methods
    const syncFollowEditorHandler = (isFollowingEditor: boolean) => {
        followEditor.value = isFollowingEditor;
        followEditorSent.value = false;

        if (!followEditor.value) {
            $dealRoomHub.hideMouseUpdates();
        }

        EventBusCore.emit("followEditor", followEditor);
    };

    const askSpectatorsToFollow = async () => {
        if (isSpectator.value) return;

        await $dealRoomHub.askSpectatorsToFollow();
        addChatMessage(util.getDefaultGuid(), 'SERVER', 'Asking Viewers to follow...');
    };

    const editorWantsYouToFollowHandler = () => {
        if (followRequestModalOpen.value) return;
        followRequestModalOpen.value = true;

        $modal.open(modalInfo, {
            name: 'modalInfo',
            passedData: {
                title: 'Request to Follow Editor Screen',
                info: 'The Editor is requesting for you to follow their screen.',
                acceptText: 'Follow Editor',
                cancelText: 'Decline Request',
            },
            postFunction: async () => {
                followRequestModalOpen.value = false;
                toggleFollowEditor();
                await $dealRoomHub.respondToFollowRequest(true);
            },
            cancelFunction: async () => {
                followRequestModalOpen.value = false;
                await $dealRoomHub.respondToFollowRequest(false);
            }
        });
    };

    const responseToFollowRequestHandler = (isRequestAccepted: boolean, viewerName: string) => {
        if (isSpectator.value) return;

        const message = `${viewerName} ${isRequestAccepted ? 'accepted' : 'rejected'} your request to follower.`;
        addChatMessage(util.getDefaultGuid(), 'SERVER', message);
    };
    // #endregion
</script>

<style>
    .page.fimenupage .cobrowsing-toolbar {

        position: fixed;
        top: 0;
        z-index: 112;
    }

    .cobrowsing-toolbar .tool-main {
        position: relative;
        margin: 2px;
        z-index: 1600;
    }

        .cobrowsing-toolbar .tool-main .chat-notification {
            position: absolute;
            color: var(--error-color);
            font-size: 0.5em;
            pointer-events: none;
        }

            .cobrowsing-toolbar .tool-main .chat-notification span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: var(--button-color);
                font-size: 0.6em;
            }

        .cobrowsing-toolbar .tool-main > button:not(.chat-push-notification) {
            height: 40px;
            margin: 5px;
            padding: 5px;
            border-radius: 50%;
        }

            .cobrowsing-toolbar .tool-main > button .chat-notification {
                top: 1px;
                right: -15px;
                font-size: 1.5em;
            }

                .cobrowsing-toolbar .tool-main > button .chat-notification i {
                    text-shadow: -1px 1px white;
                }

        .cobrowsing-toolbar .tool-main .floating-toolbar {
            position: absolute;
            width: 350px;
            height: 355px;
            background-color: var(--secondary-color);
            border-radius: 10px;
            border: 1px solid var(--border-color);
            box-shadow: 0px 1px 7px -5px var(--panel-color-shadow);
            transform-origin: top left;
            z-index: 1600;
        }

    .cobrowsing-toolbar .floating-toolbar .tool-container {
        display: flex;
        flex-direction: row;
    }

        .cobrowsing-toolbar .floating-toolbar .tool-container button {
            position: relative;
            font-size: 1.1rem;
            border-radius: unset;
        }

            .cobrowsing-toolbar .floating-toolbar .tool-container button:not(:first-of-type) {
                border-left: 1px solid var(--secondary-color);
            }

            .cobrowsing-toolbar .floating-toolbar .tool-container button:first-of-type {
                border-top-left-radius: 10px;
            }

            .cobrowsing-toolbar .floating-toolbar .tool-container button:last-of-type {
                border-top-right-radius: 10px;
            }

            .cobrowsing-toolbar .floating-toolbar .tool-container button.active,
            .cobrowsing-toolbar .floating-toolbar .tool-container button:hover {
                box-shadow: inset 0px 0px 20px 20px var(--black-20percent);
            }

            .cobrowsing-toolbar .floating-toolbar .tool-container button .chat-notification {
                right: 34%;
                top: 15%;
            }

            .cobrowsing-toolbar .floating-toolbar .tool-container button .role-icon {
                width: 3em;
                font-size: 0.8em;
            }

    .cobrowsing-toolbar .floating-toolbar .tool-content {
        padding: 5px;
        margin: 5px;
    }

        .cobrowsing-toolbar .floating-toolbar .tool-content:has(.viewers-content, .role-content) {
            margin-right: 10px;
            height: 295px;
            overflow-y: scroll;
            mask-image: linear-gradient(to bottom, transparent 0%, black 15px, black calc(100% - 15px), transparent 100%);
        }

        .cobrowsing-toolbar .floating-toolbar .tool-content .role-content {
            border-radius: 10px;
            text-align: center;
        }

            .cobrowsing-toolbar .floating-toolbar .tool-content .role-content .action-button {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

                .cobrowsing-toolbar .floating-toolbar .tool-content .role-content .action-button:hover {
                    color: var(--main-color);
                }

                .cobrowsing-toolbar .floating-toolbar .tool-content .role-content .action-button:has(button:disabled) {
                    cursor: not-allowed;
                    pointer-events: none;
                    color: var(--disabled-color);
                }

            .cobrowsing-toolbar .floating-toolbar .tool-content .role-content button {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 35px;
                height: 35px;
                margin: 5px;
                border-radius: 10px;
            }

        .cobrowsing-toolbar .floating-toolbar .tool-content .viewer-header {
            font-weight: bold;
            text-align: center;
            padding: 10px;
        }

        .cobrowsing-toolbar .floating-toolbar .tool-content .viewer-item {
            display: grid;
            grid-template-columns: 35px max-content;
            align-items: center;
            padding: 10px;
        }

            .cobrowsing-toolbar .floating-toolbar .tool-content .viewer-item > span {
                margin: 0 5px;
            }

            .cobrowsing-toolbar .floating-toolbar .tool-content .viewer-item .role-icon {
                --icon-background-color: var(--secondary-color);
            }

        .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content {
            position: relative;
        }

            .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-group {
                height: 260px;
                margin-bottom: 5px;
                overflow-y: scroll;
                mask-image: linear-gradient(to bottom, transparent 0%, black 15px, black calc(100% - 15px), transparent 100%);
            }

            .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-item {
                display: flex;
                column-gap: 10px;
                padding: 5px;
                border-bottom: 1px solid var(--border-color);
                overflow-wrap: anywhere;
            }

                .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-item .role-icon {
                    --icon-background-color: var(--secondary-color);
                    height: min-content;
                    width: min-content;
                    padding: 5px;
                }

                .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-item small {
                    color: var(--disabled-color);
                    margin-right: 5px;
                }

                .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-item p {
                    margin: 5px 0;
                    padding: 0;
                }

                .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-item.server-message p {
                    color: var(--disabled-color);
                }

            .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-chatbox {
                height: 10%;
                margin: 0;
                display: flex;
                align-items: center;
                flex-direction: row;
                border: 1px solid lightgrey;
                border-radius: 5px;
                background-color: var(--background-color);
            }

                .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-chatbox input {
                    padding: 7px;
                    width: 75%;
                    text-transform: none;
                    border-radius: 5px 0 0 5px;
                }

                    .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-chatbox input:hover {
                        border: 1px solid var(--main-color);
                    }

                .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-chatbox button {
                    width: 25%;
                    border-radius: 0 5px 5px 0;
                }

            .cobrowsing-toolbar .floating-toolbar .tool-content .messages-content .message-scroll {
                position: absolute;
                bottom: 15%;
                right: 20px;
                width: 35px;
                height: 35px;
                padding: 0;
                border-radius: 50%;
                text-align: center;
            }

    .cobrowsing-toolbar .chat-push-notification {
        position: absolute;
        left: 150%;
        top: 5px;
        width: max-content;
        max-width: 350px;
        padding: 10px;
        border: 1px solid var(--border-color);
        border-radius: 10px;
        background-color: var(--success-color);
        color: white;
        cursor: pointer;
        transition: background-color 0.3s;
    }

        .cobrowsing-toolbar .chat-push-notification.server-message {
            background-color: var(--error-color);
            animation: shadowPulse 1.5s infinite;
        }

            .cobrowsing-toolbar .chat-push-notification.server-message:hover {
                animation: none;
            }

        .cobrowsing-toolbar .chat-push-notification span {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .cobrowsing-toolbar .chat-push-notification::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -10px;
            border-width: 10px;
            border-style: solid;
            border-color: transparent var(--success-color) transparent transparent;
            transition: border-color 0.3s;
        }

        .cobrowsing-toolbar .chat-push-notification.server-message::after {
            border-color: transparent var(--error-color) transparent transparent;
        }

    .cobrowsing-toolbar .mouse-container {
        /* Properties are overwritten inline */
        --origin-x: 0px;
        --origin-y: 0px;
        --facade-scale: 1;
    }

        .cobrowsing-toolbar .mouse-container > div {
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            pointer-events: none;
            text-shadow: -1px -1px white, 1px -1px white, -1px 1px white, 1px 1px white;
            transform: translate( var(--origin-x), var(--origin-y) );
            font-size: calc(18px * var(--facade-scale));
            z-index: 1500;
            transition: top 250ms, left 250ms;
        }

        .cobrowsing-toolbar .mouse-container > .mouse-editor {
            color: var(--error-color);
            border-color: var(--error-color);
            z-index: 1501;
        }

        .cobrowsing-toolbar .mouse-container > .mouse-spectator {
            color: var(--accept-color);
            border-color: var(--accept-color);
        }

        .cobrowsing-toolbar .mouse-container > div > span {
            display: inline-block;
            width: 5em;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
        }

        .cobrowsing-toolbar .mouse-container .click-animation {
            position: fixed;
            box-sizing: border-box;
            border-style: solid;
            border-radius: 50%;
            opacity: 1;
            animation: clickAnimation 0.3s ease-out;
        }

    .scale-in {
        animation: scaleIn 0.3s ease-in-out;
    }

    .scale-in-fast {
        animation: scaleIn 0.25s;
    }

    .wipe-in {
        animation: wipeIn 0.25s ease-in-out;
    }

    @keyframes clickAnimation {
        0% {
            opacity: 1;
            width: 0.5em;
            height: 0.5em;
            margin: -0.25em;
            border-width: 0.5em;
        }

        100% {
            opacity: 0.2;
            width: 10em;
            height: 10em;
            margin: -5.5em;
            border-width: 0.03em;
        }
    }

    @keyframes scaleIn {
        0% {
            transform: scale(0.1);
            opacity: 0;
        }

        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes wipeIn {
        0% {
            max-width: 0px;
            opacity: 0;
        }

        100% {
            max-width: 350px;
            opacity: 1;
        }
    }

    @keyframes shadowPulse {
        0% {
            transform: scale(0.99);
            box-shadow: 0 0 0 0 rgba(193, 27, 27, 0.5);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(193, 27, 27, 0);
        }

        100% {
            transform: scale(0.99);
            box-shadow: 0 0 0 0 rgba(193, 27, 27, 0);
        }
    }
</style>