<template>
    <Panel v-bind="panelObject" :class="{'errorBlink': loJackMsgClass()}">
        <!-- <IsBusyScreenComponent v-if="(!panelView) && vinLookupBusy || stockNumberLookupBusy"></IsBusyScreenComponent> -->
        <div class="no-margin-grid" :style="$grid('1-1')">
            <div class="no-margin-grid" :style="$grid('1-1.6-0.7')">
                <InputTextbox label="Stock Number"
                              v-model:saturn="data.stockNumber"
                              :invalid="util.isNull(validation) ? false : validation.stockNumber.$invalid"
                              placeholder="Stock Number"
                              :disabled="disabled || vinLookupBusy || stockNumberLookupBusy || isBusyEval || data.isDealPartiallyLockedBecauseWeSentACreditApp()"
                              @blur="handleStockLookupBlur">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.stockNumber.required.$invalid">Stock Number is required</div>
                    </template>
                </InputTextbox>
                <InputTextbox label="VIN"
                              :maxlength="17"
                              v-model:saturn="data.vin"
                              :invalid="util.isNull(validation) ? false : validation.vin.$invalid"
                              placeholder="VIN"
                              @blur="() => handleVinDecode(false)"
                              icon="fas fa-search"
                              :disabled="disabled || vinLookupBusy || stockNumberLookupBusy || isBusyEval || data.isDealPartiallyLockedBecauseWeSentACreditApp()">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vin.required.$invalid">VIN is required</div>
                        <div v-if="!util.isNull(validation) && validation.vin.validVIN.$invalid">Invalid VIN</div>
                    </template>
                </InputTextbox>
                <InputRichDropdown label="New/Used"
                                   v-model:saturn="inventoryType"
                                   :list="inventoryTypes"
                                   :display="i => i.text"
                                   :valueMap="i => i.value"
                                   :invalid="util.isNull(validation) ? false : validation.inventoryType.$invalid"
                                   nothingSelected="-- Select Value --"
                                   @change="changeInventoryHandle"
                                   :disabled="disabled"
                                   :sortMode="ENUMS.DROPDOWN_SORT_MODE.ORIGINAL_ORDER">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.inventoryType.required.$invalid">New/Used is required</div>
                    </template>
                </InputRichDropdown>
            </div>
            <div class="no-margin-grid" :style="$grid('1-1-1')">
                <InputNumber label="Odometer"
                             v-model:saturn="data.vehicleMiles"
                             :invalid="util.isNull(validation) ? false : validation.vehicleMiles.$invalid"
                             placeholder="Miles"
                             :precision="0"
                             :disabled="disabled"
                             @blur="carFaxOdometerWarning">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vehicleMiles.required.$invalid">Odometer is required</div>
                        <div v-if="!util.isNull(validation) && validation.vehicleMiles.maxValue.$invalid">A New Car can't have this amount of Miles</div>
                        <div v-if="!util.isNull(validation) && validation.vehicleMiles.minValue.$invalid">Odometer value must be at least 1 or last CarFax Odo</div>
                    </template>
                </InputNumber>
                <!--<InputDate label="In Service Date"
                           v-model:saturn="data.vehicleWarranty.warrantyStartDate"
                           :invalid="util.isNull(validation) ? false : validation.vehicleWarranty.warrantyStartDate.$invalid"
                           placeholder="In Service Date"
                           class="presentation-serviceDate-shift-left"
                           :disabled="disabled">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warrantyStartDate.required.$invalid">In Service date is required on used vehicles</div>
                    </template>
                </InputDate>-->
                <InputNumber label="Miles Per Year"
                             v-model:saturn="data.vehicle.milesPerYear"
                             placeholder="Miles Per Year"
                             :precision="0"
                             :invalid="util.isNull(validation) ? false : validation.vehicle.milesPerYear.$invalid"
                             :disabled="disabled"
                             :min="0">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vehicle.milesPerYear.required.$invalid">Miles Per Year is required</div>
                    </template>
                </InputNumber>
                <InputDate label="In Service Date"
                           v-model:saturn="data.vehicleWarranty.warrantyStartDate"
                           :invalid="util.isNull(validation) ? false : validation.vehicleWarranty.warrantyStartDate.$invalid"
                           :disabled="disabled"
                           icon="fas fa-calendar">
                    <template #validation>
                        <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warrantyStartDate.required?.$invalid">{{ dateErrorMsg }}</div>
                        <div v-if="!util.isNull(validation) && validation.vehicleWarranty.warrantyStartDate.isValid.$invalid">{{ invalidOrFutureDateErrorMsg + data.vehicle.modelYear }}</div>
                    </template>
                </InputDate>
            </div>
        </div>
        <template #header>
            <div style="display: inline-flex; " class="loadingContainer" v-if="vinLookupBusy || stockNumberLookupBusy">
                <strong class="vin-lookup-flash" style="align-self: center;">Loading...</strong>
                <img class="loading-anim"
                     src="@static/img/SaturnLogoAnim.svg"
                     style="width: 40px; margin-right: 2%;" />
            </div>
            <div v-if="spireonEnabled" class="loJack-installed ignore-deal-lock">
                <div class="iconClass" @mouseover="() => {$refs.loJackIcon.classList.remove('statusPulse')}">
                    <InfoIcon :infoIconObj="handleLoJackResponse()" /><div :style="{'--status': 'var(--lojacknuetral)'}" ref="loJackIcon" class="statusPulse"></div>
                </div>
                <button v-if="allowVehicleRefresh" :disabled="vinLookupBusy || stockNumberLookupBusy || $parent.populatingVehicleDetails" class="button-save" title="Redo this vehicle's vin lookup" @click="() => handleVinDecode(false, true)">Reload Vehicle</button>
                <button class="button" :disabled="enableLoJackRefresh" @click="loJackSearchByVin(true)"><i class="fas fa-sync-alt" />LoJack Refresh</button>
                <button v-if="vehicleLocation && !canPingVehicle" @click="showLocation()" class="ignore-all-locks"><i class="fas fa-map-marker-alt"></i></button>
                <button v-if="canPingVehicle" @click="showLocation()" class="ping ignore-all-locks"><i class="fas fa-map-marker-alt"></i></button>
            </div>


            <!-- <div>
                <button @click="() => vinDecode(false, forceRefresh = true)">
                    <i class="fas fa-sync-alt"></i>
                    Vehicle Refresh
                </button>
            </div> -->
        </template>
    </Panel>
</template>

<script>
    import $modal from '@core/services/modal'
    import { _ } from 'vue-underscore'
    import api from '@core/services/api'
    import {CreateBookValues} from '@/helpers/fimenuvehicle-helper'
    import ENUMS from "@core/classes/Enums"
    import FIMenuCarFax from '@core/classes/FIMenuCarFax'
    import InfoIcon from '@core/components/InfoIcon.vue'
    import InputDate from '@core/components/InputDate.vue'
    import InputNumber from '@core/components/InputNumber.vue'
    import InputRichDropdown from '@core/components/InputRichDropdown.vue'
    import InputTextbox from '@core/components/InputTextbox.vue'
    import modalInfo from '@core/modals/modalInfo.vue'
    import modalMap from '@/modals/modalMap.vue'
    import modalRichTable from '@/modals/modalRichTable.vue'
    import modalStockVehicles from '@/modals/modalStockVehicles.vue'
    import Panel from '@core/components/Panel.vue'
    import settings from 'settings'
    import { StockNumberLookupResponse } from '@core/classes/Responses/StockNumberLookupResponse'
    import util from '@core/services/util'
    import VAutoLookupResponse from '@core/classes/Responses/VAutoLookupResponse'

    export default {
        name: "PanelVehicle",
        props: {
            data: Object, // Set to FIMenu in TS.
            panelObject: Object,
            validation: Object,
            disabled: Boolean,
            panelView: Boolean,
            notFirstPencil: Boolean,
            mscanAccount: String,
            spireonEnabled: Boolean,
            customVin: Boolean,
            allowVehicleRefresh: Boolean
        },
        data() {
            return {
                inventoryType: null,
                inventoryTypes: [
                    { text: 'New', value: 'New' },
                    { text: 'Used', value: 'Used' },
                    { text: 'CPO', value: 'Certified' }
                ],
                previousVin: null,
                isBusyEval: false,
                vinLookupBusy: false,
                previousStockNumber: null,
                stockNumberLookupBusy: false,
                dateErrorMsg: "In Service date is required on used vehicles",
                invalidOrFutureDateErrorMsg: "In service date can't be in the future and must be within 1 year of ",
                dealLojackStatus: this.data.dealLojackStatus,
                loJackResponse: null,
                vehicleLocation: false,
                canPingVehicle: false,
                runVINLookupDetails: false
            }
        },
        computed: {
            util() {
                return util;
            },
            vinLookupEnabled() {
                return (this.validation && !this.validation.vin.$invalid);
            },
            settings() {
                return settings
            },
            ENUMS() {
                return ENUMS
            },
            enableLoJackRefresh() {
                return !this.data.vin || this.handleLoJackResponse().clear || !this.spireonEnabled;
            },
            isVehicleDelivered() {
                return this.data.isVehicleDelivered
            },
            noLoJackResponse(){
                return this.loJackResponse == null;
            }
        },
        created() {
            this.loJackIcon = this.$refs.loJackIcon

            this.$watch(() => this.loJackResponse, () => {
                this.showLoJackAlertIfNeeded()
            }, { deep: true })
            this.$watch(() => this.data.inventoryType, () => {
                this.loadData()
            })

            this.$watch(() => this.data.vehicleWarranty.isCertified, () => {
                this.loadData()
            })
            this.$watch(() => this.data.vin, (newValue) => {
                this.vehicleLocation = false
                if (!newValue) {
                    this.previousVin = newValue;
                }
            })
            this.$watch(() => this.data.stockNumber, (newValue) => {
                if (!newValue) {
                    this.previousStockNumber = newValue;
                }
            })
            this.$watch(() => this.runVINLookupDetails, (newValue) => {
                if (newValue) {
                    this.vinLookup()
                    this.runVINLookupDetails = false
                }
            })
        },
        mounted() {
            this.loadData()

        },
        methods: {
            pulseCheck() {
                if (this.spireonEnabled) {
                    this.$nextTick(() => {
                        this.$refs.loJackIcon.classList?.add('statusPulse')
                        switch (this.data.dealLojackStatus) {
                            case ENUMS.DEAL_LOJACK_STATUS.NOT_INSTALLED:
                                return this.$refs.loJackIcon.style.setProperty('--status', 'var(--lojackbad)');
                            case ENUMS.DEAL_LOJACK_STATUS.INSTALLED:
                                return this.$refs.loJackIcon.style.setProperty('--status', 'var(--lojackgood)');
                            default:
                                return this.$refs.loJackIcon.style.setProperty('--status', 'var(--lojacknuetral)');
                        }
                    })
                }
            },
            loJackMsgClass() {
                if (!this.spireonEnabled || this.isVehicleDelivered) return;
                return this.loJackResponse?.attributes.deviceStatus === "Not Installed"
            },
            showLoJackAlertIfNeeded() {
                this.pulseCheck();
                if (this.isVehicleDelivered) return;
                //Possibly add activated to message as a lojack can be installed but not active.
                let infoText = 'SEND THIS VEHICLE TO THE SERVICE DEPARTMENT AS SOON AS POSSIBLE.\n\nDO NOT DELIVER THE VEHICLE WITHOUT A LOJACK INSTALLED!'
                if (this.noLoJackResponse) {
                    infoText = 'LOJACK DID NOT RESPOND AND WAS MOST LIKELY NOT INSTALLED ON THIS VEHICLE.\n\nSEND THIS VEHICLE TO THE SERVICE DEPARTMENT AS SOON AS POSSIBLE.\n\nDO NOT DELIVER THE VEHICLE WITHOUT A LOJACK INSTALLED!'
                }
                //Commented out per Avidan
                //if ((this.spireonEnabled && this.noLoJackResponse) || this.loJackResponse?.active === false || this.loJackResponse?.length == 0) {
                //    $modal.open(modalInfo, {
                //        name: "modalInfo",
                //        passedData: {
                //            title: `LoJack Alert`,
                //            info: infoText,
                //            cancelText: "Close",
                //            cancelFunction: null
                //        },
                //        backdrop: true,
                //        postFunction: null
                //    });
                //}
                // eslint-disable-next-line no-console
                console.log(`LoJack Alert: ${infoText}`);
            },
            handleLoJackResponse() {
                let iconObj = { icon: 'fas fa-exclamation-circle', iconClass: 'bad', tooltipText: ['Enter VIN for LoJack Info'] }
                if (this.vinLookupBusy && !this.loJackResponse) {
                    iconObj.iconClass = 'info-lojack'
                    iconObj.tooltipText = ["Lojack is processing..."]
                    return iconObj;
                }
                if (this.isVehicleDelivered) {
                    iconObj.iconClass = 'info-lojack'
                    iconObj.tooltipText = this.dealLojackStatus == 0 ? ["LoJack Status Good"] : this.dealLojackStatus == 1 ? ["Lojack Needs Activation"] : ["LoJack Status Bad"]
                    return iconObj;
                }

                if (!this.spireonEnabled) {
                    iconObj = { icon: 'fas fa-info', iconClass: 'normal', tooltipText: ['LoJack Search Disabled'] }
                }
                else if (this.loJackResponse) {
                    const deviceStatus = this.loJackResponse?.attributes.deviceStatus
                    const location = this.loJackResponse?.lastLocation
                    const active = this.loJackResponse?.active
                    //let store = this.loJackResponse?.assetGroupName
                    this.vehicleLocation = this.loJackResponse?.lastLocation ? true : false
                    //active: 4 possibilities
                    if (active) {
                        if (location && deviceStatus === "Not Installed") {
                            iconObj.tooltipText = ["LoJack Uninstalled", "LoJack Account Activated"]
                            this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.NOT_INSTALLED
                        }
                        else if (location && deviceStatus === "Installed") {
                            iconObj.tooltipText = ["LoJack Installed", "LoJack Account Activated"]
                            iconObj.iconClass = "good"
                            iconObj.icon = "fas fa-check"
                            //clear disables lojack refresh button
                            iconObj.clear = true
                            this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.INSTALLED
                        }
                        else if (!location && deviceStatus === "Not Installed") {
                            iconObj.tooltipText = ["LoJack Device Not Installed", "LoJack Account Activated"]
                            this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.NOT_INSTALLED
                        }
                        else {
                            iconObj.tooltipText = ["LoJack Installed - Ping for Location", "LoJack Account Activated"]
                            iconObj.iconClass = "ping"
                            this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.INSTALLED
                            this.canPingVehicle = true
                        }
                    }
                    else if (!active) {
                        // not active: 5 possibilities
                        if (location && deviceStatus === "Installed") {
                            iconObj.tooltipText = ["LoJack Account found, Needs Activation"]
                            this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.NEEDS_ACTIVATION
                            //add some flag for allowing activation of loJack
                        }
                        else if (location && deviceStatus === "Not Installed") {
                            iconObj.tooltipText = ["LoJack Device Uninstalled, Account Not Active", "Last Location Found"]
                            this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.NOT_INSTALLED
                        }
                        else if (location === null || !location && deviceStatus === "Installed") {
                            iconObj.tooltipText = ["LoJack Account Needs Activation", "Last Location Not Found"]
                            this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.NEEDS_ACTIVATION
                        }
                        else if (location === null || !location && deviceStatus === "Not Installed") {
                            iconObj.tooltipText = ["LoJack Device Not Installed", "LoJack Account Not Activated!"]
                            this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.NOT_INSTALLED
                        }
                    }
                    else {
                        iconObj.tooltipText = ["LoJack Not Installed!"]
                        this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.NOT_INSTALLED
                    }
                    const currentStore = util.getSpireonAccount(this.data.storeCode)
                    if (this.loJackResponse?.storeAccount !== null && this.loJackResponse?.storeAccount !== currentStore.code) iconObj.tooltipText.push(`Found in ${this.loJackResponse?.assetGroupName}`)
                }

                if (this.noLoJackResponse) {
                    iconObj.tooltipText = ["LOJACK DID NOT RESPOND AND WAS MOST LIKELY NOT INSTALLED ON THIS VEHICLE"]
                    this.data.dealLojackStatus = ENUMS.DEAL_LOJACK_STATUS.NOT_INSTALLED
                }
                return iconObj;
            },
            async showLocation() {
                const title = this.loJackResponse.instrumentationRef?.deviceId ?? `${this.loJackResponse.attributes.deviceStatus}, Showing Last Location`
                const store = this.loJackResponse?.assetGroupName
                const spireonAcct = this.loJackResponse.storeAccount ?? util.getSpireonAccount(this.data.storeCode).code
                if (this.vehicleLocation) {
                    const eventResults = await api.spireon.getLoJackEvents(this.loJackResponse.id, spireonAcct)
                    const history = Object.values(eventResults.data.content.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {}))
                    $modal.open(modalMap, {
                        name: "modalMap",
                        passedData: {
                            title: `Device ID: ${title}`,
                            subTitle: `Found at: ${store}`,
                            center: { lat: this.loJackResponse.lastLocation.lat, lng: this.loJackResponse.lastLocation.lng },
                            history: history
                        },
                        backdrop: true,
                        postFunction: null
                    });
                }
                else {
                    try {
                        const pingResponse = await api.spireon.pingAssetById(this.loJackResponse.id, spireonAcct)
                        // eslint-disable-next-line no-console
                        console.log("pingResponse", pingResponse);
                        if (pingResponse.data.success) {
                            $modal.open(modalInfo, {
                                name: "modalInfo",
                                passedData: {
                                    info: "Vehicle was Pinged for Location, Check back within 24 hours for location.",
                                    acceptText: "Close",
                                },
                                backdrop: true,
                            });
                        }
                    }   
                    catch (err){
                        console.log("Error on Asset Ping", err)
                    }
                }
            },
        changeInventoryHandle() {
                if (this.inventoryType == "New") {
                    this.data.vehicleWarranty.warrantyStartDate = null
                }

                if (this.inventoryType == "Certified") {
                    this.data.inventoryType = "Used"
                    this.data.vehicleWarranty.isCertified = true
                } else {
                    this.data.inventoryType = this.inventoryType
                    this.data.vehicleWarranty.isCertified = false
                }
            },
            async loadData() {
                this.previousVin = this.data.vin;
                this.previousStockNumber = this.data.stockNumber;
                if (this.data.inventoryType == "Used" && this.data.vehicleWarranty.isCertified == true) {
                    this.inventoryType = "Certified"
                } else {
                    this.inventoryType = this.data.inventoryType
                }
                if (!this.loJackResponse) this.loJackResponse = await this.data.loJackSearchByVin(false)
            },
            handleStockLookupBlur() {
                this.stockNumberLookup()
            },
            async handleVinDecode(clearStockNumber, refreshVehicle = false, clearBookValues = true) {
                const hasVinChangedOrNeedsVehicleRefresh = this.previousVin != this.data.vin || refreshVehicle;

                if (!this.validation.vin.$invalid && (hasVinChangedOrNeedsVehicleRefresh)) { // If the vin actually changed
                    if (clearBookValues) this.data.vehicle.clearBookValues(); // Since vin changed; lets clear book values.

                    await this.vinDecode(clearStockNumber, refreshVehicle)
                    await this.vAutoLookup()
                } else if (!this.data.vin) { // Else clear location/response
                    this.loJackResponse = null;
                    this.vehicleLocation = false;
                }
            },
            async vAutoLookup() {
                try {
                    if (!this.data.buyersOrderEnabled) return;

                    const response = await api.vAutoLookupByVin(this.data.vin);
                    if (response.data) {
                        const bookValues = CreateBookValues(new VAutoLookupResponse(response.data));

                        if (bookValues?.length) {
                            this.data.vehicle.upsertBookValues(bookValues ?? [])
                        }

                    }

                } catch(err) {
                    console.error(err)
                }
            },
            async stockNumberLookup() {

                // Clear any book value.
                this.data.vehicle.clearBookValues();

                if (!this.validation.stockNumber.$invalid && this.previousStockNumber != this.data.stockNumber) {
                    this.stockNumberLookupBusy = true
                    this.data.vehicleWarranty.warrantyStartDate = null
                    this.EventBus.emit("stockNumberLookupBusy", true);
                    try {
                        const response = (await api.stockNumberLookup(this.data.stockNumber))?.data;
                        if (response) {
                            const Table = response.table?.map(res => new StockNumberLookupResponse(res));
                            // eslint-disable-next-line no-console
                            if (Table && Table?.length > 0) {
                                const populateMyResult = async (result) => {
                                    if (result) {
                                        this.data.vin = result.vin;
                                        if (result.stocktype == "NEW") {
                                            this.inventoryType = "New"
                                            if(result.msrp && result.msrp > 0) {
                                                this.data.vehicle.getOrCreateManufacturer().retail = result.msrp;
                                            }

                                            if(result.invoice && result.invoice > 0) {
                                                this.data.vehicle.getOrCreateManufacturer().wholesale = result.invoice;
                                            }
                                            this.data.actualCost = result.actualCost;
                                            this.data.commissionCost = result.commissionCost;

                                        } else {
                                            if (result.certified == "Y")
                                                this.inventoryType = "Certified"
                                            else
                                                this.inventoryType = "Used"
                                        }
                                        this.changeInventoryHandle();
                                    }

                                    await this.handleVinDecode(false, false, false)
                                }

                                let result = null;
                                if (Table.length > 1) {
                                    $modal.open(modalStockVehicles, { name: 'modalStockVehicles', passedData: { stock: this.data.stockNumber, stockMatches: Table }, backdrop: false, postFunction: (data) => { populateMyResult(data) } });
                                } else {
                                    result = Table[0];
                                    populateMyResult(result);
                                }
                            }
                        }
                    } finally {
                        this.stockNumberLookupBusy = false;
                        this.EventBus.emit("stockNumberLookupBusy", false);
                    }
                }
            },
            async loJackSearchByVin(ignoreLock) {
                this.canPingVehicle = false
                this.vinLookupBusy = true
                this.loJackResponse = await this.data.loJackSearchByVin(ignoreLock)
                this.vinLookupBusy = false
                this.EventBus.emit("vinLookupBusy", false);
            },
            async vinDecode(clearStockNumber, refreshVehicle = false) {
                
                this.EventBus.emit('clearVehicleInformation');
                this.vinLookupBusy = true;
                this.EventBus.emit("populatingVehicleDetails", true);

                const response =  await api.vinLookup(this.data.vin, this.customVin, !refreshVehicle)
                try {
                    if (response) {
                            const vinInfo = (response.data?.payload) ? response.data.payload : null;
                            const isContentNew = response.data?.payload?.statusCode == ENUMS.HTTP_STATUS_CODE.REFRESH_CONTENT;

                            //Processed VIN != Submitted VIN
                            if (vinInfo && vinInfo.processedNotMatchSubmitted) {
                                if (isContentNew) {
                                    this.EventBus.emit("refreshBrandsAndModels", true);
                                }
                                const onContinue = () => {
                                    this.populateVINFields(response, clearStockNumber)
                                    this.runVINLookupDetails = true
                                };
                                const onCancel = () => {
                                    this.data.vin = null;
                                    this.populateVINFields(null, clearStockNumber);
                                }

                                this.processedNotSubmittedWarning(onContinue, onCancel);
                            }
                            else {
                                if (isContentNew) {
                                    this.EventBus.emit("refreshBrandsAndModels", true);
                                }
                                this.populateVINFields(response, clearStockNumber);
                                if (vinInfo == null) {
                                    this.EventBus.emit("vinFailed", true);
                                }
                                this.runVINLookupDetails = true
                            }
                        }
                        if (this.mscanAccount != null) {
                            const mscanresponse = await api.marketScan.getVehiclesByVIN(this.mscanAccount, this.data.vin, this.data.inventoryType == 'New');
                            if (mscanresponse && mscanresponse.data && mscanresponse.data.length == 1) {
                                this.data.marketScan.vehicleId = mscanresponse.data[0].id;
                            } else {
                                this.data.marketScan.vehicleId = null;
                            }
                        }

                        this.data.vehicleWarranty.updateUsingVINCarFaxLookup(response.data, this.data.carFax);
                        this.EventBus.emit('updateStyleId');
                } catch(err) {
                    console.error(err);
                    this.EventBus.emit("vinFailed", true);
                } finally {
                    this.EventBus.emit("populatingVehicleDetails", false);
                    this.isBusyEval = true
                    this.data.resetSurcharges().finally(() => {
                        this.isBusyEval = false
                    })
                    this.vinLookupBusy = false
                }         
            },
            async vinLookup() {
                try {
                    this.vinLookupBusy = true
                    this.EventBus.emit("vinLookupBusy", true);
                    const stateForLoJack = this.data.store?.storeState ?? this.settings.userStores.find(s => s.storeCode == this.data.storeCode).storeState
                    const zip = this.data.store?.storeZip ?? this.settings.userStores.find(s => s.storeCode == this.data.storeCode).storeZip
                    const response = await util.vinLookup(this.data.vin, this.data.storeCode, false, true, true, stateForLoJack, zip)
                    if (response) {
                        this.loJackResponse = response[1]?.data.content[0];
                        this.populateCarFaxDetails(response);
                    }

                    this.vinLookupBusy = false;
                    this.EventBus.emit("vinLookupBusy", false);
                } catch (e) {
                    console.error(e);
                    util.toastr('error', 'VIN Lookup', e);
                    this.vinLookupBusy = false;
                    this.EventBus.emit("vinLookupBusy", false);
                }
            },
            populateVINFields(response, clearStockNumber) {
                let vinInfo;
                if (response && response.data) {
                    if (this.previousVin) {
                        //Clear Vehicle Info Entered
                        if (clearStockNumber) this.data.stockNumber = null;
                        if (clearStockNumber) this.data.inventoryType = null;
                        this.data.vehicleMiles = null;
                    }
                    this.previousVin = this.data.vin
                    vinInfo = response.data.payload;
                    this.data.vehicle.updateUsingVINLookupResponse(vinInfo);
                    this.data.vehicleWarranty.updateUsingVINLookupResponse(vinInfo);
                    this.checkForOtherDealsForVin();
                    this.EventBus.emit('copiedWarrantyInfo', vinInfo.warrantyInfo ? true : false);
                    this.EventBus.emit('copiedVehicleExtraInfo', true);

                } else {
                    this.previousVin = this.data.vin
                    this.data.vehicle.updateUsingVINLookupResponse({});
                    this.data.vehicleWarranty.updateUsingVINLookupResponse({});
                    this.EventBus.emit('copiedWarrantyInfo', false);
                    this.EventBus.emit('copiedVehicleExtraInfo', false);
                }

                //UPDATE VEHICLE OIL IF FUEL TYPE IS ELECTRIC
                if (this.data.vehicle.fuelType == ENUMS.FUEL_TYPES.ELECTRIC) {
                    this.data.vehicleOil = ENUMS.OIL_TYPES.NA;
                }

            },
            populateCarFaxDetails(response) {
                // eslint-disable-next-line no-console
                console.log("Carfax Details", response);
                if (response && response?.[0]?.data && (response[0].data.responseStatus != null)) {
                    // console.log("response[0].data", response[0].data)
                    this.data.carFax = new FIMenuCarFax(response[0].data.fiMenuCarFax)
                    //this.data.vehicleWarranty.updateUsingVINCarFaxLookup(vinInfo, this.data.carFax);
                    this.EventBus.emit('copiedCarFaxInfo', true);
                    this.EventBus.emit('saveCarFaxResponse', response[0].data.responseStatus, response[0].data.responseDescription);

                } else {

                    console.warn("response[0].data is empty")
                    this.data.carFax = new FIMenuCarFax({})
                    this.EventBus.emit('copiedCarFaxInfo', false);
                    this.EventBus.emit('saveCarFaxResponse', null, null);
                }
            },
            carFaxOdometerWarning() {
                util.carFaxOdometerWarning(this.data.carFax.lastOdometerReading, this.data.vehicleMiles, this.settings.lookups.carFaxOdometerMaxOffset, this.$global.isAdminView, this.data);
            },

            checkForOtherDealsForVin() {
                if (!this.validation.vin.$invalid) {
                    api.fimenu.getDealsByVIN(this.data.vin).then((response) => {
                        if (response && response.data && response.data.length > 0) {
                            //exclude current deal from list
                            const currentdeal = this.data.dealNumber;
                            const currentstore = this.data.storeCode;
                            //load modal with deal grid to go to other deals.
                            let data = _.reject(response.data, function (d) { return d.dealNumber === currentdeal && d.storeCode === currentstore; });
                            const userStores = this.$global.stores.map((store) => store.storeCode);
                            const filteredData = data.filter((d) => {
                                return userStores.includes(d.storeCode)
                            });
                            data = filteredData;
                            if (data.length > 0) {
                                const title = 'Other Deals exist for this VIN';
                                const headers = [
                                    {
                                        name: "dealNumber", display: "Deal #", sortable: true, autoFilter: true,
                                        actions: [
                                            {
                                                name: 'open',
                                                type: 'route',
                                                text: (row) => row.dealNumber,
                                                action: (row) => {
                                                    ////go to that deal
                                                    return  { name: "customer",  query: { t: row.id, dk: row.dealKind } }
                                                },
                                                click: () => {
                                                    // Close modal.
                                                    $modal.closeAll();
                                                }
                                            }
                                        ]
                                    },
                                    { name: "storeCode", display: "Store", sortable: true, autoFilter: true },
                                    { name: "dealDate", display: "Deal Date", sortable: true, autoFilter: true, filter: this.$filters.prettyDate },
                                    { name: "fullName", display: "Customer", value: (a) => a.customer.fullName, sortable: true, autoFilter: true },
                                ]
                                $modal.open(modalRichTable, { name: 'modalRichTable', passedData: { title, data, headers }, backdrop: true });
                            }
                        }
                    }).finally(() => {
                        //anything?
                    });
                }
            },
            processedNotSubmittedWarning(onContinue, onCancel) {
                // When processed VIN# != submitted VIN#, create a warning modal
                $modal.open(modalInfo, {
                    name: "modalInfo",
                    passedData: {
                        info: "The VIN number you entered seems to be incorrect and is most likely a typo. Please confirm the VIN number from the actual vehicle dashboard/door jam and re-enter the VIN number.",
                        acceptText: "Proceed Anyways",
                        cancelText: "Re-Enter VIN",
                        cancelFunction: onCancel
                    },
                    backdrop: true,
                    postFunction: onContinue
                });
            }
        },
        components: {
            Panel: Panel,
            // IsBusyScreenComponent,
            InputTextbox,
            InputRichDropdown,
            InputNumber,
            InputDate,
            InfoIcon
        }
    };
</script>
<style>

    .loadingContainer {
        display: inline-flex;
        color: var(--main-color);
        font-size: larger;
    }

    .loJack-installed {
        margin-right: 10px;
        font-weight: 600;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 10px;
    }

        .loJack-installed .info-lojack {
            color: var(--print-color);
        }

        .loJack-installed button {
            height: 30px;
            border-radius: 4px;
            width: auto;
            display: flex;
            align-items: center;
            column-gap: 10px;
        }

        .loJack-installed .ping {
            color: var(--print-color);
        }

        .loJack-installed.loJackError {
            font-weight: 900;
            color: var(--error-color);
        }

        .loJack-installed.success {
            color: var(--success-color);
        }

    @-webkit-keyframes errorBlink {
        from, to {
            border-color: transparent
        }

        50% {
            border-color: var(--error-color)
        }
    }

    @keyframes errorBlink {
        from, to {
            border-color: transparent
        }

        50% {
            border-color: var(--error-color)
        }
    }

    .errorBlink {
        border: 1px solid black;
    }

    .errorBlink {
        -webkit-animation: errorBlink 1s step-end infinite;
        animation: errorBlink 1s step-end infinite;
    }
    /*.vehicle-info-container{
        margin-bottom: unset !important;
    }*/
    .iconClass {
        position: relative;
    }

    .statusPulse {
        animation: loJackPulse 1.5s infinite;
        position: absolute;
        top: 50%;
        height: 22px;
        left: 50%;
        width: 22px;
        translate: -50% -50%;
        pointer-events: none;
    }

    :root {
        --lojackgood: #38ac2080;
        --lojackbad: #c11b1b80;
        --lojacknuetral: var(--black-50percent);
    }

    @keyframes loJackPulse {
        0% {
            border-radius: 50%;
            transform: scale(0.99);
            background: 0 0 0 0 var(--status);
        }

        70% {
            border-radius: 50%;
            transform: scale(1);
            box-shadow: 0 0 0 3px var(--status);
        }

        100% {
            border-radius: 50%;
            transform: scale(0.99);
            box-shadow: 0 0 0 0 var(--status);
        }
    }
</style>