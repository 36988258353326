<template>
    <Panel title="Pencil" class="panel-desking">
        <template v-slot:header>
            <div class="panel-buttons">
                <button class="button button-edit" :disabled="isBusy || fimenu.marketScan == null || fimenu.marketScan.marketScans == null || fimenu.marketScan.marketScans.length == 0" @click="openPriorScans()"><i class="far fa-folder-open"></i> Previous</button>
            </div>
        </template>
        <div :style="$grid('1')">
            <Panel title="Scan Options">
                <template v-slot:header>
                    <div class="panel-buttons">
                        <InputCheckbox v-model="showPreloads" label="Show Preloads"></InputCheckbox>
                        <button v-if="development" class="button button-edit" :disabled="!scanRequest" @click="viewRequestJSON()"><i class="fas fa-binoculars"></i> Request</button>
                        <button class="button button-edit" :disabled="isBusy || fimenu.marketScan == null || fimenu.marketScan.vehicleId == null || !buyersOrderCash || !buyersOrderRetail || !buyersOrderLease" @click="runScan()" :class="{'pulsing': !isBusy && rescanRequired}"><i class="fas fa-sync"></i> Run Scan</button>
                    </div>
                </template>
                <div :style="$grid('1')">
                    <PanelPreloads :fimenu="fimenu" :validation="validation" :preselected="true" v-show="showPreloads"></PanelPreloads>
                </div>
                <div :style="$grid('1-1-1')">

                    <div v-if="buyersOrderCash">
                        <!--<div class="scan-type">
            <fieldset class="scan-type">
                <InputRadio label="Profit" :valueMatch="0" v-model="scanType"></InputRadio>
                <InputRadio label="Selling Price" :valueMatch="1" v-model="scanType"></InputRadio>
                <InputRadio label="Payment" :valueMatch="2" v-model="scanType"></InputRadio>
            </fieldset>
        </div>-->
                        <div class="buyers-order-row subtotal-container" :style="$grid('1-125px', '5px')">
                            <div>MSRP</div>
                            <InputCurrency v-model:saturn="fimenu.vehicle.getOrCreateManufacturer().retail" :precision="2" placeholder="placeholder" iconSide="left" :disabled="true"></InputCurrency>
                            <div>Selling Price</div>
                            <InputCurrency @change="updateSellingPrice()" v-model:saturn="desiredValue" :precision="2" placeholder="placeholder" iconSide="left"></InputCurrency>
                            <div>Front Profit</div>
                            <InputCurrency @change="updateProfit()" v-model:saturn="profit" :precision="2" placeholder="placeholder" iconSide="left"></InputCurrency>
                        </div>
                        <BuyersOrderBucket v-if="buyersOrderCash" :bo="buyersOrderCash" hideProfits="true" bucketType="Trades" />
                        <BuyersOrderBucket v-if="buyersOrderCash" :bo="buyersOrderCash" hideProfits="true" bucketType="Products" />
                        <BuyersOrderBucket v-if="buyersOrderCash" :bo="buyersOrderCash" hideProfits="true" bucketType="Fees" />
                        <BuyersOrderBucket v-if="buyersOrderCash" :bo="buyersOrderCash" hideProfits="true" bucketType="Additions" />
                        <div v-if="buyersOrderCash" class="buyers-order-row subtotal-container" :style="$grid('1-125px', '5px')">
                            <div>Taxes</div>
                            <InputCurrency :saturn="buyersOrderCash.getTaxTotal()"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left">
                            </InputCurrency>
                            <div>Out the Door</div>
                            <InputCurrency :saturn="buyersOrderCash.getTotal()"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left">
                            </InputCurrency>

                        </div>

                    </div>
                    <PanelMini title="Retail" v-if="buyersOrderRetail">
                        <div :style="$grid('1-1-1-1')">
                            <div v-for="(c, index) in retailCash" :key="index">
                                <div class="smallcheckbox"><InputCheckbox :disabled="index==0 || (index > 0 && !retailCash[index-1].enabled)" v-model="c.enabled" :label="checkLabel(index)"></InputCheckbox></div>
                                <InputCurrency :disabled="!c.enabled" v-model:saturn="c.amount" :precision="0">
                                </InputCurrency>
                            </div>
                        </div>
                        <div class="fillrow"></div>
                        <BuyersOrderBucket v-if="buyersOrderRetail" :bo="buyersOrderRetail" hideProfits="true" bucketType="Trades" />
                        <BuyersOrderBucket v-if="buyersOrderRetail" :bo="buyersOrderRetail" hideProfits="true" bucketType="Products" />
                        <BuyersOrderBucket v-if="buyersOrderRetail" :bo="buyersOrderRetail" hideProfits="true" bucketType="Fees" />
                        <BuyersOrderBucket v-if="buyersOrderRetail" :bo="buyersOrderRetail" hideProfits="true" bucketType="Additions" />
                        <div v-if="buyersOrderRetail" class="buyers-order-row subtotal-container" :style="$grid('1-125px', '5px')">
                            <div>Taxes</div>
                            <InputCurrency :saturn="buyersOrderRetail.getTaxTotal()"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left">
                            </InputCurrency>
                            <div>Out the Door</div>
                            <InputCurrency :saturn="buyersOrderRetail.getTotal()"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left">
                            </InputCurrency>

                        </div>
                    </PanelMini>
                    <PanelMini title="Lease" v-if="buyersOrderLease">
                        <div :style="$grid('1-1-1-1')">
                            <div v-for="(c, index) in leaseCash" :key="index">
                                <div class="smallcheckbox"><InputCheckbox :disabled="index==0 || (index > 0 && !leaseCash[index-1].enabled)" v-model="c.enabled" :label="checkLabel(index)"></InputCheckbox></div>
                                <InputCurrency :disabled="!c.enabled" v-model:saturn="c.amount" :precision="0">
                                </InputCurrency>
                            </div>
                        </div>
                        <div class="fillrow"></div>
                        <BuyersOrderBucket v-if="buyersOrderLease" :bo="buyersOrderLease" hideProfits="true" bucketType="Trades" />
                        <BuyersOrderBucket v-if="buyersOrderLease" :bo="buyersOrderLease" hideProfits="true" bucketType="Products" />
                        <BuyersOrderBucket v-if="buyersOrderLease" :bo="buyersOrderLease" hideProfits="true" bucketType="Fees" />
                        <BuyersOrderBucket v-if="buyersOrderLease" :bo="buyersOrderLease" hideProfits="true" bucketType="Additions" />
                        <div v-if="buyersOrderLease" class="buyers-order-row subtotal-container" :style="$grid('1-125px', '5px')">
                            <div>Taxes</div>
                            <InputCurrency :saturn="buyersOrderLease.getTaxTotal()"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left">
                            </InputCurrency>
                            <div>Out the Door</div>
                            <InputCurrency :saturn="buyersOrderLease.getTotal()"
                                           :disabled="true"
                                           :precision="2"
                                           iconSide="left">
                            </InputCurrency>

                        </div>
                    </PanelMini>
                </div>
            </Panel>
            <Panel title="Scan Results" v-if="scanResponse">
                <template v-slot:header>
                    <div class="panel-buttons" v-if="development">
                        <button class="button button-edit" :disabled="!scanResponse" @click="viewResponseJSON()"><i class="fas fa-binoculars"></i> Response</button>
                    </div>
                </template>
                <div :style="$grid('1-1-1')">
                    <Panel title="Cash Purchase">
                        <div class="figurerow">
                            <div class="figurelabel">MSRP:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.vehicleMSRP) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Selling Price:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.sellingPrice) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Rebate:</div>
                            <div class="figurevalue">
                                <TooltipComponent v-if="scanResponse.mPencil.cashPurchaseOption.rebate"
                                                  direction="top"
                                                  :tooltip="displayRebateTooltip(scanResponse.mPencil.cashPurchaseOption.appliedRebate)">
                                    <template #showFromOutside>
                                        {{ formatPrice(scanResponse.mPencil.cashPurchaseOption.rebate) }}
                                    </template>
                                </TooltipComponent>
                            </div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Trade Allowance:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.tradeAllowance) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Trade Payoff:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.tradePayoff) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Registration Fee:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.registrationFee) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Tire Fee:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.tireFee) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Custom Fees:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.customFees) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Other Fees:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.otherFees) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Sales Tax:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.salesTax) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Taxes &amp; Fees:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.taxesAndFees) }}</div>
                        </div>
                        <div class="figurerow">
                            <div class="figurelabel">Total Cash Due:</div>
                            <div class="figurevalue">{{ formatPrice(scanResponse.mPencil.cashPurchaseOption.totalCashDue) }}</div>
                        </div>
                    </Panel>
                    <Panel title="Retail">
                        <div :style="$grid('.5-1-1-1-1')" class="header-row">
                            <div>Term</div>
                            <div v-for="(r,index) in retailCash" :key="index">
                                <div v-if="r.enabled">{{ formatDownpayment(r.amount) }}</div>
                            </div>
                        </div>
                        <div v-for="(t, index) in scanResponse.retail.terms" :key="index">
                            <div v-if="t.programs[0].payment" :style="$grid('.5-1-1-1-1')" class="value-row">
                                <div><InputCheckbox :label="t.term" v-model="retailTerms[index].selected"></InputCheckbox></div>
                                <div v-for="(p,pindex) in t.programs" :key="pindex" class="amountvalue">
                                    <TooltipComponent v-if="p.payment"
                                                      direction="top"
                                                      :tooltip="displayToolTip(p)">
                                        <template #showFromOutside>
                                            <div>{{ formatPayment(p.payment) }}</div>
                                        </template>
                                    </TooltipComponent>
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Panel title="Lease">
                        <div :style="$grid('.5-1-1-1-1')" class="header-row">
                            <div>Term</div>
                            <div v-for="(l,index) in leaseCash" :key="index">
                                <div v-if="l.enabled">{{ formatDownpayment(l.amount) }}</div>
                            </div>
                        </div>
                        <div v-for="(t, index) in scanResponse.lease.terms" :key="index">
                            <div v-if="t.programs[0].payment" :style="$grid('.5-1-1-1-1')" class="value-row">
                                <div><InputCheckbox :label="t.term" v-model="leaseTerms[index].selected"></InputCheckbox></div>
                                <div v-for="(p,pindex) in t.programs" :key="pindex" class="amountvalue">
                                    <TooltipComponent v-if="p.payment"
                                                      direction="top"
                                                      :tooltip="displayToolTip(p)">
                                        <template #showFromOutside>
                                            <div>{{ formatPayment(p.payment) }}</div>
                                        </template>
                                    </TooltipComponent>
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
            </Panel>
        </div>
    </Panel>
</template>

<script>
    import api from '@core/services/api'
    import util from '@core/services/util';
    import $modal from '@core/services/modal'
    import modalPre from '@/modals/modalPre.vue'
    import settings from 'settings'
    import FIMenu from '@core/classes/FIMenu'
    import FIMenuBuyersOrder from '@core/classes/FIMenuBuyersOrder'
    import FIMenuBuyersOrderRequest from '@core/classes/FIMenuBuyersOrderRequest'
    import ENUMS from "@core/classes/Enums"
    import Panel from '@core/components/Panel.vue'
    import PanelMini from '@/components/PanelMini.vue'
    import PanelPreloads from '@/components/PanelPreloads.vue'
    import InputCurrency from '@core/components/InputCurrency.vue'
    import InputCheckbox from '@core/components/InputCheckbox.vue'
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    import BuyersOrderBucket from '@/components/buyers-order/BuyersOrderBucket.vue'

    export default {
        name: "PanelDesking",
        props: ['fimenu', 'validation', 'mscanAccount'],
        data() {
            return {
                showPreloads: false,
                isBusy: true,
                leaseTerms: [
                    { term: 12, selected: false },
                    { term: 15, selected: false },
                    { term: 18, selected: false },
                    { term: 21, selected: false },
                    { term: 24, selected: false },
                    { term: 25, selected: false },
                    { term: 26, selected: false },
                    { term: 27, selected: false },
                    { term: 28, selected: false },
                    { term: 30, selected: false },
                    { term: 31, selected: false },
                    { term: 32, selected: false },
                    { term: 33, selected: false },
                    { term: 34, selected: false },
                    { term: 36, selected: false },
                    { term: 37, selected: false },
                    { term: 38, selected: false },
                    { term: 39, selected: false },
                    { term: 40, selected: false },
                    { term: 41, selected: false },
                    { term: 42, selected: false },
                    { term: 43, selected: false },
                    { term: 44, selected: false },
                    { term: 45, selected: false },
                    { term: 46, selected: false },
                    { term: 48, selected: false },
                    { term: 49, selected: false },
                    { term: 50, selected: false },
                    { term: 51, selected: false },
                    { term: 52, selected: false },
                    { term: 54, selected: false },
                    { term: 57, selected: false },
                    { term: 60, selected: false },
                    { term: 66, selected: false },
                    { term: 72, selected: false },
                ],
                leaseCash: [
                    { amount: 0, enabled: true },
                    { amount: 500, enabled: false },
                    { amount: 1000, enabled: false },
                    { amount: 2000, enabled: false },
                ],
                retailTerms: [
                    { term: 12, selected: false },
                    { term: 18, selected: false },
                    { term: 24, selected: false },
                    { term: 30, selected: false },
                    { term: 36, selected: false },
                    { term: 42, selected: false },
                    { term: 48, selected: false },
                    { term: 54, selected: false },
                    { term: 60, selected: false },
                    { term: 63, selected: false },
                    { term: 66, selected: false },
                    { term: 72, selected: false },
                    { term: 75, selected: false },
                    { term: 78, selected: false },
                    { term: 84, selected: false },
                    { term: 88, selected: false },
                    { term: 90, selected: false },
                    { term: 96, selected: false },
                ],
                retailCash: [
                    { amount: 0, enabled: true },
                    { amount: 500, enabled: false },
                    { amount: 1000, enabled: false },
                    { amount: 2000, enabled: false }
                ],
                rescanRequired: false,
                scanType: 1,
                desiredValue: 0,
                profit: 0,
                scanRequest: null,
                scanResponse: null,
                buyersOrderCash: null,
                buyersOrderRetail: null,
                buyersOrderLease: null,
                fimenuCash: null,
                fimenuRetail: null,
                fimenuLease: null,
            }
        },
        computed: {
            util() {
                return util;
            },
            settings() {
                return settings;
            },
            ENUMS() {
                return ENUMS;
            },
            development() {
                return this.$route.query.debug == 'true' || settings.environmentName != 'PRODUCTION';
            },
            cost() {
                return this.fimenu && this.fimenu.actualCost ? this.fimenu.actualCost : this.fimenu && this.fimenu.getInvoice() ? this.fimenu.getInvoice() : null;
            },

        },
        async created() {
            this.isBusy = true;
            //do stuff
            this.desiredValue = this.cost;
            this.buyersOrderSetup();
            this.isBusy = false;
        },

        methods: {
            displayRebateTooltip(appliedRebate) {
                let tooltip = [];
                if (appliedRebate && appliedRebate.length > 0) {
                    tooltip = appliedRebate.map(r => {
                        return r.name + ": " + this.formatPrice(r.value);
                    })
                }
                return tooltip;
            },
            displayToolTip(p) {
                const tooltip = [];
                tooltip.push(p.programDescription)
                //amounts
                let amounts = '';
                if (p.amountFinanced) amounts += (" Financed: " + this.formatPrice(p.amountFinanced));
                if (p.maxAdvance) amounts += (" Max Advance: " + this.formatPrice(p.maxAdvance));
                if (p.acquisitionFee) amounts += (" Acquisition: " + this.formatPrice(p.acquisitionFee));
                if (p.inceptionFeesTaxes) amounts += (" Inception Fees Taxes: " + this.formatPrice(p.inceptionFeesTaxes));
                if (p.price) amounts += (" Price: " + this.formatPrice(p.price));
                if (p.payment) amounts += (" Payment: " + this.formatPrice(p.payment));
                tooltip.push(amounts);
                //lender information
                let lenderInfo = '';
                if (p.lender) lenderInfo += (" Lender: " + p.lender);
                if (p.rate) lenderInfo += (" Rate: " + p.rate);
                if (p.buyRate) lenderInfo += (" Buy Rate: " + p.buyRate);
                if (p.sellRate) lenderInfo += (" Sell Rate: " + p.sellRate);
                if (p.paidReserve) lenderInfo += (" Paid Reserve: " + this.formatPrice(p.paidReserve));
                tooltip.push(lenderInfo);

                return tooltip;
            },
            async buyersOrderSetup() {
                this.isBusy = true;
                this.fimenuCash = new FIMenu(util.clone(this.fimenu));
                this.fimenuRetail = new FIMenu(util.clone(this.fimenu));
                this.fimenuLease = new FIMenu(util.clone(this.fimenu));
                this.fimenuCash.dealType = 'Cash';
                this.fimenuRetail.dealType = 'Finance';
                this.fimenuLease.dealType = 'Lease';
                let fiMenuBuyersOrderRequest = new FIMenuBuyersOrderRequest(this.fimenuCash);
                let response = await api.fimenu.getBuyersOrder(fiMenuBuyersOrderRequest);
                this.buyersOrderCash = new FIMenuBuyersOrder(response.data.buyersOrder);
                this.buyersOrderCash.sellingPrice = this.cost;
                this.buyersOrderRecalculate(this.buyersOrderCash, this.fimenuCash);
                /*eslint-disable no-console*/
                console.log('cash BO', this.buyersOrderCash);
                fiMenuBuyersOrderRequest = new FIMenuBuyersOrderRequest(this.fimenuRetail);
                response = await api.fimenu.getBuyersOrder(fiMenuBuyersOrderRequest)
                this.buyersOrderRetail = new FIMenuBuyersOrder(response.data.buyersOrder);
                this.buyersOrderRetail.sellingPrice = this.cost;
                this.buyersOrderRecalculate(this.buyersOrderRetail, this.fimenuRetail);
                console.log('finance BO', this.buyersOrderRetail);
                fiMenuBuyersOrderRequest = new FIMenuBuyersOrderRequest(this.fimenuLease);
                response = await api.fimenu.getBuyersOrder(fiMenuBuyersOrderRequest)
                this.buyersOrderLease = new FIMenuBuyersOrder(response.data.buyersOrder);
                this.buyersOrderLease.sellingPrice = this.cost;
                this.buyersOrderRecalculate(this.buyersOrderLease, this.fimenuLease);
                console.log('lease BO', this.buyersOrderLease);
                console.log('this.buyersOrderCash.sellingPrice', this.buyersOrderCash.sellingPrice);
                /*eslint-enable*/
                this.desiredValue = this.buyersOrderCash.sellingPrice;
                this.isBusy = false;
            },
            async updateSellingPrice() {
                this.profit = this.desiredValue - this.cost;
                if (this.buyersOrderCash) this.buyersOrderCash.sellingPrice = this.desiredValue;
                if (this.buyersOrderRetail) this.buyersOrderRetail.sellingPrice = this.desiredValue;
                if (this.buyersOrderLease) this.buyersOrderLease.sellingPrice = this.desiredValue;
                await this.buyersOrderRecalculate(this.buyersOrderCash, this.fimenuCash);
                await this.buyersOrderRecalculate(this.buyersOrderRetail, this.fimenuRetail);
                await this.buyersOrderRecalculate(this.buyersOrderLease, this.fimenuLease);
            },
            async updateProfit() {
                this.desiredValue = this.profit + this.cost;
                if (this.buyersOrderCash) this.buyersOrderCash.sellingPrice = this.desiredValue;
                if (this.buyersOrderRetail) this.buyersOrderRetail.sellingPrice = this.desiredValue;
                if (this.buyersOrderLease) this.buyersOrderLease.sellingPrice = this.desiredValue;
                await this.buyersOrderRecalculate(this.buyersOrderCash, this.fimenuCash);
                await this.buyersOrderRecalculate(this.buyersOrderRetail, this.fimenuRetail);
                await this.buyersOrderRecalculate(this.buyersOrderLease, this.fimenuLease);
            },
            async buyersOrderRecalculate(buyersOrder, fimenu) {
                if (buyersOrder) {
                    // eslint-disable-next-line no-console
                    console.log('recalculating buyers order');
                    buyersOrder.recalculateBuyersOrder(fimenu, null, null, 'BASE');
                }
            },
            async runScan() {
                //construct a request and send it up to the API
                this.scanResponse = null;
                let autoRebate = true;
                let autoRebateObject = null;
                let rebatesObject = null;
                //check if I have rebates specifically already
                if (this.fimenu.rebates && this.fimenu.rebates.length > 0) {
                    autoRebate = false;
                    //populate the rebates container object
                    rebatesObject = {};
                    rebatesObject.DealerZIP = this.fimenu.store.storeZip;
                    rebatesObject.ZIP = this.fimenu.customer.zip;
                    rebatesObject.RebateValues = this.fimenu.rebates.map(r => {
                        return {
                            "RebateID": r.id,
                            "Value": r.amount
                        }
                    })
                } else {
                    autoRebateObject = {};
                    autoRebateObject.DealerZIP = this.fimenu.store.storeZip;
                    autoRebateObject.ZIP = this.fimenu.customer.zip;
                    autoRebateObject.VIN = this.fimenu.vin;
                }
                /*eslint-disable no-console*/
                console.log('rebatesObject', rebatesObject);
                console.log('autoRebateObject', autoRebateObject);
                /*eslint-enable*/
                this.scanRequest = {};
                if (autoRebate) {
                    this.scanRequest.AutoRebateParams = autoRebateObject;
                } else {
                    this.scanRequest.Rebates = rebatesObject;
                }
                this.scanRequest.CreditScore = this.fimenu.customer.creditScore ?? 0;
                this.scanRequest.DesiredValue = this.desiredValue;
                this.scanRequest.LeasePart = {};
                this.scanRequest.LeasePart.AnnualMileage = this.fimenu.vehicle.milesPerYear;
                this.scanRequest.LeasePart.CustomerCash = this.leaseCash[0].amount;
                this.scanRequest.LeasePart.CustomFee = [];

                let leaseFees = this.buyersOrderLease.getBuckets("Fees", null);
                leaseFees = leaseFees.filter(b => b.bucketTotal > 0);
                // eslint-disable-next-line no-console
                console.log('leaseFees', leaseFees);
                if (leaseFees && leaseFees.length > 0) {
                    this.scanRequest.LeasePart.CustomFee = this.mapFees(leaseFees)
                }
                this.scanRequest.RetailPart = {};
                this.scanRequest.RetailPart.CustomerCash = this.retailCash[0].amount;
                this.scanRequest.RetailPart.CustomFee = [];
                let retailFees = this.buyersOrderRetail.getBuckets("Fees", null);
                retailFees = retailFees.filter(b => b.bucketTotal > 0);
                // eslint-disable-next-line no-console
                console.log('retailFees', retailFees);
                if (retailFees && retailFees.length > 0) {
                    this.scanRequest.RetailPart.CustomFee = this.mapFees(retailFees);
                }

                this.scanRequest.ScanMode = 2;
                this.scanRequest.ScanType = this.scanType;
                this.scanRequest.Location = {};
                this.scanRequest.Location.AutoTaxRate = true;
                this.scanRequest.Location.CustomerZIP = this.fimenu.customer.zip;
                this.scanRequest.Location.DealershipZIP = this.fimenu.store.storeZip;
                this.scanRequest.Vehicle = {};
                this.scanRequest.Vehicle.CurrentMileage = this.fimenu.vehicleMiles;
                this.scanRequest.Vehicle.TotalMSRP = this.fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail;
                this.scanRequest.Vehicle.BaseMSRPAmount = this.fimenu.getMSRPOrHighestOrDefaultRetailBookValue()?.retail;
                this.scanRequest.Vehicle.ID = this.fimenu.marketScan.vehicleId;
                this.scanRequest.Vehicle.TotalDealerCost = this.fimenu.getInvoice();
                if (this.fimenu.tradeIns && this.fimenu.tradeIns.length > 0) {
                    this.scanRequest.TradeIns = this.fimenu.tradeIns.map(t => {
                        const trade = {};
                        trade.VIN = t.vin;
                        trade.Year = t.modelYear;
                        trade.Make = t.make;
                        trade.Model = t.model;
                        trade.Mileage = t.mileage;
                        if (t.type == 0) {
                            trade.ActualCashValuePurchased = t.actualCashValue;
                            trade.AllowancePurchased = t.allowance;
                            trade.PayOffPurchased = t.payoff();
                        } else {
                            trade.ActualCashValueLeased = t.actualCashValue;
                            trade.AllowanceLeased = t.allowance;
                            trade.PayOffLeased = t.payoff();
                        }
                        return trade;
                    })
                }

                this.scanRequest.mPencil = {};
                this.scanRequest.mPencil.LeasePart = {};
                this.scanRequest.mPencil.RetailPart = {};
                this.scanRequest.mPencil.LeasePart.Cash = this.leaseCash.map(l => {
                    return l.enabled ? l.amount : -1;
                });
                this.scanRequest.mPencil.LeasePart.Term = this.leaseTerms.map(l => {
                    return l.term;
                });
                this.scanRequest.mPencil.RetailPart.Cash = this.retailCash.map(r => {
                    return r.enabled ? r.amount : -1;
                });
                this.scanRequest.mPencil.RetailPart.Term = this.retailTerms.map(r => {
                    return r.term;
                });
                this.scanRequest.UseConfig = {};
                /*eslint-disable no-console*/
                console.log('request', this.scanRequest);
                await api.marketScan.runScan(this.mscanAccount, this.scanRequest).then((response) => {
                    console.log('runScan response', response)
                    if (response && response.data) {
                        this.scanResponse = util.clone(response.data);
                        this.calculateBestPayments();
                    }
                })
                .catch(err => console.error(err))
                /*eslint-enable*/
            },
            mapFees(fees) {
                return fees.map(f => {
                    const fee = {};
                    fee.Description = f.name;
                    fee.ID = f.titleId;
                    fee.Taxable = f.taxIndex > 0 ? true : false;
                    fee.Value = f.bucketTotal;
                    fee.StrongType = 0;
                    switch (f.category) {
                        case "Registration":
                            fee.Type = "RegistrationFee";
                            break;
                        case "Dealer":
                            if (f.name.toLowerCase().includes("temp tag")) {
                                fee.Type = "TemporaryTagFee";
                            } else if (f.name.toLowerCase().includes("doc stamp")) {
                                fee.Type = "DocumentationFee";
                            } else {
                                fee.Type = "DealerProcessingFee";
                            }
                            break;
                        default:
                            fee.Type = "";
                            break;
                    }
                    return fee;
                })
            },
            calculateBestPayments() {
                //method to rank the payments in this.scanResponse
            },
            formatPrice(value) {
                return util.formatPrice(value);
            },
            formatDownpayment(value) {
                return util.formatPrice(value, 0);
            },
            formatPayment(value) {
                return util.formatPrice(Math.ceil(value), 0)
            },
            viewResponseJSON() {
                $modal.open(modalPre, { name: 'modalPre', passedData: { data: { response: this.scanResponse } }, backdrop: true });
            },
            viewRequestJSON() {
                $modal.open(modalPre, { name: 'modalPre', passedData: { data: { request: this.scanRequest } }, backdrop: true });
            },
            openPriorScans() {
                //do something
            },
            checkLabel(index) {
                return '$ Down ' + index.toString();
            }
        },
        components: {
            //AutocompleteComponent,
            Panel,
            PanelMini,
            PanelPreloads,
            InputCurrency,
            //InputRadio,
            InputCheckbox,
            //JsonViewer,
            TooltipComponent,
            BuyersOrderBucket,
        }
    };
</script>
<style>
    .button-small {
        padding: 0px;
        margin-left: 1px;
        border-radius: 5px;
        width: 35px;
    }

    .smallcheckbox {
        font-size: x-small !important;
    }

    .figurerow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    /*
    .figurelabel {
    }

    .figurevalue {
    }
    */

    .header-row {
        font-weight: bold;
        justify-content: flex-end;
    }

    .value-row {
        justify-content: flex-end;
        align-items: center;
    }

    .amountvalue {
        text-align: end;
    }

    .panel-desking .tooltip-container {
        width: 100%
    }

    .fillrow {
        height: 33px;
    }
</style>