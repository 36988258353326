<template>
    <!-- <fimenu v-if="true" :recordingFunctions="recordingFunctions" :recordingDetails="recordingDetails" :recordingPermissions="recPermissions" /> -->

    <main class="page customer">
        <!--<div v-if="global.isAdminView" class="admin-view-data">
            Customer Id: {{ customer.id }}
            <br/>
            Editing CustomerId: {{ customer.id }}
        </div>-->
        <IsBusyScreenComponent v-if="isLoadingInitialSetup || isCreatingDeal" />
        <TabMenu v-if="!isLoadingInitialSetup" :tabs="tabs" :initialTab="tabs[0]" ref="tabMenu" :beforeChangeFunc="beforeTabChange" queryParam="t" @changeTab="afterTabChange">
            <template v-slot:[`tab-head-${tabs[0]}`]>
                <span>Customer(s)</span>
                <button @click.stop="handleGoBack" class="close-button no-shadow deals">
                    <i class="far fa-times-circle"></i>
                </button>
            </template>

            <template v-for="(deal, index) in deals" v-slot:[`tab-head-${deal.id}`] :key="index" >
                <div class="closeTab">
                    <span :key="index+1">{{ deal.dealNumber }}</span>
                    <button @click.stop="() => deleteDealConfimation(deal)" class="close-button no-shadow deals">
                        <i class="far fa-times-circle"></i>
                    </button>
                </div>
            </template>

            <template v-for="(deal, index) in deals" v-slot:[`tab-panel-${deal.id}`] :key="index">
                <IsBusySectionComponent v-if="isLoadingDeal || !fimenu" />
                <FIMenu v-else
                        v-bind:key="index"
                        :meetingsWithWaitingCustomers="meetingsWithWaitingCustomers"
                        v-model:fimenu="fimenu"
                        ref="fimenudeal" />
            </template>

            <template v-slot:[`tab-panel-${tabs[0]}`]>
                <CustomerDetailsHeader :customer="customer"
                                       v-model:selectedStoreCode="selectedStoreCode"
                                       v-model:selectedCustomerId="selectedCustomerId"
                                       v-model:selectedStore="selectedStore"
                                       :loadData="loadSelectedCustomerInformation"
                                       v-model:availableCustomers="availableCustomers"
                                       :customerStores="customerStores"
                                       v-model:isLoading="isLoading"
                                       :metadata="metadata"
                                       :createCustomer="createCustomer"
                                       :isCustomerCreating="isCustomerCreating"
                                       :addCustomersToAvailableCustomers="addCustomersToAvailableCustomers" />

                <CustomerDetails :selectedStoreCode="selectedStoreCode"
                                 :selectedStore="selectedStore"
                                 v-model:customer="customer"
                                 :metadata="metadata"
                                 :selectedCustomerId="selectedCustomerId"
                                 :customerStores="customerStores"
                                 :createCustomer="createCustomer"
                                 :deals="deals"
                                 :maxDealsInTab="maxDealsInTab"
                                 :isLoading="isLoading || isLoadingTabChange"
                                 :openDeal="openDeal"
                                 :isCustomerCreating="isCustomerCreating" />

                <CustomerDetailsFooter v-model:customer="customer"
                                       :selectedStoreCode="selectedStoreCode"
                                       :selectedStore="selectedStore"
                                       v-model:isCreatingDeal="isCreatingDeal"
                                       :metadata="metadata"
                                       :deals="deals"
                                       :maxDealsInTab="maxDealsInTab"
                                       :createDeal="handleCreateDeal" />

            </template>
        </TabMenu>
    </main>
</template>

<script lang="ts">
    import { broadcastCallbacks, broadcastSubscribeEvents, broadcastUnsubscribeEvents } from "@core/helpers/broadcast-helper";
    import { CreateDeal, IsDealOpenable } from "@core/helpers/deals-helper";
    import { MeetingDetails, MeetingUser } from "@core/interfaces/MeetingTypes";
    import util, { EventBusCore } from "@core/services/util";
    import $modal from "@core/services/modal";
    import api from "@core/services/api";
    import auth from '@core/services/auth';
    import { AxiosResponse } from 'axios';
    import { defineComponent } from 'vue';
    import ENUMS from "@core/classes/Enums";
    import FIMenu from "@/components/FIMenu.vue";
    import FIMenuCustomer from "@core/classes/FIMenuCustomer";
    import LogRocket from 'logrocket';
    import modalLockoutByInactivity from '@/modals/modalLockoutByInactivity.vue'
    import settings from "settings";
    import TabMenu from "@/components/TabMenu.vue";

    //import AccordionCustomerQuotes from "@core/components/accordions/AccordionCustomerQuotes";
    import CustomerDetails from "@/components/CustomerDetails.vue";
    import CustomerDetailsFooter from "@/components/CustomerDetailsFooter.vue"
    import CustomerDetailsHeader from '@/components/CustomerDetailsHeader.vue'
    import CustomerSearchResult from '@core/classes/CustomerSearchResult'
    import { DealKind } from '@core/classes/SharedEnums'
    import FIMenuClass from "@core/classes/FIMenu";
    import { GetDeal } from "@core/helpers/deals-helper";
    import IsBusyScreenComponent from '@core/components/IsBusyScreenComponent.vue'
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue'
    import Store from '@core/classes/Store/Store';

    interface dealsData {
        storeCode: string,
        dealNumber: string,
        id: string, // Deal ID.
        dealKind: DealKind, // Either QUOTE or DEAL.
        isDealCreatedInDB: boolean
    }

    interface PersonType {
        code: boolean;
        description: string;
    }

    interface customersData {
        id: string,
        fullName: string,
        storeCodes: string[]
    }

    interface ComponentData {
        tabs: string[],
        isZipLookupBusy: boolean,
        editingCustomerId: string | null,
        selectedCustomerId: string | null,
        customer: FIMenuCustomer // Active customer in the page. Could be the main cusomter or could be the edidint customer.
        personTypes: PersonType[],
        isLoading: boolean,
        isLoadingInitialSetup: boolean,
        isCreatingDeal: boolean,
        isCreating: boolean,
        metadata: {
            storeCode: string | null,
            isSpectator: boolean,
            store: Store | null,
            buyersOrderEnabled: boolean,
        },
        selectedStoreCode: string | null,
        customerStores: string[],
        selectedStore: null | Store,
        legalTermsToBeAgreedTo: [],
        deals: dealsData[],
        maxDealsInTab: number,
        availableCustomers: customersData[],
        isLoadingTabChange: boolean,
        fimenu: FIMenuClass | null,
        isLoadingDeal: boolean,
        meetingsWithWaitingCustomers: any[],
        broadcastEvents: {
            event: string, 
            callback: (value: string) => void
        }[],
        inactivityTime: number,
        lockedOut: boolean,
        timing: any,
        inactivityApplyTime: number
    }

    export default defineComponent({
        name: "Customer",
        props: [],
        data(): ComponentData {
            return {
                tabs: ["customer"],
                isZipLookupBusy: false,
                editingCustomerId: null,
                selectedCustomerId: null,
                customer: new FIMenuCustomer(), // Active customer in the page. Could be the main cusomter or could be the edidint customer.
                personTypes: [
                    { code: false, description: "Person" },
                    { code: true, description: "Company" }
                ],
                isLoading: false,
                isLoadingInitialSetup: false,
                isCreatingDeal: false,
                isCreating: false,
                metadata: {
                    storeCode: null,
                    isSpectator: false,
                    store: null,
                    buyersOrderEnabled: false,
                },
                selectedStoreCode: null,
                customerStores: [],
                selectedStore: null,
                legalTermsToBeAgreedTo: [],
                deals: [],
                maxDealsInTab: 5,
                availableCustomers: [],
                isLoadingTabChange: false,
                fimenu: null,
                isLoadingDeal: false,
                meetingsWithWaitingCustomers: [],
                broadcastEvents: [
                    {
                        event: ENUMS.EVENT_EMITS.BROADCAST.CUSTOMER_JOINED_MEETING,
                        callback: (data) => this.broadcastCustomerJoinedMeetingHandler(data)
                    },
                    {
                        event: ENUMS.EVENT_EMITS.BROADCAST.CUSTOMER_LEFT_MEETING,
                        callback: (data) => this.broadcastCustomerLeftMeetingHandler(data)
                    },
                    {
                        event: ENUMS.EVENT_EMITS.BROADCAST.CUSTOMER_WAITING_MEETING,
                        callback: (data) => this.broadcastCustomerWaitingMeetingHandler(data)
                    }
                ],
                inactivityTime: 0,
                lockedOut: false,
                timing: null,
                inactivityApplyTime: 600
            };
        },
        computed: {
            userStores(): Store[] {
                return settings.userStores;
            },
            buyersOrdersEnabled(): boolean {
                return this.selectedStore?.storeSettings.buyersOrderEnabled;
            },
            isCustomerCreating(): boolean { // If we have more than one customer. That means we're editing; else we are creating.
                return this.availableCustomers.length <= 0
            },
            payload() {
                return auth.getTokenPayload();
            },
        },
        async created() {
            this.subscribeToBroadcastEvents()
            this.EventBus.emit('checkSeleniumVersion')
            this.timing = setInterval(() => {
                if (!this.lockedOut && !this.$meetingHub.code && !this.payload.EmployeeAccess.IsAdmin) {
                    this.inactivityTime++;

                    if(this.inactivityTime >= this.inactivityApplyTime) {
                        $modal.open(modalLockoutByInactivity, {
                        name: 'modalLockoutByInactivity',
                        postFunction: (data: any) => {
                            if(data.killTimer) {
                                clearInterval(this.timing)
                            }
                            this.lockedOut = false
                            this.inactivityTime = 0
                        },
                    });

                    this.lockedOut = true
                    }
                }
            }, 1000);

            window.addEventListener('mousemove', () => {
                this.inactivityTime = 0
            })

            this.isLoadingInitialSetup = true;
            if (this.$route.query?.customerId != null) {
                this.selectedCustomerId = this.$route.query.customerId as string;
                await this.loadSelectedCustomerInformation();
            }
            // Tab to be loaded.



            if (this.customerStores?.length == 1) {
                this.selectedStoreCode = this.customerStores[0];
                this.metadata.storeCode = this.selectedStoreCode;
                this.metadata.store = settings.userStores.find((x: Store) => x.storeCode == this.selectedStoreCode);
                this.selectedStore = settings.userStores.find((x: Store) => x.storeCode == this.selectedStoreCode);
            }

            this.EventBus.on('deal-saved', this.handleDealSave);
            EventBusCore.on('deal-saved', () => this.handleDealSave);

            this.$watch(() => this.deals, () =>{
                this.deals.forEach((deal: any) => {
                    if (!this.tabs.includes(deal.id)) {
                        this.tabs.push(deal.id);
                    }
                });
            }, { deep: true, immediate: true });


            this.$watch(() => this.customer.id, (newCustomerId: string) =>{
                if (this.customer.id != null && this.customer.id != "null") { // When we change the customer id; to an actual customer.
                    util.setQueryParm("customerId", newCustomerId);

                    if (this.availableCustomers.length <= 0) { // If there's no availableCustomers that means it is a new customer.
                        this.addMainCustomerToListOfAvailableCustomers()
                        this.selectedCustomerId = this.customer.id;
                    }
                }
            });

            // If we're loading straight into a deal, lets load it.
            if (this.$route.query?.t != null && this.$route.query?.t != "customer") {
                const loaded = await this.handleLoadDeal(this.$route.query?.t as string, this.getDealTypeFromQuery())
                if (!loaded) this.$router.push("/customers") // If we can't find the deal. Kick back to customers page.
            }

            await this.loadDealIntoTabSystem()

            // nextTick ensures the deals are set (if any) before we actually render the tabMenu.
            // Else, it would not be able to go back to the tabs, as it will be initialized in with default tabs only.
            this.$nextTick(async () => {
                this.isLoadingInitialSetup = false;
                await this.loadCustomerInfoFromDeals();
                this.addMainCustomerToListOfAvailableCustomers()
                this.setupEventListeners()
            })

            this.EventBus.off("switchCustomerTab", this.handleRequestSwitchCustomerTab);
            this.EventBus.on('BroadcastReconnect',() => {
                this.subscribeToBroadcastEvents()
            });
        },
        beforeUnmount(){
            clearInterval(this.timing)
            this.EventBus.off('deal-saved', this.handleDealSave);
            EventBusCore.off('deal-saved', () => this.handleDealSave);
            this.unsubscribeToBroadcastEvents()
        },
        methods: {
            subscribeToBroadcastEvents(){
                this.$meetingHub.connect()
                broadcastSubscribeEvents(this.$meetingHub, this.broadcastEvents)
            },
            broadcastCustomerLeftMeetingHandler (data: string) {
                const parsedData = JSON.parse(data)
                const dealNumber = parsedData.meetingDetails.meetingCode.split('-')[0]
                if(!this.$meetingHub.code && this.fimenu?.dealNumber?.toString() === dealNumber.toString()  && !this.fimenu.isSpectator) {
                    delete this.meetingsWithWaitingCustomers[0].users[parsedData.userDetails.userId]
                    if(!Object.keys(this.meetingsWithWaitingCustomers[0].users).length) {
                        this.meetingsWithWaitingCustomers = []
                    }
                }
            },
            unsubscribeToBroadcastEvents(){
                broadcastUnsubscribeEvents(this.$meetingHub, this.broadcastEvents)
            },
            broadcastCustomerJoinedMeetingHandler(data: string){
                const meetingDetails: MeetingDetails = JSON.parse(data)
                const dealNumber = meetingDetails.meetingCode.split('-')[0];
                const isInStore = Object.values(meetingDetails.users).some((user: MeetingUser) => user.isInStoreConnection === true);
                if(!isInStore && this.fimenu?.dealNumber?.toString() === dealNumber.toString() && !this.fimenu.isSpectator) {
                    const userConnectedData = Object.values(meetingDetails.users)[0]
                    broadcastCallbacks.openModalBroadcastMessages(JSON.stringify({
                        messageTitle: 'Meeting Notification', 
                        messageBody: `The customer ${(userConnectedData as any).fullName} is connected and ready to join the meeting.`
                    }))
                    this.meetingsWithWaitingCustomers = [meetingDetails]
                }
            },
            broadcastCustomerWaitingMeetingHandler(data: string){
                const {currentMeetingDetails, myUserDetails} = JSON.parse(data) 
                const dealNumber = currentMeetingDetails.meetingCode.split('-')[0]
                if(this.fimenu?.dealNumber?.toString() === dealNumber.toString() && !this.fimenu.isSpectator) {

                    let customerInfo = Object.values(currentMeetingDetails.users).find((u: any) => u.role == myUserDetails.role)
                    util.toastr('warn', 'Customer Waiting', 
                        `Customer ${(customerInfo as any).fullName} is connected and ready to join the meeting.`
                    );
                    this.meetingsWithWaitingCustomers = [currentMeetingDetails]
                }
            },
            async handleDealSave(deal: FIMenuClass) {
                const dealId = deal.id;

                // Getting the deal information for our tabs.
                const tabDealInfoIndex = this.deals.findIndex(d => d.id == dealId)


                if (tabDealInfoIndex >= 0) {
                    if (this.deals[tabDealInfoIndex].isDealCreatedInDB == false) {
                        this.deals[tabDealInfoIndex].isDealCreatedInDB = true;
                    }

                    if (deal.buyersOrderEnabled) {
                        // This will sync wether the deal is a quote or a deal.
                        this.deals[tabDealInfoIndex].dealKind = deal.dealKind;
                    }
                }
            },
            getDealTypeFromQuery(): DealKind {
                return this.$route.query.dk != null ? parseInt(this.$route.query.dk as string) as DealKind : DealKind.Deal;
            },
            afterTabChange(currentTab: any) {
                this.videoRecordingHandler(false)

                console.log("CURRENT TAB", currentTab)

                // This code partition handles adding wether the deal is Quote or Deal in the query parameters

                // If the current tab is "customer", remove the query parameter "dk"
                if (currentTab == "customer") return util.deleteQueryParam("dk");
                // Find the deal that matches the current tab ID
                const dealAboutToBeOpened = this.deals.find(x => x.id == currentTab);
                // If no deal is found, exit the function
                if (!dealAboutToBeOpened) return;
                // Set the query parameter "dk" to the deal kind of the deal about to be opened
                util.setQueryParm("dk", dealAboutToBeOpened.dealKind.toString());
            },

            currentTab(): string | null {
                return (this.$refs.tabMenu as any).activeTab;
            },
            choosePreselectedStore(): string | null {
                const { userStores } = settings;
                const hasAccessToOneStore = userStores.length === 1;
                const allStoresAreSame = this.deals.length > 0 && this.deals.every(deal => deal.storeCode === this.deals[0].storeCode);

                this.selectedStoreCode = null;

                if (allStoresAreSame) {
                    return this.deals[0].storeCode;
                }

                if (hasAccessToOneStore) {
                    return userStores[0].storeCode;
                }

                return null;
            },
            setupEventListeners() {
                this.EventBus.on('switchCustomerTab', this.handleRequestSwitchCustomerTab);

            },
            /*
            /*
                This method will add customers to the array of available customers which will display in the dropdown.
            */
            addCustomersToAvailableCustomers(customersToAdd: any[] = []) {
                const newCustomers = customersToAdd
                    .filter(customer => !this.availableCustomers.some(x => x.id == customer.id)) // Filter customers that already exist in the array.
                    .map(customer => ({
                        id: customer.id,
                        fullName: customer.fullName.toUpperCase(),
                        storeCodes: customer.storeCodes
                    }));

                this.availableCustomers.push(...newCustomers);
            },
            /*
                This method will fetch and load the bare minimum information for the customers in the deals opened. (fullname, customerId, storeCode)
            */
            async loadCustomerInfoFromDeals() {
                const response: AxiosResponse<Record<string, CustomerSearchResult[]>> = await api.fimenu.getCustomersInfoFromDeal(this.deals)

                if (response.data) {
                    for (const [key, value] of Object.entries(response.data)) {
                        const dealId = key

                        if (value?.length ?? 0 > 0) { // If we have customers to add from the api call
                            this.addCustomersToAvailableCustomers(value)
                        } else {
                            // If the deal doesn't have customer. Lets create them
                            const response = await api.fimenu.getDeal(dealId);
                            if (response.data?.fimenu) {
                                const deal = new FIMenuClass(response.data.fimenu)
                                await deal.upsertCustomers();
                                await deal.saveCustomers();
                            }
                        }
                    }
                }
            },
            unsavedRedflags() {
                return (this.$refs?.panelCustomer as any)?.hasUnsavedRedflagsChanges;
            },
            /*
                This method will load the deal into the tabs system; getting any required information from the id.
                Additionally will load all the customers from that deal.
            */
            async loadDealIntoTabSystem() {

                const possibleDealId = this.$route.query.dealId ?? this.$route.query.t; // This could be the deal id or the tab.

                const dealId = possibleDealId != null && possibleDealId != "customer" ? possibleDealId : null;
                // const type = this.$route.query.dealType ?? DealKind.Deal
                const type = this.$route.query.dk != null ? parseInt(this.$route.query.dk as string) as DealKind : DealKind.Deal;

                let dealNumber = this.$route.query.dealNumber as string;
                let storeCode = this.$route.query.storeCode as string;

                const isSelectedTabADeal = dealId != this.tabs[0];

                if ((!dealNumber || !storeCode) && dealId) { // If we don't have storecode or deal number. Lets get it.

                    if (this.fimenu && this.fimenu.storeCode && this.fimenu.dealNumber) {
                        storeCode = this.fimenu.storeCode;
                        dealNumber = this.fimenu.dealNumber;
                    }
                    else {

                        const response = await api.fimenu.getDeal(dealId, type);

                        if (response.data?.fimenu) {
                            storeCode = response.data.fimenu.storeCode;
                            dealNumber = response.data.fimenu.dealNumber;
                        }
                    }
                }

                if (dealNumber && dealId && storeCode && isSelectedTabADeal) {

                    if (this.deals.some(x => x.id == dealId)) return; // If we have the deal in teh list, lets not add it. ### FAILSAFE (only happened with hot-reload.)

                    this.deals.push({ storeCode, dealNumber, id: dealId as string, dealKind: type, isDealCreatedInDB: true });

                    if (!util.isNullOrEmpty(dealId) && isSelectedTabADeal) { // If the tab parameter is different than "custoemr"
                        util.deleteQueryParam("dealId")
                        util.setQueryParm("t", dealId as string)
                        util.setQueryParm("dk", type.toString())
                        this.tabs.push(dealId as string)
                    }
                }
            },
            addMainCustomerToListOfAvailableCustomers() {
                if (this.customer.id) {
                    this.availableCustomers = this.availableCustomers.filter(x => x.id != this.customer.id);

                    this.availableCustomers.push({
                        id: this.customer.id,
                        fullName: this.customer?.getPaperworkName()?.toUpperCase() || this.customer.fullName,
                        storeCodes: []
                    })
                }

            },
            async loadSelectedCustomerInformation() {
                this.isLoading = true;
                try {
                    const { data: retrievedCustomer } = await api.customers.getCustomerById(this.selectedCustomerId);

                    this.customer = new FIMenuCustomer();
                    if (retrievedCustomer) {
                        this.customer.setFromCustomer(retrievedCustomer);
                        // Hide stores that the FIManager doesnt have access to.
                        this.customerStores = retrievedCustomer.storeCodes.filter((customerStoreCode: string) => {
                            return settings.userStores.find((store: Store) => store.storeCode == customerStoreCode);
                        });

                        await this.customer.loadCustomerData(); // Loads credit report.
                    }

                    if (this.selectedStoreCode) {
                        this.metadata.storeCode = this.selectedStoreCode;
                        this.metadata.store = settings.userStores.find((x: Store) => x.storeCode == this.selectedStoreCode);
                        this.metadata.buyersOrderEnabled = this.metadata.store.storeSettings.buyersOrderEnabled;
                        this.selectedStore = this.metadata.store;
                    }

                    await this.customer.getAndSetCustomerApplication();

                } catch (error: any) {
                    util.toastr("error", "Retrieving Customer", error.message || error);

                    console.error(error);
                } finally {
                    this.isLoading = false;
                }
            },
            handleRequestSwitchCustomerTab(tabName: string, customerId?: string, storeCode?: string) { // This gets executed from within a deal.
                if (customerId == null) {
                    util.deleteQueryParam("customerId")

                } else {
                    util.setQueryParm("customerId", customerId)
                }

                if (storeCode != null) {
                    this.selectedStoreCode = storeCode;
                }

                if (customerId == "null") this.selectedCustomerId = null;
                else this.selectedCustomerId = customerId;

                this.switchTab(tabName); // Should technically be the last one.
            },
            beforeTabChange(previousTab?: string, tabName?: string) {
                const isloading = this.dealLoading();

                if (isloading) util.modalInformation("Please wait until the deal is finished loading.")
                else this.changeTabHandler(previousTab, tabName)

                return !isloading;
            },
            async changeTabHandler(previousTab: string, newTab: string) {
                this.isLoadingTabChange = true
                try {

                    let clearCustomer = true;

                    if (this.selectedCustomerId == "null") {
                        this.selectedCustomerId = null;
                    }
                    util.deleteQueryParam("customerId")

                    if (newTab == "customer") {
                        await this.loadCustomerInfoFromDeals()

                        this.availableCustomers = this.availableCustomers.filter(x => x.id != this.customer.id);

                        if (this.selectedCustomerId) { // Actually want to edit an user.
                            clearCustomer = false;
                            await this.loadSelectedCustomerInformation();
                        }
                        this.selectedStoreCode = this.choosePreselectedStore();

                        this.fimenu = null; // Clear the deal everytime we leave a deal.

                    } else { // If we're loading a deal
                        // If we're switching between deals; we must clear the FIMenu so we may load it again.
                        if (previousTab != "customer") {
                            this.fimenu = null
                        }
                        // We only fetch deals when we don't have it.
                        // We MIGHT have it when we create a new deal, because it's passed in as memory.
                        if (!this.fimenu) { // Only fetch if we don't have it.
                            const dealInfo = this.deals.find(deal => deal.id == newTab);
                            if (dealInfo != null) await this.handleLoadDeal(newTab, dealInfo.dealKind)
                        }
                    }

                    // If the previous tab is not customer. Try to delete all not-elastic saved deals.
                    //  Deal to Deals -> Execute
                    //  Deal -> Customer Execute
                    //  Customer to Deals -> Not Execute (That's when we're actually creating a deal.)
                    if (previousTab != "customer") this.clearNotSavedDeals()
                    if (!clearCustomer) return;

                    this.selectedCustomerId = null;
                    this.customer = new FIMenuCustomer();
                    this.customerStores = []
                } catch (err) {
                    console.error(err)
                } finally {
                    this.isLoadingTabChange = false;
                }
            },
            openDeal(deal: FIMenuClass, type: DealKind) {
                const { storeCode: storeCode, dealNumber: dealNumber, id: id } = deal;

                if (this.deals.some((x) => x.id === deal.id)) {
                    if ((this.$refs?.tabMenu as any)?.switchTab)
                        return this.switchTab(deal.id);
                    else
                        return util.toastr("error", "Error", "Deal is already open in a tab.");
                }

                if (this.deals.length >= this.maxDealsInTab) {
                    return util.toastr("error", "Error", "You may only open 5 deals at a time!")
                }

                if (this.deals.some(x => x.id == deal.id)) return; // If we have the deal in teh list, lets not add it. ### FAILSAFE (only happened with hot-reload.)
                this.deals.push({ storeCode, dealNumber, id, dealKind: type, isDealCreatedInDB: true });

            },
            clearNotSavedDeals() {
                const dealsNotCreated = this.deals.filter(x => x.isDealCreatedInDB == false)

                dealsNotCreated.forEach(element => {
                    const dealIndex = this.deals.findIndex((x => x.id == element.id))
                    if (dealIndex >= 0) this.deals.splice(dealIndex, 1)

                    const tabsIndex = this.tabs.findIndex((x) => x == element.id)
                    if (tabsIndex >= 0) this.tabs.splice(tabsIndex, 1)
                });
            },
            deleteDealConfimation(deal: dealsData) {
                util.modalConfirmation(
                    "Are you sure you want to close this deal",
                    "Yes",
                    () => this.deleteDealTab(deal),
                    "No",
                    null,
                    "Confirmation",
                );
            },
            deleteDealTab(deal: dealsData) {

                if (this.dealLoading()) return this.beforeTabChange();
                const currentTab = (this.$refs.tabMenu as any).activeTab;

                const index = this.tabs.findIndex(tab => tab == deal.id);
                if (index >= 0) {
                    const isInCurrentTab = this.tabs[index] == currentTab;

                    const dealIndex = this.deals.findIndex(dealOfList => dealOfList.id == deal.id);
                    this.tabs.splice(index, 1)
                    this.deals.splice(dealIndex, 1)

                    if (isInCurrentTab) {
                        setTimeout(() => {
                            this.switchTab(this.tabs[0])
                        }, 1)
                    }
                }
            },
            switchTab(tab: string) {
                (this.$refs.tabMenu as any)?.switchTab(tab)
            },
            dealLoading() {
                if (!this.$refs.fimenudeal) return false;
                return !!(this.$refs.fimenudeal as any)[0]?.isBusyLoadingDeal || this.isLoadingDeal;
            },
            async createCustomer() {
                if (!this.selectedStoreCode)
                    return util.toastr("error", "Failed", "You must select a store before you create a customer.");

                try {
                    this.isLoading = true;

                    const response = await api.customers.createCustomerFromFIMenu(
                        this.customer,
                        this.selectedStoreCode,
                        ENUMS.CUSTOMER_CREATION_SOURCE.CustomersPage
                    );
                    util.toastr("success", "Success", `Customer ${!this.customer.id ? "Created" : "Saved"}`);

                    //Set the customer id. If the customer matched something else on the backend. We would get it; therefor is important to set it.
                    if (this.customer.id != response.data.id) {
                        this.customer.id = response.data.id;
                        this.$router.replace({ query: { id: this.customer.id } });
                    }

                    // Update associated stores.
                    const customerStores = response.data.storeCodes;
                    const userStores = settings.userStores;

                    this.customerStores = customerStores.filter((storeCode: string) =>
                        userStores.find((store: Store) => store.storeCode == storeCode)
                    );
                    LogRocket.track(`Cust_Created`, {
                        customerId: this.customer.id,
                        customerCell: this.customer.cell,
                        customerEmail: this.customer.email,
                        customerDob: this.customer.dob,
                        storeName: this.$global.selectedStore.storeName,
                        environment: settings.environmentName,
                    });
                } catch (err) {

                    console.error(err);
                    util.toastr("error", "Error", "Customer could not be created.");
                } finally {

                    this.isLoading = false;

                }
            },
            handleGoBack() {
                util.modalConfirmation(
                    "Are you sure you want to go back?",
                    "Yes",
                    () => this.$router.push('/customers'),
                    "No",
                    null,
                    "Confirmation",
                )
            },
            videoRecordingHandler(startRecording = true){
                if(startRecording) {
                    if(this.fimenu?.store?.storeSettings.videoRecordingSettings?.isScreenRecordingEnabled) {
                        //Start video recording if Store Settings is set to true
                        console.log("Request for Video Recording to Start");
                        // this.EventBus.emit('startVideoRecording', this.fimenu.id, {...this.fimenu?.store.storeSettings.videoRecordingSettings, isRecordingEnabled: this.fimenu?.store.storeSettings.isScreenRecordingEnabled})
                    }
                } else {
                    //stop the video recording when tab is changed to have it ready for next record
                    //(funciton handles if the recording is happening or not)
                    console.log("Request for Video Recording to Finish");
                    this.EventBus.emit('stopVideoRecording')
                }
            },
            async handleCreateDeal(coCustomer: FIMenuCustomer = null) {
                // Takes in a customer search result
                this.isCreatingDeal = true;

                try {

                    const deal = await CreateDeal(this.customer, this.metadata.storeCode, coCustomer);

                    if (deal != null) {
                        this.fimenu = deal;
                        // Add the deal to the tab, save it into local storage and switch to the newly added deal.
                        this.deals.push({
                            storeCode: deal.storeCode,
                            dealNumber: deal.dealNumber,
                            id: deal.id,
                            dealKind: deal.dealKind,
                            isDealCreatedInDB: false
                        });

                        this.videoRecordingHandler()

                        this.switchTab(deal.id); // Should technically be the last one.
                    }

                    $modal.cancel();
                } catch (err: any) {
                    util.toastr(
                        "error",
                        "Error saving",
                        err.message ?? "Unexpected server error occured. Please refresh and try again."
                    );
                } finally {
                    this.isCreatingDeal = false;
                }
            },
            /**
            * Loads a deal based on the given deal ID and type.
            *
            * @param {string} dealId - The ID of the deal to load.
            * @param {DealKind} dealType - The type of the deal (either Quote or Deal).
            * @returns {Promise<void>} - A promise that resolves when the deal is loaded.
            */
            async handleLoadDeal(dealId: string, dealType: DealKind = DealKind.Deal) {          // DO NOT add logic here; UNLESS is specific for customer/deals.
                this.isLoadingDeal = true;  // Indicate that the deal is currently loading.
                this.fimenu = null;          // Reset the fimenu property before loading a new deal.

                // Fetch the deal using the provided ID and type.
                let deal = await GetDeal(dealId, dealType);
                let canLoad = false;
                // Check if the deal is loadable based on its type.
                if (deal) {
                    canLoad = IsDealOpenable(deal, dealType);
                }

                // If the deal cannot be loaded, attempt to fetch a fallback deal.
                if (!canLoad) {
                    let lookupKind = dealType;
                    // Determine the kind of deal to look up based on the current deal type.
                    if (dealType == DealKind.Quote) {
                        lookupKind = DealKind.Deal; // Fallback to Deal if the current type is Quote.
                    }
                    if (dealType == DealKind.Deal) {
                        lookupKind = DealKind.Quote; // Fallback to Quote if the current type is Deal.
                    }

                    // Attempt to fetch the fallback deal.
                    const fallbackDeal = await GetDeal(dealId, lookupKind);

                    // If a fallback deal is found, use it.
                    if (fallbackDeal) {
                        this.$router.push({
                            query: {
                                ...this.$route.query,
                                dk: lookupKind.toString()
                            }
                        });
                        deal = fallbackDeal;
                    }
                }

                if (deal) {
                    this.fimenu = deal; // Get deal is already trycatching.
                    this.videoRecordingHandler()
                }
                else {
                    util.toastr("error", "Error", "There was an error loading the deal, please try refreshing the page.")
                    this.switchTab(this.tabs[0])
                }
                this.isLoadingDeal = false;

                return !!this.fimenu;
            }
        },
        components: {
            CustomerDetailsHeader,
            CustomerDetails,
            CustomerDetailsFooter,
            TabMenu,
            FIMenu,
            IsBusyScreenComponent,
            IsBusySectionComponent
        }
    });
</script>
<style>
    .customer {
        padding-bottom: 150px;
        box-sizing: border-box;
    }

        .customer .customer-body {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            margin: 0 auto;
        }

    .page.customer .buttons-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
    }

    .page.customer {
        /* One side has more padding to make up to the built in css in the other side, if that makes any sense. */
        padding: 15px 25px 0px 5px;
        box-sizing: border-box;
        height: 100%;
        overflow: hidden;
    }

        .page.customer .button-save .create-quote {
            min-width: 120px;
        }

        .page.customer .tabmenu-container {
            height: 100%;
        }

            .page.customer .tabmenu-container ul.tabmenu-menu {
                height: 100%;
            }

    .tabmenu-container .tabmenu-main {
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
    }

    .tabmenu-container .tabmenu-panel:has(.fimenupage) {
        height: 100%;
    }

    .storesListContainer {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .floating-menu-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px !important;
    }

    .remove-button-style {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: black;
        background-color: unset;
        border: unset;
        box-shadow: unset !important;
        max-width: 120px;
        padding: 0;
        gap: 10px;
        box-sizing: border-box;
    }

    .customer .FloatingMenu .menu {
        min-width: 200px;
    }

    .tabmenu-panel:has(.customer-header) {
        display: grid;
        grid-template-rows: auto 1fr;
    }

    .tabmenu-panel.tab-body--active {
        height: 100%;
        overflow: hidden;
    }

    .tabmenu-panel .customer-body {
        padding: 10px 20px;
        box-sizing: border-box;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .customer .accordionBodyContainer {
        padding: 0.5% 1% !important;
    }

    .customer-footer {
        display: flex;
        width: 100%;
        height: 65px;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
    }

    .page.customer .customer-body .fi-customer-menu-deals .children-container {
        min-height: unset;
    }

    .admin-view-data {
        position: absolute;
        font-size: 12px;
        right: 25px;
        display: flex;
        flex-direction: column
    }

    .page.customer .tabmenu-container .tabmenu-main:has(.page.fimenupage) {
        padding: unset;
    }
</style>
