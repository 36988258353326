<template>
    <IsBusySectionComponent v-if="isBusy" style="height: 100%" />

    <Panel v-else title="Customer Documents" :action="headerActions" class="customer-docs-panel ignore-deal-lock">
        <!--Document List-->
        <section v-show="showDocList" class="docs-list">
            <RichTable class="overflow" :tableData="docList" :headers="tableHeaders" :isBusy="isBusy" noData="No Documents Uploaded">
                <template #editIcons="row">
                    <fieldset class="document-actions" :disabled="!isAssociatedWithDeal(row.associations)">
                        <button class="button-span button-delete-doc" @click="() => deleteDocumentRow(row)">
                            <i class="fas fa-trash-alt" />
                        </button>
                    </fieldset>
                </template>

                <template #previewButton="row">
                    <button class="ignore-all-locks" type="button" @click="() => openDocumentPreviewModal(row)">Preview</button>
                </template>
            </RichTable>
        </section>

        <!--Upload New Document-->
        <section v-show="!showDocList" class="docs-add-edit">
            <fieldset class="docs-add-edit-fieldset">
                <div class="file-upload-form">
                    <InputFile filesAccepted="image/*, application/pdf"
                               :key="updateKey"
                               showFilePreview
                               :invalid="v$.currentDoc.fileData.$invalid"
                               @fileDataChange="onFileDataChange" />
                </div>

                <div class="file-metadata-form">
                    <InputRichDropdown label="Document Type"
                                       v-model:saturn="currentDoc.docType"
                                       :list="documentTypes"
                                       :valueMap="(d) => d.code"
                                       :display="(d) => d.display"
                                       :invalid="v$.currentDoc.docType.$invalid"
                                       :customHandling="customHandlingDocType"
                                       :containerHeight="440"
                                       :sortMode="ENUMS.DROPDOWN_SORT_MODE.ORIGINAL_ORDER"
                                       @change="onDocTypeSelected"
                                       search />
                </div>
            </fieldset>
        </section>
    </Panel>
</template>

<script>
    import settings from 'settings';
    import api from '@core/services/api';
    import util from '@core/services/util';
    import $modal from '@core/services/modal';
    import ENUMS from "@core/classes/Enums";
    import FIMenu from '@core/classes/FIMenu';
    import DocumentAssociation from '@core/classes/DocumentAssociation';
    import { required, requiredIf } from '@vuelidate/validators';
    import modalInfo from '@core/modals/modalInfo.vue';
    import modalDocumentPreview from '@core/modals/modalDocumentPreview.vue';
    import Panel from '@core/components/Panel.vue';
    import RichTable from '@core/components/RichTable.vue';
    import InputFile from '@core/components/InputFile.vue';
    import InputRichDropdown from '@core/components/InputRichDropdown.vue';
    import IsBusySectionComponent from '@core/components/IsBusySectionComponent.vue';
    import { useVuelidate } from '@vuelidate/core';

    export default {
        name: "PanelCustomerDocuments",
        props: {
            fimenu: FIMenu
        },
        setup(){
            // New setup for vuelidate. Swap $v with v$
            return {v$: useVuelidate({$scope: false})}
        },
        data() {
            return {
                currentDoc: {
                    fileData: null,
                    fileType: null,
                    fileName: null,
                    docType: null,
                },
                showDocList: true,
                isBusy: false,
                tableHeaders: [
                    {
                        display: 'Association',
                        name: 'association',
                        value: (r) => {
                            const associationId = r.associations[0].id;
                            const associationType = r.associations[0].type;

                            if (associationType === ENUMS.DOC_ASSOCIATION.Deal) return 'Deal';

                            const idToMatch = (associationType === ENUMS.DOC_ASSOCIATION.Customer)
                                ? this.fimenu.customer.id
                                : this.fimenu.customer.applicationId;

                            return (associationId === idToMatch) ? 'Customer' : 'Co-Customer';
                        },
                        autoFilter: true
                    },
                    {
                        display: 'Document',
                        name: 'docType',
                        value: (r) => {
                            if (r.docInfo.docType.startsWith('CUSTOM_'))
                                return util.toProperCase(r.docInfo.docType.substring(6).replaceAll('_', ' '));

                            return this.documentTypes.find(t => t.code === r.docInfo.docType)?.display ?? 'Unknown';
                        },
                        autoFilter: true,
                        sortable: true
                    },
                    {
                        display: 'Date Uploaded',
                        name: 'timestamp',
                        value: (r) => {
                            return new Date(r.docInfo.timestamp).toLocaleString('en-US', { dateStyle: 'short', timeStyle: 'short' });
                        },
                        sortable: true
                    },
                    { display: '', name: 'preview', slot: 'previewButton' },
                    { display: '', name: 'edit', slot: 'editIcons' },
                ],
                documentTypes: settings.lookups.uploadedDocumentTypes,
                updateKey: 0
            }
        },
        validations() {
            return {
                currentDoc: {
                    fileData: { required },
                    docType: { required },
                }
            };
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            docList() {
                return this.fimenu.paperwork?.uploadedDocuments ?? [];
            },
            headerActions() {
                if (this.fimenu.isSpectator) return null;

                if (this.showDocList)
                    return [{ text: 'Upload New Document', onClick: this.toggleDocList }];

                return [
                    { text: 'Cancel', class: 'button-unwind', onClick: this.toggleDocList },
                    { text: 'Save', class: 'button-save', onClick: this.addNewDocument, disabled: this.v$.$invalid }
                ];
            }
        },
        created() {
            // Move 'OTHER' document type to end of list
            const otherTypeIndex = this.documentTypes.findIndex(d => d.code === 'OTHER');
            const docTypeEndIndex = this.documentTypes.length - 1;

            if (otherTypeIndex !== null || otherTypeIndex !== docTypeEndIndex) {
                const tempEnd = this.documentTypes[docTypeEndIndex];
                this.documentTypes[docTypeEndIndex] = this.documentTypes[otherTypeIndex];
                this.documentTypes[otherTypeIndex] = tempEnd;
            }

        },
        methods: {
            toggleDocList() {
                this.showDocList = !this.showDocList;

                if (this.showDocList)
                    this.resetCurrentDoc();
            },
            async getDocumentList() {
                this.isBusy = true;
                await this.fimenu.getUploadedDocuments();
                this.isBusy = false;
            },
            openDocumentPreviewModal(row) {
                $modal.open(modalDocumentPreview, {
                    name: 'modalDocumentPreview',
                    passedData: {
                        title: row.docInfo.fileName,
                        document: row,
                    },
                    backdrop: true
                });
            },
            customHandlingDocType(docType) {
                return {
                    code: `CUSTOM_${docType.trim().toUpperCase().replaceAll(' ', '_')}`,
                    display: docType
                };
            },
            onDocTypeSelected(_, docDropdownItem) {
                if (docDropdownItem.isCustom) {
                    this.currentDoc.isDocTypeCustom = true;
                }
            },
            async addNewDocument() {
                const isFileValid = settings.lookups.uploadedDocumentsAcceptedFileTypes.includes(this.currentDoc.fileType)
                if (!isFileValid) return util.toastr('error', 'Upload Error', 'File type not supported.');
                this.isBusy = true;

                const documentForm = new FormData();
                documentForm.append('fileData', this.currentDoc.fileData);

                this.currentDoc.fileName = this.formatFileNameForAzure(this.currentDoc);

                const associations = [new DocumentAssociation({ id: this.fimenu.id, type: ENUMS.DOC_ASSOCIATION.Deal })];

                const docInfo = {
                    fileName: this.currentDoc.fileName,
                    fileType: this.currentDoc.fileType,
                    docType: this.currentDoc.docType,
                    source: 'FI MENU UPLOAD'
                };

                documentForm.append('associations', JSON.stringify(associations));
                documentForm.append('docInfo', JSON.stringify(docInfo));

                const response = await api.documents.upsertDocument(documentForm);
                if (!response) {
                    util.toastr('error', 'Upload Error', 'No response from server. Unable to upload documents to deal.');
                    return;
                }

                util.toastr('success', 'Upload Successful', 'Successfully uploaded document to deal.');
                await this.getDocumentList();
                this.toggleDocList();

                this.isBusy = false;
            },
            formatFileNameForAzure(document) {
                let currFileName = document.fileName;

                if (/^((LPT|COM)[1-9]|PRN|AUX|NUL|CON|CLOCK\$|\.|\.\.)$/.test(currFileName))
                    currFileName = `${document.docType}_${Date.now()}`;

                currFileName = currFileName.replaceAll(/[^a-zA-Z0-9\-._~]/g, '-');

                const fileExtension = document.fileType.substring(document.fileType.indexOf('/') + 1);
                if (!currFileName.endsWith(`.${fileExtension}`))
                    currFileName += `.${fileExtension}`;

                if (currFileName.length > 255) {
                    const amountToCut = 255 - `.${fileExtension}`.length;
                    currFileName = `${currFileName.substring(0, amountToCut)}.${fileExtension}`;
                }

                return currFileName;
            },
            onFileDataChange(fileObject) {
                this.currentDoc.fileData = fileObject;
                this.currentDoc.fileType = fileObject.type;
                this.currentDoc.fileName = fileObject.name;
            },
            deleteDocumentRow(row) {
                $modal.open(modalInfo, {
                    name: 'modalInfo',
                    passedData: {
                        info: `Document "${row.docInfo.fileName}" is about to be deleted from this deal. Do you want to continue?`,
                        acceptText: 'Delete',
                        acceptTextClass: 'button-unwind',
                        cancelText: 'Cancel',
                        cancelTextClass: 'button-accept'
                    },
                    postFunction: async () => {
                        this.isBusy = true;
                        // eslint-disable-next-line no-console
                        console.log('Delete: ', row);

                        const response = await api.documents.deleteDocumentById(row.id);
                        // console.log(response);
                        if (!response) {
                            util.toastr('error', 'Could not delete document.', 'Could not delete document from server.');
                            return;
                        }

                        this.fimenu.paperwork.uploadedDocuments = this.fimenu.paperwork.uploadedDocuments.filter(doc => doc.id !== row.id);
                        this.isBusy = false;
                    }
                });
            },
            resetCurrentDoc() {
                this.currentDoc.fileName = null;
                this.currentDoc.fileData = null;
                this.currentDoc.docType = null;
                this.updateKey++; // Force re-render
            },
            isAssociatedWithDeal(associations) {
                return associations.some(a => a.id === this.fimenu.id);
            }
        },
        components: {
            Panel,
            RichTable,
            InputFile,
            InputRichDropdown,
            IsBusySectionComponent,
        }
    };
</script>
<style>
    .customer-docs-panel .docs-list .document-actions {
        display: flex;
        justify-content: space-evenly;
    }

        .customer-docs-panel .docs-list .document-actions button:disabled {
            opacity: 0.5;
        }

        .customer-docs-panel .docs-list .document-actions button.button-delete-doc {
            color: var(--error-color);
        }

            .customer-docs-panel .docs-list .document-actions button.button-delete-doc:disabled {
                color: var(--error-color-hover);
            }

    .customer-docs-panel .docs-add-edit .docs-add-edit-fieldset {
        display: flex;
    }

        .customer-docs-panel .docs-add-edit .docs-add-edit-fieldset > * {
            margin: 0 5px;
        }

        .customer-docs-panel .docs-add-edit .docs-add-edit-fieldset .file-metadata-form {
            flex-basis: 60%;
        }

            .customer-docs-panel .docs-add-edit .docs-add-edit-fieldset .file-metadata-form > * {
                margin: 10px 0;
            }

            .customer-docs-panel .docs-add-edit .docs-add-edit-fieldset .file-metadata-form input {
                text-transform: none;
            }

        .customer-docs-panel .docs-add-edit .docs-add-edit-fieldset .file-upload-form {
            flex-basis: 40%;
            max-width: 40%;
        }
</style>