<template>
    <Panel title="Vehicle Registration and Titling Information" class="PanelVehicleRegistration">
        <div :style="$grid('1-1')">
            <InputRichDropdown
                label="Vehicle Delivery Method"
                v-model:saturn="registrationInfo.deliveryMethod"
                :list="getDeliveryMethodsEnumHelper.enumList"
                :valueMap="getDeliveryMethodsEnumHelper.getToListItemValue"
                :display="getDeliveryMethodsEnumHelper.getToListItemDisplay"
                nothingSelected="-- Select Value --"
                :invalid="v.deliveryMethod.$invalid"
            >
                <template #validation>
                    <div v-if="v.deliveryMethod.required.$invalid">Delivery Method is required</div>
                </template>
            </InputRichDropdown>
            <InputRichDropdown
                label="Primary Use"
                v-model:saturn="registrationInfo.primaryUsage"
                :list="vehiclePrimaryUsageEnumHelper.enumList"
                :valueMap="vehiclePrimaryUsageEnumHelper.getToListItemValue"
                :display="vehiclePrimaryUsageEnumHelper.getToListItemDisplay"
                :invalid="v.primaryUsage.$invalid"
                nothingSelected="-- Select Value --"
            >
                <template #validation>
                    <div v-if="v.primaryUsage.required.$invalid">Primary usage is required</div>
                </template>
            </InputRichDropdown>
        </div>

        <div v-if="registrationInfo.primaryUsage == VehiclePrimaryUsage.EXPORT">
            <PanelExportInfo :registrationInfo="registrationInfo"
                             :fallbackzipcode="fimenu.store.storeZip"
                             :validation="validation.vehicle.registrationInfo">
            </PanelExportInfo>
        </div>

        <div :style="$grid('1-1')">
            <div :style="$grid('7-4')" class="no-margin-grid">
                <div class="radio-button-inline" :invalid="v.isPlateTransfer.$invalid">
                    <span>Is the plate being transfered?</span>
                    <InputRadio
                        label="Yes"
                        :valueMatch="true"
                        v-model="registrationInfo.isPlateTransfer"
                        :isButton="true"
                    />
                    <InputRadio
                        label="No"
                        :valueMatch="false"
                        v-model="registrationInfo.isPlateTransfer"
                        :isButton="true"
                    />
                </div>
                <SelectVehicle :fimenu="fimenu" label="Load Plate From Trade-In" ref="vehicleSelector" />
            </div>

            <div
                :style="$grid('1-1')"
                v-if="plateTransactionsEnabled && registrationInfo.isPlateTransfer"
                class="no-margin-grid"
            >
                <button
                    @click="handleOpenDDiResponseModal"
                    :disabled="loadingVehicleInquiryModal || v.plateNumber.$invalid"
                    class="ignore-all-locks"
                >
                    <i v-if="loadingVehicleInquiryModal" class="fas fa-spinner fa-spin" />
                    <span v-else>View Vehicle Inquiry</span>
                </button>
            </div>
        </div>

        <div :style="$grid('1')">
            <fieldset :style="$grid('1-1-1-1')" class="no-margin-grid" :disabled="!registrationInfo.isPlateTransfer">
                <InputTextbox
                    label="Plate Number"
                    v-model:saturn="registrationInfo.plateNumber"
                    :invalid="v.plateNumber.$invalid"
                    placeholder="Plate Number"
                >
                    <template #validation>
                        <div v-if="v.plateNumber.required.$invalid">Plate Number is required</div>
                    </template>
                </InputTextbox>

                <InputRichDropdown
                    label="Plate State"
                    v-model:saturn="registrationInfo.plateState"
                    :list="stateHelper.enumList"
                    :invalid="v.plateState.$invalid"
                    :valueMap="stateHelper.getToListItemValue"
                    :display="stateHelper.getStateDescription"
                    search
                >
                    <template #validation>
                        <div v-if="v.plateState.required.$invalid">Plate State is required</div>
                    </template>
                </InputRichDropdown>

                <InputDate
                    label="Current Plate Expiration Date"
                    v-model:saturn="registrationInfo.currentPlateExpirationDate"
                    :invalid="v.currentPlateExpirationDate.$invalid"
                    icon="fas fa-calendar"
                >
                    <template #validation>
                        <div v-if="v.currentPlateExpirationDate.required.$invalid">
                            Current Plate Expiration Date is Required
                        </div>
                    </template>
                </InputDate>

                <InputNumber
                    label="Registration Duration (months)"
                    v-model:saturn="registrationInfo.registrationLengthInMonths"
                    :invalid="v.registrationLengthInMonths.$invalid"
                    placeholder="Registration Length In Months"
                    :precision="0"
                >
                    <template #validation>
                        <div v-if="v.registrationLengthInMonths.required.$invalid">Registration Length is required</div>
                    </template>
                </InputNumber>
            </fieldset>
        </div>

        <PanelDealAddresses
            :fimenu="fimenu"
            :validation="validation"
            :disabled="fimenu.isDealLocked()"
            class="address-container"
        />

        <div
            class="radio-button-inline"
            :invalid="util.isNull(validation) ? false : v.titledUnder.$invalid"
            :style="$grid('411px-1')"
        >
            <span>Who is the vehicle being titled under?</span>
            <InputRichDropdown
                v-model:saturn="registrationInfo.titledUnder"
                :list="vehicleTitledUnderEnumHelper.enumList"
                :invalid="v.titledUnder.$invalid"
                :valueMap="vehicleTitledUnderEnumHelper.getToListItemValue"
                :display="vehicleTitledUnderEnumHelper.getToListItemDisplay"
                :disabled="!fimenu.hasCoSigner"
            />
        </div>
    </Panel>
</template>

<script lang="ts">
    import { defineComponent, PropType } from "vue";
    import {
        GetDeliveryMethodsEnumHelper,
        IsPlateTransactionsEnabled,
        TransferTypesList,
        VehiclePrimaryUsageEnumHelper,
        VehicleTitledUnderEnumHelper,
    } from "@/helpers/plate-transactions-helper";
    import { openDDiResponseModal } from "@/helpers/plate-transactions-helper";
    import { VehicleDeliveryMethods, VehiclePrimaryUsage, VehicleTitledUnder } from "@core/classes/SharedEnums";

    import BuyersOrderHelper from "@core/helpers/buyers-order-helper";
    import ENUMS from "@core/classes/Enums";
    import FIMenu from "@core/classes/FIMenu";
    import FIMenuExportInfo from "@core/classes/FIMenuExportInfo";
    import FIMenuPlateTransactions from "@core/classes/FIMenuPlateTransactions";
    import FIMenuTradeIn from "@core/classes/FIMenuTradeIn";
    import FIMenuVehicleRegistrationInfo from "@core/classes/FIMenuVehicleRegistrationInfo";
    import InputDate from "@core/components/InputDate.vue";
    import InputNumber from "@core/components/InputNumber.vue";
    import InputRadio from "@core/components/InputRadio.vue";
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    import InputTextbox from "@core/components/InputTextbox.vue";
    import Panel from "@core/components/Panel.vue";
    import PanelDealAddresses from "@/components/PanelDealAddresses.vue";
    import PanelExportInfo from "@/components/PanelExportInfo.vue";
    import SelectVehicle from "@/components/SelectVehicle.vue";
    import StateHelper from "@core/classes/StateHelper";
    import util from "@core/services/util";

    export default defineComponent({
        name: "PanelVehicleRegistration",
        props: {
            fimenu: {
                type: Object as PropType<FIMenu>,
            },
            validation: {
                type: Object,
            },
        },
        data() {
            return {
                registrationInfo: this.fimenu.vehicle.registrationInfo as FIMenuVehicleRegistrationInfo,
                stateHelper: new StateHelper(),
                vehiclePrimaryUsageEnumHelper: new VehiclePrimaryUsageEnumHelper(),
                vehicleTitledUnderEnumHelper: new VehicleTitledUnderEnumHelper(),
                getDeliveryMethodsEnumHelper: new GetDeliveryMethodsEnumHelper(),
                transferTypesList: new TransferTypesList(),
                allPlateTransactions: this.fimenu.vehicle.registrationInfo
                    .plateTransactions as FIMenuPlateTransactions[],
                plateTypes: [
                    { text: "Regular", value: 1 },
                    { text: "Commercial", value: 2 },
                ],
                loadingVehicleInquiryModal: false,
                VehicleDeliveryMethods,
                VehiclePrimaryUsage,
                freightForwarderInfo: this.fimenu.vehicle.registrationInfo.freightForwarderInfo as FIMenuExportInfo,
                shippingCompanyInfo: this.fimenu.vehicle.registrationInfo.shippingCompanyInfo as FIMenuExportInfo,
            };
        },
        methods: {
            resetRegistrationInfo() {
                this.registrationInfo.plateNumber = null;
                this.registrationInfo.plateState = null;
                this.registrationInfo.currentPlateExpirationDate = null;
                this.registrationInfo.registrationLengthInMonths = null;
            },
            async handleOpenDDiResponseModal() {
                this.loadingVehicleInquiryModal = true;
                await openDDiResponseModal(
                    this.fimenu.storeCode,
                    null,
                    this.fimenu.id,
                    this.registrationInfo.plateNumber,
                    this.registrationInfo.plateState,
                );
                this.loadingVehicleInquiryModal = false;
            },
        },
        computed: {
            util(): typeof util {
                return util;
            },
            tradeIns(): FIMenuTradeIn[] {
                return this.fimenu.tradeIns as FIMenuTradeIn[];
            },
            dealHasTradeIns(): boolean {
                return this.tradeIns.length > 0;
            },
            vehiclesList(): FIMenuTradeIn[] {
                return this.tradeIns.filter(veh => veh.plateNumber && veh.plateState);
            },
            ENUMS(): typeof ENUMS {
                return ENUMS;
            },
            v(): any {
                return this.validation.vehicle.registrationInfo;
            },
            plateTransactionsEnabled(): boolean {
                return IsPlateTransactionsEnabled(this.fimenu);
            },
        },

        async created() {
            this.$watch(
                () => this.registrationInfo.isPlateTransfer,
                (newVal, oldVal) => {
                    if (newVal === false) {
                        this.resetRegistrationInfo();
                    }
                },
                { deep: false, immediate: true },
            );
            if (this.fimenu.hasCoSigner) {
                this.registrationInfo.titledUnder = VehicleTitledUnder.Both;
            } else {
                this.registrationInfo.titledUnder = VehicleTitledUnder.Customer;
            }
        },
        components: {
            Panel,
            InputTextbox,
            InputDate,
            InputNumber,
            InputRichDropdown,
            PanelDealAddresses,
            InputRadio,
            SelectVehicle,
            PanelExportInfo,
        },
    });
</script>
<style>
    .PanelVehicleRegistration > .panel-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: fit-content;
        padding-bottom: 30px;
    }

    .PanelVehicleRegistration .two-sided {
        grid-template-columns: 1fr 1fr;
    }

    .PanelVehicleRegistration .questions-container {
        grid-template-columns: 1fr 1fr 1fr;
        height: auto;
    }

    .PanelVehicleRegistration .two-sided > *,
    .PanelVehicleRegistration .questions-container > * {
        width: 100%;
    }

    .PanelVehicleRegistration .radio-button-inline {
        min-height: 40px;
    }
</style>
