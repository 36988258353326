<template>
    <div class="modalCoBrowsingConnect modal-container">
        <div class="modal-header">
            {{ modalTitle }}
        </div>

        <div class="modal-body">
            <p>{{ bodyMessage }}</p>
            <b v-if="bodyAdditionalMessage">
                {{ bodyAdditionalMessage }}
            </b>
        </div>

        <div class="button-group">
            <button class="button-unwind" @click="cancel">
                <i class="fas fa-undo" /> Return to Customers
            </button>

            <button class="button-save" @click="close(false, false)">
                <i class="fas fa-wifi" /> {{ connectButtonText }}
            </button>

            <button v-if="!isEditor && !isSalesPerson" class="button" @click="close(true, false)">
                <i class="fas fa-eye" /> Connect & Follow
            </button>

            <button v-if="!isEditor" class="button-edit" @click="close(true, true)">
                <i class="fas fa-pencil-alt" /> Send Edit Request
            </button>
        </div>
    </div>
</template>

<script>
    import auth from '@core/services/auth';
    import $modal from '@core/services/modal';

    export default {
        name: 'modalCoBrowsingConnect',
        props: ['modal'],
        data() {
            return {
                isEditor: false,
                currentEditorName: null,
            }
        },
        computed: {
            modalTitle() {
                return (this.isEditor) ? 'Already Connected in Another Tab' : 'Someone Else is Editing the Deal';
            },
            bodyMessage() {
                return (this.isEditor)
                    ? 'You are currently editing this deal in another tab. Would you like to continue editing in this tab?'
                    : `${this.currentEditorName ?? 'Someone'} is currently editing this deal. Would you like to connect to this deal as a Viewer?`;
            },
            bodyAdditionalMessage() {
                return (this.isEditor)
                    ? 'Your other session will be disconnected, and any unsaved data will be lost.'
                    : null;
            },
            connectButtonText() {
                return (this.isEditor) ? 'Continue Editing Here' : 'Connect as Viewer';
            },
            user() {
                return auth.getTokenPayload()
            },
            isSalesPerson() {
                return this.user.Position == "SALES PERSON"

            }
        },
        created() {
            this.isEditor = this.modal.passedData.isEditor;
            this.currentEditorName = this.modal.passedData.editorName;
        },
        methods: {
            close(followEditor, sendEditRequest) {
                $modal.close({ followEditor, sendEditRequest });
            },
            cancel() {
                $modal.cancel();
                window.location.assign('/');
            }
        },
        components: {}
    }
</script>

<style>
    .modalCoBrowsingConnect .button-group {
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 10px;
        border-top: 1px solid var(--main-color);
    }

        .modalCoBrowsingConnect .button-group button {
            max-width: 25%;
        }

        .modalCoBrowsingConnect .button-group button.button-edit {
            filter: hue-rotate(45deg);
        }
</style>