import EnumHelper, { ToListItems } from "@core/helpers/enum-helper";
import { CreditBureaurs, DealType } from "@core/classes/SharedEnums";

export default class CreditBureausEnumsHelper extends EnumHelper<CreditBureaurs> {
    public static toList: ToListItems<CreditBureaurs>[] = [
        {
            display: "Equifax",
            value: CreditBureaurs.Equifax,
        },
        {
            display: "Experian",
            value: CreditBureaurs.Experian,
        },
        {
            display: "TransUnion",
            value: CreditBureaurs.TransUnion,
        },
    ];

    constructor() {
        super(CreditBureausEnumsHelper.toList);
    }
}
