<template>
    <div :class="{'document-packet-actions': true, 'text-error': doc.status == ENUMS.PaperworkDocumentStatus.ErrorGenerating}">
        <TooltipComponent :tooltip="errorMessage" direction="left" maxWidth="450">
            <template #showFromOutside>
                <div v-if="documentWasGenerated" :class="{'has-field-error': hasError, 'has-impact-doc': needsWetSignature}">
                    <button v-if="canSelectDocument(doc)"
                        :title="needsWetSignature ? 'Sign Document' : 'Preview Document'" 
                        class="download-button ignore-all-locks" 
                        :disabled="paperworkBusy" 
                        @click="() => executeView(doc)"
                    >
                        <i :class="['fas', needsWetSignature ? 'fa-file-signature' : 'fa-eye']" />
                    </button>

                    <ButtonLoading v-else 
                        title="Download Document" 
                        class="download-button ignore-all-locks" 
                        :isLoading="paperworkBusy || isDownloading" 
                        iconOnly 
                        @click="executeDownload"
                    >
                        <i class="fas fa-download" />
                    </ButtonLoading>
                </div>

                <div v-else class="messageContainer">
                    <button class="download-button button-unwind" :disabled="!$global.isAdminView" @click="() => regenerateDocument(doc)">
                        <i class="fas fa-exclamation-triangle" style="color: white;" />
                    </button>
                </div>
            </template>
        </TooltipComponent>
    </div>
</template>

<script>
    import api from '@core/services/api';
    import auth from "@core/services/auth";
    import ENUMS from '@core/classes/Enums';
    import util from '@core/services/util';

    import ButtonLoading from '@core/components/ButtonLoading.vue';
    import TooltipComponent from '@core/components/TooltipComponent.vue'

    export default {
        name: "DocumentPacketActions",
        components: {
            ButtonLoading,
            TooltipComponent,
        },
        props: {
            doc: Object,                            // PaperworkDocument.cs 
            paperworkBusy: Boolean, 
            isCurrent: Boolean, 
            canSelectDocument: Function,            // (doc: PaperworkDocument) => boolean
            downloadDocument: Function,             // (docPath: string, docDescription: string, documentConfigId: string, isCurrent: boolean) => void
            openPreviewModal: Function,             // (packetDocuments: string[], uploadedDocuments: PaperworkDocument[]) => void
            openPreviewModalForImpact: Function,    // (docId: string, docForDisplay: string, docForImpact: string) => void
            regenerateDocument: Function            // (doc: PaperworkDocument) => void
        },
        data() {
            return {
                isDownloading: false
            }
        },
        computed: {
            ENUMS() {
                return ENUMS;
            },
            user() {
                return auth.getTokenPayload();
            },
            documentWasGenerated() {
                return !!(this.doc.filledDoc || this.doc.signedDoc || this.doc.docInfo);
            },
            needsWetSignature() {
                return this.isCurrent && !!this.doc.impactDoc && this.doc.status !== ENUMS.PaperworkDocumentStatus.Signed;
            },
            hasError() {
                return this.doc.error != undefined && this.doc.error != null;
            },
            errorMessage() {
                const message = [];

                if (!this.documentWasGenerated && this.user.EmployeeAccess.IsAdmin) 
                    message.push(...["Please click to regenerate document", ""]);
                if (this.doc?.error?.errorDescription) 
                    message.push(this.doc.error.errorDescription);

                return message;
            }
        },
        methods: {
            executeView(doc) {
                if (doc.impactDoc) {
                    this.openPreviewModalForImpact(doc.id, doc.signedDoc ?? doc.filledDoc, doc.impactDoc, doc.impactDocPdf);
                }
                else {
                    const packetDocuments = [];
                    const uploadedDocuments = [];
                    
                    if (doc.documentGenerationType == ENUMS.DocumentGenerationType.Paperwork) {
                        packetDocuments.push(doc.signedDoc ?? doc.filledDoc);
                    } 
                    else if (doc.documentGenerationType == ENUMS.DocumentGenerationType.Uploaded) {
                        uploadedDocuments.push(doc);
                    }
                    
                    this.openPreviewModal(packetDocuments, uploadedDocuments);
                }
            },
            async executeDownload() {
                this.isDownloading = true;

                try {
                    switch (this.doc.documentGenerationType) {
                        case ENUMS.DocumentGenerationType.Paperwork: {
                            const docPath = this.doc.signedDoc || this.doc.filledDoc;
                            const docDescription = this.doc.description || this.doc.typeString;
                            this.downloadDocument(
                                docPath,
                                docDescription,
                                this.doc.paperworkDocumentConfigId,
                                this.isCurrent
                            );
                            break;
                        }
                        case ENUMS.DocumentGenerationType.Uploaded: {
                            await api.documents.downloadDocument(this.doc.id, this.doc.docInfo.fileName);
                            break;
                        }
                        default:
                            throw new Error('Unsupported document generation type', { cause: `Type: ${this.doc.documentGenerationType}` });
                    }
                } 
                catch (error) {
                    util.toastr('error', 'Error Downloading', 'Unable to download document.');
                    console.error('Error during document download:', error);
                } 
                finally {
                    this.isDownloading = false;
                }
            }
        }
    };
</script>
<style>
    .document-packet-actions .download-button {
        width: 55px;
        border-radius: var(--default-border-radius); 
    }

    .document-packet-actions .has-impact-doc .download-button {
        background-color: var(--success-color);
    }
</style>