<template>
    <Panel v-bind="{ title: 'Lender Applications' }" class="panel-routeOneDealerTrack">
        <IsBusyScreenComponent v-if="isLoading" />
        <div :style="$grid('1')">
            <div
                :style="$grid(canSubmitCreditApplication || isEvaluatingExpresions ? '5-1' : '1')"
                class="credit-application-lender-wrapper no-margin-grid"
            >
                <InputRichDropdown
                    label="Select a lender"
                    lazyLoad
                    :list="sortedLenderList"
                    :valueMap="valuePredicate"
                    :display="displayPredicate"
                    :desc="descriptionPredicate"
                    v-model:saturn="dropdownSelectedLender"
                    :sortMode="ENUMS.DROPDOWN_SORT_MODE.ORIGINAL_ORDER"
                    :invalid="!fimenu.dealType"
                    :disabled="!fimenu.dealType || isEvaluatingExpresions || loadingCustomFields"
                    search
                    class="ignore-deal-lock"
                    @change="handleLenderChange"
                >
                    <template #validation>
                        <span>You must select a deal type before selecting a lender.</span>
                    </template>
                </InputRichDropdown>
                <div v-if="isEvaluatingExpresions || loadingCustomFields">
                    <i class="fas fa-spinner fa-spin" />
                </div>
                <button
                    v-else-if="canSubmitCreditApplication"
                    :disabled="!dropdownSelectedLender"
                    class="button-block"
                    @click="openSendModal"
                >
                    Send
                </button>
            </div>
            <div style="height: 15px; color: var(--error-color); font-weight: bold; text-align: center">
                <PanelCreditApplicationErrorMessage
                    :canSubmitCreditApplication
                    :creditApplications
                    :fimenu-lender-code="fimenu.lender?.lenderCode"
                    :selected-lender="dropdownSelectedLender ?? null"
                />
            </div>
            <RichTable
                v-if="
                    canSubmitCreditApplication ||
                    (fimenu.dealJacket.applications.length && !dealLenderInformation) ||
                    hasSelectedApplication()
                "
                :hidePaging="true"
                :hideHeader="true"
                :tableData="creditApplications"
                :headers="header"
                :paging="true"
            >1
                <template #checkbox="row">
                    <InputCheckbox
                        :useSquare="true"
                        :isControlled="true"
                        :modelValue="row.selected"
                        cssClass="button-nothing button-nothing-green"
                        @click="() => richTableItemSelected(row)"
                        :invalid="!fimenu.dealJacket.applications.some(x => x.selected)"
                        :disabled="row.lender.lenderCode == null || fimenu.dealStatus >= ENUMS.DEAL_STATUS.ACCOUNTING_IN_PROCESS"
                        class="ignore-deal-lock"
                    >
                    </InputCheckbox>
                </template>

                <template #creditAppData="row">
                    <div style="display: flex; flex-direction: column; gap: 1px">
                        <span style="font-size: 14px; font-weight: 600">{{ lenderName(row) }}</span>
                        <span style="font-size: 12px">{{ CreditApplicationType[row.type] }}</span>
                        <span style="font-size: 12px">{{
                            row.dateCreated ? util.toMoment(row.dateCreated).format("MM/DD/YYYY hh:mma") : ""
                        }}</span>
                    </div>
                </template>
            </RichTable>
            <LenderInformation
                v-else-if="
                    reserveProfitCalcs &&
                    reserveProfitCalcs.length &&
                    dealLenderInformation &&
                    !canSubmitCreditApplication
                "
                :deal="fimenu"
                :selectedLender="dealLenderInformation"
                :validation="validation"
                :lendersList="lendersList"
                :reserveProfitCalcs="reserveProfitCalcs"
            />
        </div>
    </Panel>
</template>

<script setup lang="ts">
    import { BookValue, DealType, FinanceChannelsProvider } from "@core/classes/SharedEnums";
    import { computed, inject, ref, watch } from "vue";
    import { CreditApplicationDecision, CreditApplicationType } from "@core/classes/CreditApplication";
    import { CreditApplicationsEnabled, RetrieveCustomFields, SubmitCreditApplication } from "@/helpers/finance-channels-helper";
    import util, { EventBusCore } from "@core/services/util";
    import $modal from "@core/services/modal";
    import _ from "underscore";
    import api from "@core/services/api";
    import ENUMS from "@core/classes/Enums";
    import FIDealJacketAdditionalInformation from "@core/classes/FIDealJacketAdditionalInformation";
    import { FIDynamicFields } from "@core/classes/FIDynamicFields";
    import FIMenu from "@core/classes/FIMenu";
    import FIMenuCreditApplication from "@core/classes/FIMenuCreditApplication";
    import FIMenuLender from "@core/classes/FIMenuLender";
    import InputCheckbox from "@core/components/InputCheckbox.vue";
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    import IReserveProfitCalc from "@core/classes/IReserveProfitCalc";
    import IsBusyScreenComponent from "@core/components/IsBusyScreenComponent.vue";
    import LenderDraft from "@core/classes/LenderDraft";
    import LenderInformation from "@/components/LenderInformation.vue";
    import LenderLease from "@core/classes/LenderLease";
    import LenderRetail from "@core/classes/LenderRetail";
    import modalRouteOneSend from "@/modals/modalRouteOneSend.vue";
    import modalRouteOneView from "@/modals/modalRouteOneView.vue";
    import Panel from "@core/components/Panel.vue";
    import PanelCreditApplicationErrorMessage from "./PanelCreditApplicationErrorMessage.vue";
    import PartnerCode from "@core/classes/Store/PartnerCode";
    import RichTable from "@core/components/RichTable.vue";
    import { SaturnGlobalPlugin } from "@core/classes/StaticClasses";
    import SaturnResponse from "@core/classes/Responses/SaturnResponse";
    import VersionsLender from "@core/classes/LenderVersionClass";

    //#region Global Variables
    const $global = inject<SaturnGlobalPlugin>("$global");
    //#endregion


    const props = defineProps<{
        fimenu?: FIMenu | null;
        validation?: any;
    }>();

    const r1DealerId = ref<string | null>(
        props.fimenu.store.partnerCodes.find((p: PartnerCode) => p.type === ENUMS.PARTNER_CODES.ROUTEONE_DEALER_ID)
            ?.code,
    );
    const r1PartnerId = ref<string | null>(
        props.fimenu.store.partnerCodes.find((p: PartnerCode) => p.type === ENUMS.PARTNER_CODES.ROUTEONE_PARTNER_ID)
            ?.code,
    );
    const dropdownSelectedLender = ref(null);
    const lendersList = computed<VersionsLender[]>(() => $global.Lenders ?? []);
    const header = [
        {
            name: "selected",
            display: "",
            cssClass: "action-column1",
            slot: "checkbox",
        },
        {
            name: "data",
            display: "",
            slot: "creditAppData",
        },
        //    {name:'selected',display:"Selected"},
    ] as any[];
    const creditAppFieldsResponse = ref<any>(null);
    const isLoading = ref(false);
    const isEvaluatingExpresions = ref(false);
    const reserveProfitCalcs = ref<IReserveProfitCalc[]>([]);

    const loadingCustomFields = ref(false)
    const lenderCustomFields = ref<FIDynamicFields>(new FIDynamicFields());

    const canSubmitCreditApplication = computed(() => {
        const r1Code = dropdownSelectedLender.value?.getLenderByDealType(props.fimenu.dealType)?.getR1Code();
        if (r1Code == null) return false;
        return associatedFinanceSources.includes(r1Code);
    });

    const sortedLenderList = computed(() => {
        return [...filteredLenderList.value].sort((l1, l2) => {
            // Get the lenderExpressionVersion for both lenders
            const version1 = l1.versions[0].getLenderByDealType(props.fimenu.dealType);
            const version2 = l2.versions[0].getLenderByDealType(props.fimenu.dealType);

            // Get R1Codes
            const r1Code1 = version1?.getR1Code();
            const r1Code2 = version2?.getR1Code();

            // Handle cases where both R1Codes are null
            if (r1Code1 === null && r1Code2 === null) {
                return l1.lenderName.localeCompare(l2.lenderName); // Fallback to lenderName sorting
            }

            // Handle cases where only one R1Code is null
            if (r1Code1 === null) return 1; // Put l1 after l2
            if (r1Code2 === null) return -1; // Put l1 before l2

            // Handle cases where R1Codes match the associated finance sources
            const isAssociated1 = associatedFinanceSources.includes(r1Code1);
            const isAssociated2 = associatedFinanceSources.includes(r1Code2);

            if (isAssociated1 && !isAssociated2) return -1; // Place associated lender first
            if (!isAssociated1 && isAssociated2) return 1; // Place associated lender second

            // If both or neither are associated, fallback to lenderName sorting
            return l1.lenderName.localeCompare(l2.lenderName);
        });
    });
    const filteredLenderList = computed<VersionsLender[]>(() => {
        return lendersList.value.filter(x => {
            const lenderByDealType = x.getLenderByDealType(dealType.value);

            // Add the lenders that have R1 code setup for this store, regardless of the divisions.
            if (associatedFinanceSources.includes(lenderByDealType.getR1Code())) {
                return true;
            }

            // Filter lenders without division.
            // We should use getDefaultDivision when we use proper division types.
            if (lenderByDealType.divisions.length <= 0) return false;
            if (x.lenderCode == "UNLISTED") return false;

            return true;
        });
    });
    const creditApplications = computed<FIMenuCreditApplication[]>(() => {
        const returnvalue = props.fimenu.dealJacket.applications.filter((ca: FIMenuCreditApplication) =>
            ca.MatchesDealType(props.fimenu.dealType),
        );
        return _.sortBy(returnvalue, (r: FIMenuCreditApplication) => r.dateCreated).reverse();
    });
    const dealType = computed<DealType>(() => {
        return props.fimenu.isFinance() ? DealType.Retail : props.fimenu.isLease() ? DealType.Lease : DealType.Draft;
    });

    const dealLenderInformation = computed<VersionsLender | null>(() =>
        filteredLenderList.value.find(x => x.lenderCode == props.fimenu.lender.lenderCode),
    );

    header.push({
        name: "action",
        display: "",
        sortable: false,
        cssClass: "open-credit-app",
        actions: [
            {
                name: "error",
                type: (c: any) => (c.errorResponse != null ? "button" : null),
                icon: "fa-exclamation-triangle",
                cssClass: "button button-nothing clickable button-nothing-red ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
            {
                name: "approved",
                type: (c: any) =>
                    c.errorResponse == null && c.status == CreditApplicationDecision.Approved ? "button" : null,
                icon: "fa-thumbs-up",
                cssClass: "button button-nothing clickable button-nothing-green ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
            {
                name: "denied",
                type: (c: any) =>
                    c.errorResponse == null && c.status == CreditApplicationDecision.Denied ? "button" : null,
                icon: "fa-thumbs-down",
                cssClass: "button button-nothing clickable button-nothing-red ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
            {
                name: "conditional",
                type: (c: any) =>
                    c.errorResponse == null && c.status == CreditApplicationDecision.Condition ? "button" : null,
                icon: "fa-exchange-alt",
                cssClass: "button button-nothing clickable button-nothing-orange ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
            {
                name: "preliminary",
                type: (c: any) =>
                    c.errorResponse == null && c.status == CreditApplicationDecision.Preliminary ? "button" : null,
                icon: "fas fa-circle-notch fa-spin",
                cssClass: "button button-nothing clickable button-nothing-orange ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
            {
                name: "withdrawn",
                type: (c: any) =>
                    c.errorResponse == null && c.status == CreditApplicationDecision.Withdrawn ? "button" : null,
                icon: "fa-trash",
                cssClass: "button button-nothing clickable button-nothing-red ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
            {
                name: "booked",
                type: (c: any) =>
                    c.errorResponse == null && c.status == CreditApplicationDecision.Booked ? "button" : null,
                icon: "fa-solid fa-coins",
                cssClass: "button button-nothing clickable button-nothing-green ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
            {
                name: "waiting",
                type: (c: any) =>
                    c.errorResponse == null && (c.status == null || c.status == CreditApplicationDecision.Pending)
                        ? "button"
                        : null,
                icon: "fas fa-spinner fa-spin",
                cssClass: "button button-nothing button-nothing-red ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
            {
                name: "new",
                type: (c: any) =>
                    c.errorResponse == null && (c.status == null || c.status == CreditApplicationDecision.New)
                        ? "button"
                        : null,
                icon: "fas fa-spinner fa-spin",
                cssClass: "button button-nothing clickable button-nothing-red ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
            {
                name: "contract_received",
                type: (c: any) =>
                    c.errorResponse == null &&
                    (c.status == null || c.status == CreditApplicationDecision.ContractRecevied)
                        ? "button"
                        : null,
                icon: "fa-solid fa-file-contract",
                cssClass: "button button-nothing clickable button-nothing-green ignore-all-locks",
                action: (row: any) => {
                    openViewModal(row);
                },
            },
        ],
    });

    const lenderName = (ca: FIMenuCreditApplication) => {
        return ca.lender.lenderName ?? "Unknown lender";
    };
    const openSendModal = async () => {
        if (await props.fimenu.checkForFraud()) return;

        $modal.open(modalRouteOneSend, {
            name: "modalRouteOneSend",
            passedData: { 
                fimenu: props.fimenu, 
                lender: dropdownSelectedLender,
                loading: loadingCustomFields.value,
                customFields: lenderCustomFields.value
             },
            backdrop: false,
            postFunction: (additionalInformation: FIDealJacketAdditionalInformation) => send(dropdownSelectedLender.value, additionalInformation),
        });
    };
    const openViewModal = (ca: FIMenuCreditApplication) => {
        if (ca.status == null || ca.status == CreditApplicationDecision.Pending) return;

        $modal.open(modalRouteOneView, {
            name: "modalRouteOneView",
            passedData: { row: ca, fimenu: props.fimenu },
            backdrop: false,
        });
    };
    const fetchDealFromQuote = async (dealId: string) => {
        try {
            isLoading.value = true;
            const response = await api.fimenu.getDeal(dealId);

            if (response.data) {
                props.fimenu.copyFromQuote(response.data.fimenu);
            }
        } finally {
            isLoading.value = false;
        }
    };
    const hasSelectedApplication = () => {
        return !!props.fimenu?.dealJacket?.applications?.some(x => x.selected);
    };
    const richTableItemSelected = async (selectedData: Partial<FIMenuCreditApplication>) => {
        props.fimenu.dealJacket.providerId = selectedData.dealJacketId;

        props.fimenu.dealJacket.applications.forEach(c => {
            c.selected = c.id === selectedData.id;
        });

        const selectedLender = lendersList.value?.find(x => x.lenderCode == selectedData.lender.lenderCode);
        const selectedLenderInformationForTheDeal = selectedLender?.versions[0]?.getLenderByDealType(
            props.fimenu.dealType,
        );
        
        props.fimenu.lender = new FIMenuLender({
            divisions: selectedLenderInformationForTheDeal?.divisions ?? [],
            lenderCode: selectedData.lender.lenderCode,
            lenderContacts: selectedLenderInformationForTheDeal?.lenderContacts ?? [],
            lenderName: selectedData.lender.lenderName,
            selectedProvider: selectedData?.selectedProvider ?? FinanceChannelsProvider.RouteOne, // When we get new providers; this needs to update to the provider.
        });

        props.fimenu.dealJacket.purchaseVehicleBookValue = selectedData.vehicleBookValue;

        //// This will preload the current deal with the quote deal information.
        //await this.fetchDealFromQuote(selectedData.dealId);
    };

    const fetchDynamicFields = async (lenderCode: string) => {
    
        try { 
        
            loadingCustomFields.value = true
            const tokenId = "get-getCustomFields";
            api.cancel(tokenId);

            const customFields = await RetrieveCustomFields(props.fimenu, lenderCode, "get-getCustomFields");
            lenderCustomFields.value = customFields;
        } catch(err) { 
            console.error(err)
        } finally {
            loadingCustomFields.value = false
            // Reset cancel token
        }
    }

    const handleLenderChange = async (lender: VersionsLender) => {
        const lenderInfo = lender.getLenderByDealType(props.fimenu.dealType); // either lenderRetail, lenderLease or lenderdraft.
        let forceAssignLender = false;
        let providerLendercode = lenderInfo.getR1Code();

        if (lenderInfo.hasExpression()) {
            // If the lender has an expression.
            const providerLendercode = await handleFetchPartnerId(lenderInfo, lender.lenderCode); // Get the lender code as response.
            if (providerLendercode == null) forceAssignLender = true; // If we have an expression and we don't get any value; then it should grab the default.
        }

        // If we selected a lender that is not available for submission.
        if (!canSubmitCreditApplication.value || forceAssignLender) {
            updateLenderOnFIMenu(lender); // Update FIMenu as it goes without credit applications
            setAllPaperworkToMustRepopulate(); // Since the lender changed. IF we have paperwork; they must repopulate.
            setMaxDaysToFirstPayment(lender); // Set the days for max payment.
            creditApplications.value.forEach(x => (x.selected = false)); // Clear credit applications selection.
            saveDealIfNeeded(); // Will save the deal if required.
        } else {
            // If we select a lender that is available for submission
            fetchDynamicFields(providerLendercode)
            setMaxDaysToFirstPayment(lender); // Set the days for max payment.
            props.fimenu.lender = new FIMenuLender(); // Clear FIMenuLender.
            creditApplications.value.forEach(x => (x.selected = false)); // Clear credit applications selection.
        }
        EventBusCore.emit('recalculate');
    };

    const handleFetchPartnerId = async (lender: LenderRetail | LenderLease | LenderDraft, lenderCode: string): Promise<string | null> => {
        try {
            isEvaluatingExpresions.value = true;
            const response = await api.lenders.getLenderPartnerId(props.fimenu, lenderCode);

            return response.data;
        } catch (ex) {
            console.error(ex);
        } finally {
            isEvaluatingExpresions.value = false;
        }
    };
    const updateLenderOnFIMenu = (lender: VersionsLender) => {
        // Copy from PanelLEnder.
        const lenderVersion = lender.getLenderByDealType(dealType.value); // Get the version of the lender. Either Retail, Lease or Cash.
        const selectedLenderIsDifferent = lender.lenderCode !== props.fimenu.lender.lenderCode; // wether the lender is the same.

        if (selectedLenderIsDifferent) {
            // Create FImenuLender and assign it to the deal.
            props.fimenu.lender = new FIMenuLender({
                    divisions: lenderVersion.divisions,
                    lenderContacts: lenderVersion.lenderContacts,
                    lenderCode: lender.lenderCode,
                    lenderName: lender.lenderName,
                selectedProvider:
                    lenderVersion.getR1Code() != null ? FinanceChannelsProvider.RouteOne : FinanceChannelsProvider.None,
            });
        }
    };
    const saveDealIfNeeded = () => {
        const isLenderInvalid = util.isNull(props.validation) ? false : props.validation.lender.lenderCode.$invalid;

        if (!isLenderInvalid && props.fimenu.isDealLocked() && !props.fimenu.isSpectator) {
            props.fimenu.save();
        }
    };
    const setMaxDaysToFirstPayment = (lender: VersionsLender) => {
        const lenderVersion = lender.getLenderByDealType(dealType.value);

        if (lenderVersion && "maxDaysToFirstPayment" in lenderVersion && lenderVersion.maxDaysToFirstPayment) {
            props.fimenu.loanTerms.maxDaysToFirstPayment = lenderVersion.maxDaysToFirstPayment;
        } else {
            props.fimenu.loanTerms.maxDaysToFirstPayment = 45;
        }
    };
    const setAllPaperworkToMustRepopulate = () => {
        //set all current packet document to must repopulate
        if (props.fimenu.hasPaperworkPacket()) {
            props.fimenu.paperwork.currentPacket().documents.forEach((d: any) => {
                d.mustRepopulate = true;
            });
        }
    };
    const getLatestCreditAppFieldsResponse = () => {
        if (!$global.r1CreditAppField) return;
        creditAppFieldsResponse.value = util.clone($global.r1CreditAppField);
    };
    const send = async (lender: VersionsLender, additionalInformation: FIDealJacketAdditionalInformation) => {
        // You could still see this component if you have previous credit applications. But cannot send new one if we
        // dont have credentials or is disabled.
        if (!CreditApplicationsEnabled(props.fimenu))
            return util.toastr("error", "Error", "Cannot send credit applications. Currently disabled.");

        if (props.fimenu.isSpectator) return false;

        isLoading.value = true;
        try {

            const response = await SubmitCreditApplication(
                props.fimenu,
                additionalInformation,
                lender
            );

            if (response?.statusCode < 400) {
                const creditApp = new FIMenuCreditApplication(response.payload);
                props.fimenu.dealJacket.applications.push(creditApp);
                props.fimenu.dealJacket.providerId = creditApp.dealJacketId;

                util.toastr("success", "Credit Application", response.messages.pop() ?? "Submitted successfully.");
                dropdownSelectedLender.value = null;
            } else {
                util.toastr(
                    "error",
                    "Credit Application",
                    response.messages.pop() ?? "There was an error submitting your credit application",
                );
                console.error(JSON.stringify(response));
            }
        } catch (err) {
            console.error(err);
        } finally {
            isLoading.value = false;
        }
    };
    const valuePredicate = (l: VersionsLender) => {
        return l;
    };
    const comparerPredicate = (a: VersionsLender, b: VersionsLender) => {
        return a && b && a.lenderCode === b.lenderCode;
    };

    const displayPredicate = (l: VersionsLender) => {
        return l.lenderName;
    };
    const descriptionPredicate = (l: VersionsLender) => {
        const version = l.getLenderByDealType(props.fimenu.dealType);

        if (associatedFinanceSources.includes(version.getR1Code()) || version.hasExpression()) {
            return "R1";
        }

        return null;
    };
    const getReserveProfitCalcs = async () => {
        const { data } = await api.settings.getGetReserveProfitCalcs();
        if (data && Array.isArray(data)) reserveProfitCalcs.value = data;
        else console.error("Couldn't get reserve profit calcs. Wrong Data?");
    };

    getLatestCreditAppFieldsResponse();
    // Get finance sources that allow AutoSubmit
    const dealersThatAllowAutoSubmit =
        creditAppFieldsResponse.value?.financeSources ??
        []
            .filter((source: any) => source.services.some((service: any) => service.name === "AutoSubmit"))
            .map((source: any) => source.fsId);

    // Find the dealer that matches the current routeOneDealerId
    const dealer = creditAppFieldsResponse.value?.dealers.find((d: any) => d.routeOneDealerId === r1DealerId.value);

    // Get associated finance sources that are allowed for AutoSubmit
    const associatedFinanceSources = dealer
        ? dealer.associatedFinanceSources.filter((fsId: string) => {
              return dealersThatAllowAutoSubmit.some((obj: any) => obj.fsId == fsId);
          })
        : [];

    if (!hasSelectedApplication())
        dropdownSelectedLender.value = lendersList.value.find(x => x.lenderCode == props.fimenu.lender.lenderCode);

    // This provides feedback for the specctator when the editor changes lender.
    watch( () => props.fimenu.lender.lenderCode, (newVal, oldVal) => {
        if(props.fimenu.isSpectator) {
            dropdownSelectedLender.value = lendersList.value.find(x => x.lenderCode == props.fimenu.lender.lenderCode);
        }
    })

    props.fimenu.loadLatestestCreditDecision();
    getReserveProfitCalcs();
</script>
<style>
    .panel-routeOneDealerTrack {
        height: 100%;
    }
    .panel-routeOneDealerTrack .action-column1 {
        width: 28px;
    }

    .panel-routeOneDealerTrack .open-credit-app {
        width: 43px;
    }

    .credit-application-lender-wrapper {
        align-items: center;
    }

    .panel-routeOneDealerTrack .rich-table-rows .action-cell button {
        width: 40px;
        height: 40px;
        justify-content: space-between;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lender-selector {
        font-size: 25px;
    }

    .input-checkbox-container {
        display: block;
        max-width: 28px;
    }
</style>
