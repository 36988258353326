<template>
      <TooltipComponent v-if="doc.documentGenerationType == ENUMS.DocumentGenerationType.Paperwork" :tooltip="getProductDetails(doc)" direction="right" maxWidth="450">
        <template #showFromOutside>
            <div v-if="doc.description" >
                {{ doc.description }} <i v-if="doc.isLenderSpecific && user && user.EmployeeAccess.IsAdmin" class="fas fa-university" />
            </div>
            <div v-else>
                <span class="defaultCursor">{{ fimenu.getPaperDocumentName(doc) }}</span>
            </div>

            <div v-if="doc.type != ENUMS.PAPERWORK_DOCUMENT_TYPES.PaperworkDocument && doc.type != ENUMS.PAPERWORK_DOCUMENT_TYPES.AcceptDecline" class="defaultCursor">
                Provider: {{ getPaperworkDocType(doc).provider || doc.typeString }}
            </div>

            <div v-if="doc.impactDoc && documentCategoryName" class="defaultCursor">
                <em>{{ documentCategoryName }}</em>
            </div>

            <div v-if="$global.isAdminView" class="admin-details">
                <div v-if="doc.paperworkDocumentConfigId" class="details-wrapper">
                    <div class="latest-version">
                        <strong>Config Id:</strong><span>{{ doc.paperworkDocumentConfigId }}</span>
                    </div>

                    <div class="latest-version">
                        <strong>Latest version:</strong>

                        <a v-if="showDocConfigLink" :href="docConfigPageUrl" target="_blank">{{ extraConfigInfo.isLatest ? "Current" : extraConfigInfo.latestId }}</a>
                        <span v-else-if="extraConfigInfo">{{ extraConfigInfo.isLatest ? "Current" : extraConfigInfo.latestId }}</span>
                        <button title="More info" @click="() => setExtraConfigInfo(doc)">
                            <i class="fas fa-info"></i>
                        </button>
                    </div>
                </div>

                <span v-else><strong>Config Id:</strong> N/A</span>
            </div>
        </template>
    </TooltipComponent>

    <div v-else-if="doc.documentGenerationType == ENUMS.DocumentGenerationType.Uploaded" class="defaultCursor">
        <span class="defaultCursor">{{ doc.display }}</span>
        <br/>
        <span v-if="uploadedDocumentName">{{ uploadedDocumentName }}</span>
    </div>

</template>

<script>
    import api from "@core/services/api";
    import settings from 'settings';

    import ENUMS from "@core/classes/Enums"
    import FIMenu from '@core/classes/FIMenu';
    import util from '@core/services/util';

    import TooltipComponent from '@core/components/TooltipComponent.vue'

    export default {
        name: "DocumentPacketDocumentName",
        components: {
            TooltipComponent
        },
        props: {
            doc: Object,                    // PaperworkDocument.cs
            getProductDetails: Function,    // (doc: PaperworkDocument) => string[]
            user: Object,                   // AccessEmployeeClass.cs
            getPaperworkDocType: Function,  // (doc: PaperworkDocument) => { productType: number, provider: string, typeString: string }
            fimenu: FIMenu
        },
        data() {
            return {
                extraConfigInfo: null
            }
        },
        computed:{
            ENUMS() {
                return ENUMS
            },
            uploadedDocumentName() {
                // Returns the customers names associated to the document.
                const documentAssociations = this.doc?.associations?.filter(asoc => asoc?.type == ENUMS.DOC_ASSOCIATION.Customer);
                if (!documentAssociations || documentAssociations.length === 0) return null;

                const customerNames = [];

                for (const association of documentAssociations) {
                    const customerId = association?.id;
                    if (util.isNullOrEmpty(customerId)) continue;

                    if (this.fimenu.customer?.id === customerId) {
                        const customerName = this.fimenu.customer?.getFullName()?.toUpperCase();
                        if (customerName) customerNames.push(customerName);
                    }

                    if (this.fimenu.coCustomer?.id === customerId) {
                        const coCustomerName = this.fimenu.coCustomer?.getFullName()?.toUpperCase();
                        if (coCustomerName) customerNames.push(coCustomerName);
                    }
                }

                if (customerNames.length < 1) return null;

                // Not sure if a document could match both customers. But the code is very flexible
                // and allows it; therefor added support for it.
                return `Customer(s): ${customerNames.join(' & ')}`;
            },
            docConfigPageUrl() {
                const docID = this.extraConfigInfo.isLatest ? this.doc.paperworkDocumentConfigId : extraConfigInfo.latestId;
                const docUrl = this.$router.resolve({
                    name: "utilities-manage-base-paperwork",
                    query: { docID },
                });
                return docUrl.href;
            },
            documentCategoryName() {
                const category = settings.lookups.paperworkCategories.find(cat => cat.id === this.doc.categoryId);
                return category.description;
            },
            showDocConfigLink() {
                return settings.environmentName !== 'PRODUCTION' && this.extraConfigInfo;
            },
        },
        methods: {
            async setExtraConfigInfo(doc) {
                const response = await api.utilities.getActiveDocConfigForCode(doc.paperworkDocumentConfigId)
                const activeDocConfigId = response.data?.id

                this.extraConfigInfo = {
                    id: doc.paperworkDocumentConfigId,
                    isLatest: doc.paperworkDocumentConfigId === activeDocConfigId,
                    latestId: activeDocConfigId
                }
            }
        }
    };
</script>

<style scoped>
    .admin-details{
        margin-top: 5px;
        font-size: 12px;
    }

        .admin-details .details-wrapper {
            display: flex;
            flex-direction: column;
        }

        .admin-details .details-wrapper button {
            padding: 0;
            width: 15px;
            height: 15px;
            min-height: unset;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 8px;
        }

        .admin-details .details-wrapper .latest-version {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 5px;
        }
</style>