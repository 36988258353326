<template>
    <div class="modalInfo">
        <div :class="{'modal-container': true, 'modal-info': true, 'force-fit-content': fitContent}" :style="{zIndex: '9999999999'}">
            <div class="modal-header">
                {{ title }}
            </div>
            <div class="modal-body">
                <div v-if="Array.isArray(info)" class="modal-body-info" :style="$grid('1')">
                    <span v-for="(i, index) in info" :key="index">{{ i }}</span>
                </div>
                <div v-else class="modal-body-info"><p>{{ info }}<EllipsisAnimated /></p></div>
                <div v-if="additionalInfo" class="modal-body-info modal-body-info-important">{{ additionalInfo }}</div>
                <i class="fas fa-spinner fa-spin" />
            </div>
            <div class="modal-footer">
                <button v-if="cancelText" :class="cancelTextClass ? cancelTextClass : 'button-unwind'" @click="cancel()">{{ cancelText }}</button>
                <button v-if="acceptText" :class="acceptTextClass ? acceptTextClass : 'button-save'" @click="close()">{{ acceptText }}</button>
            </div>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'
    import EllipsisAnimated from '@core/components/UI/EllipsisAnimated.vue';

    export default {
        name: "modalResendingMessage",
        props: ['modal'],
        data() {
            return {
                title: 'Information',
                info: null,
                cancelText: null,
                cancelTextClass: null,
                acceptText: null,
                acceptTextClass: null,
                additionalData: null,
                additionalInfo: null,
                fitContent: false
            };
        },
        computed: {

        },
        created() {
            this.info = this.modal.passedData.info;
            this.acceptText = this.modal.passedData.acceptText;
            this.acceptTextClass = this.modal.passedData.acceptTextClass;
            this.cancelText = this.modal.passedData.cancelText;
            this.cancelTextClass = this.modal.passedData.cancelTextClass;
            this.additionalData = this.modal.passedData.additionalData;
            this.additionalInfo = this.modal.passedData.additionalInfo;
            this.fitContent = this.modal.passedData.fitContent ?? false;

            if (this.modal.passedData.title)
                this.title = this.modal.passedData.title;
        },
        mounted(){

        },
        methods: {
            close() {
                $modal.close(this.additionalData);
            },
            cancel() {
                if (typeof this.modal.passedData.cancelFunction == "function") {
                    this.modal.passedData.cancelFunction()
                }
                $modal.cancel();
            }
        },
        components: {
            EllipsisAnimated
        }

    };
</script>
<style>
.fas.fa-spinner {
    font-size: 18px;
}

</style>
