<template>
    <div class="modal-container modal-large">
        <div class="modal-header">Send Credit Application to {{ modal.passedData.lender.lenderName }}</div>
        <div class="modal-body">
            <div :style="$grid(modal.passedData.fimenu.isFinance() ? '1-1' : '3-4')">
                <Panel :style="$grid('1')" title="Vehicle Valuation" :isSection="true">
                    <!-- <BookValueCRUD v-if="modal.passedData.fimenu.inventoryType != 'New'" :BookValues="BookValues" ref="BookValueCrud"/> -->
                    <div :style="$grid('1-1')">
                        <InputRichDropdown
                            label="Select a Valuation Company"
                            class="grid-2-column"
                            v-model:saturn="submissionData.selectedBookValue"
                            :list="BookValuesDropdownValues"
                            :valueMap="valuePredicate"
                            :display="displayPredicate"
                            :invalid="v$.submissionData.selectedBookValue.$invalid"
                            search
                        >
                            <template #validation>
                                <span v-if="!submissionData.selectedBookValue">
                                    Please select which vehicle value to submit
                                </span>
                                <span v-else>
                                    <div v-if="v$.submissionData?.selectedBookValue.$invalid">{{v$.submissionData?.selectedBookValue.$silentErrors[0].$message}}</div>
                                </span>
                            </template>
                        </InputRichDropdown>
                        <InputCurrency
                            label="MSRP/Retail"
                            :saturn="BookValueSelected?.retail"
                            :disabled="true"
                            :precision="2"
                            :isCurrency="true"
                        />
                        <InputCurrency
                            label="Invoice/Wholesale"
                            :precision="2"
                            :disabled="true"
                            :saturn="BookValueSelected?.wholesale"
                            :isCurrency="true"
                        />
                    </div>
                </Panel>
                <PanelRouteOneSendTerms
                    v-model:deal="fimenu"
                    v-model:submission-data="submissionData"
                    :lender="modal.passedData.lender"
                />
                <div :style="$grid(DisplayAdditionalFields ? '1-1' : '1')" class="grid-2-column" >
                    <FinanceInstitutionsCustomFields v-model:dynamicFields="submissionData.dynamicFields" v-if="DisplayAdditionalFields"/>
                    <div :style="$grid(DisplayAdditionalFields || !fimenu.hasCoSigner ? '1' : '1-1')">
                        <PanelRouteOneSendCustomerInformation 
                            v-if="fimenu.hasCoSigner"
                            v-model:customer-info="fimenu.customersInfo"
                        />
                        <PanelRouteOneSendOtherPanel 
                            v-model:submissionData="submissionData"
                        />
                    </div>
                </div>

                <Panel title="Lender Provided Information" :isSection="true" v-if="CustomFieldsDisplay.length">
                    <div v-for="fieldData in CustomFieldsDisplay" :key="fieldData.name" >
                        <Panel :title=" fieldData.name?.toUpperCase() ?? ''" :collapsed="true" :isSection="true">
                            <DisplayData
                                class="color-iteration"
                                v-for="kvp in fieldData.kvp"
                                :key="fieldData.name + '' + kvp.key"
                                :label="kvp.key"
                                :saturn="kvp.value.toLowerCase() == kvp.key.toLowerCase() ? null : kvp.value"
                            />
                        </Panel>
                    </div>
                </Panel>

            </div>
        </div>
        <div class="modal-footer">
            <button class="button-unwind" @click="cancel()">Cancel</button>
            <button :disabled="v$.submissionData.$invalid" class="button-save" @click="close()">Send</button>
        </div>
    </div>
</template>

<script lang="ts">
    import $modal from "@core/services/modal";
    import { CustomersRelationship } from "@core/classes/SharedEnums";
    import { defineComponent } from "vue";
    import FIMenu from "@core/classes/FIMenu";
    import { GetBookValueDropdownValues } from "@/helpers/fimenuvehicle-helper";
    import InputNumber from "@core/components/InputNumber.vue";
    import InputRichDropdown from "@core/components/InputRichDropdown.vue";
    import util from "@core/services/util";
    import { VehicleBookValue } from "@core/classes/VehicleBookValue";
    import InputCurrency from "@core/components/InputCurrency.vue";
    import FinanceInstitutionsCustomFields from "@/components/FinanceInstitutionsCustomFields.vue";
    import FIDealJacketAdditionalInformation, { FIDealJacketAdditionalInformationValidation } from "@core/classes/FIDealJacketAdditionalInformation";
    import {useVuelidate} from "@vuelidate/core";
    import Panel from "@core/components/Panel.vue";
    import PanelRouteOneSendOtherPanel from "@/components/PanelRouteOneSendOtherPanel.vue";
    import PanelRouteOneSendCustomerInformation from "@/components/PanelRouteOneSendCustomerInformation.vue";
    import PanelRouteOneSendTerms from "@/components/PanelRouteOneSendTerms.vue";
    import InputCheckbox from "@core/components/InputCheckbox.vue";
    import { FIField, FIFieldType } from "@core/classes/FIDynamicFields";
    import DisplayData from "@/components/DisplayData.vue";
import VersionsLender from "@core/classes/LenderVersionClass";

    interface BookValueDropDown {
        value: number;
        display: string;
    }

    export default defineComponent({
        name: "modalRouteOneSend",
        props: ["modal"],
        setup(){
            return {
                v$:useVuelidate()
            }
        },
        data() {
            return {
                BookValueCrudRef: null,
                submissionData: new FIDealJacketAdditionalInformation({
                    dynamicFields: this.modal.passedData.customFields,
                })
            };
        },
        created() {
            const deal = this.modal.passedData.fimenu as FIMenu;
            if(deal.isFinance()) {
                // Checks for nullability. Better be safe than sorry.
                if (deal?.loanTerms?.term1 != null) {
                    this.submissionData.buyRate =  deal.loanTerms.term1.buyrate;
                    this.submissionData.sellRate = deal.loanTerms.term1.sellrate;
                    this.submissionData.term =deal.loanTerms.term1.term;
                }
            } else if(deal.isLease()) {
                // Checks for nullability. Better be safe than sorry.
                if (deal?.leaseTerms?.term1 != null) {
                    this.submissionData.buyRate =  deal.leaseTerms.term1.buyrate;
                    this.submissionData.sellRate = deal.leaseTerms.term1.sellrate;
                    this.submissionData.term = deal.leaseTerms.term1.term;
                    this.submissionData.residualPercentage = deal.leaseTerms.term1.residual;
                    this.submissionData.residualAmount = deal.leaseTerms.term1.residualAmount;

                }

            }
        },
        computed: {
            util(): any {
                return util;
            },
             CustomFieldsDisplay(): FIField[] {
                return  this.submissionData.dynamicFields.fields?.filter(x => x.type === FIFieldType.DISPLAY) ?? [];
            },
            BookValuesDropdownValues(): BookValueDropDown[] {
                const deal = this.modal.passedData.fimenu as FIMenu;

                return GetBookValueDropdownValues().filter(dropdownValue =>
                    deal.vehicle.bookValues.some(
                        (vehBookVal: VehicleBookValue) =>
                            dropdownValue.value == vehBookVal.source && !!vehBookVal.retail && !!vehBookVal.wholesale,
                    ),
                );
            },
            BookValueSelected(): VehicleBookValue {
                var deal = this.modal.passedData.fimenu as FIMenu;
                const found = deal.vehicle.bookValues.find(
                    (x: VehicleBookValue) => x.source === this.submissionData.selectedBookValue,
                );
                return found;
            },
            Loading() : boolean{
                return this.modal?.passedData?.loading ?? false;
            },
            IsFinance(): boolean {
                return this.modal.passedData.fimenu.isFinance()
            },
            fimenu(): FIMenu{
                return this.modal.passedData.fimenu
            },
            CustomersRelationship(): typeof CustomersRelationship {
                return CustomersRelationship
            },
            DisplayAdditionalFields(): boolean {
                return !!this.submissionData.dynamicFields.fields.filter(x => x.type != FIFieldType.DISPLAY).length
            }
        },
        mounted() {
            if (this.$refs.BookValueCrud != null) {
                this.BookValueCrudRef = this.$refs.BookValueCrud;
            }
        },
        validations(){
            return {
                submissionData: FIDealJacketAdditionalInformationValidation(this.modal.passedData.fimenu, this.submissionData)  
            }
        },

        methods: {
            close() {
                // const bookValue = this.submissionData.selectedBookValue == BookValue.Default ? null : this.submissionData.selectedBookValue;
                // console.log("Asdasdsa", this.submissionData)
                $modal.close(this.submissionData);
            },
            cancel() {
                $modal.cancel();
            },
            displayPredicate(val: BookValueDropDown): any {
                return val.display;
            },
            valuePredicate(val: BookValueDropDown): any {
                return val.value;
            },
        },
        components: {
            PanelRouteOneSendTerms,
            InputRichDropdown,
            InputCurrency,
            FinanceInstitutionsCustomFields,
            Panel,
            PanelRouteOneSendOtherPanel,
            PanelRouteOneSendCustomerInformation,
            DisplayData
        },
    });
</script>
<style scoped>
.color-iteration:not(:first-child):nth-child(even) {
    background: var(--black-10percent);
}
.color-iteration:not(:first-child):nth-child(odd) {
    background: transparent;
}
</style>
