<template>
    <div class="modalCountdown">
        <div :class="['modal-container', 'modal-countdown', passedData.modalClass]" :style="{zIndex: '9999999999'}">
            <div class="modal-header">
                {{ title }}

                <span class="modal-timer" ref="timerTicker">
                    <span class="modal-timer-inner">
                        {{ timeLeft }}
                    </span>
                </span>
            </div>
            <div class="modal-body">
                <div v-if="Array.isArray(passedData.info)" :class="['modal-body-info', infoListClass]">
                    <span v-for="(i, index) in passedData.info" :key="index" :class="isInfoDetailed(i) ? i.class : ''">
                        {{ (isInfoDetailed(i)) ? i.text : i }}
                    </span>
                </div>
                <div v-else :class="['modal-body-info', (isInfoDetailed(passedData.info) ? passedData.info.class : '')]">
                    {{ isInfoDetailed(passedData.info) ? passedData.info.text : passedData.info }}
                </div>

                <div v-if="passedData.additionalInfo" class="modal-body-info modal-body-info-important">
                    {{ passedData.additionalInfo }}
                </div>
            </div>
            <div class="modal-footer">
                <button v-if="passedData.cancelText" :class="passedData.cancelTextClass ? passedData.cancelTextClass : 'button-unwind'" @click="cancel">
                    {{ passedData.cancelText }}
                </button>
                <button v-else-if="$global.isAdminView && isDevEnvironment" class="button-unwind hint-click" @click="cancel()">
                    CANCEL MODAL
                </button>
                <button v-if="passedData.acceptText" :class="passedData.acceptTextClass ? passedData.acceptTextClass : 'button-save'" @click="close">
                    {{ passedData.acceptText }}
                </button>
                <button v-if="passedData.altAcceptText" :class="passedData.altAcceptClass ? passedData.altAcceptClass : 'button-save'" @click="altClose">
                    {{ passedData.altAcceptText }}
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts">

    export interface IDetailedInfo {
        text: string;
        class?: string;
    }

    export interface IModalCountdownProps {
        modal: {
            passedData?: IModalCountdownPassedData,
            altPostFunction?: () => any,
            cancelFunction?: () => any
        }
    }

    export interface IModalCountdownPassedData {
        /** Title of the modal. Defaults to "Urgent Action Required" if no value is sent in. */
        title?: string;

        /** CSS class to apply to the modal container */
        modalClass?: string;

        /** Text to display in the main body. Can be either a string or an array of strings. Each string will be contained in its own <span> tag.*/
        info?: string|string[]|IDetailedInfo[]|IDetailedInfo[];

        /** Defaults to `inline`. When passing info as a list, configures how each object will get displayed. */
        infoListDisplayMode?: 'block'|'flex'|'inline';

        /** An additional line of text to display underneath the info text. Will be styled with a bold font-weight. */
        additionalInfo?: string;

        /** Text to display inside of the accept (close modal) button */
        acceptText?: string;

        /** CSS class to apply to the accept (close modal) button */
        acceptTextClass?: string;

        /** Text to display inside an additional accept (close modal) button. If not text is provided, the additional accept button will not be shown. */
        altAcceptText?: string;

        /** CSS class to apply to the alternative accept button. */
        altAcceptClass?: string;

        /** Text to display inside of the cancel button */
        cancelText?: string;

        /** CSS class to apply to the cancel button */
        cancelTextClass?: string;

        /** Defaults to true. When set to false, will make the count down timer circle tick counter-clockwise. */
        circleTickUp?: boolean;

        /** Defaults to 30. The amount of "ticks" the timer takes before timing out. The length of a single tick is defined in `timerTickUnit` */
        timerDuration?: number;

        /** Defaults to 1000. The length of a single "tick". Should provide the value in miliseconds. For example, to make a tick last 1 second, set
         * this value to 1000.
         */
        timerTickUnit?: number;
    }
</script>
<script setup lang="ts">
    /* A modal that will auto-accept after a set amount of time. (default ticks in seconds, does not support mixed units)
     * Functionality is virtually identical to modalInfo
     */
    import { computed, inject, onBeforeMount, onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from 'vue';

    import $modal from '@core/services/modal';
    import { SaturnGlobalPlugin } from '@core/classes/StaticClasses';
    import settings from '@/settings';


    const props = defineProps<IModalCountdownProps>();
    const $global = inject<SaturnGlobalPlugin>("$global");
    const timerTickerRef = useTemplateRef<HTMLElement>('timerTicker');

    /** Contains all `props.modal.passedData` properties except those managed by their own local ref. */
    const passedData = computed((): Omit<IModalCountdownPassedData, 'title'|'infoListDisplayMode'|'timerDuration'|'timerTickUnit'|'circleTickUp'> => {
        return props.modal.passedData;
    });

    const altPostFunction = computed((): (() => any) => {
        return props.modal.altPostFunction;
    });

    const cancelFunction = computed((): (() => any) => {
        return props.modal.cancelFunction;
    });

    // ================
    // TIMER MANAGEMENT
    // ================
    // #region Timer Management
    const timerDuration = ref<number>(null);
    const timerTickUnit = ref<number>(null);
    const circleTickUp = ref<boolean>(null);
    const timeLeft = ref(0);
    const startTime = ref<number>(null);
    const tickTimeout = ref<number|string|ReturnType<typeof setTimeout>>(null);

    const timerToDegrees = computed((): number => {
        const rawDegrees = (timeLeft.value / timerDuration.value) * 360;
        return (circleTickUp.value) ? 360 - rawDegrees : rawDegrees;
    });

    const isDevEnvironment = computed((): boolean => {
        return settings.environmentName === 'DEVELOPMENT';
    });

    watch(() => $global.isAdminView, (newVal: boolean) => {
        // Don't pause timeout if we're not in development
        if (!isDevEnvironment.value) return;

        // Pauses timer when admin view is enabled.
        if (newVal)
            clearTimeout(tickTimeout.value);
        else
            timerTick();
    });

    const timerTick = () => {
        clearTimeout(tickTimeout.value);

        timeLeft.value = timerDuration.value - Math.trunc(((Date.now() - startTime.value) / timerTickUnit.value));

        // Set arc of little progress circle
        if(timerTickerRef.value)
            timerTickerRef.value.style.setProperty('--degree', `${timerToDegrees.value}deg`);

        if (timeLeft.value > 0) {
            tickTimeout.value = setTimeout(timerTick, timerTickUnit.value);
        }
        else {
            // Auto-accept
            close();
        }
    };

    const pageHiddenHandler = () => {
        // When a page is hidden, repeated timer ticks will get throttled by the browser.
        // So a single, longer timeout will fire more accurately.
        if (document.visibilityState === 'visible') {
            timerTick();
            return;
        }

        clearTimeout(tickTimeout.value);
        const milisecondsRemaining = (timerDuration.value * timerTickUnit.value) - (Date.now() - startTime.value);
        tickTimeout.value = setTimeout(close, milisecondsRemaining);
    };
    // #endregion

    // =================
    // INFO DISPLAY MODE
    // =================
    // #region Info Display Mode
    const title = ref<string>(null);
    const infoListDisplayMode = ref<'block'|'flex'|'inline'>(null);

    const infoListClass = computed((): string => {
        return `display-${infoListDisplayMode.value}`;
    });

    const isInfoDetailed = (info: any): info is IDetailedInfo => {
        return info.text !== undefined;
    };
    // #endregion

    // ================
    // MODAL MANAGEMENT
    // ================
    // #region Modal Management
    const close = (): void => {
        timeLeft.value = timerDuration.value - Math.trunc(((Date.now() - startTime.value) / timerTickUnit.value));
        $modal.close();
    };

    /** Performs normal close function, but will pass along an additional value to it from the altPostFunction() */
    const altClose = async (): Promise<void> => {
        let altReturnValue;
        if (altPostFunction.value)
            altReturnValue = await altPostFunction.value();

        $modal.close(altReturnValue);
    };

    const cancel = async () => {
        let cancelValue;
        if (cancelFunction.value)
            cancelValue = await cancelFunction.value();

        $modal.cancel(cancelValue);
    };
    // #endregion

    // ===============
    // LIFECYCLE HOOKS
    // ===============
    // #region Lifecycle Hooks
    onBeforeMount(() => {
        // Setting defaults here because withDefaults doesn't work well with deeply nested values.
        title.value = props.modal?.passedData?.title ?? 'Urgent Action Required';
        infoListDisplayMode.value = props.modal?.passedData?.infoListDisplayMode ?? 'inline';
        timerDuration.value = props.modal?.passedData?.timerDuration ?? 30;
        timerTickUnit.value = props.modal?.passedData?.timerTickUnit ?? 1000;
        circleTickUp.value = props.modal?.passedData?.circleTickUp ?? true;

        timeLeft.value = props.modal.passedData.timerDuration;
    });

    onMounted(() => {
        document.addEventListener('visibilitychange', pageHiddenHandler);

        startTime.value = Date.now();

        // When in dev environment, don't need to start timeout when admin view is enabled
        if (isDevEnvironment.value && $global.isAdminView) return;

        // Checks page visibility & starts the right kind of timeout
        pageHiddenHandler();
    });

    onBeforeUnmount(() => {
        clearTimeout(tickTimeout.value);
        document.removeEventListener('visibilitychange', pageHiddenHandler);
    });
    // #endregion
</script>
<style>
    .modalCountdown {
        margin: auto;
    }

        .modalCountdown .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

            .modalCountdown .modal-body .modal-body-info.display-block,
            .modalCountdown .modal-body .modal-body-info.display-inline {
                display: block;
            }

                .modalCountdown .modal-body .modal-body-info.display-block > * {
                    display: block;
                    margin: 10px 0;
                }

                    .modalCountdown .modal-body .modal-body-info.display-block > *:first-child {
                        margin-top: 0;
                    }

                    .modalCountdown .modal-body .modal-body-info.display-block > *:last-child {
                        margin-bottom: 0;
                    }

                .modalCountdown .modal-body .modal-body-info.display-inline > * {
                    display: inline;
                    margin-right: 0.5rem;
                }

            .modalCountdown .modal-body .modal-body-info.display-flex {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                align-items: center;
            }

        .modalCountdown .modal-timer {
            --degree: 0deg;

            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            width: 35px;
            padding: 1px;
            border-radius: 50%;
            background: conic-gradient(var(--error-color) var(--degree), transparent calc(var(--degree) + 0.5deg) 100%);
            color: var(--error-color);
        }

            .modalCountdown .modal-timer .modal-timer-inner {
                height: 80%;
                width: 80%;
                border-radius: 50%;
                background: var(--background-color);
                text-align: center;
            }

        .modalCountdown .modal-body-info {
            white-space: pre-wrap;
            margin: 10px 0;
        }

        .modalCountdown .modal-body-info-important {
            font-weight: bold;
        }

        .modalCountdown .modal-container {
            width: auto;
            max-width: 815px;
        }
</style>
