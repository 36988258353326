<template>
   <TooltipComponent :tooltip="signedInformation(doc)" direction="right" maxWidth="450">
        <template #showFromOutside>
            <div v-if="doc.status == ENUMS.PaperworkDocumentStatus.ErrorGenerating" class="text-error defaultCursor signersText">
                <i class="fa fa-exclamation-circle iconSpacing"></i>
                <span>CAN'T CAPTURE SIGNATURE</span>
            </div>

            <div v-else-if="doesntNeedSignatures" class="defaultCursor signersText text-success">
                <i class="fa fa-check-circle iconSpacing"></i>
                <span>N/A</span>
            </div>

            <div v-else-if="needsWetSignature" class="text-error defaultCursor signersText">
                <i class="fa fa-exclamation-circle iconSpacing" />
                <span>REQUIRES WET SIGNATURE</span>
            </div>

            <div v-else class="text-warning splitlines defaultCursor">
                <div :class="{'signersText': true, 'signed': hasCustomerSignature}">
                    <i :class="['fa iconSpacing', hasCustomerSignature ? 'fa-check-circle' : 'fa-exclamation-circle']" />
                    <span>{{ customerName }}</span>
                </div>

                <div v-if="needsCoCustomerSignature" :class="{'signersText': true, 'signed': hasCoCustomerSignature}">
                    <i :class="['fa iconSpacing', hasCoCustomerSignature ? 'fa-check-circle' : 'fa-exclamation-circle']" />
                    <span>{{ coCustomerName }}</span>
                </div>
            </div>
        </template>
    </TooltipComponent>
</template>

<script>
    import ENUMS from "@core/classes/Enums"
    import TooltipComponent from '@core/components/TooltipComponent.vue'

    export default {
        name: "DocumentPacketDocumentSignature",
        props: {
            doc: Object,                    // PaperworkDocument.cs
            customerName: String, 
            coCustomerName: String, 
            hasSigned: Function,            // (overlays: DocumentOverlay[], personType: ENUMS.PERSON_TYPES) => boolean
            signedInformation: Function,    // (doc: PaperworkDocument) => string | string[]
            requiresSigner: Function        // (overlays: DocumentOverlay[], personType: ENUMS.PERSON_TYPES) => boolean
        },
        computed:{
            ENUMS() {
                return ENUMS;
            },
            doesntNeedSignatures() {
                return !this.doc?.overlays || this.doc.overlays.length <= 0 || this.hasOnlyDealerSigOverlays(this.doc.overlays);
            },
            needsWetSignature() {
                // If an Impact Doc has been generated, then this document is not eligible for e-signing.
                if (this.doesntNeedSignatures || !this.doc?.impactDoc) return false;
                return !this.hasCustomerSignature || (this.needsCoCustomerSignature && !this.hasCoCustomerSignature);
            },
            hasCustomerSignature() {
                if (!this.doc) return false;

                // If we need a wet signature, assume the signed doc is fully signed. 
                if (this.doc.impactDoc && this.doc.status === ENUMS.PaperworkDocumentStatus.Signed) return true;

                return this.hasSigned(this.doc.overlays, ENUMS.PERSON_TYPES.CUSTOMER);
            },
            needsCoCustomerSignature() {
                if (!this.doc) return false;
                return this.requiresSigner(this.doc.overlays, ENUMS.PERSON_TYPES.COCUSTOMER);
            },
            hasCoCustomerSignature() {
                if (!this.doc) return false;

                // If we need a wet signature, assume the signed doc is fully signed. 
                if (this.doc.impactDoc && this.doc.status === ENUMS.PaperworkDocumentStatus.Signed) return true;

                return this.hasSigned(this.doc.overlays, ENUMS.PERSON_TYPES.COCUSTOMER);
            }
        },
        methods: {
            hasOnlyDealerSigOverlays(overlays) {
                return overlays.every(o => {
                    if (o.overlayType === ENUMS.OVERLAY_TYPES.SIGNATURE) {
                        return o.signature.personType !== ENUMS.PERSON_TYPES.CUSTOMER && o.signature.personType !== ENUMS.PERSON_TYPES.COCUSTOMER
                    }
                    else if (o.overlayType === ENUMS.OVERLAY_TYPES.INITIALS) {
                        return o.initials.personType !== ENUMS.PERSON_TYPES.CUSTOMER && o.initials.personType !== ENUMS.PERSON_TYPES.COCUSTOMER
                    }
                    else {
                        return false
                    }
                })
            }
        },
        components: {
            TooltipComponent
        }
    };
</script>