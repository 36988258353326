<template>
    <Panel title="Rebates" class="panel-rebates">
        <template v-slot:header>
            <div class="panel-buttons">
                <button class="button panel-button button-edit" @click="openMSRebates()" v-if="mscanAccount != null"><i class="fas fa-hand-holding-usd"></i> MS Rebates</button>
                <button class="button panel-button button-edit" @click="addEditRebate(null, 'Add')"><i class="fas fa-plus"></i> Add Manual</button>
            </div>
        </template>

        <div :style="$grid('1')" class="panel-body no-margin-grid local-panel-body">
            <div class="richtable-container">
                <div v-if="fimenu.rebates && fimenu.rebates.length > 0">
                    <RichTable :tableData="fimenu.rebates" :headers="headers" class="rich-table">
                        <template #code="row">
                            <div :style="$grid('1')">
                                <div>
                                    <button v-if="row.source === 'Manual'" class="button-link" @click="addEditRebate(row, 'Edit')">{{ row.code }}</button>
                                    <span v-else>{{ row.code }}</span>

                                    <button v-if="row.source === 'Manual'" class="button-clear" @click="deleteRebate(row)"><i class="fas fa-trash-alt"></i></button>
                                </div>
                                <span v-if="row.code != row.identifier">{{ row.identifier }}</span>
                            </div>
                        </template>
                        <template #amount="row">
                            <div :style="$grid('1')">
                                <div v-if="row.rebateTerms && row.rebateTerms.length>0" :style="$grid('1')">
                                    <span v-for="term in row.rebateTerms" :key="term.start">{{ term.termStart }}-{{ term.termEnd }}: {{ $filters.currency2(term.amount) }}</span>
                                </div>
                                <span v-else>{{ getAmount(row) }}</span>
                            </div>
                        </template>
                        <template #rebateType="row">
                            <TooltipComponent :tooltip="[rebateTypes.find((r) => r.code == row.rebateType).description]">
                                <template #showFromOutside>
                                    <i v-if="row.rebateType==0" class="fas fa-dollar-sign"></i>
                                    <i v-if="row.rebateType==1" class="fas fa-file-invoice-dollar"></i>
                                    <i v-if="row.rebateType==2" class="fas fa-money-check-alt"></i>
                                    <i v-if="row.rebateType==3" class="fas fa-percentage"></i>
                                    <i v-if="row.rebateType==4" class="fas fa-percent"></i>
                                </template>
                            </TooltipComponent>
                        </template>
                    </RichTable>
                </div>
                <p v-else class="empty-list">There are currently no rebates for this deal. Rebates will be auto-applied.</p>
            </div>
        </div>
    </Panel>
</template>

<script>
    import api from '@core/services/api'
    import util from '@core/services/util';
    import $modal from '@core/services/modal'
    import modalAddEditMScanRebate from '@/modals/modalAddEditMScanRebate.vue'
    import modalMSRebate from '@/modals/modalMSRebate.vue'
    import TooltipComponent from '@core/components/TooltipComponent.vue'
    import ENUMS from "@core/classes/Enums"
    import Panel from '@core/components/Panel.vue'
    import RichTable from '@core/components/RichTable.vue'

    export default {
        name: "PanelRebates",
        props: ['fimenu', 'validation', 'mscanAccount'],
        data() {
            return {
                isBusy: true,
                headers: [
                    { name: 'code', display: "Code", slot: 'code' },
                    { name: 'desc', display: "Description" },
                    { name: 'amount', display: "Amount(s)", cssClass: 'column-number', slot: 'amount' },
                    //{ name: 'rebateType', display: "Type", value: (v) => this.rebateTypes.filter((r) => r.code === v.rebateType)[0].shortDescription },
                    { name: 'rebateTypeSlot', display: "Type", slot: 'rebateType' },
                ],
                rebateTypes: [
                    { code: null, description: 'Unknown' },
                    { code: 0, description: 'Consumer Cash', shortDescription: 'C $' },
                    { code: 1, description: 'Dealer Cash', shortDescription: 'D $' },
                    { code: 2, description: 'Money Factor', shortDescription: 'MF' },
                    { code: 3, description: 'Residual', shortDescription: 'Res' },
                    { code: 4, description: 'APR', shortDescription: '%' },
                ],

            }
        },
        computed: {
            util() {
                return util;
            },
            ENUMS() {
                return ENUMS;
            },
            manualItems() {
                return this.fimenu.rebates && this.fimenu.rebates.filter(r => r.source === 'Manual');
            }
        },
        async created() {
            this.isBusy = true;
            //do stuff

            this.isBusy = false;
        },

        methods: {
            getAmount(row) {
                if (row.amountType === 0)
                    return this.$filters.currency2(row.amount);
                else if (row.amountType === 1)
                    return row.amount + '%';
                else
                    return row.amount;
            },
            async openMSRebates() {
                const vehicleId = await this.getVehiclesForVin();
                // console.log('after await this.getVehiclesForVin')
                if (vehicleId) {
                    // console.log('calling openMSRebatesModal');
                    await this.openMSRebatesModal();
                }
            },
            async openMSRebatesModal() {
                // console.log('in openMSRebatesModal');
                $modal.open(modalMSRebate, { name: 'modalMSRebate', passedData: { fimenu: this.fimenu, mscanAccount: this.mscanAccount, rebates: this.fimenu.rebates.filter(r => r.source == 'MSRebates') }, backdrop: false, postFunction: (data) => this.saveRebates(data.rebateData, data.responseData) });
            },
            async getVehiclesForVin() {
                let vehicleId = null
                if (!this.fimenu.marketScan?.vehicleId) {
                    vehicleId = await util.getVehiclesForVin(this.mscanAccount, this.fimenu.vin, this.fimenu.inventoryType == 'New');

                } else {
                    vehicleId = this.fimenu.marketScan?.vehicleId
                }
                this.fimenu.marketScan.vehicleId = vehicleId;
                return vehicleId;
            },
            async setFimenuVehicleId(vehicleId) {
                this.fimenu.marketScan.vehicleId = vehicleId;
            },
            addEditRebate(row, mode) {
                $modal.open(modalAddEditMScanRebate, { name: 'modalAddEditMScanRebate', passedData: { rebate: row, mode: mode, rebates: this.fimenu.rebates }, backdrop: false, postFunction: (row) => this.saveRebate(row) });
            },
            saveRebates(data, response) {
                //do something with the data coming out of the MS Rebates modal
                // console.log('data', data);
                // console.log('response', response);
                const cashTypes = [11, 4];
                const financeTypes = [11, 4, 3];
                const leaseTypes = [10, 2, 1];
                if (data) {
                    //set the marketscan rebate response
                    this.fimenu.marketScan.rebateResponse = JSON.stringify(response);
                    //map the data to our rebate object
                    const mappedData = data.map((d) => {
                        return {
                            id: d.id,
                            code: d.number,
                            identifier: d.identCode,
                            amount: d.amount ? d.amount : d.value && d.value.values && d.value.values.length > 0 ? d.value.values[0].value : 0,
                            desc: d.nameDisplay,
                            qualification: '',//for now. fix by passing in customer types as well
                            amountType: d.valueType,
                            //if their type is 5, then it's an APR, otherwise it's cash, then check if it's dealer or customer cash
                            rebateType: d.type == 5 ? 4 : d.receipientType == 2 ? 1 : 0,
                            isAllDeals: d.availableToAll ? d.availableToAll : d.transactionType === 1000 ? true : false,
                            isCash: cashTypes.some(a => a == d.transactionType),
                            isLease: leaseTypes.some(a => a == d.transactionType),
                            isFinance: financeTypes.some(a => a == d.transactionType),
                            isSubventedLease: d.transactionType == 1 || d.transactionType == 10,
                            isNonSubventedLease: d.transactionType == 2 || d.transactionType == 10,
                            isSubventedFinance: d.transactionType == 3 || d.transactionType == 11,
                            isNonSubventedFinance: d.transactionType == 4 || d.transactionType == 11,
                            //set the source to 'MSRebates' to differentiate between manual or other sources
                            source: 'MSRebates',
                            rebateTerms: d.terms && d.terms.length > 0 ?
                                d.terms.map(t => {
                                    return {
                                        termStart: t.start,
                                        termEnd: t.end,
                                        amount: t.value && t.value.values && t.value.values.length > 0 ? t.value.values[0].value : 0,
                                        amountType: d.valueType
                                    }
                                })
                                : []
                        };
                    });
                    // eslint-disable-next-line no-console
                    console.log('mappedData', mappedData);
                    if (this.fimenu.rebates && this.fimenu.rebates.length > 0) {
                        //replace
                        this.fimenu.rebates = this.fimenu.rebates.filter(r => r.source != 'MSRebates').concat(mappedData);
                    } else {
                        //insert
                        this.fimenu.rebates = util.clone(mappedData);
                    }
                }
            },
            saveRebate(row) {
                //do something to save the record
                // eslint-disable-next-line no-console
                console.log('saveRebate', row);
                if (this.fimenu.rebates.find(r => r.code === row.code)) {
                    //edit
                    const index = this.fimenu.rebates.findIndex(r => r.code === row.code);
                    this.fimenu.rebates.splice(index, 1, row);
                } else {
                    //add
                    this.fimenu.rebates.push(row);
                }
            },
            deleteRebate(row) {
                //do something to delete
                // eslint-disable-next-line no-console
                console.log('deleteRebate', row);
            }
        },
        components: {
            Panel,
            RichTable,
            TooltipComponent,
        }
    };
</script>
<style>
    .panel-rebates {
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

        .panel-rebates .richtable-container {
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }

        .panel-rebates .empty-list {
            text-align: center;
            position: relative;
        }

        .panel-rebates .local-panel-body {
            height: 100%;
        }

    .button-small {
        padding: 0px;
        margin-left: 1px;
        border-radius: 5px;
        width: 35px !important;
        min-width: 35px !important;
    }
</style>