<template>
    <div class="modal-container modal-medium">
        <div class="modal-header">
            {{ messageTitle }}
        </div>
        <div class="modal-body">
            {{ messageBody }}
        </div>
        <div class="modal-footer">
            <button class="button-save" @click="close()">Close</button>
        </div>
    </div>
</template>


<script>
    import $modal from '@core/services/modal'

    export default {
        name: "modalBroadcastMessages",
        props: ['modal'],
        setup(){
            return { }
        },
        data() {
            return { 
                messageTitle: "System Message",
                messageBody: "System Message Broadcasted."
            }
        },
        computed: {

        },
        mounted() {
            this.messageBody = this.modal.passedData.data.messageBody ? this.modal.passedData.data.messageBody : this.messageBody
            this.messageTitle = this.modal.passedData.data.messageTitle ? this.modal.passedData.data.messageTitle : this.messageTitle
        },
        methods: {
            close() {
                $modal.close();
            },
            cancel() {
                $modal.cancel();
            }
        },
        components: {
            
        }
    };
</script>
<style>
</style>
