import { HubConnectionBuilder, LogLevel, Subject } from "@microsoft/signalr";
import { reactive } from 'vue'
import util from "@core/services/util";
import LogRocket from "logrocket";


export default {
    install(app) {
        const connection = reactive(new HubConnectionBuilder()
            .withUrl("/MeetingRoomHub", { withCredentials: false })
            //.withAutomaticReconnect([0, 2000, 10000, 30000]) yields the default behavior
            .withAutomaticReconnect([0, 3000, 8000, 10000, 12000])
            .configureLogging(LogLevel.Debug)
            .build()
        );
        // use new Vue instance as an event bus
        const meetingHub = reactive({
                manuallyClosed: false,
                connected: false,
                code: '',
                myConnectionId: null,
                connection: connection,
                volumeLevel: 0.5,
                isSendCustomerHtmlEnabled: false,
                isRetryingToSendMsg: false,
        });

        app.config.globalProperties.EventBus.emit('MeetingHubConnectionChanged', meetingHub.connected);
        // every component will use this.$meetingHub to access the event bus
        app.config.globalProperties.$meetingHub = reactive(meetingHub);
        app.provide('$meetingHub', app.config.globalProperties.$meetingHub);
        // Forward server side SignalR events through $meetingHub, where components will listen to them

        connection.onreconnected(connectionId => {
            console.log(`Connection reestablished. Connected with connectionId "${connectionId}".`);
            meetingHub.myConnectionId = connectionId;
            app.config.globalProperties.MeetingHubBus.emit('reconnectedMeetingHub');

        });

        connection.onreconnecting(error => {
            console.log('%cRECONNECTING', 'padding: 12px; background: orange;')
            console.log(`Connection lost due to error "${error}". Reconnecting.`);
            app.config.globalProperties.MeetingHubBus.emit('reconnectingMeetingHub');
        });

        connection.onclose(() => {
            console.log("****** Connection has been closed ******");
            meetingHub.connected = false;

            if (app.config.globalProperties.meetingHelper.inStore && app.config.globalProperties.meetingHelper.myRole == "fimanager")  {
                app.config.globalProperties.meetingHelper.currentMeetingUsers = []
            }

            app.config.globalProperties.EventBus.emit('BroadcastReconnect');
        });

        connection.on("ConnectedToSignalR", myConnectionId => {
            console.log(
                "ConnectedToSignalR. My connectionId is: " + myConnectionId,
                "meetingHub.code",
                meetingHub.code
            );
            meetingHub.myConnectionId = myConnectionId;
        });
        connection.on("DisconnectedFromSignalR", connectionId => {
            console.log("DisconnectedFromSignalR. The disconnected users connectionid is: " + connectionId);

            //I GOT DISCONNECTED
            if (meetingHub.myConnectionId == connectionId) {
                meetingHub.myConnectionId = null;
            }

            //EMIT THAT SOMEONE GOT DISCONNECTED (me or someone else)
            app.config.globalProperties.MeetingHubBus.emit('disconnectedFromSignalR', connectionId);


        });

        //THIS SHOULD ONLY GET CALLED ON THE CUSTOMER APP
        //THIS TELLS ALL CUSTOMER PARTICIPANTS TO DISCONNECT
        connection.on('EndMeeting', async () => {
            console.log('Meeting Has Ended. Stopping meetingHub connection.');
            app.config.globalProperties.MeetingHubBus.emit('endMeeting');
            await meetingHub.stopConnection("FI Manager has ended meeting for all participants");
        });

        //General Actions Watchers

        connection.on('MeetingRoomJoinSuccess', (role, isInStoreConnection, meetingRoomCode, currentMeetingDetails) => { app.config.globalProperties.MeetingHubBus.emit('meetingRoomJoinSuccess', {role, isInStoreConnection, meetingRoomCode, currentMeetingDetails}) })
        // connection.on('MeetingRoomJoinError', () => { app.config.globalProperties.MeetingHubBus.emit('meetingRoomJoinSuccess', null) })
        connection.on('RegisterCustomerViewed', (type, moment, videoSeen) => { app.config.globalProperties.MeetingHubBus.emit('registerCustomerViewed', {type, moment, videoSeen}) })
        connection.on('UpdatePreferredCoverage', (term) => { app.config.globalProperties.MeetingHubBus.emit('updatePreferredCoverage', term) })
        connection.on('UpdateLoanDaysToPayment', (days) => { app.config.globalProperties.MeetingHubBus.emit('updateLoanDaysToPayment', days) })
        connection.on('UpdateDraftLoanTerms', (term, sellRate, buyRate) => { app.config.globalProperties.MeetingHubBus.emit('updateDraftLoanTerms', {term, sellRate, buyRate}) })
        connection.on('UpdateCustomerVideoRecordings', (videoData) => { app.config.globalProperties.MeetingHubBus.emit('updateCustomerVideoRecordings', videoData) })
        connection.on('SaveLatestTimestamp', (videoData) => { app.config.globalProperties.MeetingHubBus.emit('saveLatestTimestamp', videoData) })
        connection.on('SaveManuallyEndedScreenRecordEndTime', (endTime) => { app.config.globalProperties.MeetingHubBus.emit('saveManuallyEndedScreenRecordEndTime', endTime) })
        connection.on('CustomerDisconnected', (updatedMeetingParticipants) => { app.config.globalProperties.MeetingHubBus.emit('customerDisconnected', updatedMeetingParticipants) })
        // connection.on('CheckIfMeetingStillActiveResponse', (isMeetingStillActive) => { app.config.globalProperties.MeetingHubBus.emit('CheckIfMeetingStillActiveResponse', isMeetingStillActive) })
        connection.on('ReceiveDocument', (doc) => { app.config.globalProperties.MeetingHubBus.emit('receiveDocument', doc) })
        connection.on('MoveToSecureRoom', (newSecureRoom) => { app.config.globalProperties.MeetingHubBus.emit('moveToSecureRoom', {newSecureRoom}) })
        connection.on('MeetingRoomError', (errorMessage) => {app.config.globalProperties.MeetingHubBus.emit('meetingRoomError', errorMessage) })

        //Customer Actions Watchers
        connection.on('TriggerCustomerAction', (action, fimenu) => { app.config.globalProperties.MeetingHubBus.emit('triggerCustomerAction', {action, fimenu}) })
        connection.on('CheckIfCustomerShouldGoToPaymentSheet', (action, fimenu) => { app.config.globalProperties.MeetingHubBus.emit('checkIfCustomerShouldGoToPaymentSheet') })
        connection.on('LanguageChanged', (language) => { app.config.globalProperties.MeetingHubBus.emit('languageChanged', language) })


        //Fimanager Actions Watchers
        connection.on('TriggerFIManagerAction', (action, fimenu) => { app.config.globalProperties.MeetingHubBus.emit('triggerFIManagerAction', {action, fimenu}) })
        connection.on('TriggerSaveFIMenu', () => { app.config.globalProperties.MeetingHubBus.emit("triggerSaveFIMenu"); })
        connection.on('UpdatePaperworkToSigned', (document) => {
            app.config.globalProperties.MeetingHubBus.emit('updatePaperworkToSigned', document)
        })
        connection.on('FinalizePaperwork', (fimenu) => { app.config.globalProperties.MeetingHubBus.emit('finalizePaperwork', fimenu) })
        connection.on('ReceivingHTMLFromCustomer', (customerScreenData) => { app.config.globalProperties.MeetingHubBus.emit('receivingHTMLFromCustomer', customerScreenData) })
        connection.on('ReceiveScrollData', (metadata) => {
            app.config.globalProperties.MeetingHubBus.emit('receiveScrollData', metadata);
        })
        connection.on('PlayVideoFromCustomer', (time) => { app.config.globalProperties.MeetingHubBus.emit('playVideoFromCustomer', time) })
        connection.on('PauseVideoFromCustomer', () => { app.config.globalProperties.MeetingHubBus.emit('pauseVideoFromCustomer') })
        connection.on('ChangeCustomerVideoVolume', (volumeLevel) => { app.config.globalProperties.MeetingHubBus.emit('changeCustomerVideoVolume', volumeLevel) })
        connection.on('ShowLastFrameVideo', () => { app.config.globalProperties.MeetingHubBus.emit('showLastFrameVideo') })
        connection.on('DownloadDocument', (path) => { app.config.globalProperties.MeetingHubBus.emit('downloadDocument', path) })
        connection.on('UpdateSignatureAndInitials', (signatureInfo) => { app.config.globalProperties.MeetingHubBus.emit("updateSignatureAndInitials", signatureInfo); })
        connection.on('UpdateDocumentIsOpen', (pdfIndex, isOpen) => { app.config.globalProperties.MeetingHubBus.emit('updateDocumentIsOpen', {pdfIndex, isOpen}) })
        connection.on('UpdateMeetingHubParticipants', (updatedMeetingParticipants) => { app.config.globalProperties.MeetingHubBus.emit('updateMeetingHubParticipants', updatedMeetingParticipants) })
        connection.on('UserRequestingToPresent', (userId) => { app.config.globalProperties.MeetingHubBus.emit('userRequestingToPresent', userId) })

        connection.on('SetAccessKey', (accessKey) => { meetingHub.accessKey = accessKey })
        connection.on('SetUserId', (id) => { meetingHub.userId = id })

        connection.on('UserJoinedVideoChatRoom', (userId) => { app.config.globalProperties.MeetingHubBus.emit('userJoinedVideoChatRoom', userId) })
        connection.on('GetInitiatorSignal', (signal) => { app.config.globalProperties.MeetingHubBus.emit('getInitiatorSignal', signal) })
        connection.on('GetNonInitiatorSignal', (signal) => { app.config.globalProperties.MeetingHubBus.emit('getNonInitiatorSignal', signal) })

        //CashBash
        connection.on('CashBashReadyToStartAdmin', () => { app.config.globalProperties.MeetingHubBus.emit('cashBashReadyToStartAdmin') })
        connection.on('CashBashReadyToStartGame', (playerConnectedCode) => { app.config.globalProperties.MeetingHubBus.emit('cashBashReadyToStartGame', playerConnectedCode) })
        connection.on('CashBashGameConnected', () => { app.config.globalProperties.MeetingHubBus.emit('cashBashGameConnected') })

        connection.on('CashBashAlreadyReadyGame', () => { app.config.globalProperties.MeetingHubBus.emit('cashBashAlreadyReadyGame') })

        connection.on('CashBashAdminCloseConnection', () => { app.config.globalProperties.MeetingHubBus.emit('cashBashAdminCloseConnection') })
        connection.on('CashBashGameCloseConnection', (playerConnectedCode) => { app.config.globalProperties.MeetingHubBus.emit('cashBashGameCloseConnection', playerConnectedCode) })

        connection.on('CashBashReadySelectPlayer', (playerId) => { app.config.globalProperties.MeetingHubBus.emit('cashBashReadySelectPlayer', playerId) })
        connection.on('CashBashReadySelectCard', (cardObject) => { app.config.globalProperties.MeetingHubBus.emit('cashBashReadySelectCard', cardObject) })
        connection.on('CashBashReadySelectPage', (page) => { app.config.globalProperties.MeetingHubBus.emit('cashBashReadySelectPage', page) })
        connection.on('CashBashReadySelectCardOK', (cardObject) => { app.config.globalProperties.MeetingHubBus.emit('cashBashReadySelectCardOK', cardObject) })
        connection.on('CashBashUpdateActivePlayer', (JSONStringPlayer) => { app.config.globalProperties.MeetingHubBus.emit('cashBashUpdateActivePlayer', JSONStringPlayer) })
        connection.on('CashBashUpdateBashInfo', (JSONStringBash) => { app.config.globalProperties.MeetingHubBus.emit('cashBashUpdateBashInfo', JSONStringBash) })


        connection.on('SendBackBuffer', (data) => { app.config.globalProperties.MeetingHubBus.emit('sendBackBuffer', data) })
        connection.on('StreamVideoData', (data) => { app.config.globalProperties.MeetingHubBus.emit('streamVideoData', data) })

        //class Variables
        meetingHub.subject = new Subject();
        meetingHub.accessKey = null;
        meetingHub.userId = null; // We're actually using connectionId; not userIdentifier.

        // Other Functions
        meetingHub.accessAllowed = pageThis$router => {
            if (!meetingHub.connected) {
                pageThis$router.push("/meetingroom/inStoreConnection");
            }
        };

        meetingHub.generateLobbyCode = () => {
            let codes = [];
            for (var i = 0; i < 6; i++) {
                let number = parseInt(Math.random() * 10);
                if (i == 0 && number == 0) {
                    codes.push(1);
                } else {
                    codes.push(parseInt(Math.random() * 10));
                }
            }
            return codes.join("");
        };

        meetingHub.decodeMeetingCode = code => {
            let dealNumber = "";
            let storeCode = "";
            code.split("").forEach((c, index) => {
                if (index % 2) {
                    //this will trigger if odd
                    storeCode += c;
                } else {
                    dealNumber += c;
                }
            });
            let dif = parseInt(dealNumber) % 10;
            dealNumber = parseInt(dealNumber / 10);
            storeCode = storeCode.substring(0, storeCode.length - dif);
            return dealNumber + "-" + storeCode;
        };
        meetingHub.encodeMeetingCode = (dealNumber, storeCode) => {
            let dif = dealNumber.length - storeCode.length;
            let output = "";
            let tempStoreCode = storeCode.split("");
            dealNumber.split("").forEach((c, index) => {
                output +=
                    c +
                    (tempStoreCode[index]
                        ? tempStoreCode[index]
                        : String.fromCharCode(Math.floor(Math.random() * 26) + 97).toUpperCase());
            });
            return (output + dif).toUpperCase();
        };

        let startedPromise = null;
        meetingHub.connect = () => {
            //IF I AM CONNECTED ALREADY, DO NOTHING
            if (!meetingHub.connected && connection._connectionState === "Disconnected") {
                console.log("INIT HUB CONNECTION");
                startedPromise = connection
                    .start()
                    .then(() => {
                        app.config.globalProperties.$meetingHub.connected = true;
                    })
                    .catch(err => {
                        console.error("Failed to connect with hub", err);
                        return new Promise((resolve, reject) =>
                            setTimeout(() => meetingHub.connect().then(resolve).catch(reject), 5000)
                        );
                    });
            }
            return startedPromise;
        };

        meetingHub.startConnection = code => {
            console.log("*** meetingHub - startConnection", meetingHub.code, code);
            console.log("*** meetingHub - meetingHub.connected", meetingHub.connected);
            console.log("!!!! connection._connectionState", connection._connectionState);

            meetingHub.code = code;
            return meetingHub.connect();
        };

        meetingHub.stopConnection = async message => {
            meetingHub.manuallyClosed = true;
            console.log("disconnected. Reason:", message);
            console.log("*** meetingHub - stopConnection", meetingHub.code);
            if (meetingHub.connected && connection._connectionState !== "Disconnected") {
                return await connection
                    .stop()
                    .then(() => {
                        meetingHub.connected = false;
                        meetingHub.manuallyClosed = false;
                        meetingHub.code = "";
                        meetingHub.myConnectionId = "";
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        };

        meetingHub.fimanagerStopConnection = async message => {
            meetingHub.manuallyClosed = true;
            console.log("disconnected. Reason:", message);
            console.log("*** meetingHub - stopConnection", meetingHub.code);
            if (meetingHub.connected && connection._connectionState !== "Disconnected") {
                return await connection
                    .stop()
                    .then(() => {
                        meetingHub.connected = false;
                        meetingHub.manuallyClosed = false;
                        meetingHub.code = "";
                        meetingHub.myConnectionId = "";
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        };

        //  General Invoke Functions
        meetingHub.sendScrollEvent = scrollData => {
            util.debounce(meetingHub.sendScrollEventDebounced, 100, scrollData);
        };

        meetingHub.sendScrollEventDebounced = scrollData => {
            console.log("SENDSCROLLEVENTDEBOUNCED", meetingHub.code, scrollData);
            return startedPromise
                .then(() => connection.invoke('SendScrollEvent', meetingHub.code, scrollData))
                .catch((err) => {
                    console.error(err)
                })
        }

        meetingHub.joinMeetingRoom = (
            role,
            isInStoreConnection = true,
            fimenuDealId = null,
            firstName = null,
            lastName = null,
            fullName = null,
        ) => {
            console.log("joining ", meetingHub.code, " role: ", role, " fimenuDealId: ", fimenuDealId);
            console.log("meetingHub.code :", meetingHub.code);
            return startedPromise
                .then(() =>
                    connection.invoke(
                        "JoinMeetingRoom",
                        meetingHub.code,
                        role,
                        isInStoreConnection,
                        fimenuDealId,
                        firstName,
                        lastName,
                        fullName,
                    ),
                )
                .catch(error => console.error(error));
        };

        meetingHub.allowUserIntoMeeting = meetingUser => {
            console.log("allowUserIntoMeeting ", meetingUser);
            return startedPromise
                .then(() => connection.invoke("AllowUserIntoMeeting", meetingHub.code, meetingUser))
                .catch(console.error);
        };

        meetingHub.inStorePreConnection = (role, newSecureRoom) => {
            console.log("*** meetingHub - inStorePreConnection - ", role, meetingHub.code);
            console.log("*** meetingHub - meetingHub.connected", meetingHub.connected);
            console.log("*** meetingHub - newSecureRoom", newSecureRoom);
            return startedPromise
                .then(() => connection.invoke("InStorePreConnection", meetingHub.code, role, newSecureRoom))
                .catch(console.error);
        };
        meetingHub.leavePreConnectionRoom = (newSecureRoom) => {
            console.log("*** meetingHub - leavePreConnectionRoom");
            console.log("newSecureRoom => ", newSecureRoom, ", MeetingHubCode => ", meetingHub.code);
            let oldCode = meetingHub.code;
            console.log('oldCode: ', typeof oldCode);

            meetingHub.code = newSecureRoom;
            console.log('meetingHub.code: ', meetingHub.code);
            return startedPromise
                .then(() => connection.invoke("LeavePreConnectionRoom", oldCode))
                .catch(console.error);
        };

        //FIManager Invoke Functions
        meetingHub.sendActionToCustomer = (customerAction, fimenu) => {
            return startedPromise
                .then(() => connection.invoke("SendActionToCustomer", meetingHub.code, customerAction, fimenu))
                .catch(console.error);
        };

        meetingHub.saveFIMenu = () => {
            return startedPromise
                .then(() => connection.invoke('SaveFIMenu', meetingHub.code))
                .catch(console.error)
        }

        meetingHub.openPaymentSheet = () => {
            return startedPromise
                .then(() => connection.invoke("OpenPaymentSheet", meetingHub.code))
                .catch(console.error);
        };

        meetingHub.openPaperworkScreen = () => {
            return startedPromise
                .then(() => connection.invoke("OpenPaperworkScreen", meetingHub.code))
                .catch(console.error);
        };

        meetingHub.changeFimenuLanguage = language => {
            return startedPromise
                .then(() => connection.invoke("ChangeFimenuLanguage", meetingHub.code, language))
                .catch(console.error);
        };

        meetingHub.fimanagerAlreadyConnected = fimenu => {
            return startedPromise
                .then(() => connection.invoke("FIManagerAlreadyConnected", meetingHub.code, fimenu))
                .catch(console.error);
        };
        meetingHub.sendDownloadedDocument = doc => {
            return startedPromise
                .then(() => connection.invoke("SendDownloadedDocument", meetingHub.code, doc))
                .catch(err => {
                    console.error(err);
                    console.log("No doc data received");
                });
        };
        meetingHub.disconnectParticipant = (userId, shouldMeetingEnd = false) => {
            console.log("DisconnectParticipant", meetingHub.code, userId);
            return startedPromise
                .then(() => connection.invoke("DisconnectParticipant", meetingHub.code, userId, shouldMeetingEnd))
                .catch(console.error);
        };
        meetingHub.reconnectParticipant = (
            role,
            isInStoreConnection = true,
            fimenuDealId = null,
            firstName = null,
            lastName = null
        ) => {
            console.log("reconnectParticipant", meetingHub.code, role, isInStoreConnection, fimenuDealId, firstName, lastName);
            return startedPromise
                .then(() =>
                    connection.invoke(
                        "ReconnectParticipant",
                        meetingHub.code,
                        role,
                        isInStoreConnection,
                        fimenuDealId,
                        firstName,
                        lastName
                    )
                )
                .catch(console.error);
        };
        meetingHub.sendParticipantToLobby = (userId, shouldMeetingEnd = null) => {
            console.log("SendParticipantToLobby", meetingHub.code, userId);
            return startedPromise
                .then(() => connection.invoke("SendParticipantToLobby", meetingHub.code, userId, shouldMeetingEnd))
                .catch(console.error);
        };
        meetingHub.adjustCustomerVideoVolume = volumeLevel => {
            console.log("adjustCustomerVideoVolume", meetingHub.code, volumeLevel);
            return startedPromise
                .then(() => connection.invoke("AdjustCustomerVideoVolume", meetingHub.code, volumeLevel))
                .catch(console.error);
        };

        //Customer Invoke Functions
        meetingHub.customerAlreadyConnected = role => {
            return startedPromise
                .then(() => connection.invoke("customerAlreadyConnected", meetingHub.code, role))
                .catch(console.error);
        };

        meetingHub.saveCustomerViewed = (type, moment, videoId) => {
            return startedPromise
                .then(() => connection.invoke("SaveCustomerViewed", meetingHub.code, type, moment, videoId))
                .catch(console.error);
        };

        meetingHub.setPreferredCoverage = term => {
            return startedPromise
                .then(() => connection.invoke("SetPreferredCoverage", meetingHub.code, term))
                .catch(console.error);
        };

        meetingHub.sendCustomerVideoDataToSave = videoData => {
            return startedPromise
                .then(() => connection.invoke("SendCustomerVideoDataToSave", meetingHub.code, videoData))
                .catch(console.error);
        };
        meetingHub.shouldCustomerGoToPaymentSheet = () => {
            return startedPromise
                .then(() => connection.invoke("ShouldCustomerGoToPaymentSheet", meetingHub.code))
                .catch(console.error);
        };
        meetingHub.setLoanDaysToPayment = days => {
            return startedPromise
                .then(() => connection.invoke("SetLoanDaysToPayment", meetingHub.code, days))
                .catch(console.error);
        };

        meetingHub.setDraftLoanTerms = (term, sellRate, buyRate) => {
            return startedPromise
                .then(() => connection.invoke("SetDraftLoanTerms", meetingHub.code, term, sellRate, buyRate))
                .catch(console.error);
        };

        meetingHub.saveCustomerFImenu = fimenu => {
            return startedPromise
                .then(() => connection.invoke("SaveCustomerFImenu", meetingHub.code, fimenu))
                .catch(console.error);
        };

        async function resendMessageHandler(callback, time, retries = 3) {
            console.log('resendingMessage retry attempt: ', retries);
            if (retries <= 0) {
                console.warn('Retry limit reached');
                meetingHub.isRetryingToSendMsg = false;
                return;
            }
        
            let timeout = null;
        
            const waitForRetry = () => new Promise(resolve => {
                timeout = setTimeout(resolve, time);
            });
        
            try {
                await waitForRetry();
                const success = await callback(); // Expect callback to return true/false
                if (success) {
                    console.log("Message sent successfully, exiting retries.");
                    return; // Exit retries on success
                }
            } finally {
                clearTimeout(timeout);
            }
        
            // Exponential backoff
            // const nextDelay = time * 2;
            const nextDelay = time;

            console.log(`Retrying in ${nextDelay}ms...`);
        
            // Update retries by decrementing its value
            await resendMessageHandler(callback, nextDelay, retries - 1); // Reduce retries count
        }

        meetingHub.paperworkSigned = async (pdfIndex, document, signer = null) => {
            const docInfo = {
                overlays: document.overlays,
                status: document.status,
                signatureInfo: document.signatureInfo,
                description: document.description,
            };
        
            console.log("Attempt to invoke.", "pdfIndex:", pdfIndex, "Document:", docInfo, "Signer:", signer, "MeetinghubCode:", meetingHub.code);
        
            try {
                await startedPromise;
                await connection.invoke("PaperworkSigned", {
                    ...docInfo,
                    meetingRoomCode: meetingHub.code,
                    pdfIndex,
                    signer,
                });
                return true;
            } catch (err) {
                LogRocket.track('Paperwork Signed Error');
                console.error('PAPER WORK SIGNED ERROR doc:', document.description, err);
        
                if (!meetingHub.isRetryingToSendMsg) {
                    meetingHub.isRetryingToSendMsg = true;
                    app.config.globalProperties.MeetingHubBus.emit('openResendMessageModal');
                    // Await the first call to ensure retries are handled sequentially
                    await resendMessageHandler(async() => await meetingHub.paperworkSigned(pdfIndex, document, signer), 2000, 1000);
                    meetingHub.isRetryingToSendMsg = false;
                    return false;
                } else {
                    console.log('Retry already in progress, skipping redundant call.');
                }
            }
        };

        meetingHub.requestMissingSignedDocuments = async(missingPdfList) => {
            await connection.invoke("RequestMissingSignedDocuments", meetingHub.code, missingPdfList);
        }
        
        meetingHub.finishAllPaperwork = (fimenu) => {
            return startedPromise
                .then(() => connection.invoke("FinishAllPaperwork", meetingHub.code, fimenu))
                .catch(console.error);
        };
        meetingHub.customerDisconnect = () => {
            return startedPromise
                .then(() => connection.invoke("CustomerDisconnect", meetingHub.code))
                .catch(console.error);
        };
        // meetingHub.checkIfMeetingStillActive = (code) => {
        //     return startedPromise
        //         .then(() => connection.invoke('CheckIfMeetingStillActive', code))
        //         .catch(console.error)
        // }
        meetingHub.sendHTMLFromCustomer = customerScreenData => {
            customerScreenData.meetingRoomCode = meetingHub.code;

            return startedPromise
                .then(() => connection.invoke("SendHTMLFromCustomer", customerScreenData))
                .catch(console.error);
        };

        meetingHub.requestToBePresenter = userId => {
            return startedPromise
                .then(() => connection.invoke("RequestToBePresenter", meetingHub.code, userId))
                .catch(console.error);
        };

        meetingHub.grantRequestToBePresenter = userId => {
            return startedPromise
                .then(() => connection.invoke("GrantRequestToBePresenter", meetingHub.code, userId))
                .catch(console.error);
        };

        meetingHub.updateMutedStatus = (userId, isMuted) => {
            return startedPromise
                .then(() => connection.invoke("UpdateMutedStatus", meetingHub.code, userId, isMuted))
                .catch(console.error);
        };

        meetingHub.sendPlayVideoFromCustomer = time => {
            return startedPromise
                .then(() => connection.invoke("sendPlayVideoFromCustomer", meetingHub.code, time))
                .catch(console.error);
        };

        meetingHub.sendPauseVideoFromCustomer = () => {
            return startedPromise
                .then(() => connection.invoke("SendPauseVideoFromCustomer", meetingHub.code))
                .catch(console.error);
        };

        meetingHub.sendShowLastFrameVideo = () => {
            return startedPromise
                .then(() => connection.invoke("SendShowLastFrameVideo", meetingHub.code))
                .catch(console.error);
        };

        meetingHub.requestDownloadDocument = path => {
            return startedPromise
                .then(() => connection.invoke("RequestDownloadDocument", meetingHub.code, path))
                .catch(console.error);
        };

        meetingHub.sendClientSignatureInitials = (who, signature, initials) => {
            console.log(
                "Attempt to invoke. ",
                "who: ",
                who,
                "signature: ",
                !!signature,
                "initials: ",
                !!initials,
                "MeetinghubCode: ",
                meetingHub.code
            );
            return startedPromise
                .then(() =>
                    connection.invoke("SendClientSignatureInitials", {
                        meetingRoomCode: meetingHub.code,
                        who,
                        signature,
                        initials,
                    })
                )
                .catch(err => {
                    console.error(err);
                })
        }

        meetingHub.sendDocumentIsOpen = (docIndex, isOpen) => {
            return startedPromise
                .then(() => connection.invoke("SendDocumentIsOpen", meetingHub.code, docIndex, isOpen))
                .catch(console.error);
        };

        meetingHub.saveScreenRecordEndTime = endTime => {
            return startedPromise
                .then(() => connection.invoke("SaveScreenRecordEndTime", meetingHub.code, endTime))
                .catch(console.error);
        };

        meetingHub.saveLatestTimestamp = latestTimeStamp => {
            return startedPromise
                .then(() => connection.invoke("SaveLatestTimestamp", meetingHub.code, latestTimeStamp))
                .catch(console.error);
        };

        meetingHub.saveVideoChunk = videoData => {
            return startedPromise
                .then(() => connection.invoke("SaveVideoChunk", meetingHub.code, videoData))
                .catch(console.error);
        };

        //Cash Bash
        meetingHub.adminCashBashReadyToStart = () => {
            return meetingHub
                .startConnection()
                .then(() => connection.invoke("AdminCashBashReadyToStart"))
                .catch(console.error);
        };
        meetingHub.gameCashBashReadyToStart = playerConnectedCode => {
            return meetingHub
                .startConnection()
                .then(() => connection.invoke("GameCashBashReadyToStart", playerConnectedCode))
                .catch(console.error);
        };
        meetingHub.gameCashBashLeave = playerConnectedCode => {
            return meetingHub
                .startConnection()
                .then(() => connection.invoke("GameCashBashLeave", playerConnectedCode))
                .catch(console.error);
        };
        meetingHub.adminCashBashLeave = () => {
            return meetingHub
                .startConnection()
                .then(() => connection.invoke("AdminCashBashLeave"))
                .catch(console.error);
        };

        meetingHub.gameCashBashConnected = () => {
            return meetingHub
                .startConnection()
                .then(() => connection.invoke("GameCashBashConnected"))
                .catch(console.error);
        };
        meetingHub.gameAlreadyReady = () => {
            return startedPromise.then(() => connection.invoke("GameAlreadyReady")).catch(console.error);
        };
        meetingHub.adminSelectActivePlayer = playerId => {
            return startedPromise
                .then(() => connection.invoke("AdminSelectActivePlayer", playerId))
                .catch(console.error);
        };
        meetingHub.gameSelectCard = cardObject => {
            return startedPromise.then(() => connection.invoke("GameSelectCard", cardObject)).catch(console.error);
        };
        meetingHub.gameChangePage = page => {
            return startedPromise.then(() => connection.invoke("GameChangePage", page)).catch(console.error);
        };
        meetingHub.adminSelectCardOk = cardObject => {
            return startedPromise.then(() => connection.invoke("AdminSelectCardOk", cardObject)).catch(console.error);
        };
        meetingHub.gameUpdateActivePlayerInfo = JSONStringPlayer => {
            return startedPromise
                .then(() => connection.invoke("GameUpdateActivePlayerInfo", JSONStringPlayer))
                .catch(console.error);
        };
        meetingHub.adminUpdateBashInfo = JSONStringBash => {
            return startedPromise
                .then(() => connection.invoke("AdminUpdateBashInfo", JSONStringBash))
                .catch(console.error);
        };

        //Stream Video Functions
        meetingHub.videoDataSendToServer = blob => {
            return startedPromise
                .then(() => connection.invoke("VideoDataReceive", meetingHub.code, blob))
                .catch(console.error);
        };

        // Video Chat Room
        meetingHub.joinVideoChatRoom = () => {
            return startedPromise
                .then(() => connection.invoke("JoinVideoChatRoom", meetingHub.code))
                .catch(console.error);
        };

        meetingHub.sendingSignal = signal => {
            return startedPromise
                .then(() => connection.invoke("SendingSignal", meetingHub.code, signal))
                .catch(console.error);
        };
        meetingHub.requestDirectConnectionSignal = signal => {
            return startedPromise
                .then(() => connection.invoke("RequestDirectConnectionSignal", meetingHub.code, signal))
                .catch(console.error);
        };
        meetingHub.acceptedInitiatorSignal = signal => {
            return startedPromise
                .then(() => connection.invoke("AcceptedInitiatorSignal", meetingHub.code, signal))
                .catch(console.error);
        };

        meetingHub.returningSignal = signal => {
            return startedPromise
                .then(() => connection.invoke("ReturningSignal", meetingHub.code, signal))
                .catch(console.error);
        };

        meetingHub.subscribeToGroupEvent = (eventName, groupId, callback) => {
            //ON EVENT BROADCAST, WE CALL THE callback METHOD
            connection.on(eventName, data => {
                if (data) callback(data);
                else callback();
            });

            return meetingHub
                .connect()
                .then(() => connection.invoke("JoinGroup", groupId, eventName))
                .catch(console.error);
        };

        meetingHub.unsubscribeToGroupEvent = (eventName) => {
            connection.off(eventName);
        };

        meetingHub.unsubscribeToGroupEvents = groupId => {
            return meetingHub
                .connect()
                .then(() => connection.invoke("LeaveGroup", groupId))
                .catch(console.error);
        };

        meetingHub.broadcastGroupEvent = (eventName, groupId, message) => {
            return meetingHub
                .connect()
                .then(() => connection.invoke("BroadcastGroupEvent", eventName, groupId, message))
                .catch(console.error);
        };
    },
};
