<template>
    <section>
        <fieldset :disabled="props.isLocked" :class="{'wizard-content': true, 'scroll-bar-margins': !props.disableScrollBarMargins}" ref="wizardContent" :key="lockConditions.updateKey">
            <slot></slot>
        </fieldset>

        <slot v-if="props.slotName" :name="props.slotName"></slot>

        <div v-else-if="props.hideContinue" class="wizard-buttons" />

        <div v-else class="wizard-buttons">
            <FIMenuWizardMessage :actualStep="props.actualStep" :fimenu="fimenu" />
            <i v-if="props.continueBusy" class="fas fa-cog fa-spin fa-2x" />
            <button :disabled="props.continueDisabled || props.continueBusy"
                    class="button-save"
                    :class="props.continueClass"
                    @click="emit('continue')">
                {{ finalContinueText }}
            </button>
        </div>
    </section>
</template>
<script setup lang="ts">
    import { computed, inject, onBeforeMount, onBeforeUnmount, onMounted, provide, ref, useTemplateRef, watch } from 'vue';
    import FIMenu from '@core/classes/FIMenu';
    import FIMenuWizardMessage from '@/components/FIMenuWizardMessages.vue';
    import util from '@core/services/util';

    interface IFIMenuWizardContentProps {
        fimenu: FIMenu;
        hideContinue?: boolean;
        disableScrollBarMargins?: boolean;
        continueBusy?: boolean;
        continueDisabled?: boolean;
        continueClass?: string | { [cssClass: string]: boolean };
        continueText?: string;
        slotName?: string;
        actualStep?: string;
        isLocked?: boolean;
    }

    const props = withDefaults(defineProps<IFIMenuWizardContentProps>(), { continueText: 'Continue' });
    const EventBus = inject<any>('EventBus');
    const emit = defineEmits(['continue']);

    const wizardContentRef = useTemplateRef<HTMLFieldSetElement>('wizardContent');

    const domObserver = ref<MutationObserver>(null);

    const innerContentBusy = ref(false);

    watch(innerContentBusy, (newVal) => {
        if (!newVal)
            util.debounce(checkAllButtons, 100);
    });

    const finalContinueText = computed((): string => {
        return props.continueText;
    });

    const isDealLocked = computed((): boolean => {
        return props.fimenu.isDealLocked();
    });

    // ===============
    // PROVIDED VALUES
    // ===============
    const lockConditions = ref({
        isDealLocked: false,
        updateKey: 0,
        canIgnoreDisable: (elementRef: HTMLElement) => canIgnoreDisable(elementRef),
        isDisabled: (elementRef: HTMLElement) => isDisabled(elementRef)
    });

    provide('fiMenuFieldLocks', lockConditions);

    // ===============
    // LIFECYCLE HOOKS
    // ===============
    onBeforeMount(() => {
        domObserver.value = new MutationObserver(onDomUpdate);
    });

    onMounted(() => {
        if (props.fimenu.isDealLocked()) {
            startDomWatcher();
            util.debounce(checkAllButtons, 100);
        }

        EventBus.on('panelFimenuBusy', updateInnerContentBusy);

        watch(isDealLocked, (newVal: boolean) => {
            lockConditions.value.isDealLocked = newVal;

            if (newVal) {
                startDomWatcher();
                util.debounce(checkAllButtons, 100);
            }
            else {
                stopDomWatcher();
                lockConditions.value.updateKey++; // Force a re-render
            }
        });

        watch(() => props.fimenu.isSpectator, (newVal: boolean) => {
            if (newVal) {
                util.debounce(checkAllButtons, 100);
                lockConditions.value.isDealLocked = newVal;
            }
            
            lockConditions.value.updateKey++;
        });
    });

    onBeforeUnmount(() => {
        stopDomWatcher();
    });
    
    // ==============
    // HELPER METHODS
    // ==============
    const updateInnerContentBusy = (newValue: boolean) => {
        innerContentBusy.value = newValue;
    };

    const startDomWatcher = () => {
        if (!wizardContentRef.value) return;
        domObserver.value.observe(wizardContentRef.value, { subtree: true, attributes: true, childList: true });
    };

    const stopDomWatcher = () => {
        domObserver.value.disconnect();
    };

    const onDomUpdate = (mutationList: MutationRecord[]) => {
        // Ignore if mutation isn't a DOM insertion/deletion or if mutation isn't on a button
        if (!mutationList.some(m => (m.target as HTMLElement).tagName === 'BUTTON' || m.type === 'childList')) return;
        util.debounce(checkAllButtons, 100);
    };

    const checkAllButtons = () => {
        const allButtons = wizardContentRef.value?.getElementsByTagName('button');
        if (!allButtons) return;

        for (let i = 0; i < allButtons.length; i++) {
            if (isDisabled(allButtons[i]) && !allButtons[i].disabled) {
                allButtons[i].disabled = true;
            }
        }
    };

    const canIgnoreDisable = (elementRef: HTMLElement) => {
        if (!elementRef) return false;

        let ignoreClass;
        if (!props.fimenu.isSpectator)
            ignoreClass = '.ignore-deal-lock';
        else if (!props.fimenu.getSelectedCoverageTerm() && !props.fimenu.hasPaperworkPacket())
            ignoreClass = '.ignore-spectator-lock';

        const universalIgnore = '.ignore-all-locks';
        const ignoreSelector = (ignoreClass) ? `${ignoreClass}, ${universalIgnore}` : universalIgnore;

        return !!elementRef.closest(ignoreSelector);
    };

    const isDisabled = (elementRef: HTMLElement) => {
        const canIgnore = canIgnoreDisable(elementRef);
        return isDealLocked.value && !canIgnore;
    };
</script>